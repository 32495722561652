import { Answer } from "./types"
import { UUID } from "../types"
import {
  AnswerActionTypes,
  REMOVE_ANSWER,
  ADD_ANSWER,
  CHANGE_ANSWER,
} from "../../actions/answers"
import {
  NEW_ADVISOR_SUCCESS,
  AdvisorActionTypes,
  FETCH_ADVISOR_SUCCESS,
}from "~/actions/advisors"

type AnswerState = Record<UUID, Answer>

const defaultState = {}

export default (
  state: AnswerState = defaultState,
  action: AnswerActionTypes | AdvisorActionTypes
): AnswerState => {
  switch (action.type) {
    case FETCH_ADVISOR_SUCCESS:
    case NEW_ADVISOR_SUCCESS: {
      const { answers } = action.payload
      return answers.reduce((acc, q) => ({ ...acc, [q.id]: q }), state)
    }

    case CHANGE_ANSWER: {
      const { id, title, helpText, customLabel } = action.payload
      const target = state[id]

      return {
        ...state,
        [id]: {
          ...target,
          title: title,
          helpText: helpText,
          matchingFeatureLabel: customLabel,
        },
      }
    }

    case ADD_ANSWER: {
      const { id, title, helpText, customLabel, isNeutralAnswer } =
        action.payload

      return {
        ...state,
        [id]: {
          id: id,
          title: title,
          helpText: helpText,
          matchingFeatureLabel: customLabel,
          isNeutralAnswer: isNeutralAnswer,
        },
      }
    }

    case REMOVE_ANSWER: {
      const { id } = action.payload
      const newState = { ...state }
      delete newState[id]

      return newState
    }

    default:
      return state
  }
}
