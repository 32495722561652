import { ApplicationState } from ".."
import { Question } from "./types"
import sortBy from "lodash/sortBy"
import { getInfoScreens }from "~/store/screens/selectors"

export const getQuestion = (
  state: ApplicationState,
  questionId: string
): Question | undefined => state.questions[questionId]

export const getQuestions = (
  state: ApplicationState,
  questionIds: string[]
): Question[] =>
  questionIds.map((q) => state.questions[q]).filter((q) => q !== undefined)

export const getQuestionsWithRules = (
  state: ApplicationState,
  advisorId: string
) => {
  const flow = state.flow[advisorId]
  const s = state.advisors[advisorId]
  if (!s || !flow) return []
  const changes = flow.proposedChanges

  return s.questions
    .map((qId) => state.questions[qId])
    .filter<Question>((q): q is Question => !!q)
    .map((q) => {
      return {
        ...q,
        next: changes[q.id] || flow?.nodes[q.id],
        rules: q.rules
          .map((rId) => state.rules[rId])
          .filter((a) => !!a)
          .map((rule) => {
            return {
              ...rule,
              targetQuestionId:
                changes[rule.id] ||
                flow?.rules.find((r) => r.ruleId === rule.id)?.target,
            }
          }),
      }
    })
}

export const getFirstQuestion = (
  state: ApplicationState,
  advisorId: string
): string => {
  const adv = state.advisors[advisorId]
  if (!adv) return "ADVICE"

  return state.flow[advisorId]?.nodes["START"] || "ADVICE"

  const questions = getQuestionsWithRules(state, advisorId)
  const infoScreens = getInfoScreens(state, advisorId)
  const outgoing = new Set([
    ...questions.flatMap((question) => {
      return [
        question.next,
        ...question.rules.map((r) => r.targetQuestionId),
      ].filter<string>((id): id is string => !!id)
    }, {}),
    ...infoScreens
      .map((c) => c.next)
      .filter<string>((id): id is string => !!id),
  ])

  const questionsWithoutInwardVertex = sortBy(
    [
      ...questions.filter((q) => !outgoing.has(q.id)),
      ...infoScreens.filter((c) => !outgoing.has(c.id)),
    ],
    ["id"]
  )
  return questionsWithoutInwardVertex[0]?.id || "ADVICE"
}
