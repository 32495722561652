import React, { useState } from "react"
import styled from "styled-components"
import LoginForm from "./LoginForm"
import { useLocation, Switch, Route } from "react-router"
import { useTransition, animated, useSpring } from "react-spring"
import useMeasure from "react-use-measure"
import { useAuth } from "~/context/auth"
import ForgotPasswordForm from "./ForgotPassword"
import ResetPasswordForm from "./ResetPassword"
import { useTranslation } from "react-i18next"
import HolidayNotice from "~/components/HolidayNotice"

const LoginContainer = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr;
  @media screen and (min-width: 60rem) {
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
  }
  & > div {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
  }

  a,
  a:visited {
    color: ${(p) => p.theme.secondary};
    text-decoration: underline;
  }
`

const Right = styled.div`
  background: ${(p) => p.theme.backgroundShade};
  @media screen and (min-width: 60rem) {
    /* height: 100vh; */
    overflow: hidden;
    overflow-y: auto;
  }
`

const LoginBox = styled(animated.div)`
  flex-grow: 0;
  position: relative;
  box-sizing: border-box;
  max-width: 24rem;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(155, 163, 161, 0.11),
    0 2px 2px rgba(155, 163, 161, 0.11), 0 4px 4px rgba(155, 163, 161, 0.11),
    0 6px 8px rgba(155, 163, 161, 0.11), 0 8px 16px rgba(155, 163, 161, 0.11);
  border-radius: ${(p) => p.theme.borderRadius};
  background-color: ${(props) => props.theme.background};
  & > div {
    padding: 2rem;
    min-height: 100px;
    position: absolute;
    width: 100%;
  }
`

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  a {
    margin-right: 0.5rem;
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }
`

const UpdatesContainer = styled.div`
  width: 100%;
  height: 100vh;
  /* height: 100dvh; */
  overflow: hidden;
  /* max-width: 40rem; */
  /* padding: 0 1rem 3rem; */
  /* margin: 1rem auto; */
  /* display: flex; */
  /* text-align: center; */
  display: grid;
  grid-template-rows: min-content 1fr;

  iframe {
    border: none;
    height: 100%;
  }
`

const Login = () => {
  const location = useLocation()
  const [innerRef, { height = 0 }] = useMeasure()
  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  const props = useSpring({ height: height })
  const [email, setEmail] = useState("")
  const { t } = useTranslation()

  const { login, forgotPassword, resetPassword } = useAuth()

  const navLinks = [
    { href: "https://aiden.cx/support", content: t("helpSupport") },
    { href: "https://aiden.cx/release-notes", content: t("releaseNotes") },
    { href: "https://aiden.instatus.com", content: t("status") },
  ]

  return (
    <LoginContainer>
      <div>
        <LoginBox style={props}>
          {transitions.map(({ item: location, props, key }) => (
            <animated.div key={key} style={props} ref={innerRef}>
              <Switch location={location}>
                <Route
                  path="/login"
                  exact
                  render={() => (
                    <>
                      <LoginForm
                        login={login}
                        email={email}
                        setEmail={setEmail}
                      />
                    </>
                  )}
                />
                <Route
                  path="/login/forgot-password"
                  exact
                  render={() => (
                    <ForgotPasswordForm
                      forgot={forgotPassword}
                      email={email}
                      setEmail={setEmail}
                    />
                  )}
                />
                <Route
                  path="/login/reset-password"
                  exact
                  render={() => (
                    <ResetPasswordForm
                      reset={resetPassword}
                      email={email}
                      setEmail={setEmail}
                    />
                  )}
                />
              </Switch>
            </animated.div>
          ))}
        </LoginBox>
      </div>
      <Right style={{ padding: 0 }}>
        <UpdatesContainer className="right-side-container">
          <Nav>
            {navLinks.map((link, i) => (
              <a
                key={i}
                href={link.href}
                rel="noopener noreferrer"
                target="_blank"
              >
                {link.content}
              </a>
            ))}
          </Nav>
          <iframe
            src="https://aiden.cx/updates-on-app-aiden-cx"
            width="100%"
            title="Aiden updates"
            allowTransparency={true}
          ></iframe>
        </UpdatesContainer>
      </Right>
    </LoginContainer>
  )
}

export default Login
