import React from "react"
import Select, { SelectProps, ListOption } from "./Select"
import SelectionUI from "./SelectionUI"

export type SingleSelectProps = {
  value: any
  emptyText?: React.ReactNode
  inline?: boolean
  small?: boolean
  selectionElement?: (open: boolean) => React.ReactNode
  onSelect: (value: any) => void
  listElement?: (opt: ListOption) => React.ReactNode
  testId?: string
  disabled?: boolean
  hasError?: boolean
} & Pick<SelectProps, "options" | "position" | "testId">

const SingleSelect: React.FC<SingleSelectProps> = (props) => {
  const onSelect = (selected: any, setOpen: (open: boolean) => void) => {
    if (props.value !== selected) props.onSelect(selected)
    setOpen(false)
  }

  const currentValue = props.options.find((opt) => opt.value === props.value)

  return (
    <Select
      disabled={props.disabled}
      selectionElement={(open) =>
        props.selectionElement ? (
          props.selectionElement(open)
        ) : (
          <SelectionUI.SelectedValue small={props.small} inline={props.inline}>
            {currentValue?.label || props.emptyText}
            <SelectionUI.Chevron isOpen={open} />
          </SelectionUI.SelectedValue>
        )
      }
      listElement={
        props.listElement ||
        ((opt) => (
          <SelectionUI.SelectOption>{opt.label}</SelectionUI.SelectOption>
        ))
      }
      options={props.options}
      value={props.value}
      position={props.position}
      inline={!!props.inline}
      onSelect={onSelect}
      testId={props.testId}
      hasError={props.hasError}
    />
  )
}

export default SingleSelect
