// @ts-ignore
import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import { EmbedConfig } from "../store/advisors"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"

const EmbedCodeBuilder: React.FC<{
  apiUrl: string
  authtoken: string
  advisorId: string
  locale: string
  webshopUrl: string | undefined
  background: string | undefined
  buttonRadius: string | undefined
  buttonPaddingVertical: string | undefined
  buttonPaddingHorizontal: string | undefined
  textColor: string | undefined
  embedConfig: EmbedConfig | undefined
}> = (props) => {
  const { featureFlags } = useFeatureFlags()
  function setupPorts(ports) {
    ports.copyEmbedCode.subscribe(function (target) {
      const el = document.getElementById(target)

      if (el && el.textContent) {
        navigator.clipboard
          .writeText(el.textContent)
          .then(() => ports.embedCodeCopied.send(true))
          .catch((err) => ports.embedCodeCopied.send(false))
      }
    })
  }

  return (
    // @ts-ignore
    <Elm
      src={ElmModule.EmbedCodeBuilder}
      flags={{
        advisorId: props.advisorId,
        authToken: props.authtoken,
        apiUrl: props.apiUrl,
        advisorLocale: props.locale,
        webshopUrl: props.webshopUrl || "",
        background: props.background || "",
        buttonRadius: props.buttonRadius || "",
        buttonPaddingVertical: props.buttonPaddingVertical || "",
        buttonPaddingHorizontal: props.buttonPaddingHorizontal || "",
        textColor: props.textColor || "",
        embedConfig: props.embedConfig || {},
        pdpIntegrationEnabled: featureFlags.pdpIntegrationEnabled || false,
      }}
      ports={setupPorts}
    />
  )
}

export default EmbedCodeBuilder
