import React, { useState, useEffect, useCallback } from "react"
import FieldGroup from "~/components/forms/FieldGroup"
import FormSection from "~/components/forms/FormSection"
import Form from "~/components/forms/Form"
import Button from "~/components/Button"
import LoadingSpinner from "~/components/Loading"
import { Language } from "~/i18n"
import { useTranslation } from "react-i18next"
import HelpText from "~/components/HelpText"
import Field from "~/components/forms/Field"
import { useAuth } from "~/context/auth"
import organisations from "~/api/organisations"
import { useDispatch } from "react-redux"
import { organisationNameChanged } from "~/actions/organisations"
import { useIntercom } from "react-use-intercom"
import useCurrentUser from "../../hooks/useCurrentUser"
import FormHeader from "../forms/FormHeader"

const Preferences = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { token, organisationId } = useAuth()
  const [name, setName] = useState("")
  // const [language, setLanguage] = useState(Language.americanEnglish)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(false)
  const { update } = useIntercom()
  const { currentUser } = useCurrentUser()

  const save = useCallback(() => {
    let isMounted = true
    setError(false)
    setSaving(true)

    organisations
      .update(token!, organisationId, {
        name,
        language: Language.americanEnglish,
      })
      .then((org) => {
        dispatch(organisationNameChanged(org.name))
        update({
          userId: currentUser!.id,
          company: {
            companyId: organisationId.orgId,
            name: name,
          },
        })
      })
      .catch(() => {
        if (isMounted) setError(true)
      })
      .finally(() => {
        if (isMounted) setSaving(false)
      })

    return () => {
      isMounted = false
    }
  }, [dispatch, name, organisationId, token, update, currentUser])

  useEffect(() => {
    let isMounted = true
    if (token) {
      organisations
        .get(token, organisationId)
        .then((org) => {
          if (isMounted) {
            setName(org.name)
            // setLanguage(org.language)
          }
        })
        .catch((_) => {
          if (isMounted) setError(true)
        })
        .finally(() => {
          if (isMounted) setLoading(false)
        })
    }
    return () => {
      isMounted = false
    }
  }, [organisationId, token])

  const invalidName = name === ""

  return (
    <Form
      onSubmit={save}
      actions={
        !loading && (
          <Button isLoading={saving} primary>
            {saving ? <LoadingSpinner size="small" /> : "Save"}
          </Button>
        )
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FormSection>
            <FormHeader>{t("settings.companySettings.navLink")}</FormHeader>

            <FieldGroup>
              <div>
                <Field
                  label={t("settings.companySettings.name")}
                  onChange={setName}
                  value={name}
                  hasError={invalidName}
                />
                {invalidName && (
                  <HelpText hasError>
                    {t("settings.companySettings.validation.nameEmpty")}
                  </HelpText>
                )}
              </div>
            </FieldGroup>
          </FormSection>
          {error && <HelpText hasError>{t("errors.error")}</HelpText>}
        </>
      )}
    </Form>
  )
}

export default Preferences
