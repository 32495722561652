import { UUID }from "~/store/types"
import { RequestStatusActionTypes }from "~/actions/requestStatus"
import {
  START_REQUEST,
  REQUEST_SUCCESS,
  REQUEST_FAILURE,
}from "~/actions/requestStatus/constants"
import { ApiError } from "~/api/APIBase"

export enum Status {
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

export type ReqStatus = { type: Status; message?: string; resp?: ApiError }

type RequestStatusState = Record<UUID, ReqStatus>

export default (
  state: RequestStatusState = {},
  action: RequestStatusActionTypes
): RequestStatusState => {
  switch (action.type) {
    case START_REQUEST: {
      const id = action.payload.requestId
      return {
        ...state,
        [id]: { type: Status.PENDING },
      }
    }
    case REQUEST_SUCCESS: {
      const id = action.payload.requestId
      return {
        ...state,
        [id]: { type: Status.SUCCEEDED },
      }
    }
    case REQUEST_FAILURE: {
      const id = action.payload.requestId
      const { resp } = action.payload
      return {
        ...state,
        [id]: { type: Status.FAILED, message: resp?.message, resp },
      }
    }
    default:
      return state
  }
}
