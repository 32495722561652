import { Link } from "react-router-dom"
import React from "react"

type ButtonProps = {
  round?: boolean
  evenPadding?: boolean
  primary?: boolean
  disabled?: boolean
  fullWidth?: boolean
  isLoading?: boolean
  hasIconLeft?: boolean
  hasIconRight?: boolean
  iconOnly?: boolean
  isSmall?: boolean
  link?: boolean
  solid?: boolean
  text?: boolean
  alignRight?: boolean
  type?: "button" | "reset" | "submit" | undefined
}

const Button: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = (
  props
) => {
  const {
    round,
    isSmall,
    link,
    solid,
    evenPadding,
    primary,
    text,
    fullWidth,
    isLoading,
    hasIconLeft,
    hasIconRight,
    iconOnly,
    alignRight,
    ...btnprops
  } = props
  return (
    <button
      className={`btn ${primary ? "primary" : ""} ${
        isSmall ? "is-small" : ""
      } ${solid ? "is-solid" : ""}
      ${fullWidth ? "is-full-width" : ""}
      ${hasIconLeft ? "has-icon-left" : ""}
      ${hasIconRight ? "has-icon-right" : ""}
      ${iconOnly ? "has-icon-only" : ""}
      ${text ? "text" : ""}
      ${link ? "link" : ""}
      ${alignRight ? "align-right" : ""}
      `}
      {...btnprops}
    />
  )
}

export const ButtonAsText: React.FC<
  Omit<ButtonProps, "primary"> & React.HTMLProps<HTMLButtonElement>
> = (props) => {
  const {
    round,
    isSmall,
    link,
    solid,
    evenPadding,
    text,
    fullWidth,
    isLoading,
    hasIconLeft,
    iconOnly,
    alignRight,
    ...btnprops
  } = props
  return (
    <button
      className={`btn text ${isSmall ? "is-small" : ""} ${
        solid ? "is-solid" : ""
      }
      ${fullWidth ? "is-full-width" : ""}
      ${hasIconLeft ? "has-icon-left" : ""}
      ${iconOnly ? "has-icon-only" : ""}
      ${text ? "text" : ""}
      ${link ? "link" : ""}
      ${alignRight ? "align-right" : ""}
      `}
      {...btnprops}
    />
  )
}

export const ButtonLink: React.FC<ButtonProps & { to: any } & any> = (
  props
) => {
  const {
    round,
    isSmall,
    link,
    solid,
    evenPadding,
    primary,
    text,
    fullWidth,
    isLoading,
    hasIconLeft,
    iconOnly,
    ...btnprops
  } = props
  return (
    <Link
      className={`btn ${primary ? "primary" : ""} ${
        isSmall ? "is-small" : ""
      } ${solid ? "is-solid" : ""}
      ${fullWidth ? "is-full-width" : ""}
      ${hasIconLeft ? "has-icon-left" : ""}
      ${iconOnly ? "has-icon-only" : ""}
      ${text ? "text" : ""}
      ${link ? "link" : ""}
       is-link`}
      {...btnprops}
    />
  )
}

export default Button
