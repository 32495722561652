const FOCUSABLE = `
  a, button, input, select, textarea, svg, area, details, summary,
  iframe, object, embed,
  [tabindex], [contenteditable]
`

class ModalDialog extends HTMLElement {
  nodes: Element[]

  static get observedAttributes() {
    // Which attributes call attributeChangedCallback
    return ["is-open"]
  }

  constructor() {
    super()
    this.nodes = []
  }

  releaseFocusTrap() {
    this.nodes.forEach((node) => node.removeAttribute("tabindex"))
    this.nodes = []
    this.focusFirst(document)
  }

  focusFirst(rootNode) {
    // @ts-ignore
    rootNode.querySelectorAll(FOCUSABLE)[0]?.focus()
  }

  trapFocus() {
    this.nodes = [...document.querySelectorAll(FOCUSABLE)].filter(
      (node) => !this.contains(node) && node.getAttribute("tabindex") !== "-1"
    )
    this.nodes.forEach((node) => node.setAttribute("tabindex", "-1"))
    this.focusFirst(this)
  }

  checkFocus(attr: string) {
    if (attr === "true") {
      this.trapFocus()
    } else {
      this.releaseFocusTrap()
    }
  }

  connectedCallback() {
    this.checkFocus(this.getAttribute("is-open") || "")
  }
  // Update content if placement attribute changed
  attributeChangedCallback(_name, _oldValue, newValue) {
    if (!this.isConnected) return
    if (_name === "is-open") {
      this.checkFocus(newValue)
    }
  }
}

const define = () => customElements.define("modal-dialog", ModalDialog)

export { define }
