export default {
  background: "#ffffff",
  main: "#1D264D",
  primary: "#FFCB1B" ,
  secondary: "#008DFF",
  tertiary: "#00DFA7",
  success: "#00DFA7",
  error: "#EE0202",
  backgroundShade: "#FBFBFC",
  shade0: "#fcfdff",
  shade1: "#F7F9FC",
  shade2: "#F7F8FC",
  shade3: "#e8eaf0",
  shade4: "#7a8bac",
  shadowColor: "rgba(116,142,166,0.25)",

  dp: {
    5: "rgba(116,142,166,0.05)",
    10: "rgba(116,142,166,0.10)",
    18: "rgba(116,142,166,0.18)",
    20: "rgba(116,142,166,0.20)",
    25: "rgba(116,142,166,0.25)",
    30: "rgba(116,142,166,0.30)",
    32: "rgba(116,142,166,0.32)",
  },

}
