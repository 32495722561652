import {
  dismiss as dismissActionCreator,
  notify as notifyActionCreator,
}from "~/actions/notifications"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState }from "~/store"

const useNotifications = () => {
  const dispatch = useDispatch()
  const notifications = useSelector((state: ApplicationState) =>
    Object.values(state.notifications)
  )

  const dismiss = useCallback(
    (...args: Parameters<typeof dismissActionCreator>) => {
      dispatch(dismissActionCreator(...args))
    },
    [dispatch]
  )

  const notify = useCallback(
    (...args: Parameters<typeof notifyActionCreator>) => {
      dispatch(notifyActionCreator(...args))
    },
    [dispatch]
  )

  return {
    dismiss,
    notify,
    notifications,
  }
}

export default useNotifications
