import { useDispatch, useSelector } from "react-redux"
import {
  createNewAdvisor,
  deleteAdvisorRoutine,
  fetchAdvisorRoutine,
  updateAdvisorRoutine,
}from "~/actions/advisors"
import { useHistory } from "react-router-dom"
import { useAuth } from "~/context/auth"
import { advisors as advisorsApi } from "~/api"
import AdvisorLib from "~/lib/Advisor"
import { Advisor }from "~/store/advisors/types"
import { UUID }from "~/store/types"
import { ApplicationState }from "~/store"
import { useCallback, useMemo, useState } from "react"
import { requestStatus }from "~/store/requestStatus/selectors"
import { Status }from "~/store/requestStatus/reducer"
import { ApiError } from "~/api/APIBase"
import { withRequestId } from "~/lib/requestId"

export default () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { token, organisationId, apiContext, logout } = useAuth()
  const state = useSelector((state: ApplicationState) => state)

  const useReqId = useState

  const isLoading = (requestId: UUID): boolean => {
    const status = requestStatus(state, requestId)
    return status ? status.type === Status.PENDING : false
  }

  const createAdvisor: (
    name: string,
    productLabel: string,
    icon: string,
    locales: string[]
  ) => string = useMemo(
    () => (name, productLabel, icon, locales) =>
      withRequestId((requestId) =>
        dispatch(
          createNewAdvisor(
            requestId,
            {
              name,
              productLabel,
              icon,
              locales,
            },
            {
              context: apiContext,

              history,
              logout,
            }
          )
        )
      ),
    [apiContext, dispatch, history, logout]
  )

  const saveAdvisor: (advisorId: UUID) => string = useMemo(
    () => (advisorId) =>
      withRequestId((requestId) =>
        dispatch(
          updateAdvisorRoutine(apiContext, advisorId, requestId, { logout })
        )
      ),
    [apiContext, dispatch, logout]
  )

  const getAdvisor: (id: UUID) => string = useMemo(
    () => (id) =>
      withRequestId((requestId) =>
        dispatch(fetchAdvisorRoutine(requestId, id, apiContext, { logout }))
      ),
    [apiContext, dispatch, logout]
  )

  const deleteAdvisor: (id: UUID) => string = useMemo(
    () => (id) =>
      withRequestId((requestId) =>
        dispatch(deleteAdvisorRoutine(requestId, id, apiContext, { logout }))
      ),
    [apiContext, dispatch, logout]
  )

  const defaultHandleError = useCallback(
    (error: ApiError) => {
      if (error.status === 401) logout()
      return Promise.reject(error)
    },
    [logout]
  )

  const getAdvisors: (page) => Promise<{
    total: number
    size: number
    offset: number
    results: Advisor[]
  }> = useMemo(
    () => (page) =>
      token
        ? advisorsApi
            .all(apiContext.token, apiContext.organisationId, page)
            .then((advs) => ({
              total: advs.total,
              size: advs.size,
              offset: advs.offset,
              results: AdvisorLib.listExternalToInternal(advs.results),
            }))
            .catch(defaultHandleError)
        : Promise.reject("No auth token present"),
    [apiContext.organisationId, apiContext.token, defaultHandleError, token]
  )

  return {
    createAdvisor,
    getAdvisors,
    getAdvisor,
    deleteAdvisor,
    saveAdvisor,
    isLoading,
    useReqId,
  }
}
