import React, { createRef, useCallback, useEffect, useState } from "react"
import { marked } from "marked"
import DOMPurify from "dompurify"
import Label from "./forms/Label"
import { useTranslation } from "react-i18next"

type MarkdownTextareaProps = {
  id?: string
  text: string
  label?: string | React.ReactNode
  isOptional?: boolean
  placeholder?: string
  disabled?: boolean
  toolbar?: FormatOptions
  contentStyle?: string
  maxLength: number
  minHeight?: number
  bounds?: string
  hasError?: boolean
  onChange: (a: string) => void
  dataTestId?: string
  italic?: boolean
}
type FormatOption = "bold" | "italic" | "link" | "list"
export type FormatOptions = FormatOption[]
const defaultFormatOpts: FormatOptions = ["bold", "italic", "link"]

const MarkdownTextarea: React.FC<MarkdownTextareaProps> = (props) => {
  const id = props.id || "editor"
  return (
    <div key={id}>
      <MarkdownTextareaComp {...props} />
    </div>
  )
}

const MarkdownTextareaComp: React.FC<MarkdownTextareaProps> = (props) => {
  const formatOptionList = props.toolbar || defaultFormatOpts
  const { t } = useTranslation()
  const [content] = useState(DOMPurify.sanitize(marked.parse(props.text || "")))
  const [focussed, setFocussed] = useState(false)
  const quillRef = createRef<null | any>()
  // const formatOptions = ["bold", "italic", "link"]
  const disabled = props.disabled

  const handleChange = props.onChange

  useEffect(() => {
    const editor = quillRef.current
    if (editor) {
      const handler = (event) => {
        handleChange(event.detail)
      }
      editor.addEventListener("editor-input", handler)
      const focusHandler = (event) => {
        setFocussed(true)
      }
      editor.addEventListener("editor-focus", focusHandler)

      const blurHandler = (event) => {
        setFocussed(false)
      }
      editor.addEventListener("editor-blur", blurHandler)

      return () => {
        editor.removeEventListener("editor-input", handler)
        editor.removeEventListener("editor-blur", blurHandler)
        editor.removeEventListener("editor-focus", focusHandler)
      }
    }
  }, [handleChange])

  return (
    <div
      // minHeight={props.minHeight ? props.minHeight : 20}
      data-test-id={props.dataTestId}
      className={
        disabled
          ? "form-field__quill-wrapper disabled"
          : "form-field__quill-wrapper"
      }
      // hasError={props.hasError}
      // italic={props.italic}
    >
      {props.label && (
        <Label
          className="form-field__label"
          disabled={props.disabled}
          focussed={!props.disabled && focussed}
        >
          {props.label}
          {!!props.isOptional && (
            <span className="form-field__label-optional">{t("optional")}</span>
          )}
        </Label>
      )}

      {/* @ts-ignore */}
      <quill-editor
        ref={quillRef}
        formats={formatOptionList.join(",")}
        toolbar={formatOptionList.join(",")}
        content={content}
        bounds={props.bounds}
        disabled={disabled}
        maxLength={props.maxLength}
      >
        {/* @ts-ignore */}
      </quill-editor>
    </div>
  )
}

export default MarkdownTextarea
