import { useCallback, useEffect } from "react"
import useNotifications from "../hooks/useNotifications"

const ActionNotifications: React.FC<{}> = () => {
  const notifications = useNotifications()

  const handler = useCallback((event) => {
    if (event.data.type === "action") {
      notifications.notify({
        text: `"${event.data.data.actionId}" triggered`,
      })

      const action = event.data

      setTimeout(() => {
        event.source?.postMessage(
          {
            type: "action-callback",
            status: "SUCCEEDED",
            advisorId: action.advisorId,
            advisorName: action.advisorName,
            actionId: action.data.actionId,
            triggerId: action.data.triggerId,
            products: action.data.products,
          },
          "*"
        )
      }, 300)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("message", handler)

    return () => {
      window.removeEventListener("message", handler)
    }
  }, [])

  return null
}

export default ActionNotifications
