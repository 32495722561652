import React, { useState } from "react"
import { Switch, Route, useRouteMatch } from "react-router"
// import CreateDataSource from "./CreateCatalogue"
// import DataSources from "./DataSources"
import UploadNewData from "./UploadNewData"
import SelectSourceType from "./Create"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import useDatasources from "~/hooks/useDatasources"
import useMountEffect from "~/hooks/useMountEffect"
import LoadingSpinner from "~/components/Loading"
import SplitLayout, { SidebarContent } from "~/components/SplitLayout"
import HelpText from "~/components/HelpText"
import { useTranslation } from "react-i18next"
import Page, { PageHeader } from "~/components/advisors/Page"
import { Advisor }from "~/store/advisors/types"
import useNavigation from "~/hooks/useNavigation"
import CatalogueNewFeed from "~/elm-tsx-wrappers/CatalogueNewFeed"

const CreateCatalogue: React.FC<{ advisor: Advisor }> = () => {
  const { url } = useRouteMatch()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { id: advisorId, productLabel } = useAdvisorContext()
  const { getAll } = useDatasources(advisorId)
  const { toProductCatalogue, toCatalogueCreate } = useNavigation()

  useMountEffect(() => {
    let isCancelled = false

    getAll(advisorId)
      .then((sources) => {
        if (!isCancelled && sources[0]) {
          toProductCatalogue(advisorId)
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))

    return () => {
      isCancelled = true
    }
  })

  if (error)
    return (
      <SplitLayout
        left={
          <SidebarContent padTop>
            <HelpText hasError>{t("errors.error")}</HelpText>
          </SidebarContent>
        }
      />
    )

  if (loading)
    return (
      <Page>
        <LoadingSpinner />
      </Page>
    )
  return (
    <Switch>
      <Route
        exact
        path={`${url}/`}
        render={(_) => (
          <Page>
            <PageHeader>
              <h1>
                {t("advisor.navigation.catalogue.title", { productLabel })}
              </h1>
            </PageHeader>
            <SelectSourceType />
          </Page>
        )}
      />
      <Route
        path={`${url}/new-google-shopping-feed`}
        render={(props) => (
          <Page>
            <PageHeader>
              <h1>
                {t("advisor.navigation.catalogue.title", { productLabel })}
              </h1>
            </PageHeader>
            <CatalogueNewFeed
              abort={() => toCatalogueCreate(advisorId)}
              success={() => toProductCatalogue(advisorId)}
              advisorId={advisorId}
            />
          </Page>
        )}
      />
      <Route
        path={`${url}/new`}
        render={(props) => (
          <Page>
            <PageHeader>
              <h1>
                {t("advisor.navigation.catalogue.title", {
                  productLabel,
                })}
              </h1>
            </PageHeader>
            <UploadNewData {...(props as any)} />
          </Page>
        )}
      />
    </Switch>
  )
}

export default CreateCatalogue
