import { Account } from "./types"
import { CurrentUserActionTypes }from "~/actions/currentUser"
import {
  FETCH_USER_ACCOUNT_SUCCESS,
  CHANGED_USER_ACCOUNT,
}from "~/actions/currentUser/constants"

const defaultState = {
  user: undefined,
}

type CurrentUserState = {
  user: Account | undefined
}

export default (
  state: CurrentUserState = defaultState,
  action: CurrentUserActionTypes
): CurrentUserState => {
  switch (action.type) {
    case FETCH_USER_ACCOUNT_SUCCESS: {
      return { ...state, user: action.payload.account }
    }
    case CHANGED_USER_ACCOUNT: {
      return {
        ...state,
        user: {
          ...state.user!,
          ...action.payload,
          updatedAt: new Date().getTime(),
        },
      }
    }
    default:
      return state
  }
}
