import {
  AdvisorActionTypes,
  FETCH_ADVISOR_SUCCESS,
  NEW_ADVISOR_SUCCESS,
}from "~/actions/advisors"
import {
  ADD_AFTER,
  CLEAR_CHANGES,
  FlowActionTypes,
  MOVE,
  PROPOSE_CHANGE,
}from "~/actions/flow"
import {
  ADD_QUESTION,
  CHANGE_NEXT_QUESTION,
  QuestionActionTypes,
  REMOVE_QUESTION,
}from "~/actions/questions"
import {
  ADD_RULE,
  CHANGE_RULE,
  CHANGE_RULE_TARGET,
  REMOVE_RULE,
  RulesActionTypes,
}from "~/actions/rules"
import { ScreenActionTypes }from "~/actions/screens"
import {
  CHANGE_NEXT,
  CHANGE_SCREEN_NEXT,
  REMOVE_INFO_SCREEN,
}from "~/actions/screens/constants"
import flow from "lodash/flow"
import { ConversationScreens } from "./screens"

const defaultState: FlowState = {}

type NodeId = string
type RuleId = string

type FlowRule = { nodeId: NodeId; ruleId: RuleId; target: NodeId | undefined }

export type Flow = {
  nodes: Record<NodeId, NodeId | undefined>
  rules: FlowRule[]
  proposedChanges: Record<NodeId | RuleId, NodeId>
}

type FlowState = Record<string, Flow>

export const reducer = (
  state: FlowState = defaultState,
  action:
    | FlowActionTypes
    | AdvisorActionTypes
    | QuestionActionTypes
    | ScreenActionTypes
    | RulesActionTypes
): FlowState => {
  switch (action.type) {
    case NEW_ADVISOR_SUCCESS:
    case FETCH_ADVISOR_SUCCESS: {
      const { questions, rules, screens, advisor } = action.payload
      const advisorId = advisor.id

      return { ...state, [advisorId]: newFlow(questions, rules, screens) }
    }
    case MOVE: {
      const { toMove, previousId: nodeIdToMoveTo, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state

      return {
        ...state,
        [advisorId]: moveNode(toMove.id, toMove.next, nodeIdToMoveTo)(target),
      }
    }
    case REMOVE_QUESTION:
    case REMOVE_INFO_SCREEN: {
      const { id, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      const next = target.nodes[id]
      return {
        ...state,
        [advisorId]: cutNode(id, next)(target),
      }
    }
    case REMOVE_RULE: {
      const { ruleId, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      return {
        ...state,
        [advisorId]: {
          ...target,
          rules: target.rules.filter((r) => r.ruleId !== ruleId),
        },
      }
    }
    case CHANGE_NEXT: {
      const { screenId, next, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      return {
        ...state,
        [advisorId]: {
          ...target,
          nodes: {
            ...target.nodes,
            [screenId]: next,
          },
        },
      }
    }
    case CHANGE_NEXT_QUESTION: {
      const { id, nextQuestionId, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      return {
        ...state,
        [advisorId]: {
          ...target,
          nodes: {
            ...target.nodes,
            [id]: nextQuestionId,
          },
        },
      }
    }
    case CHANGE_SCREEN_NEXT: {
      const { id, nextId, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      return {
        ...state,
        [advisorId]: {
          ...target,
          nodes: {
            ...target.nodes,
            [id]: nextId,
          },
        },
      }
    }
    case ADD_QUESTION: {
      const { question, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      return {
        ...state,
        [advisorId]: {
          ...target,
          nodes: {
            ...target.nodes,
            [question.id]: question.next,
          },
        },
      }
    }
    case ADD_AFTER: {
      const { toAdd, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      return {
        ...state,
        [advisorId]: {
          ...target,
          nodes: {
            ...target.nodes,
            [toAdd.id]: toAdd.next,
          },
        },
      }
    }
    case ADD_RULE: {
      const { rule, questionId, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      return {
        ...state,
        [advisorId]: {
          ...target,
          rules: [
            ...target.rules,
            {
              ruleId: rule.id,
              target: rule.targetQuestionId,
              nodeId: questionId,
            },
          ],
        },
      }
    }
    case CHANGE_RULE_TARGET: {
      const { id, targetQuestionId, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      return {
        ...state,
        [advisorId]: {
          ...target,
          rules: target.rules.map((r) => {
            if (r.ruleId === id) {
              return { ...r, target: targetQuestionId }
            }
            return r
          }),
        },
      }
    }
    case CHANGE_RULE: {
      const { rule, advisorId } = action.payload
      const target = state[advisorId]

      if (!target) return state
      return {
        ...state,
        [advisorId]: {
          ...target,
          rules: target.rules.map((r) => {
            if (r.ruleId === rule.id) {
              return { ...r, target: rule.targetQuestionId }
            }
            return r
          }),
        },
      }
    }
    case CLEAR_CHANGES: {
      const { advisorId } = action.payload
      const target = state[advisorId]
      return {
        ...state,
        [advisorId]: {
          ...target,
          proposedChanges: {},
        },
      }
    }
    case PROPOSE_CHANGE: {
      const { fromId, toId, advisorId } = action.payload
      const target = state[advisorId]
      return {
        ...state,
        [advisorId]: {
          ...target,
          proposedChanges: {
            ...target.proposedChanges,
            [fromId]: toId,
          },
        },
      }
    }

    default:
      return state
  }
}

const identity = <T>(f: T): T => f

const moveNode =
  (nodeId: NodeId, next: NodeId, after: NodeId | undefined) =>
  (state: Flow): Flow => {
    const nextOfRemoved = state.nodes[nodeId]
    return flow(
      cutNode(nodeId, nextOfRemoved),
      pasteNode(nodeId, next, after)
      // removeRuleIfNodeNextHasSameTarget
    )(state)
  }

const cutNode =
  (nodeId: NodeId, nextOfRemoved: NodeId | undefined) =>
  (state: Flow): Flow => {
    return flow(
      deleteNode(nodeId),
      updateNextOfOtherNodes(nodeId, nextOfRemoved),
      removeNodeFromRules(nodeId, nextOfRemoved)
    )(state)
  }

const deleteNode =
  (nodeId: NodeId) =>
  (state: Flow): Flow => {
    return { ...state, nodes: deleteKey(state.nodes, nodeId) }
  }

const pasteNode =
  (nodeId: NodeId, next: NodeId, after: NodeId | undefined) =>
  (state: Flow): Flow => {
    return flow(
      changeNext(nodeId, next),
      changeNext(after, nodeId)
      // updateRules(nodeId, nextOfRemoved)
    )(state)
  }

const changeNext =
  (nodeId: NodeId | undefined, next: NodeId | undefined) =>
  (state: Flow): Flow => {
    if (!nodeId || ["ADVICE"].includes(nodeId || "")) return state
    return {
      ...state,
      nodes: {
        ...state.nodes,
        [nodeId]: next,
      },
    }
  }

/**
 * Updates rules to point to the "next" of the removed node to stitch the
 * conversation together.
 *
 * If the "next" of the node to which the rule is attached is the same as
 * the rule target then the rule is redundant and removed.
 */
const removeNodeFromRules =
  (deletedNodeId: NodeId, nextOfDeleted: NodeId | undefined) =>
  (state: Flow): Flow => {
    return {
      ...state,
      rules: state.rules
        .map((r) => {
          if (nodeNextIsSameAsRuleTarget(deletedNodeId, r.target)(state)) {
            return null
          } else if (r.target === deletedNodeId) {
            return { ...r, target: nextOfDeleted }
          }
          return r
        })
        .filter<FlowRule>((r): r is FlowRule => !!r),
    }
  }

const nodeNextIsSameAsRuleTarget =
  (deletedNodeId: NodeId, ruleTarget: NodeId | undefined) =>
  (state: Flow): boolean => {
    const nextOfNode = state.nodes[deletedNodeId]
    if (!nextOfNode) return false
    return nextOfNode === ruleTarget
  }

const updateNextOfOtherNodes =
  (movingNodeId: NodeId, nextOfMoving: NodeId | undefined) =>
  (state: Flow): Flow => {
    return {
      ...state,
      nodes: updateObj(state.nodes, ([nodeId, nextId]) => {
        if (nodeId === movingNodeId) return [nodeId, nextId]
        if (nextId === movingNodeId) return [nodeId, nextOfMoving]
        return [nodeId, nextId]
      }),
    }
  }

// const removeRuleIfNodeNextHasSameTarget = (state: Flow): Flow => {
//   const nodes = Object.entries(state.nodes)
//   return {
//     ...state,
//     rules: state.rules.filter((r) =>
//       nodes.some(([n, target]) => r.nodeId === n && r.target === target)
//     ),
//   }
// }

function updateObj<V>(
  obj: Record<string, V>,
  fn: ([string, V]) => [string, V]
): Record<string, V> {
  return Object.fromEntries(Object.entries(obj).map(fn))
}

function deleteKey<V>(obj: Record<string, V>, key: string): Record<string, V> {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => k !== key))
}
function newFlow(
  questions: import("./questions").Question[],
  rules: import("./rules").Rule[],
  screens: ConversationScreens
): Flow {
  let nodes = {}

  questions.forEach((q) => {
    nodes[q.id] = q.next
  })

  screens.infoScreens.forEach((s) => {
    nodes[s.id] = s.next
  })

  nodes["START"] = screens.intro.next

  const flowRules: FlowRule[] = rules.reduce((acc, rule) => {
    const nodeId = questions.find((q) => q.rules.includes(rule.id))?.id

    if (!nodeId) return acc
    else
      return [
        ...acc,
        {
          ruleId: rule.id,
          nodeId: nodeId,
          target: rule.targetQuestionId,
        } as FlowRule,
      ]
  }, [] as FlowRule[])

  return {
    nodes,
    rules: flowRules,
    proposedChanges: {}
  }
}
