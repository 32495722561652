import styled from "styled-components"
import { QuestionLabelColor } from "themes/types"

const QuestionLabel = styled.span<{col: QuestionLabelColor}>`
  background: ${(p) => p.col.color};
  display: inline-block;
  flex-grow: 0;
  padding: ${(p) => p.theme.labelPadding};
  margin: 0.5rem 0;
  border-radius: ${(p) => p.theme.borderRadius};
  font-weight: bold;
  width: auto;
  color: ${(p) => p.col.text};
`

export default QuestionLabel
