import { useAuth } from "~/context/auth"
import { dataSources } from "~/api"
import { ApiError } from "~/api/APIBase"
import { UUID }from "~/store/types"
import { DataSource } from "~/types"

export default (advisorId: UUID) => {
  const context = useAuth()
  const token = context.token || ""
  const organisationId = context.organisationId
  const logout = context.logout

  const defaultHandleError = (error: ApiError) => {
    if (error.status === 401) logout()
    return Promise.reject(error)
  }

  const createCSV = async (columnMapping: DataSource["columnMapping"]) =>
    dataSources
      .create(advisorId, "CSV", "CSV", columnMapping, {}, token, organisationId)
      .catch(defaultHandleError)

  const createShoppingFeed = async (name: string, config: { url: string }) =>
    dataSources
      .create(
        advisorId,
        name,
        "GOOGLE_SHOPPING_FEED",
        {
          image: undefined,
          name: undefined,
          id: undefined,
          url: undefined,
          price: undefined,
        },
        config,
        token,
        organisationId
      )
      .catch(defaultHandleError)

  const getAll = async (advisorId: UUID) =>
    dataSources.all(advisorId, token, organisationId).catch(defaultHandleError)

  const get = async (id: string) =>
    dataSources
      .get(advisorId, id, token, organisationId)
      .catch(defaultHandleError)

  const update = async (dataSource: DataSource) =>
    dataSources
      .update(advisorId, dataSource, token, organisationId)
      .catch(defaultHandleError)

  const remove = async (id: string) =>
    dataSources
      .remove(advisorId, id, token, organisationId)
      .catch(defaultHandleError)

  const uploadFileFun = async (id: string, file: File) =>
    dataSources
      .uploadFile(advisorId, id, file, token, organisationId)
      .catch(defaultHandleError)

  const previewChangesFun = async (id: string, newIds: UUID[]) =>
    dataSources
      .previewChanges(advisorId, id, newIds, token, organisationId)
      .catch(defaultHandleError)

  const getProducts = async (id: string, page: number, size: number = 10) => {
    const offset = page - 1 * size

    return dataSources
      .products(advisorId, id, offset, size, token, organisationId)
      .catch(defaultHandleError)
  }

  return {
    createCSV,
    createShoppingFeed,
    getAll,
    get,
    update,
    remove,
    uploadFile: uploadFileFun,
    previewChanges: previewChangesFun,
    getProducts,
  }
}
