import { differenceInMilliseconds } from "date-fns"

type JWT = { sub: string; iat: number; exp: number }

const parse = (token: string): JWT => {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

const expirationDateTime = (token: string): Date => {
  const date = new Date(0)
  date.setUTCSeconds(parse(token).exp)
  return date
}

const isExpired = (token: string): boolean => {
  return msUntilExpiration(token) <= 0
}

const msUntilExpiration = (token: string): number => {
  const ms = differenceInMilliseconds(expirationDateTime(token), new Date())
  return ms
}

const parseValues = (token: string) => {
  const val = parse(token)

  if (!val) {
    localStorage.removeItem("authToken")
    throw Error("No User info present in token")
  }

  return JSON.parse(val.sub)
}

export default { parse, parseValues, msUntilExpiration, expirationDateTime, isExpired }
