import styled from "styled-components"
import React from "react"
import { Cross } from "~/components/icons"
import Add from "~/components/icons/Add"

const ExtraPanel = styled.div`
  width: calc(100% + 4rem);
  margin-left: -2rem;
  border-top: 2px solid ${p => p.theme.shade1};
  padding-left: 2rem;
  padding-right: 2rem;
`
const ExtraHeader = styled.div<{ hover?: boolean }>`
  width: calc(100% + 2rem);
  display: flex;
  justify-content: space-between;
  align-items: center;}
  cursor: ${p => (p.hover ? "pointer" : "default")};
`
const Toggle = styled.div`
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  place-content: center;
  cursor: pointer;
  color: ${p => p.theme.shade4};
  border-radius: 100%;
  transition: background 250ms ease-out;
  &:hover {
    background: ${p => p.theme.shade1};
  }
`

const ExtraLabel = styled.span`
  padding: 1rem 0;
  color: ${p => p.theme.shade4};
  font-weight: 500;
`

const SidebarExtraPanel: React.FC<{
  label: string
  open: boolean
  onOpen: () => void
  onDelete: () => void
}> = ({ label, children, open, onOpen, onDelete }) => (
  <ExtraPanel>
    {open ? (
      <ExtraHeader>
        <ExtraLabel>{label}</ExtraLabel>
        <Toggle onClick={() => (open ? onDelete() : onOpen())}>
          {<Cross />}
        </Toggle>
      </ExtraHeader>
    ) : (
      <ExtraHeader hover onClick={() => (open ? onDelete() : onOpen())}>
        <ExtraLabel>{label}</ExtraLabel>
        <Toggle>
          <Add />
        </Toggle>
      </ExtraHeader>
    )}

    {open && children}
  </ExtraPanel>
)

export default SidebarExtraPanel
