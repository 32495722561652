import React from "react"

const SingleAnswerIcon: React.FC<{ style?: React.CSSProperties }> = (props) => (
  <svg width="24" height="24" {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <path
        d="m20 10c0 3.5726558-1.9059892 6.873926-5 8.660254-3.0940107 1.786328-6.90598925 1.786328-10 0-3.09401083-1.786328-5-5.0875982-5-8.660254 0-5.52284761 4.47715258-10 10-10 5.5228474 0 10 4.47715239 10 10z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle cx="10" cy="10" fill="currentColor" r="7" />
    </g>
  </svg>
)
const MultiAnswerIcon: React.FC<{ style?: React.CSSProperties }> = (props) => (
  <svg width="24" height="24"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)
const NumericAnswerIcon: React.FC<{ style?: React.CSSProperties }> = (
  props
) => (
  <svg width="24" height="24"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
    />
  </svg>
)
const DrilldownAnswerIcon: React.FC<{ style?: React.CSSProperties }> = (
  props
) => (
  <svg width="24" height="24" {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m14.94 15.508-2.8585545 5.6649419c-.2986715.5916265-1.0204005.8291136-1.6120271.5304422-.2289568-.1155846-.4148576-.3014853-.53044212-.5304422l-1.97697628-3.9149419zm4.143-8.206-2.008 3.977-10.768 2.701-1.556-3.083zm1.7371625-7.302c.6627417 0 1.2.5372583 1.2 1.2 0 .18785814-.0441052.37309232-.1287654.54079244l-.6743971 1.33420756-18.12 4.544-2.96797535-5.87820756c-.29867141-.59162653-.06118437-1.31335561.53044215-1.61202703.16770012-.08466022.3529343-.12876541.54079244-.12876541z"
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(.989893 1)"
    />
  </svg>
)

export {
  SingleAnswerIcon,
  MultiAnswerIcon,
  NumericAnswerIcon,
  DrilldownAnswerIcon,
}
