import { ElmApp } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import Elm from "./ElmWrapper"
import { useImpactAnalytics } from "~/hooks/useImpactAnalytics"

const AppOverview: React.FC<{}> = () => {
  const { timeConfigured, loading } = useImpactAnalytics()

  const [ports, setPorts] = React.useState<any>()

  function setupPorts(ports) {
    setPorts(ports)
  }

  if (loading) return null
  return (
    // @ts-ignore
    <Elm
      // @ts-ignore
      src={ElmApp.AppOverview}
      flags={{ impactAnalyticsTimeConfigured: timeConfigured || null }}
      ports={setupPorts}
    />
  )
}

export default AppOverview
