import styled from "styled-components"

const PageContainer = styled.div`
  margin: 10vh auto 2rem;
  padding: 0 2rem;
  max-width: 64rem;
  width: 100%;
`

export default PageContainer
