import API from "./api"
import { UUID } from "~/store/types"
import { Product, DataSource, NumericColumnMapping, Currency } from "~/types"
import { ApiContext, OrganisationId, Paginated } from "./types"

/** Create a new Data source */
const create = async (
  advisorId: UUID,
  name: string,
  type: DataSource["type"],
  columnMapping: DataSource["columnMapping"],
  config: {} | { url: string },
  token: string,
  organisationId: OrganisationId
) =>
  API.post<DataSource>({
    path: `/advisors/${advisorId}/datasources`,
    data: {
      advisorId,
      name,
      type,
      columnMapping,
      config,
    },
    token,
    organisationId,
  })

/** Update a  Data source */
const update = async (
  advisorId: UUID,
  dataSource: DataSource,
  token: string,
  organisationId: OrganisationId
) =>
  API.put<DataSource>({
    path: `/advisors/${advisorId}/datasources/` + dataSource.id,
    data: dataSource,
    token,
    organisationId,
  })

const updateTypeFormat = async (
  advisorId: UUID,
  dataSourceId: UUID,
  column: string,
  format: { currency: Currency },
  token: string,
  organisationId: OrganisationId
) =>
  API.put<DataSource>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/typemapping/${column}`,
    data: format,
    token,
    organisationId,
  })

/** Delete a  Data source */
const remove = async (
  advisorId: UUID,
  dataSourceId: string,
  token: string,
  organisationId: OrganisationId
) =>
  API.delete<{ status: "ok" }>({
    path: `/advisors/${advisorId}/datasources/` + dataSourceId,
    token,
    organisationId,
  })

/** Retrieve a Data source */
const all = async (
  advisorId: UUID,
  token: string,
  organisationId: OrganisationId
) =>
  API.get<DataSource[]>({
    path: `/advisors/${advisorId}/datasources`,
    token,
    organisationId,
  })

/** Retrieve a Data source */
const get = async (
  advisorId: UUID,
  id: string,
  token: string,
  organisationId: OrganisationId
) =>
  API.get<DataSource>({
    path: `/advisors/${advisorId}/datasources/` + id,
    token,
    organisationId,
  })

/** Upload a new file for a data source */
const uploadFile = async (
  advisorId: UUID,
  id: string,
  file: File,
  token: string,
  organisationId: OrganisationId
) => {
  const formData = new FormData()
  formData.append("file", file)

  return API.post<{ status: "ok" }>({
    path: `/advisors/${advisorId}/datasources/${id}/upload`,
    token,
    organisationId,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then((resp) => ({ status: "ok" }))
}

/** See what products would be created/updated/deleted given a list of product IDs */
const previewChanges = async (
  advisorId: UUID,
  id: string,
  ids: UUID[],
  token: string,
  organisationId: OrganisationId
) => {
  return API.post<{
    insert: number
    update: number
    delete: number
  }>({
    path: `/advisors/${advisorId}/datasources/${id}/preview-changes`,
    token,
    organisationId,
    data: { ids },
  })
}

/** Retrieve a page of products */
const products = async (
  advisorId: UUID,
  dataSourceId: string,
  offset: number,
  size: number,
  token: string,
  organisationId: OrganisationId
) =>
  API.get<Product[]>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/products?size=${size}&offset=${offset}`,
    token,
    organisationId,
  })

const activeProducts = async (
  advisorId: UUID,
  dataSourceId: string,
  offset: number,
  size: number,

  token: string,
  organisationId: OrganisationId
) =>
  API.post<{
    results: {
      id: string
      productId: string
      name: string
      fields: Record<string, (string | number)[]>
    }[]
  }>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/products?size=${size}&offset=${offset}&fields=name,id,productId`,
    data: {
      filter: {
        column: "active",
        combinator: "AND",
        filter: [],
        kind: "SINGLE",
        values: ["true"],
      },
      query: "",
    },
    token,
    organisationId,
  })

/** Retrieve a product */
const getProduct = async (
  advisorId: UUID,
  dataSourceId: UUID,
  id: string,
  token: string,
  organisationId: OrganisationId
) =>
  API.get<Product>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/products/${id}`,
    token,
    organisationId,
  })

/** Retrieve a numeric mapping for a question */
const getNumericMapping = async (
  advisorId: UUID,
  dataSourceId: UUID,
  questionId: UUID,
  token: string,
  organisationId: OrganisationId
) =>
  API.get<NumericColumnMapping>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/numeric/${questionId}`,
    token,
    organisationId,
  })

const createNumericMapping = async (
  advisorId: UUID,
  dataSourceId: UUID,
  questionId: UUID,
  numericMapping: NumericColumnMapping,
  token: string,
  organisationId: OrganisationId
) =>
  API.post<NumericColumnMapping>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/numeric/${questionId}`,
    data: numericMapping,
    token,
    organisationId,
  })

const updateNumericMapping = async (
  advisorId: UUID,
  dataSourceId: UUID,
  questionId: UUID,
  numericMapping: NumericColumnMapping,
  token: string,
  organisationId: OrganisationId
) =>
  API.put<NumericColumnMapping>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/mapping/numeric/${questionId}`,
    data: numericMapping,
    token,
    organisationId,
  })

export type DataSourceUpdate = {
  id: string
  dataSourceId: string
  status: "PENDING" | "FAILED" | "WARNINGS" | "COMPLETED"
  startedAt: number
  completedAt: number
  changeSummary?: {
    total: number
    added: number
    removed: number
    updated: number
    failed?: number
  }
  columnsAdded: string[]
  columnsRemoved: string[]
  warnings: UpdateProblem[]
  errorCode:
    | null
    | "TECHNICAL_ERROR"
    | "EMPTY_FEED"
    | "UNAVAILABLE_FEED"
    | "INVALID_FEED"
}

type UpdateProblem =
  | {
      column: string
      expected: "TEXT" | "NUMBER"
      actual: ("TEXT" | "NUMBER")[]
      error: "UNEXPECTED_TYPE"
      products: {
        id: string
        name: string
      }[]
    }
  | {
      column: string
      expected: null
      actual: []
      error: "MISSING" | "INVALID"
      products: {
        id: string
        name: string
      }[]
    }

const updates = async (
  advisorId: UUID,
  dataSourceId: UUID,
  opts: {
    offset: number
    size: number
  },
  token: string,
  organisationId: OrganisationId
) =>
  API.get<Paginated<DataSourceUpdate[]>>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/updates?offset=${opts.offset}&size=${opts.size}`,
    token,
    organisationId,
  }).then((paginated) => ({
    ...paginated,
    results: paginated.results.map((update) => {
      return {
        ...update,
        columnsAdded: update.columnsAdded || [],
        columnsRemoved: update.columnsRemoved || [],
        warnings: update.warnings || [],
      }
    }),
  }))

const syncData = async (
  advisorId: UUID,
  dataSourceId: UUID,
  token: string,
  organisationId: OrganisationId
) =>
  API.post<any>({
    path: `/advisors/${advisorId}/datasources/${dataSourceId}/update`,
    token,
    organisationId,
  })

export {
  create,
  get,
  all,
  update,
  updateTypeFormat,
  remove,
  uploadFile,
  previewChanges,
  products,
  activeProducts,
  getProduct,
  getNumericMapping,
  createNumericMapping,
  updateNumericMapping,
  updates,
  syncData,
}
