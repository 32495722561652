import React from "react"
import Avatar from "./Avatar"
import useCurrentUser from "~/hooks/useCurrentUser"

const AvatarWithAccount: React.FC<{
  size?: "large" | "small"
  hideName?: boolean
}> = ({ size = "small", hideName = false }) => {
  const { currentUser: account, profileImage } = useCurrentUser()

  return (
    <Avatar
      image={profileImage}
      label={hideName ? "" : account?.firstName || account?.email}
      name={account?.firstName || ""}
      size={size}
    />
  )
}

export default AvatarWithAccount
