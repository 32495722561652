import { Manager, Reference, Popper } from "react-popper"
import React, { useEffect } from "react"
import styled from "styled-components"
import SelectionUI from "./SelectionUI"

export const StyledRef = styled.div<{}>`
  &.disabled {
    > div {
      background-color: ${(p) => p.theme.shade3};
      color: ${(p) => p.theme.shade4};
      border-color: ${(p) => p.theme.shade2};
      cursor: default;
    }
  }
  &.error {
    > div {
      border-color: ${(p) => p.theme.error};
    }
  }
`

export type ListOption = {
  label: React.ReactNode
  value: any
  hide?: boolean
}

export type SelectProps = {
  value: any
  options: ListOption[]
  inline: boolean
  onSelect: (value: any, setOpen: (open: boolean) => void) => void
  listElement: (opt: ListOption) => React.ReactNode
  selectionElement: (open: boolean) => React.ReactNode
  testId?: string
  position?:
    | "bottom"
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end"
    | undefined
  disabled?: boolean
  hasError?: boolean
}

const LayoutUpdate = (props: { forceUpdate: any; value: any }) => {
  const { forceUpdate, value } = props
  useEffect(() => {
    forceUpdate()
  }, [value, forceUpdate])

  return null
}

class Select extends React.Component<SelectProps, { ref: any; open: boolean }> {
  constructor(props) {
    super(props)
    this.state = { open: false, ref: React.createRef<HTMLDivElement>() }
    this.onGlobalClick = this.onGlobalClick.bind(this)
  }

  componentDidMount() {
    const self = this
    document.addEventListener("click", self.onGlobalClick)
  }
  componentWillUnmount() {
    const self = this
    document.removeEventListener("click", self.onGlobalClick)
  }

  onGlobalClick(e: any) {
    if (this.state.ref?.current?.contains(e.target)) return
    this.setState({ open: false })
  }

  render() {
    const props = this.props
    const setOpen = (open) => this.setState({ open })
    const open = this.state.open
    const onSelect = (selected: any) => props.onSelect(selected, setOpen)

    return (
      <SelectionUI.DropdownWrapper
        ref={this.state.ref}
        inline={props.inline}
        data-test-id={props.testId}
      >
        <Manager>
          <Reference>
            {({ ref }) => (
              <StyledRef
                ref={ref}
                onClick={() => !props.disabled && setOpen(!open)}
                style={{ display: props.inline ? "inline-block" : "block" }}
                className={
                  (props.disabled ? "disabled" : "") +
                  (props.hasError ? "error" : "")
                }
              >
                {props.selectionElement(open)}
              </StyledRef>
            )}
          </Reference>
          {!props.disabled && open && (
            <Popper placement={props.position || "bottom"}>
              {({ ref, style, placement, arrowProps, forceUpdate }) => (
                <SelectionUI.DropdownList
                  ref={ref}
                  style={style}
                  data-placement={placement}
                >
                  <LayoutUpdate value={props.value} forceUpdate={forceUpdate} />
                  {props.options.map((opt, i) =>
                    opt.hide ? null : (
                      <div
                        key={`${i}-${opt.value}`}
                        onClick={() => onSelect(opt.value)}
                      >
                        {props.listElement(opt)}
                      </div>
                    )
                  )}
                  <div ref={arrowProps.ref} style={arrowProps.style} />
                </SelectionUI.DropdownList>
              )}
            </Popper>
          )}
        </Manager>
      </SelectionUI.DropdownWrapper>
    )
  }
}

export default Select
