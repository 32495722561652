export class Graph {
  constructor() {
    this.vertices = []
    this.adjacent = {}
    this.edges = 0
  }

  addVertex(v) {
    this.vertices.push(v)
    this.adjacent[v] = []
  }

  addEdge(v, w) {
    if (this.adjacent[v] && this.adjacent[w]) {
      this.adjacent[v].push(w)
      this.adjacent[w].push(v)
      this.edges++
    }
  }

  topoSort(v = this.vertices[0], discovered = [], s) {
    const stack = s || []

    let adj = this.adjacent

    discovered[v] = true

    const adjacencies = adj[v] || []

    adjacencies.forEach(w => {
      if (!discovered[w]) {
        this.topoSort(w, discovered, stack)
      }
    })


    // for (let i = 0; i < adj[v].length; i++) {
    //   let w = adj[v][i]

    //   if (!discovered[w]) {
    //     this.topoSort(w, discovered, stack)
    //   }
    // }

    stack.unshift(v)
    return stack || []
  }
}
