import { AdvisorActionTypes, ADVISOR_VALIDATED }from "~/actions/advisors"
import { AdvisorValidationResult } from "~/types"

const defaultState: ValidationState = {}

type ValidationState = Record<string, AdvisorValidationResult>

export const reducer = (
  state: ValidationState = defaultState,
  action: AdvisorActionTypes
): ValidationState => {
  switch (action.type) {
    case ADVISOR_VALIDATED: {
      const { advisorId, validation } = action.payload

      return { ...state, [advisorId]: validation }
    }

    default:
      return state
  }
}
