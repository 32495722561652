import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "~/context/auth"
import Form from "~/components/forms/Form"
import Button from "~/components/Button"
import LoadingSpinner from "~/components/Loading"
import FormSection from "~/components/forms/FormSection"
import FormHeader from "~/components/forms/FormHeader"
import Field from "~/components/forms/Field"
import HelpText from "~/components/HelpText"
import { Account, Role }from "~/store/currentUser/types"
import { SingleSelect } from "~/components/selection"
import Label from "~/components/forms/Label"
import FormValidation from "~/lib/FormValidation"
import { accounts } from "~/api"
import FormSplit from "~/components/forms/FormSplit"
import UserTable from "./UserTable"
import useMountEffect from "~/hooks/useMountEffect"
import { Invite } from "~/api/accounts"
import useNotifications from "~/hooks/useNotifications"

export type User = Account

const Users: React.FC<{}> = () => {
  const { t } = useTranslation()
  const [emailInput, setEmail] = useState("")
  const [role, setRole] = useState(Role.Contributor)
  const [pending, setPending] = useState(false)
  const { token, organisationId } = useAuth()
  const valid = FormValidation.isValidEmail(emailInput)
  const [feedback, setFeedback] = useState("")

  const [users, setUsers] = useState<User[]>([])
  const [updating, setUpdating] = useState<string[]>([])
  const [usersLoading, setUsersLoading] = useState(true)
  const [invited, setInvited] = useState<Invite[]>([])

  const { notify } = useNotifications()

  const invite = () => {
    if (!valid) return
    const email = emailInput.trim()
    setPending(true)
    accounts
      .invite([{ email, role }], token!!, organisationId)
      .then((resp) => {
        const invite = resp.invited.find((inv) => inv.email === email)
        setEmail("")
        setFeedback(
          invite
            ? t("settings.users.feedback.success", { email })
            : t("settings.users.feedback.failed", { email })
        )
        if (invite) {
          setInvited([...invited.filter((inv) => inv.email !== email), invite])
          notify({
            text: t("settings.users.feedback.success", { email: email }),
            type: "success",
          })
        }
      })
      .finally(() => setPending(false))
  }

  const changeEmail = (email: string) => {
    if (email === "") setFeedback("")
    setEmail(email)
  }

  useMountEffect(() => {
    const users = accounts.all(token!, organisationId)
    const invites = accounts.invites(token!, organisationId)
    Promise.all([users, invites])
      .then(([accounts, invited]) => {
        setUsers(accounts)
        setInvited(invited)
      })
      .finally(() => setUsersLoading(false))
  })

  const updateUser = (user: User) => {
    setUpdating([...updating, user.id])

    accounts
      .updateAccount(user.id, { role: user.role }, token!, organisationId)
      .then((_account) => {
        setUsers(users.map((u) => (u.id === user.id ? user : u)))
        notify(
          {
            text: t("settings.users.feedback.roleUpdated", {
              name: user.firstName || user.email,
              role: t("roles." + user.role.toLowerCase()),
            }),
            type: "success",
          },
          4000
        )
      })
      .finally(() => setUpdating(updating.filter((id) => id !== user.id)))
  }

  const deleteUser = (user: User) => {
    setUsers(users.filter((u) => u.id !== user.id))
    accounts
      .deleteAccount(user.id, token!, organisationId)
      .then(() => {
        notify(
          {
            text: t("settings.users.feedback.accountDeleted", {
              name: user.firstName,
            }),
            type: "success",
          },
          4000
        )
      })
      .catch(() => {
        setUsers([...users, user])
        notify(
          {
            text: t("settings.users.feedback.accountNotDeleted", {
              name: user.firstName,
            }),
            type: "error",
          },
          4000
        )
      })
  }

  const updateInvite = (user: User) => {
    const invite = invited.find((i) => i.id === user.id)
    if (invite) {
      setUpdating([...updating, user.id])
      accounts
        .updateInvitation(
          { id: user.id, role: user.role },
          token!,
          organisationId
        )
        .then(() => {
          setInvited(
            invited.map((u) =>
              u.id === user.id ? { ...invite, role: user.role } : u
            )
          )
          notify(
            {
              text: t("settings.users.feedback.roleUpdated", {
                name: user.email,
                role: t("roles." + user.role.toLowerCase()),
              }),
              type: "success",
            },
            4000
          )
        })
        .finally(() => setUpdating(updating.filter((id) => id !== user.id)))
    }
  }

  const deleteInvite = (user: User) => {
    const invite = invited.find((i) => i.id === user.id)
    if (invite) {
      setInvited(invited.filter((u) => u.email !== user.email))
      accounts
        .revokeInvitation({ id: user.id }, token!, organisationId)
        .then(() => {
          notify(
            {
              type: "success",
              text: t("settings.users.feedback.inviteRevoked", {
                email: user.email,
              }),
            },
            4000
          )
        })
        .catch(() => {
          setInvited([...invited, invite])
        })
    }
  }

  const resendInvite = (user: User) => {
    accounts
      .invite([{ email: user.email, role: user.role }], token!, organisationId)
      .then((resp) => {
        if (resp.invited.map((i) => i.email).includes(user.email)) {
          notify({
            text: t("settings.users.feedback.success", { email: user.email }),
            type: "success",
          })
        } else {
          notify({
            text: t("settings.users.feedback.failed", { email: user.email }),
            type: "error",
          })
        }
      })
  }

  return (
    <>
      <Form onSubmit={invite}>
        <FormSection>
          <h2>{t("settings.users.title")}</h2>
        </FormSection>
        <FormSplit />
        <FormSection>
          <FormHeader>{t("settings.users.inviteTitle")}</FormHeader>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div style={{ width: "20rem", marginRight: "1rem" }}>
                <Field
                  type="email"
                  label={t("settings.users.emailLabel")}
                  placeholder={"somebody@example.com"}
                  value={emailInput}
                  onChange={changeEmail}
                />
              </div>
              <div style={{ width: "10rem" }}>
                <Label>{t("settings.users.roleLabel")}</Label>
                <SingleSelect
                  options={[
                    {
                      label: t("roles.admin"),
                      value: Role.Admin,
                    },
                    {
                      label: t("roles.contributor"),
                      value: Role.Contributor,
                    },
                  ]}
                  value={role}
                  onSelect={setRole}
                />
              </div>
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <Button
                style={{ marginTop: "2.5rem" }}
                isLoading={pending}
                disabled={!valid}
                primary
                onClick={(e) => {
                  e.preventDefault()
                  invite()
                }}
              >
                {pending ? <LoadingSpinner size="small" /> : "Invite"}
              </Button>
            </div>
          </div>
        </FormSection>
        {feedback && <HelpText>{feedback}</HelpText>}
        {!valid && emailInput && (
          <HelpText hasError>
            {t("settings.users.feedback.emailInvalid")}
          </HelpText>
        )}
      </Form>
      <FormSplit />
      <FormSection>
        <FormHeader>{t("settings.users.overviewTitle")}</FormHeader>
        {usersLoading && <LoadingSpinner size="small" />}
        <UserTable
          users={users}
          invited={invited}
          updating={updating}
          updateUser={updateUser}
          deleteUser={deleteUser}
          updateInvite={updateInvite}
          deleteInvite={deleteInvite}
          resendInvite={resendInvite}
        />
      </FormSection>
    </>
  )
}

export default Users
