import { Middleware } from "redux"

import { UPDATE_ADVISOR_SUCCESS, validated }from "~/actions/advisors"
import { advisors } from "~/api"

type FluxStandardAction = {
  type: string
  payload?: Record<string, any>
  meta?: Record<string, any>
}

export const createAutosaveMiddleware = () => {
  const qAndAValidationMiddleware: Middleware =
    (storeAPI) => (next) => (action: FluxStandardAction) => {
      // Calling `next` passes the action to the reducer and updates the store
      // So everything after next deals with the updated store
      let result = next(action)
      const advisorId = action.payload?.advisor?.id

      if (action.type === UPDATE_ADVISOR_SUCCESS && !!advisorId) {
        advisors
          .conversationValidation(
            advisorId,
            localStorage.getItem("authToken") || "",
            { orgId: localStorage.getItem("selectedOrganisation") || "" }
          )
          .then((validationResult) => {
            next(validated(advisorId, validationResult))
          })
      }
      return result
    }

  return qAndAValidationMiddleware
}

const qAndAAutosaveMiddleware = createAutosaveMiddleware()

export default qAndAAutosaveMiddleware
