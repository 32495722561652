import React, { CSSProperties }  from "react"
import styled from "styled-components"

const ButtonBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem auto;
`

const ButtonBar: React.FC<{
  left?: React.ReactNode
  right?: React.ReactNode
  style?: CSSProperties
}> = ({ left, right, style }) => {
  return (
    <ButtonBarContainer style={style}>
      {left ? left : <div />}
      {right ? right : <div />}
    </ButtonBarContainer>
  )
}

export default ButtonBar
