import React, { useEffect, useState, useCallback } from "react"

type QandASidebarContextT = {
  collapsed: boolean
  changeCollapse: (collapsed: boolean) => boolean
}

const QandASidebarContext = React.createContext({
  collapsed: false,
  changeCollapse: (collapsed: boolean) => collapsed,
} as QandASidebarContextT)

const QandASidebarProvider = props => {
  const defaultCollapsed =
    localStorage.getItem("collapseQandASideBar") === "false" ? false : true
  const [collapsed, setCollapsed] = useState(defaultCollapsed)

  useEffect(() => {
    localStorage.setItem("collapseQandASideBar", collapsed.toString())
  }, [collapsed])

  const changeCollapse = useCallback((collapsed: boolean) => {
    setCollapsed(collapsed)
    return collapsed
  }, [setCollapsed])

  return (
    <QandASidebarContext.Provider
      value={{ collapsed, changeCollapse }}
      {...props}
    />
  )
}

const useQandASidebarContext = () =>
  React.useContext<QandASidebarContextT>(QandASidebarContext)
export { QandASidebarProvider, useQandASidebarContext }
