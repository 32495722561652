import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "~/components/LoadingSpinner"
import { Valid, Invalid } from "~/components/icons"
import Button from "~/components/Button"
import styled from "styled-components"
import Icon from "~/components/icons/Icon"
import useNavigation from "~/hooks/useNavigation"
import useDatasources from "~/hooks/useDatasources"
import { useRouteMatch } from "react-router"
import { PropType } from "~/lib/TypeHelpers"
import useMountEffect from "~/hooks/useMountEffect"
import { Currency, DataSource } from "~/types"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { dataSources } from "~/api"
import { useAuth } from "~/context/auth"

const Container = styled.div`
  width: 100%;
  max-width: 30rem;
  margin: 2rem auto;
  text-align: center;
  ${Icon} {
    width: 3rem;
    height: 3rem;
  }
`

const Upload: React.FC<{
  columnMapping: PropType<DataSource, "columnMapping">
  priceCurrency?: Currency
  delimiter: String
  file?: File
  existingDataSource?: DataSource
  errorBtnFn: () => void
  updateDataSource: (dataSource: DataSource) => DataSource
  uploadCompletedCallback?: () => void
}> = ({
  columnMapping,
  priceCurrency,
  delimiter,
  file,
  existingDataSource,
  updateDataSource,
  errorBtnFn,
}) => {
  const { t } = useTranslation()
  const { toAdvice } = useNavigation()
  const match = useRouteMatch<{ advisorId: string }>("/advisors/:advisorId")
  const [uploading, setUploading] = useState(true)
  const [error, setError] = useState(false)
  const ok = !error
  const { id: advisorId } = useAdvisorContext()
  const { update, uploadFile } = useDatasources(advisorId)
  const { productLabel } = useAdvisorContext()
  const { token, organisationId } = useAuth()
  const { createCSV } = useDatasources(advisorId)
  const copySource = existingDataSource ? "update" : "create"

  useMountEffect(async () => {
    if (file) {
      try {
        setUploading(true)
        const newDataSource = existingDataSource
          ? existingDataSource
          : await createCSV(columnMapping)

        try {
          const config: PropType<DataSource, "config"> = {
            ...newDataSource.config,
            delimiter: delimiter,
          }
          const updatedDataSource = { ...newDataSource, columnMapping, config }

          const ds = await update(updatedDataSource)
          const data = await updateDataSource(ds)

          if (priceCurrency) {
            await dataSources.updateTypeFormat(
              advisorId,
              updatedDataSource.id,
              "price",
              { currency: priceCurrency },
              token!,
              organisationId
            )
          }
          await uploadFile(data.id, file)
        } catch (e) {
          console.error(e)
          setError(true)
        }
      } catch (e) {
        console.error(e)
        setError(true)
      }
      setUploading(false)
    }
  })

  return (
    <>
      <Container>
        <h2>{t(`productDataset.${copySource}.step3.title`)}</h2>
        {uploading ? <LoadingSpinner /> : ok ? <Valid /> : <Invalid />}
        <p>
          {uploading
            ? t(`productDataset.${copySource}.step3.uploading`)
            : ok
            ? t(`productDataset.${copySource}.step3.done`, { productLabel })
            : t(`productDataset.${copySource}.step3.error`)}
        </p>
        {!uploading &&
          (ok ? (
            <Button onClick={() => toAdvice(match?.params.advisorId!)} primary>
              {t(`productDataset.${copySource}.step3.button`)}
            </Button>
          ) : (
            <Button onClick={() => errorBtnFn()} primary>
              {t(`productDataset.${copySource}.step3.errorButton`)}
            </Button>
          ))}
      </Container>
    </>
  )
}

export default Upload
