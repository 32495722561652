import React, { useState } from "react"
import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import { useRedirectTo } from "~/context/redirectTo"
import { useHistory } from "react-router"

const CRMAdmin : React.FC<{}> = (props) => {

    const { redirectTo, clearRedirectTo } = useRedirectTo()
    const history = useHistory()

    React.useEffect(() => {
        if (redirectTo) {
            clearRedirectTo()
            history.replace(redirectTo)
        }
    }, [redirectTo, clearRedirectTo])

    const [ports, setPorts] = useState<any>()

    return (
        // @ts-ignore
        <Elm
          // @ts-ignore
          src={ElmModule.CRM.Main}
          ports={setPorts}/>
      )

}
export default CRMAdmin
