import { questionLabelColors } from "./light"
import { QuestionLabelColor } from "./types"

export { default as light, questionLabelColors } from "./light"
export { default as dark } from "./dark"

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

const colors: QuestionLabelColor[] = questionLabelColors

export const getCurrentColor = (value: string | undefined) =>
  colors.find((col) => col.value === value) || colors[0]
