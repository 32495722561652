import styled, { css } from "styled-components"
import React, { ReactNode } from "react"
import { NavLink } from "react-router-dom"

const activeClassName = "nav-item-active"
const borderThickness = "3px"

const TabButton = styled.button<{ selected?: boolean; }>`
  margin: 0 1rem;
  font-weight: bold;
  font-size: 0.875rem;
  border-radius: 0;
  padding: 0.5rem;
  border-bottom: ${borderThickness} solid transparent;
  margin-bottom: -${borderThickness};
  transition: 200ms border ease-in;
  display: flex;
  align-items: center;
  svg:first-child {
    margin-right: 0.5rem;
  }
  svg:last-child {
    margin-left: 0.5rem;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    border-bottom: 3px solid ${(p) => p.theme.primary};
  }
  ${(p) => p.selected &&
    css`
      border-bottom: 3px solid ${(p) => p.theme.primary};
    `
  };
`

const Tab = styled(NavLink).attrs({
  activeClassName,
})<{ to: string }>`
  margin: 0 1rem;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0.5rem;
  border-bottom: ${borderThickness} solid transparent;
  margin-bottom: -${borderThickness};
  transition: 200ms border ease-in;
  display: flex;
  align-items: center;
  svg:first-child {
    margin-right: 0.5rem;
  }
  svg:last-child {
    margin-left: 0.5rem;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &.${activeClassName}, &:hover {
    ${p =>
      p.to &&
      css`
        border-bottom: 3px solid ${p.theme.primary};
      `}}
  }
`

const Tabs = styled.nav<{ withUnderLineBorder?: boolean }>`
  padding: 0;
  margin: 0;
  display: inline-flex;
  list-style: none;
  border-bottom: ${p => p.theme.shade1} ${borderThickness} solid;
  ${p =>
    p.withUnderLineBorder &&
    css`
      & > * {
        margin-left: 0;
        margin-right: 0;
        padding: 0.5rem 1rem;
        /* padding-right: 3rem; */
      }
    `}
`

const TabNav: React.FC<{
  options: {
    label: ReactNode;
    link?: string;
    hide?: boolean;
    active?: boolean;
    onClick?: () => void
  }[]
  withUnderLineBorder?: boolean
  exact?: boolean
  buttons?: boolean
}> = ({ options, withUnderLineBorder = false, exact = false, buttons = false }) => (
  <Tabs withUnderLineBorder={withUnderLineBorder}>
    {options.map(
      (t, i) =>
        !t.hide && (
          buttons ?
            <TabButton
              key={i}
              selected={t.active}
              onClick={t.onClick}
            >
              {t.label}
            </TabButton>
          :
            <Tab
              key={i}
              to={t.link || ""}
              activeClassName={activeClassName}
              exact={exact}
            >
              {t.label}
            </Tab>
        )
    )}
  </Tabs>
)

export default TabNav
