import { organisationNameChanged }from "~/actions/organisations"
import organisations from "~/api/organisations"
import { useAuth } from "~/context/auth"
import useMountEffect from "~/hooks/useMountEffect"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState }from "~/store"

const OrganisationName: React.FC<{ style?: React.CSSProperties }> = (props) => {
  const orgName = useSelector(
    (state: ApplicationState) => state.organisations.organisation.name
  )
  const { token, organisationId } = useAuth()
  const dispatch = useDispatch()

  useMountEffect(() => {
    if (!orgName) {
      token &&
        organisations
          .get(token, organisationId)
          .then((org) => dispatch(organisationNameChanged(org.name)))
          .catch((e: Error) => {})
    }
  })

  return (
    <span {...props} style={{ ...props.style, fontWeight: "bold" }}>
      {orgName}
    </span>
  )
}

export default OrganisationName
