import { Answer } from "~/store/answers/types"
import { UUID } from "../types"

export type QuestionWithAnswers = Omit<Question, "answers"> & {
  answers: Answer[]
}

export enum QuestionType {
  Multi = "multi",
  Single = "single",
  Numeric = "numeric",
  Drilldown = "drilldown",
  AutoDrilldown = "auto_drilldown",
}

export enum QuestionMediaType {
  QUESTION_WITH_VIDEO = "QUESTION_VIDEO",
  QUESTION_WITH_IMAGE = "QUESTION_IMAGE",
  ANSWERS_WITH_IMAGE = "ANSWERS_IMAGE",
  NO_MEDIA = "NO_MEDIA",
}

export enum QuestionColor {
  "Color1" = "Color1",
  "Color2" = "Color2",
  "Color3" = "Color3",
  "Color4" = "Color4",
  "Color5" = "Color5",
  "Color6" = "Color6",
  "Color7" = "Color7",
  "Color8" = "Color8",
}

export type DrilldownAnswerOption =
  | {
      title: string
      id: UUID
    }
  | { branchId: UUID; title: string; answers: DrilldownAnswerOption[] }

export type DrilldownConfig = {
  filter: boolean
  matchPath: boolean
  levels: number
  levelConfiguration: { label: string }[]
  answers: DrilldownAnswerOption[]
}

export type AutoDrilldownConfig = {
  filter: boolean
  levels: number
  levelConfiguration: { label: string; sourceField: string }[]
}

export type PredefinedQuestion = {
  id: UUID
  title: string
  label: string
  color: QuestionColor
  helpText: string
  next: UUID | undefined
  answers: UUID[]
  rules: UUID[]
  type: QuestionType.Single | QuestionType.Multi
  mediaType: QuestionMediaType
  config: { filter: boolean }
}

export type NumericQuestion = {
  id: UUID
  title: string
  label: string
  color: QuestionColor
  helpText: string
  next: UUID | undefined
  answers: UUID[]
  rules: UUID[]
  mediaType: QuestionMediaType
  type: QuestionType.Numeric
  config: NumericalQuestionConfig
}
export type DrilldownQuestion = {
  id: UUID
  title: string
  label: string
  color: QuestionColor
  helpText: string
  next: UUID | undefined
  answers: UUID[]
  rules: UUID[]
  type: QuestionType.Drilldown
  mediaType: QuestionMediaType
  config: DrilldownConfig
}
export type AutoDrilldownQuestion = {
  id: UUID
  title: string
  label: string
  color: QuestionColor
  helpText: string
  next: UUID | undefined
  answers: UUID[]
  rules: UUID[]
  type: QuestionType.AutoDrilldown
  mediaType: QuestionMediaType
  config: AutoDrilldownConfig
}

export type Question = PredefinedQuestion | NumericQuestion | DrilldownQuestion | AutoDrilldownQuestion

export enum NumericalInputType {
  INPUT = "INPUT",
  // SLIDER = "SLIDER",
}

export enum Position {
  PREFIX = "PREFIX",
  SUFFIX = "SUFFIX",
}

export type InputInfo = {
  label: string
  position: Position
}

export type NumericalQuestionConfig = {
  filter: boolean
  inputType: NumericalInputType.INPUT
  optional: boolean
  info: string
  placeholder: string
  allowDecimals: boolean
  inputInfo: InputInfo
  matchingFeatureLabel: string
}
// | {
//     inputType: NumericalInputType.SLIDER
//     info: string
//     min: number
//     max: number
//   }
