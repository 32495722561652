import React from "react"
import { Advisor }from "~/store/advisors"
import useNavigation from "../../../hooks/useNavigation"
import { Redirect } from "react-router"

const Dashboard: React.FC<{ advisor: Advisor }> = ({ advisor }) => {
  const { map } = useNavigation()

  return <Redirect push={false} to={map.advisorSettings(advisor.id)} />
}
export default Dashboard
