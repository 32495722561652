import useCopyToClipboard from "~/hooks/useCopyToClipboard"
import Duplicate from "./icons/Duplicate"
import Button from "./Button"

const CopyToClipboard: React.FC<{
  value: string
}> = ({ value }) => {
  const [copyStatus, copyValue] = useCopyToClipboard(value)

  const previewButtonContent = () => {
    switch (copyStatus) {
      case "copied":
        return "Copied!"
      case "failed":
        return "Oops, unable to copy!"
      default:
        return "Copy"
    }
  }

  return (
    <Button hasIconLeft onClick={copyValue}>
      <Duplicate />
      <span>{previewButtonContent()}</span>
    </Button>
  )
}

export default CopyToClipboard
