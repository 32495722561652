// @ts-nocheck
import { Check, Cross } from "~/components/icons"
import Info from "~/components/icons/Info"
import Warning from "~/components/icons/Warning"
import React from "react"
import { animated, useTransition, config } from "react-spring"
import styled from "styled-components"
import useNotifications from "~/hooks/useNotifications"

const NotificationsContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 0.5rem;
  height: 0;
  width: 50rem;
  z-index: 999999;
`

const Notifications = () => {
  const { notifications, dismiss } = useNotifications()

  const transitions = useTransition<any, any>(
    notifications,
    (notification: any) => notification.id,
    {
      config: {
        ...config.stiff,
        tension: 300,
      },
      from: { opacity: 0, height: 0, transform: "translate3d(100px, 0, 0)" },
      enter: { opacity: 1, height: 70, transform: "translate3d(0, 0, 0)" },
      leave: {
        opacity: 0,
        height: 0,
        transform: "translate3d(100px, 0, 0)",
        zIndex: -1,
      },
    }
  )

  return (
    <NotificationsContainer>
      <div className="toaster">
        {transitions.map(({ item: notification, key, props }, i) => (
          <animated.div key={key} style={props}>
            <div style={{ display: "flex" }}>
              <div className={`toast ${notification.type}`}>
                <span className={`toast-icon`}>
                  {notification.type === "warning" ? (
                    <Warning />
                  ) : notification.type === "success" ? (
                    <Check />
                  ) : notification.type === "error" ? (
                    <Cross />
                  ) : (
                    <Info />
                  )}
                </span>
                <span className="text">{notification.text}</span>
                <span>
                  {notification.action && (
                    <span
                      className="actionBtn"
                      onClick={() => {
                        notification.action?.onClick()
                        dismiss(notification.id)
                      }}
                    >
                      {notification.action?.text}
                    </span>
                  )}
                </span>
                <button
                  className="btn-toast-remove"
                  onClick={() => dismiss(notification.id)}
                >
                  <Cross />
                </button>
              </div>
            </div>
          </animated.div>
        ))}
      </div>
    </NotificationsContainer>
  )
}

export default Notifications
