/* eslint-disable no-useless-escape */
import { ColumnMapping } from "~/types"

export type ValidColumnMapping = {
  image: string
  name: string
  id: string
  url: string
  price: string | undefined
}

type Guess = ReturnType<typeof guess>

/**
 * Given a list of headers and a defined ColumnMapping returns a list of
 * mapped columns. Useful for validating if a CSV file contains the previously
 * defined columns so it can be updated corrrectly. Checking is case insensitive.
 */
export const missingColumns = (
  headers: string[],
  columnMapping: ValidColumnMapping
) =>
  Object.values(columnMapping).filter(
    (mappedColumn) => !headers.includes(mappedColumn!)
  )

/**
 * Given a list of headers will some of the required ones against a list of
 * predefined words to match on.
 */
export const guessColumnMapping = (headers: string[]): ColumnMapping => ({
  id: guessId(headers),
  name: guessName(headers),
  image: guessImage(headers),
  url: guessUrl(headers),
  price: guessPrice(headers),
})

/** Guesses the 'ID' based on common identifiers used in product data sets. */
const guessId = (headers: string[]): Guess =>
  guess(headers, incl(["id", "sku", "ean"]))

/** Guesses the 'ID' based on common identifiers used in product data sets. */
const guessPrice = (headers: string[]): Guess =>
  guess(headers, incl(["price", "pricing", "prijs"]))

/** Guesses the 'name' based on common column names used in product data sets. */
const guessName = (headers: string[]): Guess =>
  guess(headers, incl(["name", "title", "product", "naam", "titel"]))

/** Guesses the 'image' based on common column names used in product data sets. */
const guessImage = (headers: string[]): Guess =>
  guess(headers, incl(["image", "picture", "afbeelding", "foto"])) ||
  guess(headers, (str) =>
    ["image", "picture", "photo", "afbeelding", "foto"].some((e) =>
      str.includes(e)
    )
  )

/** Guesses the 'url' based on common column names used in product data sets. */
export const guessUrl = (headers: string[]): Guess =>
  guess(headers, incl(["url", "offerurl", "link", "page"]))

export const isValidURL = (str: string): boolean =>
  str === "" || (!!str.match(URLRegex) && !endsWith(str, imageExtensions))

export const isValidImageURL = (str: string): boolean =>
  str === "" || (!!str.match(URLRegex) && !endsWith(str, [".html", ".asp"]))

export const isValidProductName = (str: string): boolean => str.length >= 1

const normalCurrencyRegexes = [
  /^[€£$]?\s?\d+(,\d+)$/,
  /^[€£$]?\s?\d+(\.\d+)$/,
  /^[€£$]?\s?\d+(,\d\d\d){0,100}(\.\d\d)?$/,
  /^[€£$]?\s?\d+(\.\d\d\d){0,100}(\,\d\d)?$/,
  /^[€£$]?\s?\d+(\.\d\d\d){0,100}(\,-)?$/,
  /^[€£$]?\s?\d+(\,\d\d\d){0,100}(\.-)?$/,
  /^[€£$]?\s?\d+(,\d\d\d){0,100}(\.\d\d)?$/,
]
const iso4217CurrencyRegexes = [
  /^\-?\d+([\.]\d{1,3})*(\,\d+){0,100}(\s[A-Z]{3})?$/, // Matches 1.000,00 USD
  /^\-?\d+([\,]\d{1,3})*(\.\d+){0,100}(\s[A-Z]{3})?$/, // Matches 1,000.00 USD, -1000.00 USD
]
const currencyPatternRegexes = [
  ...normalCurrencyRegexes,
  ...iso4217CurrencyRegexes,
]

export const isValidPrice = (str: string): boolean =>
  currencyPatternRegexes.some((pattern) => pattern.test(str))

export const isValidID = (str: string): boolean => str.length >= 1

export const defaultColumnMapping = {
  id: undefined,
  name: undefined,
  url: undefined,
  image: undefined,
  price: undefined,
}

//
// Implementation
//
const incl = (matchers: string[]) => (str: string) => matchers.includes(str)

const guess = (
  headers: string[],
  matcher: (str: string) => boolean
): string | undefined => headers.find((h) => matcher(h.trim().toLowerCase()))

const endsWith = (str: string, ends: string[]) =>
  ends.some((end) => str.endsWith(end))

const imageExtensions = [".jpeg", ".jpg", ".gif", ".png", ".webp"]

// ESLint thinks \+
// eslint-disable-next-line
const URLRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/
