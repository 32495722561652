import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { useAuth } from "~/context/auth"
import { envVar, EnvVar } from "~/utils/environment"

const CatalogueNewFeed: React.FC<{
  abort: () => void
  advisorId: string
  success: () => void
}> = ({ advisorId, abort, success }) => {
  const [ports, setupPorts] = React.useState<any | undefined>(undefined)
  const { token: authToken } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string

  const { productLabel } = useAdvisorContext()

  React.useEffect(() => {
    if (ports) {
      ports?.abort?.subscribe(abort)
      ports?.success?.subscribe(success)
      return () => {
        ports?.abort?.unsubscribe(abort)
        ports?.sucess?.unsubscribe(success)
      }
    }
  }, [ports, abort, success])

  const seed = React.useMemo(
    () => Array.from(crypto.getRandomValues(new Uint32Array(4))),
    []
  )

  return (
    // @ts-ignore
    <Elm
      // @ts-ignore
      src={ElmModule.CatalogueNewFeed}
      flags={{
        advisorId: advisorId,
        apiUrl: apiUrl,
        authToken: authToken,
        productLabel: productLabel,
        seed: seed,
      }}
      ports={setupPorts}
    />
  )
}

export default CatalogueNewFeed
