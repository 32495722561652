import {
  getQuestion,
  Question,
  QuestionColor,
  QuestionMediaType,
  QuestionType,
}from "~/store/questions"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState }from "~/store"
import {
  changeMediaType,
  changeNextQuestion,
  changeQuestion,
  changeQuestionColor,
  changeQuestionHelpText,
  changeQuestionLabel,
  changeQuestionTypeAndRemoveRules,
}from "~/actions/questions"
import { UUID }from "~/store/types"

export default (advisorId: UUID, questionId: UUID) => {
  const dispatch = useDispatch()
  const question : Question | undefined = useSelector((state: ApplicationState) => {
    const q = getQuestion(state, questionId)
    if(!q) return undefined
    const next = state.flow[advisorId].nodes[q?.id || ""]
    return { ...q, next: next ? next : q?.next }
  })

  return {
    question,
    changeNextQuestion: (newId: UUID) =>
      question &&
      dispatch(changeNextQuestion(question.id, newId, { advisorId })),
    changeQuestionTitle: (title: string) =>
      question && dispatch(changeQuestion(question.id, title, { advisorId })),
    changeQuestionLabel: (label: string) =>
      question &&
      dispatch(changeQuestionLabel(question.id, label, { advisorId })),
    changeQuestionColor: (color: QuestionColor) =>
      question &&
      dispatch(changeQuestionColor(question.id, color, { advisorId })),
    changeQuestionType: (type: QuestionType) =>
      question &&
      dispatch(changeQuestionTypeAndRemoveRules(question, type, { advisorId })),
    changeQuestionHelpText: (text: string) =>
      question &&
      dispatch(changeQuestionHelpText(question.id, text, { advisorId })),
    changeQuestionMediaType: (type: QuestionMediaType) =>
      question && dispatch(changeMediaType(question.id, type, { advisorId })),
  }
}
