import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router"
import useDatasource from "~/hooks/useDatasource"
import useQuestions from "~/hooks/useQuestions"
import qs from "qs"
import useNavigation from "~/hooks/useNavigation"
import { Advisor }from "~/store/advisors"
import LoadingSpinner from "~/components/Loading"
import { useTranslation } from "react-i18next"
import EmptyState from "./EmptyState"
import Page, { PageHeader } from "~/components/advisors/Page"
import { Matching } from "~/elm-tsx-wrappers/Matching"

type AdviceProps = {
  advisor: Advisor
}

const Advice: React.FC<AdviceProps> = ({ advisor }) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { map } = useNavigation()

  const { questions } = useQuestions(advisor.id)
  const { get, dataSource, dataSourceLoading } = useDatasource(advisor.id, true)


  const questionId = qs.parse(
    location.search.substring(1, location.search.length)
  )["questionId"] as string

  useEffect(() => {
    if (questionId && !questions.map((q) => q.id).includes(questionId)) {
      history.replace(map.advice(advisor.id))
    }
  }, [advisor.id, history, map, questions, questionId])

  useEffect(() => {
    get()
  }, [get])

  if (dataSourceLoading)
    return (
      <Page>
        <LoadingSpinner />
      </Page>
    )

  if (!dataSource)
    return (
      <Page>
        <PageHeader>
          <h1>Matching</h1>
        </PageHeader>
        <EmptyState
          text={t("productMapping.emptyState.text")}
          button={t("productMapping.emptyState.button")}
          href={map.catalogue(advisor.id)}
        />
      </Page>
    )



  return <Matching advisorId={advisor.id} dataSource={dataSource} />

}
export default React.memo(Advice)
