import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Field from "~/components/forms/Field"
import Button from "~/components/Button"
import Logo from "../../components/Logo"
import styled from "styled-components"
import HelpText from "../../components/HelpText"
import FormSection from "~/components/forms/FormSection"

const Login = styled(Link)`
  margin-top: 1rem;
  display: block;
  text-align: center;
  font-size: 0.8rem;
  &:hover {
    text-decoration: underline;
  }
`

const ForgotPasswordForm: React.FC<{
  forgot: (email: string) => Promise<boolean>
  email: string
  setEmail: (email: string) => void
}> = ({ forgot, email, setEmail }) => {
  const { t } = useTranslation()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const valid = email.length >= 3

  const onSubmit = async e => {
    e.preventDefault()

    valid && forgot(email)
      .then(() => {
        setSuccess(true)
      })
      .catch(error => {
        setError(t("login.reset.error.other"))
      })
  }

  return (
    <>
      {!success ? (
        <form onSubmit={onSubmit} data-test-id="forgot-password-form">
          <div style={{ textAlign: "center", marginBottom: "2rem" }}>
            <Logo style={{ width: "8rem" }} />
            <p style={{ marginTop: 0 }}>{t("login.forgot.intro")}</p>
          </div>
          <FormSection>
            <Field
              onChange={setEmail}
              label={t("login.email")}
              type="email"
              value={email}
            />
            {error && <HelpText hasError>{error}</HelpText>}
          </FormSection>
          <Button primary fullWidth disabled={!valid}>
            {t("login.forgot.submit")}
          </Button>
          <Login to="/login">{t("login.forgot.backToLogin")}</Login>
        </form>
      ) : (
        <div>
          <div style={{ textAlign: "center", marginBottom: "2rem" }}>
            <Logo style={{ width: "8rem" }} />
            <p style={{ marginTop: 0 }}>{t("login.forgot.successTitle")}</p>
            <p style={{ textAlign: "left", marginTop: 0 }}>
              {t("login.forgot.successMessage.1")}
            </p>
            <p style={{ textAlign: "left", marginTop: 0 }}>
              {t("login.forgot.successMessage.2")}
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default ForgotPasswordForm
