import React, { useCallback, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { AdvisorStatus } from "~/store/advisors"
import PublishButton from "../PublishButton"
import { useTranslation } from "react-i18next"
import HelpText from "~/components/HelpText"
import DT from "~/lib/DT"
import { useAuth } from "~/context/auth"
import { advisors } from "~/api"
import { AdvisorValidationResult, NoticeType } from "~/types"
import NoticeList from "./NoticeList"
import LoadingSpinner from "~/components/LoadingSpinner"
import useNotifications from "~/hooks/useNotifications"
import { PageHeader } from "../Page"
import { CopyPreviewUrl, PreviewUrl } from "~/components/Preview/CopyPreviewUrl"
import Tooltip from "~/components/Tooltip"
import { Release } from "~/api/advisors"
import TutorialLink from "~/components/TutorialLink"

const StatusComp = styled.div<{ status: string }>`
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 400;
  color: ${(p) => (p.status === "live" ? p.theme.shade4 : p.theme.shade3)};
  .dot {
    border-radius: 100%;
    margin: 0 0.25rem;
    height: 1rem;
    width: 1rem;
    background: ${(p) =>
      p.status === "live" ? p.theme.tertiary : p.theme.shade3};
    ${(p) =>
      p.status === "live" &&
      css`
        animation: pulse 1s infinite;
      `}
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 223, 167, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(0, 223, 167, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 223, 167, 0);
    }
  }
`

const DisplayHorizontal = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`

const PreviewUrlContainer = styled.span`
  display: block;
  white-space: nowrap;
  overflow: auto;
  font-size: 0.75rem;
  padding: 1em;
  border-radius: ${(p) => p.theme.borderRadius};
  background: ${(p) => p.theme.shade2};
  font-family: ${(p) => p.theme.fontMonospace};
`

const Status = ({ status }) => {
  return (
    <StatusComp status={status}>
      {status}
      <span className="dot">
        <span className="blob"></span>
      </span>
    </StatusComp>
  )
}

export default ({ advisor }) => {
  const { t } = useTranslation()
  const { token, organisationId } = useAuth()
  const live = advisor.status === AdvisorStatus.Online
  const [latestRelease, setLatestRelease] = useState<Release | undefined>(
    undefined
  )
  const [loading, setLoading] = useState(true)
  const [validations, setValidations] = useState<AdvisorValidationResult>({
    advice: [],
    questions: [],
    media: [],
    answers: [],
    rules: [],
    screens: [],
    lookAndFeel: [],
    catalogue: [],
  })
  const [error, setError] = useState("")

  const { notify } = useNotifications()

  const notifyError = useCallback(
    () =>
      notify({
        text: t("errors.error"),
        type: "error",
      }),
    [t, notify]
  )

  const valid =
    !!validations &&
    Object.values(validations)
      .map((notice) => notice.filter((n) => n.type !== NoticeType.WARNING))
      .every((val) => val.length === 0) &&
    !error

  useEffect(() => {
    setError("")

    Promise.all([
      advisors.latestRelease(advisor.id, token!, organisationId),
      advisors.validation(advisor.id, token!, organisationId),
    ])
      .then(([release, validation]) => {
        setLatestRelease(release)
        setValidations(validation)
      })
      .catch(() => {
        setError(t("errors.error"))
        notifyError()
      })
      .finally(() => setLoading(false))
  }, [advisor.id, token, t, notifyError])

  const publishHandler = (resp) => {
    if (resp.status === "LIVE") {
      setLatestRelease({
        releasedAt: resp.releasedAt,
        status: resp.status,
        lastEdited: resp.lastEdited,
      })
    }
  }

  return (
    <>
      <PageHeader
      // actions={
      //   <PublishButton
      //     primary
      //     disabled={!valid}
      //     advisor={advisor}
      //     onSuccess={publishHandler}
      //     onError={() => notifyError()}
      //   />
      // }
      >
        <h1>{t("integration.status.title")}</h1>
      </PageHeader>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="card card-medium">
          <DisplayHorizontal>
            <p style={{ margin: 0 }}>
              {live && latestRelease ? (
                <>
                  {t("integration.status.online")}{" "}
                  {DT.format(latestRelease.releasedAt)}.
                </>
              ) : (
                t("integration.status.offline")
              )}
            </p>
            <DisplayHorizontal>
              <Status status={live ? "live" : "offline"} />
              <PublishButton
                primary
                disabled={!valid}
                advisor={advisor}
                onSuccess={publishHandler}
                onError={() => notifyError()}
              />
            </DisplayHorizontal>
          </DisplayHorizontal>
          <div className="pt-1">
            <TutorialLink
              article="content-check"
              label="Check the contents of your product finder"
            />
          </div>
          {
            <div>
              <p>
                {live
                  ? t("integration.release.intro.update")
                  : t("integration.release.intro.publish")}
              </p>
              {validations && (
                <NoticeList advisor={advisor} result={validations} />
              )}

              {error && <HelpText hasError={!!error}>{error}</HelpText>}
            </div>
          }
          <hr />

          <div>
            <p>
              Want your colleagues to check out your work before publishing your
              app? Send them a link to the preview:{" "}
            </p>
            <p>
              <PreviewUrlContainer>
                <PreviewUrl advisorId={advisor.id} />
              </PreviewUrlContainer>
            </p>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                content={
                  <p style={{ margin: 0 }}>
                    Good to know: the preview link shows the most recently
                    generated preview of your app. If you made any changes after
                    sharing the link, make sure to update the preview by
                    clicking the 'Preview' button in Aiden.
                  </p>
                }
                enterDelay={500}
              >
                <CopyPreviewUrl advisorId={advisor.id} />
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
