import React from "react"
import Advisors from "../../components/advisors/Advisors"
import useNavigation from "~/hooks/useNavigation"

const AdvisorsContainer = () => {
  const { toAdvisorCreate } = useNavigation()

  return <Advisors newAdvisor={() => toAdvisorCreate()} />
}

export default AdvisorsContainer
