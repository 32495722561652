import React from "react"
import { UUID }from "~/store/types"
import { ApplicationState }from "~/store"

import { useSelector, useDispatch } from "react-redux"
import { getAnswersWithQuestionId }from "~/store/answers/selectors"
import Answers from "../Answers"
import { reorderAnswer }from "~/actions/answers"
import { Question }from "~/store/questions"

const SelectAnswersEditor: React.FC<{
  advisorId: UUID
  question: Question
}> = (props) => {
  const { advisorId, question } = props
  const dispatch = useDispatch()

  const answers = useSelector((state: ApplicationState) =>
    getAnswersWithQuestionId(state, question.id, question.answers || [])
  )

  return (
    <>
      <Answers
        advisorId={advisorId}
        questionId={question.id}
        answers={answers}
        reorderAnswer={(oldPosition: number, newPosition: number) =>
          dispatch(
            reorderAnswer(question.id, oldPosition, newPosition, { advisorId })
          )
        }
      />
    </>
  )
}

export default SelectAnswersEditor
