import React, { useState, useEffect, useRef, useCallback } from "react"
import ReactCrop, { Crop, PercentCrop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import Button from "./Button"
import Avatar from "./Avatar"
import FileInput from "./forms/FileInput"
import { useTranslation } from "react-i18next"
import { Cross } from "./icons"
import styled from "styled-components"

const ImageSelection = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }
`

const pixelRatio = 1

const AvatarSelector: React.FC<{
  name: string
  image?: string
  currentImage?: string
  isCropping: boolean
  onSelectImage: (e) => void
  setIsCropping: (isCropping: boolean) => void
  setCrop: (crop: Crop) => void
}> = ({
  image,
  currentImage,
  name,
  isCropping,
  onSelectImage,
  setIsCropping,
  setCrop: setExternalCrop,
}) => {
  const { t } = useTranslation()

  const [crop, setCrop] = useState<PercentCrop>({
    width: 100,
    aspect: 1,
    unit: "%",
  })
  const [pxCrop, setPxCrop] = useState<Crop>({
    width: 100,
    aspect: 1,
    unit: "px",
  })
  const [measurements, setMeasurements] = useState<{
    width: number
    height: number
  }>()
  const imgRef = useRef<HTMLImageElement>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const fileSelectRef = useRef<HTMLInputElement>(null)
  const [croppedPreview, setCroppedPreview] = useState("")

  const setNewCrop = (pxCrop: Crop, percentCrop: PercentCrop) => {
    setCrop(percentCrop)
    setPxCrop(pxCrop)
  }

  const onLoad = useCallback(
    (img) => {
      // @ts-ignore
      imgRef.current = img
    },
    [imgRef]
  )

  const clear = (e) => {
    e.preventDefault()
    if (fileSelectRef?.current?.value) {
      fileSelectRef.current.value = ""
    }
    setCroppedPreview("")
    onSelectImage(undefined)
    setIsCropping(false)
    setExternalCrop(crop)
    setMeasurements(undefined)
  }

  useEffect(() => {
    if (!pxCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext("2d")

    canvas.width = pxCrop.width || 0 * pixelRatio
    canvas.height = pxCrop.height || 0 * pixelRatio

    ctx!.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx!.imageSmoothingEnabled = false

    ctx!.drawImage(
      image,
      pxCrop.x! * scaleX,
      pxCrop.y! * scaleY,
      pxCrop.width! * scaleX,
      pxCrop.height! * scaleY,
      0,
      0,
      pxCrop.width!,
      pxCrop.height!
    )
  }, [pxCrop])

  return (
    <div>
      <div>
        <canvas
          ref={previewCanvasRef}
          style={{
            width: pxCrop?.width ?? 0,
            height: pxCrop?.height ?? 0,
            display: "none",
          }}
        />
      </div>
      <ImageSelection>
        <Avatar
          image={croppedPreview || currentImage}
          name={name}
          size="large"
        />

        {image ? (
          <>
            <Button hasIconLeft onClick={clear}>
              <Cross />
              {t("cancel")}
            </Button>
            {isCropping && (
              <Button
                primary
                onClick={() => {
                  setCroppedPreview(
                    previewCanvasRef.current?.toDataURL("image/jpg") || ""
                  )

                  const realSize = (
                    val: number | undefined,
                    total: number | undefined
                  ) => Math.floor(((val || 0) / 100) * (total || 0))

                  const externalCrop = {
                    x: realSize(crop.x, measurements?.width),
                    y: realSize(crop.y, measurements?.height),
                    width: realSize(crop.width, measurements?.width),
                    height: realSize(crop.height, measurements?.height),
                  }

                  setExternalCrop(externalCrop)
                  setIsCropping(false)
                }}
              >
                {t("crop")}
              </Button>
            )}
          </>
        ) : (
          <FileInput
            ref={fileSelectRef}
            label={t("forms.selectImage")}
            onChange={(e) => {
              onSelectImage(e)
              if (e?.target?.files && e.target.files.length > 0) {
                const files = e.target.files || []

                let img = new Image()
                img.src = window.URL.createObjectURL(files[0])
                img.onload = () => {
                  setMeasurements({ width: img.width, height: img.height })
                }
              }

              setIsCropping(!!e.target.value)
            }}
            accept=".gif,.jpg,.jpeg,.png"
          />
        )}
      </ImageSelection>
      {isCropping && (
        <div style={{ margin: "1rem 0" }}>
          <ReactCrop
            src={image || ""}
            crop={crop}
            onImageLoaded={onLoad}
            onChange={setNewCrop}
            circularCrop={true}
          />
        </div>
      )}
    </div>
  )
}

export default AvatarSelector
