import styled, { css } from "styled-components"

export const Placeholders = css`
  ::-webkit-input-placeholder {
    /* Edge */
    color: ${(p) => p.theme.shade4};
    opacity: 1;
    font-weight: 500;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(p) => p.theme.shade4};
    opacity: 1;
    font-weight: 500;
  }

  ::placeholder {
    color: ${(p) => p.theme.shade4};
    opacity: 1;
    font-weight: 500;
  }
`

export const InputState = css<{
  hasError?: boolean
  disabled?: boolean
}>`
  background-color: ${(p) => {
    if (p.disabled) return p.theme.shade3
    if (p.hasError) return p.theme.errorBackground
    return p.theme.background
  }};
  color: ${(props) => (props.disabled ? props.theme.shade4 : props.theme.main)};
  border: 2px solid
    ${(p) => {
      if (p.disabled) return p.theme.shade2
      if (p.hasError) return p.theme.error
      return p.theme.shade3
    }};
  &:focus {
    outline: none;
    border-color: ${(p) => {
      if (p.hasError) return p.theme.error
      return p.theme.secondary
    }};
  }
`
