import styled from "styled-components"
import React from "react"
import Select from "./selection/Select"
import SelectionUI from "./selection/SelectionUI"
import { getCurrentColor, questionLabelColors } from "~/themes"
import { QuestionLabelColor } from "themes/types"

const ColorEditor = styled.div`
  ${SelectionUI.SelectedValue} {
    padding: ${(p) =>
      `0.375rem 2rem 0.375rem ${p.theme.labelPadding}`};
  }
`
const ColorPreview = styled.div<{ col: QuestionLabelColor }>`
  border-radius: 50%;
  background: ${(p) => p.col.color};
  border: 2px solid rgba(0,0,0,0.05);
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
`

const ColorPreviewText = styled.span<{ col: QuestionLabelColor }>`
  color: ${(p) => p.col.text};
  font-family: ${(p) => p.theme.fontHeader};
  font-weight: bold;
  margin-left: 0.5rem;
`

export type ColorSelectProps = {
  value: any
  onSelect: (value: any) => void
}

export const QuestionColorEditor: React.FC<ColorSelectProps> = (props) => {
  const onSelect = (selected: any, setOpen: (open: boolean) => void) => {
    if (props.value !== selected) props.onSelect(selected)
    setOpen(false)
  }
  const options: QuestionLabelColor[] = questionLabelColors as QuestionLabelColor[]

  return (
    <ColorEditor>
      <Select
        selectionElement={(open) =>
           (
            <SelectionUI.SelectedValue small>
              <ColorPreview col={getCurrentColor(props.value)} />
              <SelectionUI.Chevron isOpen={open} />
            </SelectionUI.SelectedValue>
          )
        }
        listElement={
          ((opt) => {
            const color = getCurrentColor(opt.value)
            return (
            <SelectionUI.SelectOption>
              <ColorPreview col={color} />
              <ColorPreviewText col={color}>{opt.label}</ColorPreviewText>
            </SelectionUI.SelectOption>
          )})
        }
        options={options}
        value={props.value}
        inline={true}
        onSelect={onSelect}
        position="bottom"
      />
    </ColorEditor>
  )
}

export default QuestionColorEditor
