import React from "react"
import SingleSelect from "~/components/selection/SingleSelect"
import WebFont from "webfontloader"
import { sortBy } from "lodash"

function loadFonts(fonts: string[]) {
  const families = fonts.map((f) => `${f}:400,700`)
  const params = families
    .map((f) => f.replace(" ", "+"))
    .join(
      "%7C" // %7C = | = pipe symbol
    )
  WebFont.load({
    // google: {
    //   families: [`${theme.font}:400,700`, `${theme.fontHeader}:400,700`],
    // },
    custom: {
      families: families,
      urls: [`https://fonts.bunny.net/css?family=${params}`],
    },
  })
}

const FontSelect: React.FC<{
  value: string
  options: string[]
  onSelect: (family: string) => void
}> = (props) => {
  React.useEffect(() => {
    loadFonts(props.options)
  }, [props.options.length])

  return (
    <SingleSelect
      value={props.value}
      testId="question-type-input"
      onSelect={props.onSelect}
      options={sortBy(props.options, [(opt) => opt.toLowerCase()]).map(
        (opt) => {
          return {
            label: <span style={{ fontFamily: opt }}>{opt}</span>,
            value: opt,
          }
        }
      )}
      listElement={(opt) => {
        return (
          <span
            className={
              "select__option " +
              (opt.value === props.value ? "select__option--selected" : "")
            }
            style={{ fontFamily: opt.value }}
          >
            {opt.value}
          </span>
        )
      }}
    />
  )
}

export default FontSelect
