import CheckCircle from "~/components/icons/CheckCircle"
import Saving from "~/components/icons/Saving"
import SyncProblem from "~/components/icons/SyncProblem"
import useAdvisors from "~/hooks/useAdvisors"
import usePrevious from "~/hooks/usePrevious"
import DT from "~/lib/DT"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ApplicationState }from "~/store"
import { AdvisorSavedState }from "~/store/advisors/savingStateReducer"
import { hasChanges }from "~/store/flowChangeTracker/selectors"
import styled, { keyframes } from "styled-components"

const displayMessage = keyframes`
  0% {
    transform: translateX(1rem);
    opacity: 0;
  } 100%% {
    transform: translateX(0);
    opacity: 1;
  }
`

const Appear = styled.div`
  animation: ${displayMessage} 250ms ease-out;
`

const Txt = styled.span`
  color: ${(p) => p.theme.shade4};
  font-size: 0.75rem;
  font-weight: 600;
`

const Icn = styled.span`
  &,
  svg {
    width: 1.5rem;
  }
  color: ${(p) => p.theme.secondary};
`
const OkIcn = styled.span`
  &,
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  color: ${(p) => p.theme.success};
`

const ErrorIcn = styled.span`
  color: ${(p) => p.theme.error};
`

const AutoSaveStatus: React.FC<{ advisorId: string }> = ({ advisorId }) => {
  const { t } = useTranslation()
  const pendingChanges = useSelector((state: ApplicationState) =>
    hasChanges(state, advisorId)
  )

  const { saveAdvisor } = useAdvisors()

  const [showFeedback, setShowFeedback] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tick, setTick] = React.useState(0)

  const state:
    | {
        state: AdvisorSavedState
        lastSaved: Date
      }
    | undefined = useSelector((state: ApplicationState) => {
    const a = state.advisorSavingState[advisorId]

    return a
  })
  const previousState = usePrevious(state.state) || state.state

  useEffect(() => {
    let mounted = true
    if (previousState !== state.state) {
      setShowFeedback(true)
      setTimeout(() => {
        if (mounted) setShowFeedback(false)
      }, 3000)
    }
    return () => {
      mounted = false
    }
  }, [state.state, previousState])

  useEffect(() => {
    let mounted = true
    setInterval(() => {
      if (mounted) setTick((tick) => (tick === 0 ? 1 : 0))
    }, 5000)
    return () => {
      mounted = false
    }
  }, [])

  const display =
    pendingChanges && state.state === AdvisorSavedState.SAVED ? (
      <>
        <Icn>
          <Saving />
        </Icn>
      </>
    ) : state.state === AdvisorSavedState.SAVED ? (
      <>
        {showFeedback ? (
          <Appear>
            <Txt>{t("general.feedback.changesSaved")}</Txt>
          </Appear>
        ) : (
          <Txt>{"Last saved " + DT.format(state.lastSaved.getTime())}</Txt>
        )}

        <OkIcn>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
              clipRule="evenodd"
            />
          </svg>

          {/* <CheckCircle /> */}
        </OkIcn>
      </>
    ) : state.state === AdvisorSavedState.FAILED ? (
      <>
        <Appear>
          <Txt>
            {t("errors.saveShort")}{" "}
            <span
              style={{ cursor: "pointer" }}
              className="link"
              onClick={() => saveAdvisor(advisorId)}
            >
              {t("general.tryAgain")}
            </span>
          </Txt>
        </Appear>
        <ErrorIcn>
          <SyncProblem />
        </ErrorIcn>
      </>
    ) : state.state === AdvisorSavedState.PENDING ? (
      <>
        <Icn>
          <Saving />
        </Icn>
      </>
    ) : null

  return (
    <div
      style={{ display: "flex", alignItems: "baseline", gap: "0.25rem" }}
      data-test-id="qa-autosave-status"
    >
      {display}
    </div>
  )
}

export default AutoSaveStatus
