import useQuestion from "../../../../hooks/useQuestion"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import Label from "~/components/forms/Label"
import { SingleSelect } from "~/components/selection"
import ElmMediaEditor from "~/elm-tsx-wrappers/MediaEditor"
import { QuestionMediaType, QuestionType } from "~/store/questions"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar } from "~/utils/environment"
import { Modal, ModalButtons, ModalContent } from "~/components/Modal"
import Button from "~/components/Button"
import { media as MediaAPI } from "~/api"
import { useSelector } from "react-redux"
import { ApplicationState } from "~/store"
import { getAnswers } from "~/store/answers/selectors"

const mediaChecker = (advisorId, token, organisationId) => {
  return {
    checkHasImages: async (answerIds) =>
      answerIds.length
        ? MediaAPI.mediaForAdvisor(advisorId, answerIds, {
          token: token!,
          organisationId: organisationId,
        }).then((images) => !!images.length)
        : false,
    checkHasQuestionMedia: async (questionId) =>
      MediaAPI.mediaForQuestion(advisorId, questionId, {
        token,
        organisationId,
      }).then((media) => !!media.length),
  }
}

const MediaEditor: React.FC<{
  advisorId: string
  questionId: string
}> = ({ questionId, advisorId }) => {
  const { question, changeQuestionMediaType } = useQuestion(
    advisorId,
    questionId
  )
  const { token, organisationId } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string
  const { t } = useTranslation()
  const checker = mediaChecker(advisorId, token, organisationId)

  const [confirm, setConfirm] = React.useState<
    undefined | { chosenType: QuestionMediaType }
  >(undefined)

  const transitionMediaTypeTo = async (chosenType: QuestionMediaType) => {
    if (!question) return

    if (question.mediaType === QuestionMediaType.NO_MEDIA) {
      changeQuestionMediaType(chosenType)
    } else if (question.mediaType === QuestionMediaType.ANSWERS_WITH_IMAGE) {
      const hasImages = await checker.checkHasImages(question.answers)
      hasImages
        ? setConfirm({ chosenType })
        : changeQuestionMediaType(chosenType)
    } else {
      const hasMedia = await checker.checkHasQuestionMedia(question!!.id)
      hasMedia
        ? setConfirm({ chosenType })
        : changeQuestionMediaType(chosenType)
    }
  }

  const answers =
    useSelector(
      (state: ApplicationState) => question && getAnswers(state, question)
    )?.map((a) => {
      return {
        id: a.id,
        title: a.title,
      }
    }) || []

  if (!question) return null

  const value = question.mediaType
  const mediaTypeOptions = [
    {
      label: t("mediaEditor.options.noMedia"),
      value: QuestionMediaType.NO_MEDIA,
    },
    {
      label: t("mediaEditor.options.answersWithImage"),
      value: QuestionMediaType.ANSWERS_WITH_IMAGE,
      hide:
        question.type === QuestionType.Numeric ||
        question.type === QuestionType.Drilldown,
    },
    {
      label: "Question with image",
      value: QuestionMediaType.QUESTION_WITH_IMAGE,
    },
    {
      label: "Question with video",
      value: QuestionMediaType.QUESTION_WITH_VIDEO,
    },
  ]

  return (
    <>
      <div data-test-id="media-editor">
        <p>
          <Trans
            i18nKey="mediaEditor.intro"
            values={{
              link: t("mediaEditor.introLink"),
            }}
            components={[
              <a
                href="https://docs.google.com/presentation/d/1XWXs4g2zLdx_-4PZX0LJhxI07JprfUE1qJxkNKeGGcw/edit?usp=sharing"
                target="_blank" className="link">
                Label
              </a>,
            ]}
          />
        </p>
        <div>
          <Label>{t("mediaEditor.mediaTypeLabel")}</Label>
        </div>
        <SingleSelect
          onSelect={(value) => transitionMediaTypeTo(value)}
          options={mediaTypeOptions}
          value={question.mediaType}
        />
      </div>

      <ElmMediaEditor
        advisorId={advisorId}
        mediaId={question.id}
        answers={answers}
        authToken={token as string}
        apiUrl={apiUrl}
        mediaType={value}
      />

      {confirm && (
        <Modal onClose={() => setConfirm(undefined)}>
          <ModalContent>
            <h2>Are you sure you want to change the media type?</h2>
            <p>
              Changing the media type will remove the current media for this
              question.
            </p>

            <ModalButtons>
              <Button onClick={() => setConfirm(undefined)}>
                {t("cancel")}
              </Button>
              <Button
                primary
                onClick={() => {
                  changeQuestionMediaType(confirm.chosenType)
                  setConfirm(undefined)
                }}
              >
                {t("confirm")}
              </Button>
            </ModalButtons>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default MediaEditor
