import { keyframes } from "styled-components"

const fadeIn = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1;}
`

export const appear = keyframes`
0% {
  transform: translateY(1rem);
  opacity: 0;
} 100%% {
  transform: translateY(0);
  opacity: 1;
}
`

export default {
  fadeIn,
  appear,
}
