import {
  AdvisorActionTypes,
  ADVISOR_PUBLISHED,
  CHANGE_ADVISOR_STYLE,
  FETCH_ADVISOR_SUCCESS,
  LATEST_RELEASE,
  NEW_ADVISOR_SUCCESS,
  TRACK_CHANGE,
  UPDATE_ADVISOR_SUCCESS,
}from "~/actions/advisors"

const defaultState = {}

type FlowState = Record<
  string,
  { lastUpdated: Date | undefined; lastReleased: Date | undefined }
>

export const reducer = (
  state: FlowState = defaultState,
  action: AdvisorActionTypes
): FlowState => {
  switch (action.type) {
    case NEW_ADVISOR_SUCCESS:
    case FETCH_ADVISOR_SUCCESS: {
      const { advisor } = action.payload
      const advisorId = advisor.id
      const target = state[advisorId]

      return {
        ...state,
        [advisorId]: {
          lastUpdated: advisor.appUpdateInfo.updatedAt || advisor.updatedAt,
          lastReleased: target?.lastReleased,
        },
      }
    }
    case UPDATE_ADVISOR_SUCCESS: {
      const { advisor } = action.payload
      const advisorId = advisor.id
      const target = state[advisorId]

      return {
        ...state,
        [advisorId]: {
          lastUpdated: new Date(),
          lastReleased: target.lastReleased,
        },
      }
    }

    case TRACK_CHANGE:
    case CHANGE_ADVISOR_STYLE: {
      const { advisorId } = action.payload
      const target = state[advisorId]

      return {
        ...state,
        [advisorId]: {
          lastUpdated: new Date(),
          lastReleased: target.lastReleased,
        },
      }
    }

    case LATEST_RELEASE: {
      const { advisorId, releasedAt, lastEdited } = action.payload

      return {
        ...state,
        [advisorId]: {
          lastUpdated: lastEdited,
          lastReleased: releasedAt,
        },
      }
    }

    case ADVISOR_PUBLISHED: {
      const { publishedAt } = action.payload
      const advisorId = action.payload.advisorId

      return {
        ...state,
        [advisorId]: {
          lastUpdated: publishedAt,
          lastReleased: publishedAt,
        },
      }
    }
    default:
      return state
  }
}
