/**
 * title: "List of 217 language codes: ISO 639-1.",
 * link: "http://github.com/josantonius/PHP-LANGUAGECODE",
 * comment: "Compiled from https://wikipedia.org/wiki/List_of_ISO_639-1_CODES"
 */
const localeOptions = {
  aa: "Afar",
  ab: "Abkhazian",
  af: "Afrikaans",
  am: "Amharic",
  ar: "Arabic",
  "ar-AE": "Arabic (U.A.E.)",
  "ar-BH": "Arabic (Bahrain)",
  "ar-DZ": "Arabic (Algeria)",
  "ar-EG": "Arabic (Egypt)",
  "ar-IQ": "Arabic (Iraq)",
  "ar-JO": "Arabic (Jordan)",
  "ar-KW": "Arabic (Kuwait)",
  "ar-LB": "Arabic (Lebanon)",
  "ar-LY": "Arabic (libya)",
  "ar-MA": "Arabic (Morocco)",
  "ar-OM": "Arabic (Oman)",
  "ar-QA": "Arabic (Qatar)",
  "ar-SA": "Arabic (Saudi Arabia)",
  "ar-SY": "Arabic (Syria)",
  "ar-TN": "Arabic (Tunisia)",
  "ar-YE": "Arabic (Yemen)",
  as: "Assamese",
  ay: "Aymara",
  az: "Azeri",
  ba: "Bashkir",
  be: "Belarusian",
  bg: "Bulgarian",
  bh: "Bihari",
  bi: "Bislama",
  bn: "Bengali",
  bo: "Tibetan",
  br: "Breton",
  ca: "Catalan",
  co: "Corsican",
  cs: "Czech",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  "de-AT": "German (Austria)",
  "de-CH": "German (Switzerland)",
  "de-LI": "German (Liechtenstein)",
  "de-LU": "German (Luxembourg)",
  div: "Divehi",
  dz: "Bhutani",
  el: "Greek",
  en: "English",
  "en-AU": "English (Australia)",
  "en-BZ": "English (Belize)",
  "en-CA": "English (Canada)",
  "en-GB": "English (United Kingdom)",
  "en-IE": "English (Ireland)",
  "en-JM": "English (Jamaica)",
  "en-NZ": "English (New Zealand)",
  "en-PH": "English (Philippines)",
  "en-TT": "English (Trinidad)",
  "en-US": "English (United States)",
  "en-ZA": "English (South Africa)",
  "en-ZW": "English (Zimbabwe)",
  eo: "Esperanto",
  es: "Spanish",
  "es-AR": "Spanish (Argentina)",
  "es-BO": "Spanish (Bolivia)",
  "es-CL": "Spanish (Chile)",
  "es-CO": "Spanish (Colombia)",
  "es-CR": "Spanish (Costa Rica)",
  "es-DO": "Spanish (Dominican Republic)",
  "es-EC": "Spanish (Ecuador)",
  "es-ES": "Spanish (España)",
  "es-GT": "Spanish (Guatemala)",
  "es-HN": "Spanish (Honduras)",
  "es-MX": "Spanish (Mexico)",
  "es-NI": "Spanish (Nicaragua)",
  "es-PA": "Spanish (Panama)",
  "es-PE": "Spanish (Peru)",
  "es-PR": "Spanish (Puerto Rico)",
  "es-PY": "Spanish (Paraguay)",
  "es-SV": "Spanish (El Salvador)",
  "es-US": "Spanish (United States)",
  "es-UY": "Spanish (Uruguay)",
  "es-VE": "Spanish (Venezuela)",
  et: "Estonian",
  eu: "Basque",
  fa: "Farsi",
  fi: "Finnish",
  fj: "Fiji",
  fo: "Faeroese",
  fr: "French",
  "fr-BE": "French (Belgium)",
  "fr-CA": "French (Canada)",
  "fr-CH": "French (Switzerland)",
  "fr-LU": "French (Luxembourg)",
  "fr-MC": "French (Monaco)",
  fy: "Frisian",
  ga: "Irish",
  gd: "Gaelic",
  gl: "Galician",
  gn: "Guarani",
  gu: "Gujarati",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  hr: "Croatian",
  hu: "Hungarian",
  hy: "Armenian",
  ia: "Interlingua",
  id: "Indonesian",
  ie: "Interlingue",
  ik: "Inupiak",
  in: "Indonesian",
  is: "Icelandic",
  it: "Italian",
  "it-CH": "Italian (Switzerland)",
  iw: "Hebrew",
  ja: "Japanese",
  ji: "Yiddish",
  jw: "Javanese",
  ka: "Georgian",
  kk: "Kazakh",
  kl: "Greenlandic",
  km: "Cambodian",
  kn: "Kannada",
  ko: "Korean",
  kok: "Konkani",
  ks: "Kashmiri",
  ku: "Kurdish",
  ky: "Kirghiz",
  kz: "Kyrgyz",
  la: "Latin",
  ln: "Lingala",
  lo: "Laothian",
  ls: "Slovenian",
  lt: "Lithuanian",
  lv: "Latvian",
  mg: "Malagasy",
  mi: "Maori",
  mk: "FYRO Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mo: "Moldavian",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  my: "Burmese",
  na: "Nauru",
  "nb-NO": "Norwegian (Bokmal)",
  ne: "Nepali (India)",
  nl: "Dutch",
  "nl-BE": "Dutch (Belgium)",
  "nn-NO": "Norwegian",
  no: "Norwegian (Bokmal)",
  oc: "Occitan",
  om: "(Afan)/Oromoor/Oriya",
  or: "Oriya",
  pa: "Punjabi",
  pl: "Polish",
  ps: "Pashto/Pushto",
  pt: "Portuguese",
  "pt-BR": "Portuguese (Brazil)",
  qu: "Quechua",
  rm: "Rhaeto-ROMANIC",
  rn: "Kirundi",
  ro: "Romanian",
  "ro-MD": "Romanian (Moldova)",
  ru: "Russian",
  "ru-MD": "Russian (Moldova)",
  rw: "Kinyarwanda",
  sa: "Sanskrit",
  sb: "Sorbian",
  sd: "Sindhi",
  sg: "Sangro",
  sh: "Serbo-CROATIAN",
  si: "Singhalese",
  sk: "Slovak",
  sl: "Slovenian",
  sm: "Samoan",
  sn: "Shona",
  so: "Somali",
  sq: "Albanian",
  sr: "Serbian",
  ss: "Siswati",
  st: "Sesotho",
  su: "Sundanese",
  sv: "Swedish",
  "sv-FI": "Swedish (Finland)",
  sw: "Swahili",
  sx: "Sutu",
  syr: "Syriac",
  ta: "Tamil",
  te: "Telugu",
  tg: "Tajik",
  th: "Thai",
  ti: "Tigrinya",
  tk: "Turkmen",
  tl: "Tagalog",
  tn: "Tswana",
  to: "Tonga",
  tr: "Turkish",
  ts: "Tsonga",
  tt: "Tatar",
  tw: "Twi",
  uk: "Ukrainian",
  ur: "Urdu",
  us: "English",
  uz: "Uzbek",
  vi: "Vietnamese",
  vo: "Volapuk",
  wo: "Wolof",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  zh: "Chinese",
  "zh-CN": "Chinese (China)",
  "zh-HK": "Chinese (Hong Kong SAR)",
  "zh-MO": "Chinese (Macau SAR)",
  "zh-SG": "Chinese (Singapore)",
  "zh-TW": "Chinese (Taiwan)",
  zu: "Zulu",
}

const supportedLocales = [
  "en-US",
  "en-GB",
  "nl",
  "da",
  "de",
  "fi",
  "fr",
  "it",
  "no",
  "es",
  "sv",
  "pl",
  "pt",
]
const defaultAdvisorLocale = "en-US"
const availableLocales = Object.keys(localeOptions).filter((l) =>
  supportedLocales.includes(l)
)

export { availableLocales, defaultAdvisorLocale }
