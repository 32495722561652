import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import useCurrentUser from "./useCurrentUser"
import { useSelector } from "react-redux"
import { ApplicationState } from "store"
import { isAidenSupport } from "~/utils/user"

function useIntercomPageSync() {
  const location = useLocation()
  const { update } = useIntercom()
  const { currentUser } = useCurrentUser()
  const organisationName = useSelector(
    (state: ApplicationState) => state.organisations.organisation.name
  )

  useEffect(() => {
    if (!isAidenSupport(currentUser, organisationName))
      update()
  }, [location, update, currentUser, organisationName])
}

export default useIntercomPageSync
