import styled from "styled-components"
import React from "react"
import Appear from "~/animations/Appear"
import { createPortal } from "react-dom"

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  animation-name: ${Appear.fadeIn};
  animation-duration: 250ms;
  z-index: 900;
  place-items: center;
  place-content: center;
`

const ModalBackground = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.35);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 899;
`

const ModalContent = styled.div`
  padding: 2rem;
  max-width: 70rem;
  background: ${(p) => p.theme.background};
  color: ${(p) => p.theme.main};
  position: relative;
  border-radius: 0.25rem;
  z-index: 900;
  overflow: auto;
  margin-top: 3rem;
  align-self: center;
`

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  & > * {
    margin-left: 1rem;
  }
`

type ModalProps = {
  onClose: () => void
}
const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
  return createPortal(
    <ModalContainer
      data-test-id="modal"
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <ModalBackground onClick={onClose} />
      {children}
    </ModalContainer>,

    document.getElementById("modal")!
  )
}

export { Modal, ModalContent }

export default Modal
