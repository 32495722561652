import styled from "styled-components"
import React from "react"

const FormComp = styled.form`
  padding-bottom: 3rem;
`

const FormActions = styled.div`
  position: sticky;
`

const Form: React.FC<{
  onSubmit?: () => void
  actions?: React.ReactNode
}> = props => {
  return (
    <FormComp
      onSubmit={e => {
        e.preventDefault()
        props.onSubmit && props.onSubmit()
      }}
    >
      {props.children}
      {props.actions && <FormActions>{props.actions}</FormActions>}
    </FormComp>
  )
}

export default Form
