import Label from "~/components/forms/Label"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { TextareaAutogrow, TextareaAutogrowProps } from "./Textarea"

type TextareaProps = TextareaAutogrowProps & {
  label: string
  value: string
  placeholder?: string
  password?: boolean
  isOptional?: boolean
  hasError?: boolean
  disabled?: boolean
  onBlur?: (value: string) => void
  onFocus?: () => void
  onChange: (value: string) => void
}

const InputWrapper = styled.div`
  position: relative;
`
// const InputCharm = styled.div`
//   position: absolute;
//   right: 1rem;
//   top: 0.75rem;
//   cursor: pointer;
// `

const FieldTextArea: React.FC<TextareaProps> = (props) => {
  const { label, value, placeholder, onChange, ...rest } = props
  // const [hidden, setHidden] = useState(type === "password")
  const [focussed, setFocussed] = useState(false)
  const { t } = useTranslation()

  return (
    <div>
      <Label disabled={props.disabled} focussed={focussed}>
        {label}
        {props.isOptional && (
          <span className="form-field__label-optional">{t("optional")}</span>
        )}
      </Label>
      <InputWrapper>
        <TextareaAutogrow
          {...rest}
          value={value}
          hasError={props.hasError}
          placeholder={placeholder}
          disabled={props.disabled}
          onFocus={() => {
            setFocussed(true)
            props.onFocus && props.onFocus()
          }}
          onBlur={() => {
            setFocussed(false)
            props.onBlur && props.onBlur(value)
          }}
          onChange={(e: any) => !props.disabled && onChange(e.target.value)}
        />
        {/* <Input
          type={inputType}
          autoComplete={type === "password" ? "on" : undefined}
          value={value}
          disabled={props.disabled}
          fullWidth
          placeholder={placeholder}
        /> */}
        {/*type === "password" && (
          <InputCharm onClick={() => setHidden(!hidden)}>
            {hidden ? <VisibilityOff /> : <Visibility />}
          </InputCharm>
        )*/}
      </InputWrapper>
    </div>
  )
}

export default FieldTextArea
