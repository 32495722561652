import {
  OrganisationActionTypes,
  ORGANISATION_NAME_CHANGED,
}from "~/actions/organisations"

type OrganisationState = {
  organisation: { name: string }
}

const defaultState = {
  organisation: { name: "" },
}

export default (
  state: OrganisationState = defaultState,
  action: OrganisationActionTypes
): OrganisationState => {
  switch (action.type) {
    case ORGANISATION_NAME_CHANGED:
      return {
        ...state,
        organisation: { ...state.organisation, name: action.payload.name },
      }
    default:
      return state
  }
}
