import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "./en"
export { availableLocales, defaultAdvisorLocale } from "./localeOptions"

// the translations
// (tip move them in a JSON file and import them)

// Convention: Keep nesting to a minimum.
// Hierarchies are hard to maintain and change ;)
const resources = {
  en: en,
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export enum Language {
  americanEnglish = "en-US",
}

export default i18n
