import NavigationPrompt from "react-router-navigation-prompt"
import React from "react"
import { Modal, ModalContent } from "./Modal"
import Button from "./Button"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  & > * {
    margin-left: 1rem;
  }
`

type NavPromptProps = {
  pathSection: string
  leftButtonLabel?: string
  rightButtonLabel?: string
  extraCondition?: (currentLocation: any, nextLocation?: any) => boolean
}

const NavPrompt: React.FC<NavPromptProps> = props => {
  const { t } = useTranslation()

  return (
    <NavigationPrompt
      // beforeConfirm={(clb)=>this.cleanup(clb)} //call the callback function when finished cleaning up
      // Children will be rendered even if props.when is falsey and isActive is false:
      // renderIfNotActive={true}
      // Confirm navigation if going to a path that does not start with current path:
      when={(crntLocation, nextLocation) =>
        props.extraCondition
          ? props.extraCondition(crntLocation, nextLocation) &&
            (!nextLocation ||
              !nextLocation.pathname.includes(props.pathSection))
          : !nextLocation || !nextLocation.pathname.includes(props.pathSection)
      }
    >
      {({ isActive, onCancel, onConfirm }) =>
        isActive ? (
          <Modal onClose={onCancel}>
            <ModalContent style={{ maxWidth: "40rem" }}>
              {props.children}
              <Buttons>
                <Button data-test-id="nav-prompt-confirm" onClick={onConfirm}>
                  {props.leftButtonLabel || t("ok")}
                </Button>
                <Button
                  data-test-id="nav-prompt-cancel"
                  primary
                  onClick={onCancel}
                >
                  {props.rightButtonLabel || t("cancel")}
                </Button>
              </Buttons>
            </ModalContent>
          </Modal>
        ) : (
          <></>
        )
      }
    </NavigationPrompt>
  )
}

export default NavPrompt
