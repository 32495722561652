export type Column = { name: string; type: ColumnType }

export enum ColumnType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
}
export type FilterAPIObj =
  | {
      source: FilterSource
      column: string
      kind: FilterKind
      values: string[]
      combinator: Combinator
      filter: FilterAPIObj
    }
  | undefined

export type Filter = {
  column: Column | undefined
  kind: FilterKind
  type: "productColumn" | "question"
  values: string[]
}
export enum Combinator {
  AND = "AND",
  OR = "OR",
}
export type FilterChainT = (Combinator | Filter)[]

export enum FilterKind {
  MULTI = "MULTI",
  NUMERIC_RANGE = "NUMERIC_RANGE",
  NOT_EXIST = "NOT_EXIST",
  SINGLE = "SINGLE",
}

export enum FilterSource {
  FIELDS = "FIELDS",
  MAPPING = "MAPPING",
}

export type AnswerFilter = {
  label: string
  questionId: string
  answerId: string,
  kind: string,
  value: string
}
