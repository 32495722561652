import React from "react"
import styled, { css } from "styled-components"

const TogleSwitchComponent = styled.label<{ flipLabel: boolean }>`
  ${(props) =>
    props.flipLabel
      ? css`
          flex-direction: row-reverse;
          justify-content: flex-end;

          input[type="checkbox"] + span {
            margin-right: 0;
            margin-left: 0.5em;
          }
        `
      : css``}
`

const ToggleSwitch: React.FC<{
  toggledOn: boolean
  disabled?: boolean
  label: React.ReactNode
  flipLabel?: boolean
  onChange: (enabled: boolean) => void
}> = (props) => {
  return (
    <TogleSwitchComponent
      className={!!props.disabled ? "toggle is-disabled" : "toggle"}
      flipLabel={!!props.flipLabel}
    >
      <input
        type="checkbox"
        checked={props.toggledOn}
        disabled={!!props.disabled}
        onChange={() => props.onChange(!props.toggledOn)}
      />
      <span></span>
      {props.label}
    </TogleSwitchComponent>
  )
}

export default ToggleSwitch
