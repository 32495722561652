import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { getAdvisor as selectAdvisor } from "~/store/advisors/selectors"
import Advisor from "~/components/advisors/Advisor"
import NotFound from "../../components/NotFound"
import { ApplicationState } from "../../store"
import { RouteComponentProps } from "react-router"
import useAdvisors from "~/hooks/useAdvisors"
import LoadingSpinner from "~/components/Loading"
import Page from "~/components/advisors/Page"
import ActionNotifications from "~/components/ActionNotifications"

type AdvisorContainerProps = {
  loading: boolean
} & RouteComponentProps<{ advisorId: string }>

type AdvisorContextT = {
  id: string
  name: string
  productLabel: string
  icon: string
  status?: string
}

const AdvisorContext = React.createContext<AdvisorContextT>({
  id: "",
  name: "",
  productLabel: "",
  icon: "",
  status: undefined,
})

const AdvisorContainer: React.FC<AdvisorContainerProps> = (props) => {
  const advisorId = props.match.params.advisorId
  const { getAdvisor, isLoading, useReqId } = useAdvisors()
  const [reqId, setReqId] = useReqId("")
  const loading = isLoading(reqId)

  const advisor = useSelector((state: ApplicationState) =>
    selectAdvisor(state, advisorId)
  )

  useEffect(() => {
    setReqId(getAdvisor(advisorId))
  }, [advisorId, setReqId, getAdvisor])

  if (loading) {
    return (
      <Page>
        <LoadingSpinner />
      </Page>
    )
  } else if (advisor) {
    return (
      <AdvisorContext.Provider
        value={{
          id: advisor.id,
          name: advisor.name,
          productLabel: advisor.productLabel,
          icon: advisor.icon,
          status: advisor.status,
        }}
        {...props}
      >
        <Advisor advisor={advisor} />
        <ActionNotifications />
      </AdvisorContext.Provider>
    )
  } else {
    return <NotFound />
  }
}

const useAdvisorContext = () => React.useContext(AdvisorContext)

export { useAdvisorContext }
export default AdvisorContainer
