import { Question, QuestionType } from "../../store/questions"
import { UUID } from "../../store/types"
import { ThunkDispatch } from "redux-thunk"
import { AnyAction } from "redux"
import { removeAnswer } from "../answers"
import {
  addQuestion,
  changeQuestionType,
  moveQuestion,
  removeQuestion,
} from "."
import { v4 as uuid } from "uuid"
import { changeRuleTarget, removeRule }from "~/actions/rules"
import { AdvisorMeta }from "~/actions/advisors"
import { ApplicationState }from "~/store"
import { Rule }from "~/store/rules/types"
import { Flow }from "~/actions/flow"

/**
 * Remove question and possibly change any rules
 * */
export const removeQuestionCreator =
  (questionToRemoveId: UUID, meta: AdvisorMeta) =>
  (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => ApplicationState
  ) => {
    const store = getState()
    const questionToRemove = store.questions[questionToRemoveId]

    if (!questionToRemove) return

    dispatch(removeQuestion(questionToRemoveId, meta))

    adjustRulesForChange(
      Object.values(store.rules),
      questionToRemove,
      Object.values(store.questions),
      meta
    )(dispatch)
  }

/**
 * Move question and possibly change any rules
 * */
export const moveQuestionCreator =
  (question: Question, prevQuestionId: UUID | undefined, meta: AdvisorMeta) =>
  (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => ApplicationState
  ) => {
    dispatch(
      Flow.move(
        {
          id: question.id,
          next: question.next!!,
        },
        prevQuestionId,
        meta
      )
    )

    // const store = getState()
    // const currentStateOfQuestion = Object.values(store.questions).find(
    //   (q) => q.id === question.id
    // )

    // dispatch(moveQuestion(meta, question, prevQuestionId))
    // if (currentStateOfQuestion) {
    //   adjustRulesForChange(
    //     Object.values(store.rules),
    //     currentStateOfQuestion,
    //     Object.values(store.questions),
    //     meta
    //   )(dispatch)
    // }
  }

const adjustRulesForChange =
  (
    rules: Rule[],
    affected: {id: string, next: string | undefined},
    questions: Question[],
    meta: AdvisorMeta
  ) =>
  (dispatch) => {
    rules
      .filter((r) => r.targetQuestionId === affected.id)
      .forEach((rule) => {
        const questionForRule = questions.find((q) => q.rules.includes(rule.id))

        if (questionForRule && affected.next === questionForRule.next) {
          dispatch(removeRule(questionForRule.id, rule.id, meta))
        } else if (affected.next) {
          dispatch(changeRuleTarget(rule.id, affected.next, meta))
        }
      })
  }

/**
 * Create a new Question
 */
export const addNewQuestion = (
  meta: AdvisorMeta,
  question?: Partial<Question>,
  previousQuestion?: UUID
) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const questionId = question?.id || uuid()

    dispatch(
      addQuestion(meta, { ...question, id: questionId }, previousQuestion)
    )
  }
}

/**
 * Change question type and remove rules
 */

export const changeQuestionTypeAndRemoveRules = (
  question: Question,
  type: QuestionType,
  meta: AdvisorMeta
) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(changeQuestionType(question.id, type, meta))

    if (type === QuestionType.Numeric) {
      question.answers.forEach((answerId) => {
        dispatch(removeAnswer(question.id, answerId, meta))
      })
    }

    question.rules.forEach((rule) =>
      dispatch(removeRule(question.id, rule, meta))
    )
  }
}
