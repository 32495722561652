import API from "./api"

const authenticate = async (username: string, password: string) =>
  API.post<{ token: string }>({
    path: "/authenticate",
    data: {
      username,
      password,
    },
    organisationId: null
  })

export { authenticate }
