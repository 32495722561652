import { useState, useEffect } from "react"

export const useImageSelectPreview = () => {
  const [selectedImage, setSelectedImage] = useState<File>()
  const [preview, setPreview] = useState<string>()

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedImage) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedImage)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedImage])

  const onSelectImage = e => {
    if (!e?.target.files || e?.target.files.length === 0) {
      setSelectedImage(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedImage(e.target.files[0])
  }

  return {
    onSelectImage,
    previewUrl: preview,
    selectedImage
  }
}
