import { createPopper, Instance, Placement } from "@popperjs/core"

class PopperElement extends HTMLElement {
  popper: null | Instance
  observer: null | ResizeObserver

  static get observedAttributes() {
    // Which attributes call attributeChangedCallback
    return ["placement"]
  }
  constructor() {
    super()
    this.popper = null
    this.observer = null
  }

  connectedCallback() {
    const elem = this
    const relativeToId = elem.getAttribute("relative-to")
    const containingElement = relativeToId
      ? document.querySelector("#" + relativeToId)
      : elem.parentElement

    if (!containingElement) return

    const elementRef = this
    const placement = elem.getAttribute("placement") || "auto"

    const instance = createPopper(
      containingElement,
      elementRef,
      // options
      {
        placement: placement as Placement,
      }
    )
    this.popper = instance

    this.observer = new ResizeObserver((entries) => {
      entries.forEach(() => {
        elem.popper?.update()
      })
    })

    this.observer?.observe(containingElement)
  }

  // Update content if placement attribute changed
  attributeChangedCallback(_name, _oldValue, newValue) {
    if (!this.isConnected) return
    if (_name === "placement") {
      this.popper?.setOptions({ placement: newValue || "auto" })
    }
  }

  disconnectedCallback() {
    this.observer?.disconnect()
  }
}

const define = () => customElements.define("popper-element", PopperElement)

export { define }
