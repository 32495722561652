import React from "react"

export default () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m0 0h24v24h-24z" fill="none" />
    <path d="m14.71 6.71c-.39-.39-1.02-.39-1.41 0l-4.59 4.59c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41l-3.88-3.89 3.88-3.88c.39-.39.38-1.03 0-1.41z" />
  </svg>
)
