import React, { useCallback, useState } from "react"
import useAdvisors from "./useAdvisors"

const MAX_SECONDS_OF_TAB_UNFOCUS = 60 * 2
const MAX_SECONDS_OF_INACTIVITY = 60 * 5

let shouldRefresh = false

function useQandARefresh(advisorId: string) {
  const [lastSeen, setLastSeen] = useState<undefined | Date>(undefined)
  const { getAdvisor } = useAdvisors()

  const onVisible = useCallback(() => {
    if (lastSeen) {
      const secondsPassedSinceLastSeen =
        (new Date().getTime() - lastSeen.getTime()) / 1000
      if (secondsPassedSinceLastSeen >= MAX_SECONDS_OF_TAB_UNFOCUS) {
        shouldRefresh = false
        unfocus()
        getAdvisor(advisorId)
      }

      setLastSeen(undefined)
    }
  }, [advisorId, lastSeen, setLastSeen])

  const onHide = useCallback(() => {
    setLastSeen(new Date())
  }, [setLastSeen])

  // Tab/window focus
  React.useEffect(() => {
    window.addEventListener("blur", onHide)
    window.addEventListener("focus", onVisible)
    return () => {
      window.removeEventListener("blur", onHide)
      window.removeEventListener("focus", onVisible)
    }
  }, [onHide, onVisible])

  // Tab change or minimize window
  React.useEffect(() => {
    const onChange = () => (document.hidden ? onHide() : onVisible())

    document.addEventListener("visibilitychange", onChange)

    return () => {
      document.removeEventListener("visibilitychange", onChange)
    }
  }, [onHide, onVisible])

  // No activity on tab
  React.useEffect(() => {
    const ms = MAX_SECONDS_OF_INACTIVITY * 1000
    const onAfterInactivity = () => {
      shouldRefresh = true
      const refresh = () => {
        if (shouldRefresh === true) {
          unfocus()
          shouldRefresh = false
          getAdvisor(advisorId)
        }
      }
      console.log("REFRESH INACTIVE")

      window.addEventListener("click", refresh, { once: true })
      window.addEventListener("mousedown", refresh, { once: true })
      window.addEventListener("mousemove", refresh, { once: true })
      window.addEventListener("scroll", refresh, { once: true })
      window.addEventListener("resize", refresh, { once: true })
      window.addEventListener("keydown", refresh, { once: true })
    }
    let wait = setTimeout(onAfterInactivity, ms)

    const handler = () => {
      clearTimeout(wait)
      wait = setTimeout(onAfterInactivity, ms)
    }

    window.addEventListener("mousedown", handler)
    window.addEventListener("mousemove", handler)
    window.addEventListener("scroll", handler)
    window.addEventListener("resize", handler)
    window.addEventListener("keydown", handler)

    return () => {
      window.removeEventListener("mousedown", handler)
      window.removeEventListener("mousemove", handler)
      window.removeEventListener("scroll", handler)
      window.removeEventListener("resize", handler)
      window.removeEventListener("keydown", handler)
    }
  }, [getAdvisor, advisorId])
}

/**
 * Unfocusses any element which is not the current document
 */
function unfocus() {
  if (document.activeElement != document.body) {
    //@ts-ignore
    document.activeElement?.blur()
  }
}

export default useQandARefresh
