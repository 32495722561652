import {
  getQuestions,
  Question,
  QuestionType,
  QuestionWithAnswers,
}from "~/store/questions"
import { useSelector, useDispatch } from "react-redux"
import { ApplicationState }from "~/store"
import { addNewQuestion, removeQuestionCreator }from "~/actions/questions"
import { UUID }from "~/store/types"
import { getAdvisor }from "~/store/advisors/selectors"
import { useTranslation } from "react-i18next"
import Advisor from "~/lib/Advisor"

export default (advisorId: string) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { advisor, questions } = useSelector((state: ApplicationState) => {
    const advisor = getAdvisor(state, advisorId)
    return {
      advisor,
      questions: advisor ? getQuestions(state, advisor?.questions || []) : [],
    }
  })

  const questionsWithAnswers: QuestionWithAnswers[] = useSelector(
    (state: ApplicationState) =>
      questions.map((q) => {
        return {
          ...q,
          answers: (q.type === QuestionType.Drilldown
            ? Advisor.answersForDrilldown(q)
            : q.answers.map((a) => state.answers[a])
          )

            .filter((a) => a !== undefined)
            .map((answer) => {
              return {
                ...answer,
                title: answer.title || `<${t("emptyAnswer")}>`,
              }
            }),
        }
      })
  )

  return {
    questions,
    questionsWithAnswers,
    addNewQuestion: (
      question?: Partial<Question>,
      previousQuestionId?: string
    ) =>
      advisor &&
      dispatch(addNewQuestion({ advisorId }, question, previousQuestionId)),
    removeQuestion: (questionId: UUID) =>
      advisor && dispatch(removeQuestionCreator(questionId, { advisorId })),
  }
}
