import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  width: auto;
  max-width: 100%;
`

const FileButton = styled.div`
  padding: 1rem 1rem;
  font-size: 0.75rem;
  background: ${p => p.theme.shade3};
  border-top: 2px solid ${p => p.theme.shade3};
  border-bottom: 2px solid ${p => p.theme.shade3};
  border-top-left-radius: ${p => p.theme.borderRadius};
  border-bottom-left-radius: ${p => p.theme.borderRadius};
  cursor: pointer;
  font-weight: bold;
  box-sizing: border-box;
  align-self: center;
  white-space: nowrap;
`

const FileName = styled.div`
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-top: 2px solid ${p => p.theme.shade1};
  border-bottom: 2px solid ${p => p.theme.shade1};
  border-right: 2px solid ${p => p.theme.shade1};
  border-top-right-radius: ${p => p.theme.borderRadius};
  border-bottom-right-radius: ${p => p.theme.borderRadius};
  flex: 1;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

// const FileClear = styled.div<{ disabled?: boolean }>`
//   padding: 0.5rem 1rem;
//   border: 2px solid ${p => p.theme.shade1};
//   background: ${p => p.theme.shade1};
//   border-top-right-radius: 8px;
//   border-bottom-right-radius: 8px;
//   cursor: pointer;
//   font-weight: bold;
//   box-sizing: border-box;
//   transition: 300ms all;
//   display: flex;
//   align-items: center;
//   color: ${p => p.theme.main};
//   ${p =>
//     p.disabled
//       ? css`
//           color: ${p.theme.shade1};
//           cursor: default;
//           pointer-events: none;
//         `
//       : css`
//           background: ${p => p.theme.shade1};
//         `}
// `

const FileSelect: React.FC<{
  label?: string
  filename?: string
  onClick: (e: any) => void
  onRemove: (e: any) => void
}> = ({ label, filename, onClick, onRemove }) => (
  <Wrapper>
    <FileButton onClick={onClick}>{label}</FileButton>
    <FileName onClick={onClick}>
      <span>{filename}</span>
    </FileName>
  </Wrapper>
)

export default FileSelect
