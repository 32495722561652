import React from "react"
import Color from "~/lib/Color"
import EmbedCodeBuilder from "~/elm-tsx-wrappers/EmbedCodeBuilder"
import { EnvVar, envVar }from "~/utils/environment"
import { useAuth } from "../../../context/auth"

const webshopPreviewURL = envVar(EnvVar.WEBSHOP_FRONTEND_URL)

export default ({ advisor }) => {
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL)
  const { token } = useAuth()

  return (
    <EmbedCodeBuilder
      apiUrl={apiUrl as string}
      authtoken={token as string}
      advisorId={advisor.id}
      locale={advisor.locales[0]}
      webshopUrl={webshopPreviewURL as string}
      background={advisor.style.primary}
      buttonRadius={advisor.style.borderRadius}
      buttonPaddingVertical={advisor.style.buttonPaddingVertical}
      buttonPaddingHorizontal={advisor.style.buttonPaddingHorizontal}
      textColor={Color.contrastingColor(advisor.style.primary)}
      embedConfig={advisor.embedConfig}
    />
  )
}
