import styled from "styled-components"

const Section = styled.div`
  /* background: ${(props) => props.theme.shade2}; */
  /* margin: 0; */
  /* width: 100%; */
  /* max-width: 1280px; */
`

export default Section
