import { AppThunk }from "~/store"
import { NotificationT } from "~/types"
import { v4 as uuid } from 'uuid';
import { dismissNotification, displayNotification } from "./actions"

/** Display a snackbar notification to the user */
export const notify = (
  notification: {
    text: string
    type?: NotificationT["type"]
    action?: NotificationT["action"]
    onDismiss?: NotificationT["onDismiss"]
  },
  displayTime = 4000
): AppThunk => (dispatch) => {
  const id = uuid()
  dispatch(
    displayNotification({
      ...notification,
      id,
      type: notification.type || "info",
    })
  )
  setTimeout(() => {
    dispatch(dismiss(id))
  }, displayTime)
}

export const dismiss = (id: string): AppThunk => (dispatch, getState) => {
  const notification = getState().notifications[id]
  notification && dispatch(dismissNotification(id))
  notification && notification.onDismiss && notification.onDismiss()
}
