import React, { ReactNode } from "react"
import styled, { css } from "styled-components"

export const SplitLayoutComp = styled.div<{
  setHeight: boolean
  sidebarOpen: boolean
}>`
  width: 100%;
  height: ${(p) => (p.setHeight ? "100vh" : "auto")};
  overflow: hidden;
  display: grid;
  grid-template-columns: ${(p) =>
    p.sidebarOpen ? "minmax(0, 1fr) 26rem" : "minmax(0, 1fr) 2rem"};
  grid-template-columns: minmax(0, 1fr) 2rem;

  & > div:first-child {
    position: relative;
    overflow: auto;
  }

  & > div:last-child {
    box-shadow: ${(p) => p.theme.defaultShadow};
    background: white;
    overflow: hidden;

    > div {
      opacity: 0;
    }
  }

  ${(p) =>
    p.sidebarOpen &&
    css`
      grid-template-columns: minmax(0, 1fr) 26rem;

      & > div:last-child {
        overflow: auto;

        > div {
          opacity: 1;
        }
      }
    `}
`

export const SidebarContent = styled.div<{ padTop?: boolean }>`
  background: white;
  padding: 1rem 3rem 1rem 2rem;
  ${(p) =>
    p.padTop &&
    css`
      padding-top: 1rem;
    `}
`

const Bg = styled.div<{ setHeight: boolean }>`
  background: ${(p) => p.theme.backgroundShade};
  height: ${(p) => (p.setHeight ? "100vh" : "auto")};
`

const SplitLayout: React.FC<{
  left?: ReactNode
  right?: ReactNode
  setHeight?: boolean
  sidebarOpen?: boolean
}> = ({ left, right, setHeight = false, sidebarOpen = true }) => (
  <SplitLayoutComp setHeight={setHeight} sidebarOpen={sidebarOpen}>
    <div className="page-container">{left}</div>
    <Bg setHeight={setHeight}>{right}</Bg>
  </SplitLayoutComp>
)

export default SplitLayout
