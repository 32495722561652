import styled from "styled-components"

const CardItem = styled.div`
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 1.25rem;
  transition: box-shadow 200ms ease-in, background-color 200ms ease-in;
  box-shadow: ${(props) => props.theme.defaultShadow};
  background-color: ${(props) => props.theme.background};
  position: relative;
`

export default CardItem
