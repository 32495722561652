import React from "react"

import Button from "~/components/Button"
import { EnvVar, envVar } from "~/utils/environment"
import useCopyToClipboard from "~/hooks/useCopyToClipboard"
import Duplicate from "~/components/icons/Duplicate"
import { usePreviewMode } from "~/context/previewMode"

const webshopPreviewURL = envVar(EnvVar.WEBSHOP_FRONTEND_URL)

const toUrl = (advisorId: string, previewMode: string) => {
  const layout = previewMode === "banner" ? "horizontal" : "vertical"
  return `${webshopPreviewURL}/?advisorId=${advisorId}&preview=true&layout=${layout}&mode=${previewMode}`
}

export const PreviewUrl: React.FC<{ advisorId: string }> = ({ advisorId }) => {
  const previewMode = usePreviewMode().getPreviewMode(advisorId)
  return <>{toUrl(advisorId, previewMode)}</>
}

export const CopyPreviewUrl: React.FC<{
  advisorId: string
  alignRight?: boolean
}> = ({ advisorId, alignRight = false }) => {
  const previewMode = usePreviewMode().getPreviewMode(advisorId)
  const [copyStatus, copyPreviewUrl] = useCopyToClipboard(
    toUrl(advisorId, previewMode)
  )

  const previewButtonContent = () => {
    switch (copyStatus) {
      case "copied":
        return "Copied to clipboard!"
      case "failed":
        return "Oops, unable to copy!"
      default:
        return "Copy preview link"
    }
  }

  return (
    <Button
      hasIconLeft
      alignRight={alignRight}
      isSmall
      onClick={() => copyPreviewUrl()}
    >
      <Duplicate />
      <span>{previewButtonContent()}</span>
    </Button>
  )
}
