import React from "react"
import { Account }from "~/store/currentUser/types"

type SecurityProps = {
  account: Account
}

const Security: React.FC<SecurityProps> = ({ account }) => <div>Security</div>

export default Security
