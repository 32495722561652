import { action } from "typesafe-actions"
import {
  CHANGED_USER_ACCOUNT,
  FETCH_USER_ACCOUNT,
  FETCH_USER_ACCOUNT_SUCCESS,
  FETCH_USER_ACCOUNT_FAILURE,
} from "./constants"
import { ApiError } from "~/api/APIBase"
import { Account } from "~/store/currentUser/types"
import { FeatureFlag } from "~/hooks/useFeatureFlags"

export const fetchUserAccount = (token: string) =>
  action(FETCH_USER_ACCOUNT, { token })

export const fetchUserAccountSuccess = (
  account: Account & {
    featureFlags: Record<FeatureFlag, boolean>
  }
) => action(FETCH_USER_ACCOUNT_SUCCESS, { account })

export const fetchUserAccountFailure = (error: ApiError) =>
  action(FETCH_USER_ACCOUNT_FAILURE, undefined, undefined, error)

export const changedUserAccount = (
  account: Pick<
    Account,
    "firstName" | "familyName" | "profileImage" | "emailPreferences"
  >
) =>
  action(CHANGED_USER_ACCOUNT, {
    firstName: account.firstName,
    familyName: account.familyName,
    profileImage: account.profileImage,
    emailPreferences: account.emailPreferences,
  })
