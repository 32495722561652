import React from "react"
import { Redirect, Route, Switch } from "react-router"
import { AdvisorsContainer } from "~/containers"

const Home: React.FC<{}> = () => {
  return (
    <Switch>
      <Route path="/advisors" exact component={AdvisorsContainer} />
      <Redirect from="/" to="/advisors" />
    </Switch>
  )
}

export default Home
