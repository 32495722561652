import styled, { css } from "styled-components"
import React, { ReactNode } from "react"
import { ShadowDP25 } from "~/styles/ShadowStyles"

const NavBarComp = styled.div<{ shadow: boolean }>`
  padding: 0.25rem 1rem;
  display: flex;
  ${(p) =>
    p.shadow &&
    css`
      ${ShadowDP25};
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    `};

  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  & > div {
    /* background: red; */
    &:nth-child(2) {
      flex-shrink: 0;
    }
  }
`

const NavBar: React.FC<{
  shadow?: boolean
  left?: ReactNode
  middle?: ReactNode
  right?: ReactNode
}> = ({ shadow = true, left, middle, right }) => {
  return (
    <NavBarComp shadow={shadow}>
      {left ? <>{left}</> : <div />}
      {middle && <div>{middle}</div>}
      <div
        id="action-bar"
        style={{ display: "flex", alignItems: "center", gap: "1rem" }}
      >
        {right}
      </div>
    </NavBarComp>
  )
}

export default NavBar
