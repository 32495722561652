import { useAuth } from "~/context/auth"
import { dataSources } from "~/api"
import { ApiError } from "~/api/APIBase"
import { UUID }from "~/store/types"
import { DataSource } from "~/types"
import { useState, useCallback } from "react"

export default (advisorId: UUID, loadingDefault = false) => {
  const context = useAuth()
  const token = context.token || ""
  const logout = context.logout
  const organisationId = context.organisationId

  const [dataSource, setDataSource] = useState<DataSource | undefined>()
  const [dataSourceLoading, setDataSourceLoading] = useState(loadingDefault)

  const defaultHandleError = useCallback(
    (error: ApiError) => {
      if (error.status === 401) logout()
      return Promise.reject(error)
    },
    [logout]
  )

  const get = useCallback(async (): Promise<DataSource | undefined> => {
    return dataSources
      .all(advisorId, token, organisationId)
      .then((dataSources) => {
        setDataSource(dataSources[0])
        return dataSources[0]
      })
      .catch(defaultHandleError)
      .finally(() => setDataSourceLoading(false))
  }, [advisorId, token, organisationId, defaultHandleError])

  const getProducts = useCallback(
    async (id: string, page: number, size: number = 10) => {
      const offset = page - 1 * size

      return dataSources
        .products(advisorId, id, offset, size, token, organisationId)
        .catch(defaultHandleError)
    },
    [advisorId, token, defaultHandleError, organisationId]
  )

  return {
    get,
    dataSource,
    dataSourceLoading,
    getProducts,
  }
}
