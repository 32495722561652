import React from "react"
import styled from "styled-components"
import { Valid } from "~/components/icons"

const Steps = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.8rem;
  counter-reset: section;
  font-weight: bold;
`

const StepCompBar = styled.div<{ completed: boolean; current: boolean }>`
  width: 100%;
  height: 4px;
  transition: 200ms background ease-in;
  background-color: ${p =>
    p.completed
      ? p.theme.success
      : p.current
      ? p.theme.primary
      : p.theme.shade1};
  margin-bottom: 0.25rem;
  font-weight: bold;
`

const StepIndicator = styled.div<{ completed: boolean; current: boolean }>`
  padding: 0;
  border-radius: 100%;
  border: 3px solid
    ${p =>
      p.completed
        ? p.theme.success
        : p.current
        ? p.theme.primary
        : p.theme.shade1};
  box-sizing: border-box;
  transition: 200ms border ease-in;
  width: 2rem;
  height: 2rem;
  line-height: 1;
  text-align: center;
  position: absolute;
  left: 0%;
  top: -0.85rem;
  background: #fff;
  margin-left: -0.5rem;
  place-content: center;
  display: flex;
  place-items: center;
  * {
    width: 1rem;
    height: 1rem;
    margin: 0;
    line-height: 1.2;
    display: inline-block;
  }
`

const StepLabel = styled.p<{ disabled: boolean }>`
  cursor: ${p => (p.disabled ? "not-allowed" : "pointer")};
  margin-top: 0.5rem;
`

const StepComp = styled.div<{ completed: boolean; current: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  margin: 0 1px;
  &:first-child ${StepCompBar} {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child ${StepCompBar} {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const Step: React.FC<{ completed: boolean; current: boolean }> = props => (
  <StepComp completed={props.completed} current={props.current}>
    <StepCompBar completed={props.completed} current={props.current} />
    {props.children}
  </StepComp>
)

const ProgressBar: React.FC<{
  steps: { label: string; nav?: () => void; completed: boolean }[]
  current: number
}> = ({ steps, current }) => {
  return (
    <Steps>
      {steps.map((s, i) => (
        <Step key={i} completed={s.completed} current={i + 1 === current}>
          <StepIndicator completed={s.completed} current={i + 1 === current}>
            {s.completed ? <Valid /> : <div>{i + 1}</div>}
          </StepIndicator>
          <StepLabel disabled={!s.nav} onClick={() => s.nav && s.nav()}>
            {s.label}
          </StepLabel>
        </Step>
      ))}
    </Steps>
  )
}

export default ProgressBar
