import React from "react"
import PillButtons from "~/components/PillButtons"
import { usePreviewMode } from "~/context/previewMode"
import styled from "styled-components"
import { InPage, FullPage, Sidebar } from "~/components/icons/PreviewMode"

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  svg {
    height: 1rem !important;
    width: auto !important;
  }

  span {
    margin-left: 0.5em;
    white-space: nowrap;
  }
`

const SelectPreviewMode: React.FC<{
  advisorId: string
  iconsOnly?: boolean
}> = ({ advisorId, iconsOnly }) => {
  const { getPreviewMode, savePreviewMode } = usePreviewMode()
  const text = (s: string) => (iconsOnly ? "" : <span>{s}</span>)

  return (
    <PillButtons
      buttons={[
        {
          content: (
            <ButtonContent>
              <FullPage />
              {text("Full-page")}
            </ButtonContent>
          ),
          value: "inpage",
          selected: getPreviewMode(advisorId) === "inpage",
          onClick: () => savePreviewMode(advisorId, "inpage"),
          hasTextContent: !iconsOnly,
        },
        {
          content: (
            <ButtonContent>
              <Sidebar />
              {text("Sidebar")}
            </ButtonContent>
          ),
          value: "sidebar",
          selected: getPreviewMode(advisorId) === "sidebar",
          onClick: () => savePreviewMode(advisorId, "sidebar"),
          hasTextContent: !iconsOnly,
        },
        {
          content: (
            <ButtonContent>
              <InPage />
              {text("In-page")}
            </ButtonContent>
          ),
          value: "banner",
          selected: getPreviewMode(advisorId) === "banner",
          onClick: () => savePreviewMode(advisorId, "banner"),
          hasTextContent: !iconsOnly,
        },
      ]}
    />
  )
}

export default SelectPreviewMode
