import {
  ADD_QUESTION,
  CHANGE_NEXT_QUESTION,
  CHANGE_NUMERIC_QUESTION_CONFIG,
  CHANGE_QUESTION,
  CHANGE_QUESTION_COLOR,
  CHANGE_QUESTION_HELP_TEXT,
  CHANGE_QUESTION_LABEL,
  CHANGE_QUESTION_MEDIA_TYPE,
  CHANGE_QUESTION_TYPE,
  DRILLDOWNCONFIG_CHANGED,
  AUTO_DRILLDOWNCONFIG_CHANGED,
  MOVE_QUESTION,
  QuestionActionTypes,
  REMOVE_QUESTION,
  TOGGLE_MATCHING_FILTER,
}from "~/actions/questions"
import { ADD_AFTER, MOVE, FlowActionTypes }from "~/actions/flow"

import { moveElem }from "~/utils/array"
import Transformation from "./Transformation"

import {
  NumericalInputType,
  NumericalQuestionConfig,
  DrilldownConfig,
  Question,
  QuestionColor,
  QuestionMediaType,
  QuestionType,
  AutoDrilldownConfig,
} from "./types"
import { UUID } from "../types"
import {
  ADD_ANSWER,
  AnswerActionTypes,
  REMOVE_ANSWER,
  REORDER_ANSWER,
} from "../../actions/answers"
import { ADD_RULE, REMOVE_RULE, RulesActionTypes } from "../../actions/rules"
import {
  AdvisorActionTypes,
  FETCH_ADVISOR_SUCCESS,
  NEW_ADVISOR_SUCCESS,
}from "~/actions/advisors"

type QuestionState = Record<UUID, Question>

const defaultState = {}

const defaultNumericConfig: NumericalQuestionConfig =
  Transformation.defaultNumericConfig
const defaultDrilldownConfig: DrilldownConfig =
  Transformation.defaultDrilldownConfig
const defaultAutoDrilldownConfig: AutoDrilldownConfig =
  Transformation.defaultAutoDrilldownConfig

export default (
  state: QuestionState = defaultState,
  action:
    | QuestionActionTypes
    | AnswerActionTypes
    | RulesActionTypes
    | AdvisorActionTypes
    | FlowActionTypes
): QuestionState => {
  switch (action.type) {
    case FETCH_ADVISOR_SUCCESS:
    case NEW_ADVISOR_SUCCESS: {
      const { questions } = action.payload
      return questions.reduce((acc, q) => ({ ...acc, [q.id]: q }), state)
    }
    case CHANGE_QUESTION: {
      const { id, title } = action.payload
      return { ...state, [id]: { ...state[id], title: title } }
    }
    case CHANGE_NEXT_QUESTION: {
      const { id, nextQuestionId } = action.payload
      return { ...state, [id]: { ...state[id], next: nextQuestionId } }
    }
    case CHANGE_QUESTION_TYPE: {
      const { id, type } = action.payload
      const question = state[id]
      return {
        ...state,
        [id]: Transformation.changeQuestionToType(question, type),
      }
    }
    case CHANGE_QUESTION_MEDIA_TYPE: {
      const { id, mediaType } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          mediaType: mediaType,
        },
      }
    }
    case CHANGE_QUESTION_HELP_TEXT: {
      const { id, text } = action.payload
      return { ...state, [id]: { ...state[id], helpText: text } }
    }
    case CHANGE_QUESTION_LABEL: {
      const { id, label } = action.payload
      return { ...state, [id]: { ...state[id], label: label } }
    }
    case CHANGE_QUESTION_COLOR: {
      const { id, color } = action.payload
      return { ...state, [id]: { ...state[id], color: color } }
    }
    case ADD_AFTER: {
      const { toAdd, beforeInFlow } = action.payload
      return toAdd.type === "question"
        ? addQuestion(
            state,
            {
              id: toAdd.id,
              type: QuestionType.Single,
              next: toAdd.next,
            },
            beforeInFlow
          )
        : state
    }
    case ADD_QUESTION: {
      const { question, previousQuestion: previousQuestionId } = action.payload
      return addQuestion(state, question, previousQuestionId)
    }
    // case MOVE: {
    //   const { toMove, previousId } = action.payload

    //   const target = state[toMove.id]
    //   const moveingQuestionid = toMove.id

    //   const intermediateState = Object.entries(state).reduce((acc, q) => {
    //     const [questionId, question] = q
    //     if (questionId === moveingQuestionid) return acc

    //     acc[questionId] = {
    //       ...question,
    //       next:
    //         question.next === moveingQuestionid ? target.next : question.next,
    //     }
    //     return acc
    //   }, {})

    //   const previousQuestion = previousId
    //     ? intermediateState[previousId]
    //     : undefined

    //   const intermediateState2 = {
    //     ...intermediateState,
    //     [target.id]: {
    //       ...target,
    //       next: previousQuestion?.next || toMove.next,
    //     },
    //   }
    //   return previousQuestion
    //     ? {
    //         ...intermediateState2,
    //         [previousQuestion.id]: {
    //           ...state[previousQuestion.id],
    //           next: toMove.id,
    //         },
    //       }
    //     : intermediateState2
    // }
    case MOVE_QUESTION: {
      const { question, previousQuestion: previousQuestionId } = action.payload
      const toMove = state[question.id]
      const moveingQuestionid = question.id

      const intermediateState = Object.entries(state).reduce((acc, q) => {
        const [questionId, question] = q
        if (questionId === moveingQuestionid) return acc

        acc[questionId] = {
          ...question,
          next:
            question.next === moveingQuestionid ? toMove.next : question.next,
        }
        return acc
      }, {})

      const previousQuestion = previousQuestionId
        ? intermediateState[previousQuestionId]
        : undefined

      const intermediateState2 = {
        ...intermediateState,
        [question.id]: {
          ...question,
          next: previousQuestion?.next || question.next,
        },
      }
      return previousQuestion
        ? {
            ...intermediateState2,
            [previousQuestion.id]: {
              ...state[previousQuestion.id],
              next: question.id,
            },
          }
        : intermediateState2
    }
    case REMOVE_QUESTION: {
      const { id } = action.payload
      const toRemove = state[id]

      return Object.entries(state).reduce((acc, q) => {
        const [questionId, question] = q
        if (questionId === id) return acc

        acc[questionId] = {
          ...question,
          next: question.next === id ? toRemove.next : question.next,
        }
        return acc
      }, {})
    }

    case ADD_ANSWER: {
      const { questionId, id } = action.payload
      const target = state[questionId]

      return {
        ...state,
        [questionId]: {
          ...target,
          answers: [...target.answers, id],
        },
      }
    }

    case REMOVE_ANSWER: {
      const { questionId, id } = action.payload
      const target = state[questionId]
      return {
        ...state,
        [questionId]: {
          ...target,
          answers: target.answers.filter((a) => a !== id),
        },
      }
    }

    case REORDER_ANSWER: {
      const { questionId, newPosition, oldPosition } = action.payload
      const target = state[questionId]
      return {
        ...state,
        [questionId]: {
          ...target,
          answers: moveElem(target.answers, oldPosition, newPosition),
        },
      }
    }

    case ADD_RULE: {
      const { questionId, rule } = action.payload
      const target = state[questionId]

      return {
        ...state,
        [questionId]: {
          ...target,
          rules: [...target.rules, rule.id],
        },
      }
    }

    case CHANGE_NUMERIC_QUESTION_CONFIG: {
      const { id, config } = action.payload
      const question = state[id]
      if (question.type === QuestionType.Numeric) {
        return {
          ...state,
          [id]: {
            ...question,
            config: {
              inputType: NumericalInputType.INPUT,
              placeholder: config.placeholder,
              info: config.info,
              filter: config.filter,
              optional: config.optional,
              allowDecimals: config.allowDecimals,
              inputInfo: config.inputInfo,
              matchingFeatureLabel: config.matchingFeatureLabel,
            },
          },
        }
      } else {
        return state
      }
    }

    case REMOVE_RULE: {
      const { questionId, ruleId } = action.payload
      const target = state[questionId]

      return {
        ...state,
        [questionId]: {
          ...target,
          rules: target.rules.filter((r) => r !== ruleId),
        },
      }
    }

    case TOGGLE_MATCHING_FILTER: {
      const { questionId } = action.payload
      const target = state[questionId]
      return {
        ...state,
        [questionId]: {
          ...target,
          config: { ...target.config, filter: !target.config.filter } as any,
        },
      }
    }
    case DRILLDOWNCONFIG_CHANGED: {
      const { questionId, config } = action.payload
      const target = state[questionId]

      if (target.type === QuestionType.Drilldown) {
        return { ...state, [questionId]: { ...target, config } }
      } else {
        return state
      }
    }

    case AUTO_DRILLDOWNCONFIG_CHANGED: {
      const { questionId, config } = action.payload
      const target = state[questionId]

      if (target.type === QuestionType.AutoDrilldown) {
        return { ...state, [questionId]: { ...target, config } }
      } else if (target.type === QuestionType.Drilldown) {
        return {
          ...state,
          [questionId]: {
            ...target,
            type: QuestionType.AutoDrilldown,
            config,
          },
        }
      } else {
        return state
      }
    }

    default:
      return state
  }
}

function handleFlowAddition(
  state: QuestionState,
  addedId: UUID,
  previous?: UUID
) {
  const previousQuestion = previous ? state[previous] : undefined

  return previousQuestion
    ? {
        ...state,
        [previousQuestion.id]: {
          ...previousQuestion,
          next: addedId,
        },
      }
    : state
}

function addQuestion(
  state: QuestionState,
  question: Partial<Question> & { id: UUID; type: QuestionType },
  previous?: UUID
): QuestionState {
  const previousQuestion = previous ? state[previous] : undefined

  const s = {
    id: question.id,
    title: question.title || "",
    label: "new question",
    color: QuestionColor.Color1,
    answers: [],
    rules: [],
    helpText: "",
    mediaType: QuestionMediaType.NO_MEDIA,
    next: question.next || previousQuestion?.next,
  }

  const intermediateState: QuestionState =
    question.type === QuestionType.Numeric
      ? {
          ...state,
          [question.id]: {
            ...s,
            type: QuestionType.Numeric,
            config: defaultNumericConfig,
          },
        }
      : question.type === QuestionType.Drilldown
      ? {
          ...state,
          [question.id]: {
            ...s,
            type: QuestionType.Drilldown,
            config: defaultDrilldownConfig,
          },
        }
      : question.type === QuestionType.AutoDrilldown
      ? {
          ...state,
          [question.id]: {
            ...s,
            type: QuestionType.AutoDrilldown,
            config: defaultAutoDrilldownConfig,
          },
        }
      : {
          ...state,
          [question.id]: {
            ...s,
            type: question.type,
            config: {
              filter: false,
            },
          },
        }

  return previousQuestion
    ? {
        ...intermediateState,
        [previousQuestion.id]: {
          ...state[previousQuestion.id],
          next: question.id,
        },
      }
    : intermediateState
}
