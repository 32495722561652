import { useSidebar } from "~/context/sidebar"
import React from "react"

const getIcon = (bool: boolean) =>
  bool ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  )

export const SidebarToggle: React.FC<{
  open: boolean
  onRight?: boolean
  toggle: () => void
  className?: string
}> = ({ toggle, className, open, onRight = false }) => {
  return (
    <button
      className={`${className} ${open ? "is-open" : ""} ${
        onRight ? "layout__sidebar-toggle on-right" : ""
      }`}
      onClick={toggle}
    >
      {getIcon(onRight ? !open : open)}
    </button>
  )
}

export const WithSidebar: React.FC<{
  navigation?: React.ReactNode
  disableToggle?: boolean
  collapsed?: boolean
}> = ({ navigation, children, disableToggle, collapsed }) => {
  const { toggle, open: sidebarOpen } = useSidebar()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const open = collapsed ? false : sidebarOpen
  const openClass = open ? "is-open" : "is-collapsed"

  return (
    <div className={`layout ${openClass}`}>
      <div
        className={`layout__sidebar ${openClass} ${
          mobileOpen ? "is-mobile-open" : ""
        }`}
      >
        {!disableToggle && (
          <SidebarToggle
            className={`layout__sidebar-toggle ${openClass}`}
            toggle={toggle}
            open={open}
          />
        )}
        <button
          onClick={() => setMobileOpen(!mobileOpen)}
          className={`layout__menu-toggle hamburger ${
            mobileOpen ? "is-open" : ""
          }`}
        >
          <span className="hamburger-box">
            <span className="hamburger-layer"></span>
          </span>
        </button>
        <nav className="layout__sidebar__menu">{navigation}</nav>
      </div>
      <div className="layout__page-content">{children}</div>
    </div>
  )
}

export default {
  WithSidebar,
}
