import styled, { css } from "styled-components"

const Card = styled.div<{
  withBottomMargin?: boolean
  withTopMargin?: boolean
  isDisabled?: boolean
}>`
  border-radius: ${(p) => p.theme.borderRadius};
  padding: 2rem;
  box-sizing: border-box;
  transition: box-shadow 200ms ease-in, background-color 200ms ease-in;
  /* box-shadow: 0 2px 8px 0px ${(props) => props.theme.dp[10]}; */
  box-shadow: 0 1px 1px rgba(155, 163, 161, 0.11),
    0 2px 2px rgba(155, 163, 161, 0.11), 0 4px 4px rgba(155, 163, 161, 0.11),
    0 6px 8px rgba(155, 163, 161, 0.11), 0 8px 16px rgba(155, 163, 161, 0.11);
  background-color: ${(props) => props.theme.background};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  position: relative;
  ${(p) =>
    p.withBottomMargin &&
    css`
      margin-bottom: 2rem;
    `}
  ${(p) =>
    p.withTopMargin &&
    css`
      margin-top: 2rem;
    `}

  & > p:first-child {
    margin-top: 0;
  }
`

export default Card
