import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import { StyleConfig } from "~/store/advisors"
import React from "react"

const ColorsForm: React.FC<{
  style: StyleConfig
  changeStyleStringVal: (key: keyof StyleConfig, value: string) => void
}> = ({ style, changeStyleStringVal }) => {
  const [ports, setupPorts] = React.useState<any>(undefined)

  React.useEffect(() => {
    if (ports) {
      // @ts-ignore
      ports.changeStyleStringValColorsForm.subscribe(function ([key, value]) {
        changeStyleStringVal(key, value)
      })
    }
  }, [ports, changeStyleStringVal])

  React.useEffect(() => {
    if (ports) {
      // @ts-ignore
      ports.styleUpdatedColorsForm.send(style)
    }
  }, [ports, style])

  return (
    // @ts-ignore
    <Elm
      // @ts-ignore
      src={ElmModule.ColorsForm}
      flags={style}
      ports={setupPorts}
    />
  )
}

export default ColorsForm
