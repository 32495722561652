import {
  AdvisorActionTypes,
  CHANGE_ADVISOR_META,
  CHANGE_ADVISOR_STYLE,
  FETCH_ADVISOR_SUCCESS,
  FETCH_ADVISORS,
  FETCH_ADVISORS_SUCCESS,
  NEW_ADVISOR,
  NEW_ADVISOR_SUCCESS,
  ADVISOR_PUBLISHED,
  CHANGE_CUSTOMER_INFO_CONFIG,
  UPDATE_ADVISOR_SUCCESS,
  FETCHED_ADVISOR_STYLE,
}from "~/actions/advisors"

import { AdvisorsState } from "./types"
import { ADD_AFTER, FlowActionTypes }from "~/actions/flow"
import {
  ADD_QUESTION,
  addQuestion,
  REMOVE_QUESTION,
  removeQuestion,
  REORDER_QUESTION,
  reorderQuestion,
} from "../../actions/questions"
import { ActionType } from "typesafe-actions"
import { moveElem }from "~/utils/array"
import { REMOVE_INFO_SCREEN }from "~/actions/screens/constants"
import { removeInfoScreen }from "~/actions/screens/actions"

const defaultState = {}

export default (
  state: AdvisorsState = defaultState,
  action:
    | AdvisorActionTypes
    | FlowActionTypes
    | ActionType<typeof addQuestion>
    | ActionType<typeof removeQuestion>
    | ActionType<typeof reorderQuestion>
    | ActionType<typeof removeInfoScreen>
): AdvisorsState => {
  switch (action.type) {
    case FETCH_ADVISOR_SUCCESS:
    case NEW_ADVISOR_SUCCESS:
    case NEW_ADVISOR: {
      const advisor = action.payload.advisor
      return { ...state, [advisor.id]: { ...advisor } }
    }

    case UPDATE_ADVISOR_SUCCESS: {
      const advisor = action.payload.advisor
      const target = state[advisor.id]
      return { ...state, [advisor.id]: { ...advisor, status: target.status, updatedAt: new Date() } }
    }

    case ADVISOR_PUBLISHED: {
      const target = state[action.payload.advisorId]
      return {
        ...state,
        [target.id]: {
          ...target,
          status: action.payload.status,
          updatedAt: new Date(),
          // action.payload.status === AdvisorStatus.Online
          //   ? new Date()
          //   : target.updatedAt,
        },
      }
    }

    case ADD_QUESTION: {
      const target = state[action.payload.advisorId]
      return {
        ...state,
        [action.payload.advisorId]: {
          ...target,
          questions: [...target.questions, action.payload.question.id],
          screenOrder: [...target.screenOrder, action.payload.question.id],
        },
      }
    }
    case ADD_AFTER: {
      const { toAdd } = action.payload
      const target = state[action.payload.advisorId]

      if (toAdd.type === "question") {
        return {
          ...state,
          [action.payload.advisorId]: {
            ...target,
            questions: [...target.questions, toAdd.id],
            screenOrder: [...target.screenOrder, toAdd.id],
          },
        }
      } else if (toAdd.type === "infoScreen") {
        return {
          ...state,
          [action.payload.advisorId]: {
            ...target,
            screenOrder: [...target.screenOrder, toAdd.id],
          },
        }
      } else {
        return state
      }
    }
    case REMOVE_QUESTION: {
      const { advisorId, id } = action.payload
      const target = state[advisorId]
      return {
        ...state,
        [action.payload.advisorId]: {
          ...target,
          questions: target.questions.filter((q) => q !== id),
          screenOrder: target.screenOrder.filter((q) => q !== id),
        },
      }
    }
    case REMOVE_INFO_SCREEN: {
      const { advisorId, id } = action.payload
      const target = state[advisorId]
      return {
        ...state,
        [action.payload.advisorId]: {
          ...target,
          screenOrder: target.screenOrder.filter((q) => q !== id),
        },
      }
    }

    case REORDER_QUESTION: {
      const { advisorId, oldPosition, newPosition } = action.payload
      const target = state[advisorId]
      if (!target) return state

      return {
        ...state,
        [action.payload.advisorId]: {
          ...target,
          screenOrder: moveElem(target.screenOrder, oldPosition, newPosition),
        },
      }
    }

    case FETCH_ADVISORS: {
      return state
    }
    case FETCH_ADVISORS_SUCCESS: {
      return {
        ...state,
        advisors: state.advisors,
      }
    }

    case CHANGE_ADVISOR_META: {
      return {
        ...state,
        [action.payload.advisorId]: {
          ...state[action.payload.advisorId],
          name: action.payload.name,
          productLabel: action.payload.productLabel,
          icon: action.payload.icon,
          analytics: action.payload.analytics,
          locales: action.payload.locales,
        },
      }
    }
    case FETCHED_ADVISOR_STYLE:
    case CHANGE_ADVISOR_STYLE: {
      return {
        ...state,
        [action.payload.advisorId]: {
          ...state[action.payload.advisorId],
          style: action.payload.style,
        },
      }
    }
    case CHANGE_CUSTOMER_INFO_CONFIG: {
      const { advisorId, customerInfoConfig } = action.payload
      return {
        ...state,
        [advisorId]: {
          ...state[advisorId],
          customerInfo: customerInfoConfig,
        },
      }
    }
    default:
      return state
  }
}
