import React, { useEffect } from "react"
import qs from "qs"
import { v4 as uuid } from "uuid"
import useNavigation from "~/hooks/useNavigation"
import useInfoScreen from "~/hooks/useInfoScreen"
import useQuestions from "~/hooks/useQuestions"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import QuestionLabel from "~/components/QuestionLabel"
import { getCurrentColor } from "~/themes"
import DarkSelect from "~/components/selection/DarkSelect"
import Emoji from "~/components/Emoji"
import Add from "~/components/icons/Add"
import { addAfter, Flow }from "~/actions/flow"
import { useDispatch } from "react-redux"
import Stop from "~/components/icons/Stop"

const InfoScreenRuleEditor: React.FC<{
  advisorId: string
  screenId: string
}> = ({ screenId, advisorId }) => {
  const { screen, changeScreenNext } = useInfoScreen(advisorId, screenId)
  const { addNewQuestion, questions } = useQuestions(advisorId)
  const { toQuestionInAdvisor, toScreenInAdvisor } = useNavigation()
  const { t } = useTranslation()
  const infoScreens = Flow.useInfoScreens(advisorId)
  const dispatch = useDispatch()

  const location = useLocation()
  const ruleIdParam = qs.parse(
    location.search.substring(1, location.search.length)
  )["ruleId"] as string

  const optionsWithNewQuestion = (
    options: { value: string; label: string }[]
  ) => [
    ...options,
    {
      value: "ADVICE",
      label: (
        <>
          <Emoji emoji=":point_right:" size={16} /> {t("advise")}
        </>
      ),
    },
    {
      value: undefined,
      label: (
        <>
        <Stop/>
        Nothing
        </>
      ),
    },{
      value: "NEW_QUESTION",
      label: (
        <>
          <Add />
          {t("rulesEditor.newQuestion")}
        </>
      ),
    },
    {
      value: "NEW_INFO_PAGE",
      label: (
        <>
          <Add />
          {t("rulesEditor.newInfoPage")}
        </>
      ),
    },
  ]
  const options = optionsWithNewQuestion([
    ...questions
      .filter((a) => a.id !== screen.id)
      .map((q) => ({ value: q.id, label: q.label })),
    ...infoScreens
      .filter((a) => a.id !== screen.id)
      .map((q) => ({ value: q.id, label: q.label })),
  ])

  useEffect(() => {
    setTimeout(
      () =>
        document
          ?.querySelector(`[data-rule-id='${ruleIdParam}']`)
          ?.scrollIntoView({
            behavior: "smooth",
          }),
      200
    )
  }, [ruleIdParam])

  if (!screen) return null
  const inlineStyle = { margin: "0 .35rem" }
  return (
    <>
      <div>
        {t("infoScreenRulesEditor.base.start")}
        <QuestionLabel col={getCurrentColor(screen.color)} style={inlineStyle}>
          {screen.label}
        </QuestionLabel>
        {t("infoScreenRulesEditor.base.end")}
        <span style={inlineStyle}>
          <DarkSelect
            inline
            small
            value={screen.next}
            emptyText={t("rulesEditor.selectQuestion")}
            options={options}
            position="bottom-start"
            onSelect={(value) => {
              if (value === "NEW_QUESTION") {
                const id = uuid()
                addNewQuestion({ id, next: screen.next })

                changeScreenNext(id)
                toQuestionInAdvisor(advisorId, id)
              } else if (value === "NEW_INFO_PAGE") {
                const id = uuid()
                dispatch(
                  addAfter(
                    {
                      id: id,
                      type: "infoScreen",
                      next: screen.next || "ADVICE",
                    },
                    screen.id,
                    { advisorId }
                  )
                )
                changeScreenNext(id)

                toScreenInAdvisor(advisorId, id)
              } else {
                dispatch(changeScreenNext(value))
              }
            }}
          />
        </span>
      </div>
    </>
  )
}

export default InfoScreenRuleEditor
