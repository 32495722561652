import { useSelector } from "react-redux"
import { ApplicationState }from "~/store"

const useAdviceScreen = (advisorId: string) => {
  const adviceScreen = useSelector(
    (state: ApplicationState) => state.screens[advisorId].advice
  )

  return { adviceScreen }
}

export default useAdviceScreen
