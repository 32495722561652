import { UUID } from "~/store/types"
import { action } from "typesafe-actions"
import {
    CHANGE_NEXT,
    CHANGE_SCREEN_COLOR,
    CHANGE_SCREEN_CUSTOMER_INFO,
    CHANGE_SCREEN_INFO,
    CHANGE_SCREEN_LABEL,
    CHANGE_SCREEN_MEDIA_TYPE,
    CHANGE_SCREEN_NEXT,
    CHANGE_SCREEN_TEXT,
    CHANGE_SCREEN_TITLE,
    REMOVE_INFO_SCREEN,
    UPDATE_ADVICE_SCREEN_DATA,
    UPDATE_INTRO_SCREEN_DATA,
} from "./constants"
import { AdviceScreen, IntroScreen, ScreenMediaType }from "~/store/screens"
import { AdvisorMeta }from "~/actions/advisors"
import { QuestionColor }from "~/store/questions"
import { InfoPageCustomerInfoConfig } from "~/store/advisors"

export const updateIntroScreenData = (
  advisorId: UUID,
  data: Omit<IntroScreen, "id" | "type">
) => action(UPDATE_INTRO_SCREEN_DATA, { advisorId, ...data })

export const changeNext = (
  screenId: UUID,
  next: UUID,
  meta: AdvisorMeta
) => action(CHANGE_NEXT, { screenId, next, ...meta })

export const updateAdviceScreenData = (
  advisorId: UUID,
  data: Omit<AdviceScreen, "id" | "type">
) => action(UPDATE_ADVICE_SCREEN_DATA, { advisorId, ...data })

export const changeMediaType = (
  screenId: UUID,
  mediaType: ScreenMediaType,
  meta: AdvisorMeta
) => action(CHANGE_SCREEN_MEDIA_TYPE, { id: screenId, mediaType, ...meta })

export const changeScreenTitle = (
  screenId: UUID,
  title: string,
  meta: AdvisorMeta
) => action(CHANGE_SCREEN_TITLE, { id: screenId, title, ...meta })

export const changeScreenLabel = (
  screenId: UUID,
  label: string,
  meta: AdvisorMeta
) => action(CHANGE_SCREEN_LABEL, { id: screenId, label, ...meta })
export const changeScreenColor = (
  screenId: UUID,
  color: QuestionColor,
  meta: AdvisorMeta
) => action(CHANGE_SCREEN_COLOR, { id: screenId, color, ...meta })
export const changeScreenText = (
  screenId: UUID,
  text: string,
  meta: AdvisorMeta
) => action(CHANGE_SCREEN_TEXT, { id: screenId, text, ...meta })
export const changeScreenInfo = (
  screenId: UUID,
  info: string,
  meta: AdvisorMeta
) => action(CHANGE_SCREEN_INFO, { id: screenId, info, ...meta })
export const changeScreenNext = (
  screenId: UUID,
  nextId: UUID,
  meta: AdvisorMeta
) => action(CHANGE_SCREEN_NEXT, { id: screenId, nextId, ...meta })
export const changeScreenCustomerInfo = (
  screenId: UUID,
  customerInfo: InfoPageCustomerInfoConfig,
  meta: AdvisorMeta
) => action(CHANGE_SCREEN_CUSTOMER_INFO, { id: screenId, customerInfo, ...meta })
export const removeInfoScreen = (screenId: UUID, meta: AdvisorMeta) =>
  action(REMOVE_INFO_SCREEN, { id: screenId, ...meta })
