import React, { useEffect, useState, useCallback } from "react"
import { authenticate } from "~/api/authentication"
import { forgotPasswordRequest, resetPasswordRequest } from "~/api/accounts"
import JWT from "~/lib/JWT"
import { useDispatch } from "react-redux"
import { logout as logoutAction }from "~/store/globalActions"
import { OrganisationId } from "~/api/types"
import { useIntercom } from "react-use-intercom"
import organisations from "../api/organisations"
import { organisationNameChanged } from "../actions/organisations"

type AuthContextOpts = {
  isAuthenticated: boolean
  token?: string
  organisationId: OrganisationId
  apiContext: { token: string; organisationId: OrganisationId }
  login: (username: string, password: string) => Promise<{ token: string }>
  logout: () => void
  register: () => void
  forgotPassword: () => Promise<boolean>
  resetPassword: () => Promise<boolean>
}

const AuthContext = React.createContext({
  isAuthenticated: false,
  organisationId: { orgId: "" },
  apiContext: { token: "", organisationId: { orgId: "" } },
  login: (username: string, password: string) =>
    Promise.resolve({ token: "token" }),
  logout: () => {},
  register: () => {},
  forgotPassword: () => Promise.resolve(false),
  resetPassword: () => Promise.resolve(false),
} as AuthContextOpts)

const AuthProvider = (props) => {
  const { shutdown } = useIntercom();
  const [token, setToken] = useState<string | undefined | null>(undefined)
  const [organisationId, setOrgId] = useState<string>("")
  const dispatch = useDispatch()

  useEffect(() => {
    setToken(localStorage.getItem("authToken"))
    setOrgId(localStorage.getItem("selectedOrganisation") || "")
  }, [])

  useEffect(() => {
    if (!token) return
    localStorage.setItem("authToken", token)
    if (!organisationId) {
      const jwt = JWT.parseValues(token!)
      localStorage.setItem("selectedOrganisation", jwt.organisationId || "")
      setOrgId(jwt.organisationId || "")
    }
  }, [token, organisationId])

  useEffect(() => {
    organisationId &&
      localStorage.setItem("selectedOrganisation", organisationId)
  }, [organisationId])

  useEffect(() => {
      organisationId && token && organisations
          .get(token, { orgId: organisationId })
          .then((org) => dispatch(organisationNameChanged(org.name)))
          .catch((e: Error) => {})
  }, [token, organisationId])

  const login = async (username: string, password: string) =>
    authenticate(username, password).then((data) => {
      setToken(data.token)
      const jwt = JWT.parseValues(data.token)
      localStorage.setItem("selectedOrganisation", jwt.organisationId || "")
      setOrgId(jwt.organisationId || "")
      return data
    })

  // make a login request
  const register = () => {} // register the user

  const forgotPassword = async (email: string) => forgotPasswordRequest(email)

  const resetPassword = async (
    email: string,
    token: string,
    password: string,
    passwordConfirmation: string
  ) => resetPasswordRequest(email, token, password, passwordConfirmation)

  const orgId = React.useMemo(
    () => ({ orgId: organisationId }),
    [organisationId]
  )
  const apiContext = React.useMemo(
    () => ({ token, organisationId: orgId }),
    [token, orgId]
  )

  const logout = useCallback(() => {
    dispatch(logoutAction())
    localStorage.removeItem("authToken")
    //TODO: Should be done cleaned up as long as JWT contains organisationId
    localStorage.removeItem("selectedOrganisation")
    setToken(null)
    shutdown()
  }, [setToken, dispatch]) // clear the token in localStorage and the user data
  // code for pre-loading the user's information if we have their token in
  // localStorage goes here
  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.

  // Check if the token is still valid when switching tabs. This is to
  // handle the case to logout the user before an action is required
  useEffect(() => {
    const handler = () => {
      if (token && JWT.isExpired(token)) logout()
    }
    document.addEventListener("visibilitychange", handler)

    return () => document.removeEventListener("visibilitychange", handler)
  }, [logout, token])

  if (token === undefined) return null
  if (token && JWT.isExpired(token)) logout()

  // note, I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.

  return (
    <AuthContext.Provider
      value={{
        token,
        organisationId: orgId,
        apiContext,
        isAuthenticated: !!token,
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
      }}
      {...props}
    />
  )
}

const useAuth = () => React.useContext<AuthContextOpts>(AuthContext)
export { AuthProvider, useAuth }
