import { Action, combineReducers } from "redux"
import questions from "./questions/reducer"
import answers from "./answers/reducer"
import advisors from "./advisors/reducer"
import requestStatus from "./requestStatus/reducer"
import flowChangeTracker from "./flowChangeTracker/reducer"
import currentUser from "./currentUser/reducer"
import screens from "./screens/reducer"
import rules from "./rules/reducer"
import notifications from "./notifications/reducer"
import organisations from "./organisations/reducer"

import { createStore, compose, applyMiddleware } from "redux"
import thunk, { ThunkAction } from "redux-thunk"
import autoSaveMiddleware from "./middleware/AutoSaveMiddleware"
import qAndAValidationMiddleware from "./middleware/QandAValidationMiddleware"
import savingStateReducer from "./advisors/savingStateReducer"
import { LOGOUT } from "./globalActions"
import featureFlagReducer from "./featureFlags/reducer"
import { advisorLoadingReducer } from "./advisors/loadingReducer"
import { reducer as flowReducer } from "./flow"
import { reducer as validationReducer } from "./validation"
import { reducer as unpublishedChangesReducer } from "./unpublishedChanges"

const defaultState = {}

const appReducer = combineReducers({
  advisors,
  advisorsLoading : advisorLoadingReducer,
  questions,
  rules,
  answers,
  screens,
  requestStatus,
  flowChangeTracker,
  currentUser,
  notifications,
  organisations,
  flow: flowReducer,
  featureFlags: featureFlagReducer,
  advisorSavingState: savingStateReducer,
  advisorValidation: validationReducer,
  unpublishedChanges: unpublishedChangesReducer
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export type ApplicationState = ReturnType<typeof rootReducer>

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ApplicationState,
  unknown,
  Action<string>
>

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const configureStore = () => {
  return createStore(
    rootReducer,
    defaultState,
    composeEnhancers(applyMiddleware(autoSaveMiddleware, qAndAValidationMiddleware, thunk))
  )
}

export { configureStore }
