import React from "react"
import styled from "styled-components"

const FileInputCompLabel = styled.label`
  margin: 0;
  line-height: normal;
  font-size: 1em;

  /* padding: 0.75rem;
  background: ${props => props.theme.backgroundShade1};
  font-style: normal;
  -webkit-appearance: none;
  border: 2px solid transparent;
  border-color: transparent;
  background-color: ${p => p.theme.shade1};
  color: ${p => p.theme.main};
  border-radius: 0.25rem;
  line-height: ${p => p.theme.lineHeight};
  font-weight: bold;
  cursor: pointer;
  width: auto;
  display: flex;
  place-content: center;
  text-align: center;
  outline: none;
  align-items: center; */
`
const FileInputComp = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
`

type FileInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { label: string; inline?: boolean }

const FileInput = React.forwardRef(
  (props: FileInputProps, ref?: React.Ref<HTMLInputElement>) => {
    const { onChange, accept, label, style, inline = true } = props
    return (
      <span  style={{...style,  display: inline ? "inline-block" : "block" }}>
        <FileInputCompLabel  className="btn">
          <FileInputComp ref={ref} type="file" onChange={onChange} accept={accept} />
          {label}
        </FileInputCompLabel>
      </span>
    )
  }
)

export default FileInput
