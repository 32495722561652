import React, { useState } from "react"
import FieldGroup from "~/components/forms/FieldGroup"
import Field from "~/components/forms/Field"
import FormHeader from "~/components/forms/FormHeader"
import { Account } from "~/store/currentUser/types"
import Range from "~/lib/Range"
import HelpText from "~/components/HelpText"
import FormSection from "~/components/forms/FormSection"
import Form from "~/components/forms/Form"
import Button from "~/components/Button"
import useCurrentUser from "~/hooks/useCurrentUser"
import LoadingSpinner from "~/components/Loading"
import { useTranslation } from "react-i18next"
import { Status } from "~/store/requestStatus/reducer"
import { useImageSelectPreview } from "~/hooks/useImageSelectPreview"
import AvatarSelector from "~/components/AvatarSelector"
import { useAuth } from "~/context/auth"
import { accounts } from "~/api"
import useNotifications from "~/hooks/useNotifications"
import ToggleSwitch from "~/components/ToggleSwitch"
import { useIntercom } from "react-use-intercom"

type MyAccountProps = {
  account: Account
}

const defaultCrop = { width: 0, height: 0, x: 0, y: 0 }

const MyAccount: React.FC<MyAccountProps> = ({ account }) => {
  const { t } = useTranslation()
  const {
    useReqId,
    isPending,
    updateCurrentUserInfo,
    profileImage,
    currentUser,
    getRequestStatus,
  } = useCurrentUser()

  const { notify } = useNotifications()

  const [firstName, setFirstname] = useState(account.firstName)
  const [familyName, setFamilyName] = useState(account.familyName)
  const [sendPeriodicUsageRecap, setUsageRecap] = useState(
    account.emailPreferences.sendPeriodicUsageRecap
  )
  const [requestId, setRequestId] = useReqId()
  const [isCropping, setIsCropping] = useState(false)
  const { token, organisationId } = useAuth()
  const { selectedImage, previewUrl, onSelectImage } = useImageSelectPreview()
  const [crop, setCrop] = useState(defaultCrop)
  const [error, setError] = useState("")
  const { update } = useIntercom()

  const firstNameValid = Range.in(1, 255, firstName.length)
  const familyNameValid = Range.in(1, 255, familyName.length)

  const oneIsEmpty = firstName === "" || familyName === ""
  const oneIsTooLong = firstName.length > 255 || familyName.length > 255

  const [isSaving, setIsSaving] = React.useState(false)
  const valid = firstNameValid && familyNameValid && !isCropping

  const status = getRequestStatus(requestId)
  const pending = isPending(requestId) || isSaving

  const noChanges =
    account.firstName === firstName &&
    account.familyName === familyName &&
    account.emailPreferences.sendPeriodicUsageRecap ===
      sendPeriodicUsageRecap &&
    selectedImage === undefined

  const reset = () => {
    setCrop(defaultCrop)
    onSelectImage(undefined)
  }

  const save = async () => {
    const validCrop = Object.values(crop).some((v) => v !== 0)
    if (!pending && valid) {
      setError("")
      setIsSaving(true)
      try {
        let profileImage = currentUser?.profileImage
        let emailPreferences = {
          sendPeriodicUsageRecap: sendPeriodicUsageRecap,
        }
        if (selectedImage && validCrop) {
          const updateAccount = await accounts.updateAvatar(
            currentUser!.id,
            selectedImage,
            crop,
            token!,
            organisationId
          )
          profileImage = updateAccount.profileImage
        }
        reset()

        notify({
          text: t("general.feedback.changesSaved"),
          type: "success",
        })

        setRequestId(
          updateCurrentUserInfo({
            firstName,
            familyName,
            profileImage,
            emailPreferences,
          })
        )
        update({
          userId: currentUser!.id,
          name: familyName,
        })
      } catch (e) {
        setError(t("errors.error"))
      } finally {
        setIsSaving(false)
      }
    }
  }

  return (
    <Form
      onSubmit={save}
      actions={
        <Button
          isLoading={pending}
          disabled={!valid || noChanges}
          primary
          onClick={(e) => {
            e.preventDefault()
            save()
          }}
        >
          {pending ? <LoadingSpinner size="small" /> : "Save"}
        </Button>
      }
    >
      {/* <FormHeader>Photo</FormHeader> */}
      <FormSection>
        <FormHeader>{t("settings.myAccount.personalInfo")}</FormHeader>
        <FieldGroup>
          <AvatarSelector
            name={firstName}
            isCropping={isCropping}
            onSelectImage={onSelectImage}
            setIsCropping={(isCropping) => setIsCropping(isCropping)}
            setCrop={(crop) =>
              setCrop({
                x: crop.x || 0,
                y: crop.y || 0,
                width: crop.width || 0,
                height: crop.height || 0,
              })
            }
            currentImage={profileImage}
            image={previewUrl}
          />
        </FieldGroup>
        <FieldGroup className="horizontal-group">
          <Field
            hasError={!firstNameValid}
            label={t("settings.myAccount.firstName")}
            placeholder={t("settings.myAccount.firstNamePlaceholder")}
            value={firstName}
            onChange={setFirstname}
          />
          <Field
            hasError={!familyNameValid}
            label={t("settings.myAccount.familyName")}
            placeholder={t("settings.myAccount.familyNamePlaceholder")}
            value={familyName}
            onChange={setFamilyName}
          />
        </FieldGroup>
        <HelpText hasError>
          {oneIsEmpty && t("settings.myAccount.validation.nameEmpty")}
          {oneIsTooLong && t("settings.myAccount.validation.nameToolong")}
        </HelpText>
      </FormSection>
      <FormSection>
        <FieldGroup>
          <ToggleSwitch
            onChange={setUsageRecap}
            toggledOn={sendPeriodicUsageRecap}
            label={"Receive weekly usage recap email"}
          />
        </FieldGroup>
      </FormSection>
      <FormSection>
        <FieldGroup>
          <div>
            <Field
              disabled
              label={t("settings.myAccount.email")}
              value={account.email}
              onChange={() => {}}
            />
            <HelpText>{t("settings.myAccount.emailNotice")}</HelpText>
          </div>
        </FieldGroup>
        {status?.type === Status.FAILED ||
          (error && <HelpText hasError>{t("errors.error")}</HelpText>)}
      </FormSection>
    </Form>
  )
}

export default MyAccount
