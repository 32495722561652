import React, { useCallback, useEffect, useState } from "react"
import useNavigation from "~/hooks/useNavigation"
import ConversationsCanvas from "./ConversationsCanvas"
import SidebarContent from "./Sidebar"
import styled, { css } from "styled-components"
import { Advisor } from "~/store/advisors"
import useQuestions from "~/hooks/useQuestions"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import { useSelector } from "react-redux"
import { ApplicationState } from "~/store"
import { requestStatus } from "~/store/requestStatus/selectors"
import { Status } from "~/store/requestStatus/reducer"
import { hasChanges } from "~/store/flowChangeTracker/selectors"
import { useTranslation } from "react-i18next"
import NavPrompt from "~/components/NavPrompt"
import AutoSaveStatus from "./AutoSaveStatus"
import { SidebarToggle } from "~/components/Layout"
import { useQandASidebarContext } from "./QandASidebarContext"
import ChevronBack from "~/components/icons/ChevronBack"
import useAdvisors from "~/hooks/useAdvisors"
import useMountEffect from "~/hooks/useMountEffect"
import { isAdvisorLoading } from "~/store/advisors/selectors"
import LoadingSpinner from "~/components/LoadingSpinner"
import { AdvisorValidation } from "./AdvisorValidation"
import useQandARefresh from "~/hooks/useQandARefresh"

const Canvas = styled.div`
  flex: 1;
  position: relative;
  background: ${(p) => p.theme.backgroundShade};
`

const Sidebar = styled.div`
  padding: 0 3rem 1rem 3rem;
  background: #fff;
`

const QandA = styled.div<{ sidebarOpen: boolean }>`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* display: grid; */
  /* grid-template-columns: minmax(0, 1fr) 2rem; */

  ${Sidebar} {
    opacity: 0;
    overflow: hidden;
    overflow-y: auto;
    max-height: 100vh;
    width: 0;
    box-sizing: border-box;
  }

  .sidebar-placeholder,
  ${Sidebar} {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    background: white;
    box-shadow: ${(p) => p.theme.defaultShadow};
  }

  .layout__sidebar-toggle {
    position: fixed;
    right: 1rem;

    &.is-open {
      right: 35rem;
    }
  }

  ${(p) =>
    p.sidebarOpen
      ? css`
          /* grid-template-columns: 1fr minmax(0, 36rem); */
          padding-right: 36rem;
          width: calc(100% - 36rem);

          ${Sidebar}, .sidebar-placeholder {
            opacity: 1;
            overflow: auto;
            width: 36rem;
            right: 0;
            top: 0;
          }
        `
      : css`
          padding-right: 2rem;
          .sidebar-placeholder {
            width: 2rem;
          }
        `}
`

const Flow: React.FC<{ advisor: Advisor }> = (props) => {
  const { advisor } = props
  const { map } = useNavigation()
  const { addNewQuestion, removeQuestion } = useQuestions(advisor.id)
  const { getAdvisor } = useAdvisors()
  useMountEffect(() => getAdvisor(advisor.id))
  // const { saveAdvisor } = useAdvisors()
  const [requestId, setRequestId] = useState("")
  const { t } = useTranslation()
  const status = useSelector((state: ApplicationState) =>
    requestStatus(state, requestId)
  )
  const flowWasChanged = useSelector((state: ApplicationState) =>
    hasChanges(state, advisor.id)
  )
  const { url } = useRouteMatch()
  const match = useRouteMatch<{ questionId?: string }>(
    `${url}/questions/:questionId`
  )
  const selectedQuestionId = match?.params.questionId

  const screenMatch = useRouteMatch<{ screenId?: string }>(
    `${url}/screens/:screenId`
  )
  const selectedScreenId = screenMatch?.params.screenId

  useQandARefresh(advisor.id)

  useEffect(() => {
    let timeout
    if (status?.type !== Status.PENDING)
      timeout = setTimeout(() => setRequestId(""), 2000)
    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [status])

  const { collapsed, changeCollapse } = useQandASidebarContext()

  const isRefreshing = useSelector((state: ApplicationState) =>
    isAdvisorLoading(state, advisor.id)
  )

  return (
    <>
      <NavPrompt
        pathSection={"/flow"}
        extraCondition={() => flowWasChanged}
        leftButtonLabel={t("advisorFlow.unsavedChangesConfirm")}
      >
        <p>{t("advisorFlow.unsavedChanges")}</p>
      </NavPrompt>
      <QandA sidebarOpen={!collapsed}>
        {isRefreshing ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              placeContent: "center",
              justifyContent: "center",
              position: "absolute",
              zIndex: 109,
              top: 0,
              right: 0,
              left: 0,
              background: "rgba(255,255,255,0.8)"
            }}
          >
            <LoadingSpinner />
          </div>
        ) : null}

        <Canvas>
          <ConversationsCanvas
            advisorId={advisor.id}
            selectedQuestionId={selectedQuestionId}
            selectedScreenId={selectedScreenId}
            questionIds={advisor.questions}
            addQuestion={addNewQuestion}
            removeQuestion={removeQuestion}
          />
        </Canvas>
        <div className="sidebar-placeholder">
          <SidebarToggle
            onRight={true}
            open={!collapsed}
            toggle={() => changeCollapse(!collapsed)}
          />
          <Sidebar data-test-id="sidebar" id="sidebar">
            <div className="layout__main-actions-bar">
              <Switch>
                <Route
                  path={`${url}/*`}
                  exact={false}
                  render={(match) => (
                    <Link
                      to={map.conversation(advisor.id)}
                      className="link"
                      style={{
                        display: "inline-block",
                        marginRight: "auto",
                        fontSize: ".875rem",
                      }}
                    >
                      <ChevronBack />
                      {t("Back")}
                    </Link>
                  )}
                />
              </Switch>

              <AutoSaveStatus advisorId={advisor.id} />
              <AdvisorValidation />
            </div>
            <SidebarContent advisorId={advisor.id} />
          </Sidebar>
        </div>
      </QandA>
    </>
  )
}

export default Flow
