import { getArticleOnClick } from "~/utils/intercomArticles"
// @ts-ignore
import tutorialIcon from "~/components/icons/tutorial-icon.svg?raw"

class TutorialLink extends HTMLElement {
  static get observedAttributes() {
    // Which attributes call attributeChangedCallback
    return ["article-id", "label", "class"]
  }

  connectedCallback() {
    const elem = this

    const a = document.createElement("a")

    this.setElementLink(a, elem.getAttribute("article-id") || "")

    a.classList.add("tutorial-chip")
    const extraClass = elem.getAttribute("class")
    if (extraClass && extraClass != "") a.classList.add(extraClass)

    this.setElementContent(a, elem.getAttribute("label") || "")

    elem.appendChild(a)
  }

  // Update content if placement attribute changed
  attributeChangedCallback(_name, _oldValue, newValue) {
    if (!this.isConnected) return
    const a = this.querySelector("a")
    if (!a) return

    if (_name === "article-id") {
      a?.setAttribute("article-id", newValue)
      this.setElementLink(a, newValue)
    }
    if (_name === "label") {
      a?.setAttribute("label", newValue)
      this.setElementContent(a, newValue)
    }
    if (_name === "class") {
      a?.setAttribute("class", newValue || "")
    }
  }

  setElementContent(element: HTMLElement, label: string) {
    element.innerText = "Tutorial: " + (label || "")
    const helpIcon = new DOMParser().parseFromString(
      tutorialIcon,
      "image/svg+xml",
    )
    if (helpIcon.firstElementChild) element.prepend(helpIcon.firstElementChild)
  }

  setElementLink(element: HTMLElement, articleId: string) {
    element.onclick = getArticleOnClick(articleId || "")
  }
}

const define = () => {
  try {
    customElements.define("tutorial-link", TutorialLink)
  } catch (e) {}
}

export { define }
