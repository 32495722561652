import useInfoScreen from "../../../../hooks/useInfoScreen"
import React from "react"
import { useTranslation } from "react-i18next"
import Label from "~/components/forms/Label"
import { SingleSelect } from "~/components/selection"
import MediaEditor from "~/elm-tsx-wrappers/MediaEditor"
import { ScreenMediaType }from "~/store/screens"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar }from "~/utils/environment"
import { Modal, ModalButtons, ModalContent } from "~/components/Modal"
import Button from "~/components/Button"
import { media as MediaAPI } from "~/api"

const mediaChecker = (advisorId: string, token, organisationId) => {
  return {
    checkHasScreenMedia: async (screenId: string) =>
      MediaAPI.mediaForAdvisor(advisorId, [screenId], {
        token,
        organisationId,
      }).then((media) => !!media.find((m) => m.referenceId === screenId)),
  }
}

const ScreenMediaEditor: React.FC<{
  advisorId: string
  screenId: string
}> = ({ screenId, advisorId }) => {
  const { screen, changeScreenMediaType } = useInfoScreen(advisorId, screenId)
  const { token, organisationId } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string
  const { t } = useTranslation()
  const checker = mediaChecker(advisorId, token, organisationId)

  const [confirm, setConfirm] = React.useState<
    undefined | { chosenType: ScreenMediaType }
  >(undefined)

  const value = screen.mediaType

  const transitionMediaTypeTo = async (chosenType: ScreenMediaType) => {
    if (!screen) return

    if (value === ScreenMediaType.SCREEN_WITH_NO_MEDIA) {
      changeScreenMediaType(chosenType)
    } else {
      const hasMedia = await checker.checkHasScreenMedia(screen!!.id)
      hasMedia ? setConfirm({ chosenType }) : changeScreenMediaType(chosenType)
    }
  }

  if (!screen) return null

  const mediaTypeOptions = [
    {
      label: t("mediaEditor.options.noMedia"),
      value: ScreenMediaType.SCREEN_WITH_NO_MEDIA,
    },
    {
      label: "Page with image",
      value: ScreenMediaType.SCREEN_WITH_IMAGE,
    },
    {
      label: "Page with video",
      value: ScreenMediaType.SCREEN_WITH_VIDEO,
    },
  ]

  return (
    <>
      <div data-test-id="media-editor">
        <div>
          <Label>{t("mediaEditor.mediaTypeLabel")}</Label>
        </div>
        <SingleSelect
          onSelect={(value) => transitionMediaTypeTo(value)}
          options={mediaTypeOptions}
          value={value}
        />
      </div>

      <MediaEditor
        advisorId={advisorId}
        mediaId={screen.id}
        authToken={token as string}
        apiUrl={apiUrl}
        mediaType={value}
        answers={[]}
      />

      {confirm && (
        <Modal onClose={() => setConfirm(undefined)}>
          <ModalContent>
            <h2>Are you sure you want to change the media type?</h2>
            <p>
              Changing the media type will remove the current media for this
              screen.
            </p>

            <ModalButtons>
              <Button onClick={() => setConfirm(undefined)}>
                {t("cancel")}
              </Button>
              <Button
                primary
                onClick={() => {
                  changeScreenMediaType(confirm.chosenType)
                  setConfirm(undefined)
                }}
              >
                {t("confirm")}
              </Button>
            </ModalButtons>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default ScreenMediaEditor
