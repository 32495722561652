export const linkWithParam = (
  path: string,
  search: string,
  param: string,
  paramValue: string | null | undefined
): string => {
  const urlParams = new URLSearchParams(search)
  if (
    (param === "page" && paramValue === "1") ||
    paramValue === null ||
    paramValue === undefined
  ) {
    urlParams.delete(param)
  } else {
    urlParams.set(param, paramValue)
  }

  return path + "?" + urlParams.toString()
}

export const validUrl = (str: string): boolean => {
  try {
    return (
      new URL(str).protocol === "https:" || new URL(str).protocol === "http:"
    )
  } catch (_) {
    return false
  }
}
