class ImgWithFallbackElement extends HTMLElement {
  static get observedAttributes() {
    // Which attributes call attributeChangedCallback
    return ["src", "alt", "fallback"]
  }

  connectedCallback() {
    const elem = this

    const img = document.createElement("img")
    img.src = elem.getAttribute("src") || ""
    img.alt = elem.getAttribute("alt") || ""
    img.onerror = () => {
      img.src = elem.getAttribute("fallback") || ""
    }
    elem.appendChild(img)
  }

  // Update content if placement attribute changed
  attributeChangedCallback(_name, _oldValue, newValue) {
    if (!this.isConnected) return
    const img = this.querySelector("img")
    if (!img) return

    if (_name === "src") {
      img?.setAttribute("src", newValue)
    }
    if (_name === "alt") {
      img?.setAttribute("alt", newValue)
    }
  }
}

const define = () =>
  customElements.define("img-with-fallback", ImgWithFallbackElement)

export { define }
