import React from "react"
import NavBar from "./NavBar"
import UserProfile from "./UserProfile"
import { Link } from "react-router-dom"
export default () => (
  <>
    <NavBar
      left={<Link to="/advisors">Overview</Link>}
      right={<UserProfile />}
    />

    <p>Not Found...</p>
  </>
)
