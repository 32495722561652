import HelpIcon from "~/components/icons/HelpIcon"
import UserProfile from "~/components/UserProfile"
import useNavigation from "~/hooks/useNavigation"
import React from "react"
import { Link, NavLink } from "react-router-dom"
import styled, { css } from "styled-components"

export const MenuLink: React.FC<{
  link: string | { pathname: string }
  label: React.ReactNode
  useLink?: boolean
  exact?: boolean
  target?: string
  rel?: string
  disabled?: boolean
  icon?: React.ReactNode
  extra?: React.ReactNode
  className?: string
}> = ({
  link,
  className = "",
  disabled,
  label,
  icon,
  useLink,
  target,
  rel,
  exact,
  extra,
}) => {
  const L = useLink ? Link : NavLink
  return (
    <L
      to={link}
      className={`menu-link ${className} ${disabled ? "is-disabled" : ""}`}
      rel={rel}
      target={target}
      exact={exact}
    >
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
      {extra}
    </L>
  )
}

export const MenuSection = (props: any) => (
  <div className="menu-section">{props.children}</div>
)

export const Split = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
`

const UserProfileLink = styled.div<{ open: boolean }>`
  margin: 1rem ${(p) => (p.open ? "0rem" : ".375rem")};
  ${(p) =>
    p.open
      ? css`
          margin: 1rem 0;

          > div {
            padding-left: 0.625rem;
          }
        `
      : css`
          margin: 1rem 0.375rem;
        `};
`


export const Divider = () => <div className="menu-divider" />

const Default: React.FC<{ open: boolean }> = (props) => {
  const { map } = useNavigation()
  const { open } = props

  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (ref?.current) {
      let current = ref.current
      const addClass = (e) => {
        if (e.target === ref.current) {
          ref.current?.classList.add("no-pointer-events")
        }
      }
      const removeClass = (e) => {
        if (e.target === ref.current) {
          ref.current?.classList.remove("no-pointer-events")
        }
      }

      current.addEventListener("transitionstart", addClass)
      current.addEventListener("transitionend", removeClass)

      return () => {
        if (current) {
          current?.removeEventListener("transitionstart", addClass)
          current?.removeEventListener("transitionend", removeClass)
        }
      }
    }
  }, [ref])

  return (
    <Split ref={ref}>
      <div className="nav">
        <MenuLink
          link={map.advisorOverview()}
          label={"My Apps"}
          icon={home}
          exact
        />
        {props.children && (
          <>
            <Divider />
            {props.children}
          </>
        )}
      </div>

      <div className="nav">
        <MenuLink
          link={{ pathname: "https://aiden.cx/support" }}
          target="_blank"
          rel="noopener norefferer"
          label={"Help & Support"}
          icon={<HelpIcon />}
        />
        <UserProfileLink open={open}>
          <UserProfile hideName={!open} />
        </UserProfileLink>
      </div>
    </Split>
  )
}

const home = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
    />
  </svg>
)

export const Navigation = {
  Default: Default,
}
