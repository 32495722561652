import { action } from "typesafe-actions"
import {
  ADD_QUESTION,
  AUTO_DRILLDOWNCONFIG_CHANGED,
  CHANGE_NEXT_QUESTION,
  CHANGE_NUMERIC_QUESTION_CONFIG,
  CHANGE_QUESTION,
  CHANGE_QUESTION_COLOR,
  CHANGE_QUESTION_HELP_TEXT,
  CHANGE_QUESTION_LABEL,
  CHANGE_QUESTION_MEDIA_TYPE,
  CHANGE_QUESTION_TYPE,
  DRILLDOWNCONFIG_CHANGED,
  MOVE_QUESTION,
  REMOVE_QUESTION,
  REORDER_QUESTION,
  TOGGLE_MATCHING_FILTER,
} from "."
import { UUID }from "~/store/types"
import {
  AutoDrilldownConfig,
  DrilldownConfig,
  NumericalQuestionConfig,
  Question,
  QuestionColor,
  QuestionMediaType,
  QuestionType,
}from "~/store/questions/types"
import { v4 as uuid } from "uuid"
import { AdvisorMeta }from "~/actions/advisors"
import { FlowException }from "~/store/rules"

/**
 * Create a new Question and add one answer option to it.
 */
export const changeQuestion = (
  questionId: UUID,
  title: string,
  meta: AdvisorMeta
) => action(CHANGE_QUESTION, { id: questionId, title, ...meta })

export const changeMediaType = (
  questionId: UUID,
  mediaType: QuestionMediaType,
  meta: AdvisorMeta
) => action(CHANGE_QUESTION_MEDIA_TYPE, { id: questionId, mediaType, ...meta })

export const changeQuestionLabel = (
  questionId: UUID,
  label: string,
  meta: AdvisorMeta
) => action(CHANGE_QUESTION_LABEL, { id: questionId, label, ...meta })

export const changeQuestionColor = (
  questionId: UUID,
  color: QuestionColor,
  meta: AdvisorMeta
) => action(CHANGE_QUESTION_COLOR, { id: questionId, color, ...meta })

/**
 * Change the next Question to which should be routed to if none of the Rules match.
 */
export const changeNextQuestion = (
  questionId: UUID,
  nextQuestionId: UUID,
  meta: AdvisorMeta
) => action(CHANGE_NEXT_QUESTION, { id: questionId, nextQuestionId, ...meta })

/**
 * Change the type of a Question.
 */
export const changeQuestionType = (
  questionId: UUID,
  type: QuestionType,
  meta: AdvisorMeta
) => action(CHANGE_QUESTION_TYPE, { id: questionId, type, ...meta })

/**
 * Update the help text of a Question.
 */
export const changeQuestionHelpText = (
  questionId: UUID,
  text: string,
  meta: AdvisorMeta
) => action(CHANGE_QUESTION_HELP_TEXT, { id: questionId, text, ...meta })

/**
 * Add a new Question.
 */
export const addQuestion = (
  meta: AdvisorMeta,
  question?: Partial<Question>,
  previousQuestion?: UUID
) =>
  action(ADD_QUESTION, {
    question: {
      title: question?.title?.trim(),
      type: question?.type || QuestionType.Single,
      answers: question?.answers || [],
      id: question?.id || uuid(),
      next: question?.next || "ADVICE",
    },
    previousQuestion,
    ...meta,
  })

/**
 * Move a Question.
 */
export const moveQuestion = (
  meta: AdvisorMeta,
  question: Question,
  previousQuestion?: UUID
) =>
  action(MOVE_QUESTION, {
    question,
    previousQuestion,
    ...meta,
  })

/**
 * Remove a Question.
 */
export const removeQuestion = (questionId: UUID, meta: AdvisorMeta) =>
  action(REMOVE_QUESTION, { id: questionId, ...meta })

/**
 * Reorder the list of Questions.
 */
export const reorderQuestion = (
  advisorId: string,
  oldPosition: number,
  newPosition: number
) => action(REORDER_QUESTION, { advisorId, oldPosition, newPosition })

/**
 * Update the config of a numeric Question.
 */
export const changeNumericQuestionConfig = (
  questionId: UUID,
  config: NumericalQuestionConfig,
  meta: AdvisorMeta
) => action(CHANGE_NUMERIC_QUESTION_CONFIG, { id: questionId, config, ...meta })

/**
 * Toggle if bad matches should be filtered out of results for a questions
 */
export const toggleMatchingFilter = (questionId: UUID, meta: AdvisorMeta) =>
  action(TOGGLE_MATCHING_FILTER, { questionId: questionId, ...meta })

export const drilldownConfigChanged = (
  questionId: UUID,
  config: DrilldownConfig,
  meta: AdvisorMeta
) => action(DRILLDOWNCONFIG_CHANGED, { questionId, config, ...meta })

export const autoDrilldownConfigChanged = (
  questionId: UUID,
  config: AutoDrilldownConfig,
  meta: AdvisorMeta
) => action(AUTO_DRILLDOWNCONFIG_CHANGED, { questionId, config, ...meta })
