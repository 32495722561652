import styled from "styled-components"

const Icon = styled.div<{ ok?: boolean }>`
  display: inline-block;
  width: 1rem;
  height: 1.5rem;
  color: ${p => (p.ok ? p.theme.success : "#FF7466")};
  margin-right: 0.25rem;
`
export default Icon
