import React from "react"

type RedirectToState = {
  redirectTo: string
  clearRedirectTo: () => void
}

const RedirectToContext = React.createContext({
  redirectTo: "",
  clearRedirectTo: () => {},
} as RedirectToState)


const RedirectToContextProvider = (props) => {
  const requestedLocation = props.requestedLocation
  let redirectTo = (requestedLocation === "/" || requestedLocation.startsWith("/login"))
    ? "" : requestedLocation
  const clearRedirectTo = () => {
    redirectTo = ""
  }

  return (
    <RedirectToContext.Provider value={{ redirectTo, clearRedirectTo }} {...props}>
      {props.children}
    </RedirectToContext.Provider>
  )
}

const useRedirectTo = () => React.useContext<RedirectToState>(RedirectToContext)
export { RedirectToContextProvider, useRedirectTo }
