import { getQuestion, getQuestionsWithRules }from "~/store/questions"
import { useSelector, useDispatch } from "react-redux"
import { ApplicationState }from "~/store"
import { Rule }from "~/store/rules"
import { UUID }from "~/store/types"
import {
  changeRule,
  removeRule as removeRuleAction,
  addRule as addRuleAction,
}from "~/actions/rules"

export default (advisorId: UUID, questionId: UUID) => {
  const dispatch = useDispatch()

  const question = useSelector((state: ApplicationState) =>
    getQuestion(state, questionId)
  )
  const rules = useSelector((state: ApplicationState) =>
    question
      ? getQuestionsWithRules(state, advisorId).find((q) => q.id === questionId)
          ?.rules || []
      : []
  )

  const updateRule = (rule: Rule, targetQuestionId?: UUID) => {
    question && dispatch(changeRule(rule, { advisorId }, targetQuestionId))
  }

  const questionWithRules = useSelector((state: ApplicationState) =>
    getQuestionsWithRules(state, advisorId).find((q) => q.id === questionId)
  )

  const addRule = (rule: Rule, targetQuestionId?: UUID) => {
    question &&
      dispatch(
        addRuleAction(
          questionId,
          rule,
          { advisorId },
          targetQuestionId,
          rule.id
        )
      )
  }

  const removeRule = (ruleId: UUID) => {
    question && dispatch(removeRuleAction(questionId, ruleId, { advisorId }))
  }

  return {
    rules,
    updateRule,
    addRule,
    removeRule,
    question,
    questionWithRules,
  }
}
