export type RadioGroupProps = {
  currentValue: string
  inline?: boolean
  options: { value: string; label: string }[]
  onChange: (value: string) => void
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  currentValue,
  inline = false,
  options,
  onChange,
}) => {
  return (
    <div className={`radio-group ${inline ? "radio-group--inline" : ""}`}>
      {options.map((opt) => (
        <div key={opt.value} className="radio-group--option">
          <label htmlFor={opt.value} className="radio">
            <input
              type="radio"
              id={opt.value}
              name="opt.valueition"
              value={opt.value}
              onChange={() => onChange(opt.value)}
              checked={opt.value === currentValue}
            />
            <span></span>
            <span>{opt.label}</span>
          </label>
        </div>
      ))}
    </div>
  )
}

export default RadioGroup
