import {
  AutoDrilldownConfig,
  DrilldownConfig,
  NumericalInputType,
  NumericalQuestionConfig,
  Position,
  Question,
  QuestionMediaType,
  QuestionType,
} from "./types"

function changeQuestionToType(
  question: Question,
  newType: QuestionType
): Question {
  if (question.type === newType) {
    return question
  } else if (newType === QuestionType.Numeric) {
    return changeToNumeric(question)
  } else if (newType === QuestionType.Drilldown) {
    return changeToDrilldown(question)
  } else if (newType === QuestionType.AutoDrilldown) {
    return changeToAutoDrilldown(question)
  } else {
    return {
      ...question,
      type: newType,
      config: { filter: question.config.filter },
    }
  }
}

function changeToNumeric(question: Question): Question {
  return {
    ...question,
    answers: [],
    rules: [],
    type: QuestionType.Numeric,
    mediaType:
      question.mediaType === QuestionMediaType.ANSWERS_WITH_IMAGE
        ? QuestionMediaType.NO_MEDIA
        : question.mediaType,
    config: { ...defaultNumericConfig, filter: question.config.filter },
  }
}

function changeToDrilldown(question: Question): Question {
  return {
    ...question,
    answers: [],
    rules: [],
    type: QuestionType.Drilldown,
    mediaType:
      question.mediaType === QuestionMediaType.ANSWERS_WITH_IMAGE
        ? QuestionMediaType.NO_MEDIA
        : question.mediaType,
    config: { ...defaultDrilldownConfig, filter: question.config.filter },
  }
}

function changeToAutoDrilldown(question: Question): Question {
  return {
    ...question,
    answers: [],
    rules: [],
    type: QuestionType.AutoDrilldown,
    mediaType:
      question.mediaType === QuestionMediaType.ANSWERS_WITH_IMAGE
        ? QuestionMediaType.NO_MEDIA
        : question.mediaType,
    config: {
      ...defaultAutoDrilldownConfig,
      filter: question.config.filter,
    },
  }
}

const defaultNumericConfig: NumericalQuestionConfig = {
  inputType: NumericalInputType.INPUT,
  info: "",
  placeholder: "",
  filter: false,
  optional: true,
  allowDecimals: false,
  matchingFeatureLabel: "",
  inputInfo: { label: "", position: Position.PREFIX },
}
const defaultDrilldownConfig: DrilldownConfig = {
  filter: false,
  matchPath: false,
  levels: 1,
  levelConfiguration: [{ label: "" }],
  answers: [],
}
const defaultAutoDrilldownConfig: AutoDrilldownConfig = {
  filter: false,
  levels: 1,
  levelConfiguration: [{ label: "", sourceField: "" }],
}

export default {
  changeQuestionToType,
  defaultNumericConfig,
  defaultDrilldownConfig,
  defaultAutoDrilldownConfig,
}
