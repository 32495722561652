import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Button from "~/components/Button"
import useAdvisors from "~/hooks/useAdvisors"
import { useSelector } from "react-redux"
import { ApplicationState } from "~/store"
import { requestStatus } from "~/store/requestStatus/selectors"
import { Status } from "~/store/requestStatus/reducer"
import ErrorMessage from "~/components/ErrorMessage"
import LoadingSpinner from "~/components/Loading"

import Input from "~/components/forms/Input"
import { SingleSelect } from "~/components/selection"
import { availableLocales, defaultAdvisorLocale } from "~/i18n"
import { InfoIcon } from "../../icons/Info"
import Tooltip from "../../Tooltip"
import Page from "../Page"
import Card from "~/components/Card"

import EmojiPicker from "~/elm-tsx-wrappers/EmojiPicker"
import ElmNewAdvisor from "~/elm-tsx-wrappers/ElmNewAdvisor"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"

const Title = styled.h1`
  font-weight: 400;
  font-size: 34px;
  color: #000c21;
  margin-bottom: 32px;
`

const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  color: #000c21;
  margin-bottom: 32px;
`
const Form = styled.form`
  /* max-width: 32rem; */
  z-index: 1;
  position: relative;
`

const InputWrapper = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
`
const EmojiWrapper = styled.div`
  .help-text {
    color: #000c21;
    margin-bottom: 2rem;
  }
`

const Hint = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #000c21;
  font-style: italic;
`

const InputLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #000c21;
  margin-bottom: 8px;
`

const Save = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export default () => {
  const { t } = useTranslation()
  const [name, setName] = useState("")
  const [productLabel, setProductLabel] = useState("")
  const [icon, setIcon] = useState(":tv:")
  const [locales, setLocales] = useState<string[]>([defaultAdvisorLocale])

  const [requestId, setRequestId] = useState("")
  const status = useSelector((state: ApplicationState) =>
    requestStatus(state, requestId)
  )
  const { createAdvisor } = useAdvisors()
  const valid = name.length > 0 && productLabel.length > 0 && icon.length >= 0
  const disabled = !valid
  const saving: boolean = status?.type === Status.PENDING

  const { featureFlags } = useFeatureFlags()

  const statusMessage =
    status?.type === Status.FAILED ? (
      <ErrorMessage>
        {status?.message
          ? t(`advisorCreate.failed.${status?.message}`)
          : t("advisorCreate.failed.error")}
      </ErrorMessage>
    ) : null

  useEffect(() => {
    setRequestId("")
  }, [name])

  return (
    <>
      {featureFlags.newCreateAppFlowEnabled ? (
        <ElmNewAdvisor />
      ) : (
        <Page>
          <Card style={{ maxWidth: "40rem", margin: "0 auto 6rem" }}>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                if (!disabled) {
                  setRequestId(createAdvisor(name, productLabel, icon, locales))
                }
              }}
            >
              <Title>{t("advisorCreate.title")}</Title>
              <Subtitle>{t("advisorCreate.subtitle")}</Subtitle>
              <InputWrapper>
                <InputLabel>{t("advisorCreate.name.label")}</InputLabel>
                <Input
                  data-test-id="create-advisor-name"
                  placeholder={t("advisorCreate.name.placeholder")}
                  value={name}
                  hasError={status ? status.type === Status.FAILED : false}
                  onChange={(e) => setName(e.target.value)}
                />
                <Hint>{t("advisorCreate.name.hint")}</Hint>
              </InputWrapper>
              <InputWrapper>
                <InputLabel>{t("advisorCreate.product.label")}</InputLabel>
                <Input
                  data-test-id="create-advisor-product"
                  placeholder={t("advisorCreate.product.placeholder")}
                  value={productLabel}
                  hasError={status ? status.type === Status.FAILED : false}
                  onChange={(e) => setProductLabel(e.target.value)}
                />
                <Hint>{t("advisorCreate.product.hint")}</Hint>
              </InputWrapper>
              <EmojiWrapper>
                <EmojiPicker icon={icon} setIcon={setIcon} />
              </EmojiWrapper>
              <InputWrapper>
                <InputLabel>
                  {t("advisorSettings.advisor.locale.label")}
                  <Tooltip
                    content={
                      <span>{t("advisorSettings.advisor.locale.info")}</span>
                    }
                    position="top left"
                  >
                    <InfoIcon />
                  </Tooltip>
                </InputLabel>
                <SingleSelect
                  onSelect={(value) => setLocales([value])}
                  value={locales[0] || defaultAdvisorLocale}
                  options={availableLocales
                    .map((value) => ({
                      label: t("locales." + value),
                      value: value,
                    }))
                    .sort((a, b) => {
                      if (a.label.toLowerCase() < b.label.toLowerCase()) {
                        return -1
                      }
                      if (a.label.toLowerCase() > b.label.toLowerCase()) {
                        return 1
                      }
                      return 0
                    })}
                />
              </InputWrapper>
              <Save>
                <Button
                  disabled={disabled || saving}
                  isLoading={saving}
                  round={false}
                  primary
                >
                  {saving ? (
                    <LoadingSpinner size="small" />
                  ) : (
                    t("advisorCreate.save")
                  )}
                </Button>
                {statusMessage}
              </Save>
            </Form>
          </Card>
        </Page>
      )}
    </>
  )
}
