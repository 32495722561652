import { Advisor } from "."
import { ApplicationState } from ".."

export const getAdvisor = (
  state: ApplicationState,
  advisorId: string
): Advisor | undefined => state.advisors[advisorId]

export const isAdvisorLoading = (
  state: ApplicationState,
  advisorId: string
): boolean => state.advisorsLoading.loading.includes(advisorId)
