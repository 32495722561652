import { UUID }from "~/store/types"
import { action } from "typesafe-actions"
import { START_REQUEST, REQUEST_SUCCESS, REQUEST_FAILURE } from "./constants"
import { ApiError } from "~/api/APIBase"

export const startRequest = (requestId: UUID) =>
  action(START_REQUEST, { requestId })

export const requestSuccess = (requestId: UUID) =>
  action(REQUEST_SUCCESS, { requestId })

export const requestFailure = (requestId: UUID, resp?: ApiError) =>
  action(REQUEST_FAILURE, { requestId, resp })
