import Section from "~/components/Section"
import React from "react"
import { Advisor }from "~/store/advisors"
import Catalogue from "./Catalogue"

const Products: React.FC<{ advisor: Advisor }> = () => {
  return (
    <Section data-test-id="products-section" >
      <Catalogue />
    </Section>
  )
}

export default Products
