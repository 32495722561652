export const UPDATE_INTRO_SCREEN_DATA = "@@screens/UPDATE_INTRO_SCREEN_DATA"
export const CHANGE_NEXT = "@@screens/CHANGE_NEXT"
export const UPDATE_ADVICE_SCREEN_DATA = "@@screens/UPDATE_ADVICE_SCREEN_DATA"
export const CHANGE_SCREEN_MEDIA_TYPE = "@@screens/CHANGE_SCREEN_MEDIA_TYPE"
export const CHANGE_SCREEN_TITLE = "@@screens/CHANGE_SCREEN_TITLE"
export const CHANGE_BUTTON_TEXT = "@@screens/CHANGE_BUTTON_TEXT"
export const CHANGE_SCREEN_LABEL = "@@screens/CHANGE_SCREEN_LABEL"
export const CHANGE_SCREEN_COLOR = "@@screens/CHANGE_SCREEN_COLOR"
export const CHANGE_SCREEN_TEXT = "@@screens/CHANGE_SCREEN_TEXT"
export const CHANGE_SCREEN_INFO = "@@screens/CHANGE_SCREEN_INFO"
export const CHANGE_SCREEN_NEXT = "@@screens/CHANGE_SCREEN_NEXT"
export const CHANGE_SCREEN_CUSTOMER_INFO = "@@screens/CHANGE_SCREEN_CUSTOMER_INFO"
export const REMOVE_INFO_SCREEN = "@@screens/REMOVE_INFO_SCREEN"
