import { action } from "typesafe-actions"
import { CHANGE_ANSWER, ADD_ANSWER, REMOVE_ANSWER, REORDER_ANSWER } from "."
import { UUID } from "../../store/types"
import { AdvisorMeta }from "~/actions/advisors"

/**
 * Update the Answer's editable fields
 */
export const changeAnswer = (
  answerId: string,
  title: string,
  helpText: string,
  customLabel: string,
  meta: AdvisorMeta
) =>
  action(CHANGE_ANSWER, { id: answerId, title, helpText, customLabel, ...meta })

/**
 * Add an Answer option to a question
 */
export const addAnswer = (
  questionId: string,
  answerId: string,
  title: string,
  helpText: string,
  customLabel: string,
  isNeutralAnswer: boolean,
  meta: AdvisorMeta
) =>
  action(ADD_ANSWER, {
    questionId,
    id: answerId,
    title,
    helpText,
    customLabel,
    isNeutralAnswer,
    ...meta,
  })

/**
 * Remove an Answer option to a question
 */
export const removeAnswer = (
  questionId: string,
  answerId: UUID,
  meta: AdvisorMeta
) => action(REMOVE_ANSWER, { questionId, id: answerId, ...meta })

/**
 * Update the order of an Answer option
 */
export const reorderAnswer = (
  questionId: string,
  oldPosition: number,
  newPosition: number,
  meta: AdvisorMeta
) => action(REORDER_ANSWER, { questionId, oldPosition, newPosition, ...meta })
