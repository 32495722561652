import React from "react"

type CellContent = React.ReactNode | string

const Table: React.FC<{ headers?: string[]; data?: CellContent[][] }> = ({
  headers = [],
  data = [],
}) => {
  return (
    <div className="table-wrapper">
      <div className="table-wrapper__inner">
        <table>
          {headers.length > 0 && (
            <thead>
              <tr>
                {headers.map((d, i) => (
                  <th key={i}>{d}</th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((d, i) => {
              return (
                <tr key={i}>
                  {d.map((di, i) => (
                    <td key={i}>
                      { typeof di === "string" && di.length > 30
                        ? <div>{di}</div>
                        : di
                      }
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
