import React from "react"
import FormSection from "~/components/forms/FormSection"
import Field from "~/components/forms/Field"
import { useTranslation } from "react-i18next"
import Label from "~/components/forms/Label"
import AvatarSelector from "~/components/AvatarSelector"

type UserInfoProps = {
  firstName: string
  familyName: string
  imagePreviewUrl?: string
  isCropping: boolean
  onSelectImage: (e: any) => void
  setFirstName: (val: string) => void
  setFamilyName: (val: string) => void
  setIsCropping: (isCropping: boolean) => void
  setCrop: (crop: {
    x: number
    y: number
    width: number
    height: number
  }) => void
}

const UserInfo: React.FC<UserInfoProps> = ({
  firstName,
  familyName,
  isCropping,
  onSelectImage,
  setFirstName,
  setFamilyName,
  setIsCropping,
  setCrop,
  imagePreviewUrl,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <FormSection>
        <Field
          label={t("invitation.userInfo.firstName")}
          placeholder={t("invitation.userInfo.firstNamePlaceholder")}
          value={firstName}
          onChange={setFirstName}
        />
        <Field
          label={t("invitation.userInfo.familyName")}
          placeholder={t("invitation.userInfo.familyNamePlaceholder")}
          value={familyName}
          onChange={setFamilyName}
        />
      </FormSection>
      <FormSection>
        <Label>Add a photo (optional)</Label>
        <AvatarSelector
          name={firstName}
          isCropping={isCropping}
          onSelectImage={onSelectImage}
          setIsCropping={(isCropping) => setIsCropping(isCropping)}
          setCrop={(crop) =>
            setCrop({
              x: crop.x || 0,
              y: crop.y || 0,
              width: crop.width || 0,
              height: crop.height || 0,
            })
          }
          image={imagePreviewUrl}
        />
      </FormSection>
    </>
  )
}
export default UserInfo
