import { advisorLatestRelease }from "~/actions/advisors"
import { advisors } from "~/api"
import Tooltip from "~/components/Tooltip"
import { useAuth } from "~/context/auth"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState }from "~/store"

const getLatestRelease = (dispatch, token, organisationId, advisorId) => {
  advisors
    .latestRelease(advisorId, token || "", organisationId)
    .then((response) => {
      if (response) {
        const lastReleased = new Date(response?.releasedAt)
        const lastEdited = new Date(response?.lastEdited)
        dispatch(advisorLatestRelease(advisorId, lastEdited, lastReleased))
      }
    })
    .catch((e) => {})
}

const CheckForChanges: React.FC<{
  advisorId: string
  unpublished: boolean
  render: (hasChanges: boolean) => React.ReactNode
}> = (props) => {
  const [lastUpdated, lastReleased] = useSelector((state: ApplicationState) => {
    return [
      state.unpublishedChanges[props.advisorId]?.lastUpdated,
      state.unpublishedChanges[props.advisorId]?.lastReleased,
    ]
  })
  const hasChanges = lastUpdated && lastReleased && lastUpdated > lastReleased

  const { token, organisationId } = useAuth()
  const dispatch = useDispatch()
  // const previousLastUpdated: Date | undefined = useMemo(
  //   () => lastUpdated,
  //   [lastUpdated]
  // )

  useEffect(() => {
    if (token)
      getLatestRelease(dispatch, token, organisationId, props.advisorId)
  }, [token, dispatch, props.advisorId, organisationId])

  useEffect(() => {
    const check = () => {
      if (hasChanges || !lastUpdated || props.unpublished) return
      getLatestRelease(dispatch, token, organisationId, props.advisorId)
    }
    const interval = setInterval(check, 5000)

    return () => clearInterval(interval)
  }, [
    dispatch,
    hasChanges,
    lastUpdated,
    props.advisorId,
    props.unpublished,
    token,
  ])

  return hasChanges ? (
    <Tooltip
      content={
        "Your live app is outdated. Publish your changes to bring it up-to-date"
      }
    >
      {props.render(true)}
    </Tooltip>
  ) : (
    <>{props.render(false)}</>
  )
}

export default CheckForChanges
