import React, { useState } from "react"
import { UUID } from "~/store/types"
import { useDispatch } from "react-redux"
import { Trans, useTranslation } from "react-i18next"
import {
  NumericalInputType,
  NumericalQuestionConfig,
  Position,
  Question,
  QuestionType,
} from "~/store/questions"
import useNavigation from "~/hooks/useNavigation"
import { Link } from "react-router-dom"
import { TextareaAutogrow } from "~/components/forms/Textarea"
import SidebarExtraPanel from "../SidebarExtraPanel"
import HelpText from "~/components/HelpText"
import { changeNumericQuestionConfig } from "~/actions/questions"
import ToggleSwitch from "~/components/ToggleSwitch"
import Input from "~/components/forms/Input"
import RadioGroup from "~/components/RadioGroup"
import { capitalize } from "~/lib/String"

const NumericAnswerEditor: React.FC<{
  advisorId: UUID
  question: Question
}> = (props) => {
  const { advisorId, question } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { map } = useNavigation()

  const [placeholderOpen, setPlaceholderOpen] = useState<boolean>(
    ("placeholder" in question.config && !!question.config.placeholder) || false
  )

  const [inputInfoOpen, setInputInfoOpen] = useState<boolean>(
    ("inputInfo" in question.config && !!question.config.inputInfo.label) ||
      false
  )

  const [matchingFeatureLabelOpen, setMatchingFeatureLabelOpen] =
    useState<boolean>(
      ("matchingFeatureLabel" in question.config &&
        !!question.config.matchingFeatureLabel) ||
        false
    )

  if (question.type !== QuestionType.Numeric) return null
  const config = question.config

  const updateConfig = (key: keyof NumericalQuestionConfig, value: any) =>
    dispatch(
      changeNumericQuestionConfig(
        question.id,
        { ...config, [key]: value },
        { advisorId }
      )
    )

  return (
    <>
      <p>
        <Trans
          i18nKey={"numericAnswerEditor.intro"}
          values={{
            link: t("advisor.navigation.advice.title"),
          }}
          components={[
            <Link to={map.advice(advisorId)} className="link">
              Label
            </Link>,
          ]}
        />
      </p>

      <div style={{ marginBottom: "1rem" }}>
        <ToggleSwitch
          toggledOn={question.config.allowDecimals}
          label={<strong>{t("numericAnswerEditor.allowDecimalLabel")}</strong>}
          onChange={(value) => updateConfig("allowDecimals", value)}
        />
      </div>

      <div style={{ marginBottom: "1rem" }}>
        <ToggleSwitch
          toggledOn={question.config.optional}
          label={<strong>{t("numericAnswerEditor.optionalLabel")}</strong>}
          onChange={(value) => updateConfig("optional", value)}
        />
      </div>

      {config.inputType === NumericalInputType.INPUT && (
        <SidebarExtraPanel
          label={t("numericAnswerEditor.placeholderLabel")}
          open={placeholderOpen}
          onOpen={() => setPlaceholderOpen(true)}
          onDelete={() => {
            updateConfig("placeholder", "")
            setPlaceholderOpen(false)
          }}
        >
          <>
            <TextareaAutogrow
              value={config.placeholder}
              onChange={(e: any) => updateConfig("placeholder", e.target.value)}
            />
            <HelpText>{t("numericAnswerEditor.placeholderHelpText")}</HelpText>
          </>
        </SidebarExtraPanel>
      )}

      <SidebarExtraPanel
        label={t("numericAnswerEditor.infoInputTextLabel")}
        open={inputInfoOpen}
        onOpen={() => setInputInfoOpen(true)}
        onDelete={() => {
          updateConfig("inputInfo", {
            label: "",
            position: config.inputInfo.position,
          })
          setInputInfoOpen(false)
        }}
      >
        <>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Input
              placeholder={t("numericAnswerEditor.infoInputTextPlaceholder")}
              onChange={(e) => {
                const value = e.target.value.slice(0, 3)
                updateConfig("inputInfo", {
                  ...config.inputInfo,
                  label: value,
                })
              }}
              value={config.inputInfo.label}
            />
            <RadioGroup
              options={Object.keys(Position).map((pos) => ({
                label: capitalize(pos.toLowerCase()),
                value: pos,
              }))}
              currentValue={config.inputInfo.position.toString()}
              onChange={(position) =>
                updateConfig("inputInfo", {
                  ...config.inputInfo,
                  position: position,
                })
              }
              inline={true}
            />
          </div>
          <div>
            <HelpText>
              {t("numericAnswerEditor.infoInputTextHelpText")}
            </HelpText>
          </div>
        </>
      </SidebarExtraPanel>

      <SidebarExtraPanel
        label={t("numericAnswerEditor.matchingFeature.label")}
        open={matchingFeatureLabelOpen}
        onOpen={() => setMatchingFeatureLabelOpen(true)}
        onDelete={() => {
          updateConfig("matchingFeatureLabel", "")
          setMatchingFeatureLabelOpen(false)
        }}
      >
        <Input
          fullWidth={true}
          placeholder={question.label}
          onChange={(e) => {
            updateConfig("matchingFeatureLabel", e.target.value)
          }}
          value={config.matchingFeatureLabel}
        />
        <HelpText>{t("numericAnswerEditor.matchingFeature.helpText")}</HelpText>
      </SidebarExtraPanel>
    </>
  )
}

export default NumericAnswerEditor
