import {
  DISMISS_NOTIFICATION,
  DISPLAY_NOTIFICATION,
  NotificationActionTypes,
}from "~/actions/notifications"
import { NotificationT } from "~/types"

type NotificationState = Record<string, NotificationT>

 const reducer = (
  state: NotificationState = {},
  action: NotificationActionTypes
): NotificationState => {
  switch (action.type) {
    case DISPLAY_NOTIFICATION: {
      return { [action.payload.id]: action.payload, ...state }
    }
    case DISMISS_NOTIFICATION: {
      delete state[action.payload.id]
      return { ...state }
    }
    default:
      return state
  }
}

export default reducer
