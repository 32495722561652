export default [
  "ABeeZee",
  "Abel",
  "Abril Fatface",
  "Acme",
  "Architects Daughter",
  "Arimo",
  "Arvo",
  "Asap",
  "Anek Latin",
  "Assistant",
  "Balsamiq Sans",
  "Barlow",
  "Barlow Condensed",
  "Bebas Neue",
  "Bitter",
  "Cabin",
  "Cairo",
  "Caveat",
  "Comfortaa",
  "Cormorant Garamond",
  "Crimson Text",
  "DM Sans",
  "Dosis",
  "EB Garamond",
  "Exo 2",
  "Fira Sans",
  "Figtree",
  "Fira Sans Condensed",
  "Fjalla One",
  "Heebo",
  "Hind",
  "Hind Siliguri",
  "IBM Plex Sans",
  "IBM Plex Serif",
  "Inconsolata",
  "Inter",
  "Inter Tight",
  "Indie Flower",
  "Inter",
  "Italianno",
  "Josefin Sans",
  "Kanit",
  "Karla",
  "Lato",
  "Libre Baskerville",
  "Libre Franklin",
  "Lobster",
  "Lora",
  "Manrope",
  "Maven Pro",
  "Merriweather",
  "Merriweather Sans",
  "Montserrat",
  "Mukta",
  "Mulish",
  "Nanum Gothic",
  "Noto Sans",
  "Noto Serif",
  "Nunito",
  "Nunito Sans",
  "Open Sans",
  "Oswald",
  "Overpass",
  "Oxygen",
  "Pacifico",
  "Padauk",
  "Patrick Hand",
  "Permanent Marker",
  "Playfair Display",
  "Poppins",
  "Prompt",
  "PT Sans",
  "PT Sans Narrow",
  "PT Serif",
  "Questrial",
  "Quicksand",
  "Rajdhani",
  "Raleway",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "Rubik",
  "Shadows Into Light",
  "Signika Negative",
  "Slabo 27px",
  "Source Code Pro",
  "Source Sans Pro",
  "Source Serif Pro",
  "Staatliches",
  "Teko",
  "Titillium Web",
  "Ubuntu",
  "Varela",
  "Varela Round",
  "Work Sans",
  "Yanone Kaffeesatz",
  "Zilla Slab",
]
