import React, { useEffect } from "react"
import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import useNavigation from "~/hooks/useNavigation"
import useRules from "~/hooks/useRules"
import { useDispatch, useSelector } from "react-redux"
import useQuestions from "~/hooks/useQuestions"
import { ApplicationState } from "~/store"
import { addAfter, clearChanges, Flow, proposeChange } from "~/actions/flow"
import { FlowException, Rule } from "~/store/rules"
import {
  addRule,
  changeRule,
  changeRuleTarget,
  removeRule,
} from "~/actions/rules"
import { addNewQuestion, changeNextQuestion } from "~/actions/questions"
import { useLocation } from "react-router-dom"
import qs from "qs"

const useScrollToRule = () => {
  const location = useLocation()
  const ruleIdParam = qs.parse(
    location.search.substring(1, location.search.length)
  )["ruleId"] as string
  useEffect(() => {
    setTimeout(
      () =>
        document
          ?.querySelector(`[data-rule-id='${ruleIdParam}']`)
          ?.scrollIntoView({
            behavior: "smooth",
          }),
      200
    )
  }, [ruleIdParam])
}

const FlowExceptionsEditor: React.FC<{
  advisorId: string
  questionId: string
}> = ({ advisorId, questionId }) => {
  const navigation = useNavigation()
  const { rules } = useRules(advisorId, questionId)

  const { questionsWithAnswers } = useQuestions(advisorId)
  const currentQuestionNext = useSelector(
    (state: ApplicationState) => state.flow[advisorId].nodes[questionId] || null
  )

  const infoScreens = Flow.useInfoScreens(advisorId)
  const nodes = React.useMemo(
    () => [
      ...questionsWithAnswers.map((q) => ({
        id: q.id,
        title: q.title,
        label: q.label,
        color: q.color,
      })),
      ...infoScreens.map((q) => ({
        id: q.id,
        title: q.title,
        label: q.label,
        color: q.color,
      })),
    ],
    [infoScreens, questionsWithAnswers]
  )

  const exceptions = React.useMemo(
    () =>
      rules.map((rule): FlowException => {
        return {
          id: rule.id,
          targetQuestionId: rule.targetQuestionId || null,
          predicate: rule.predicate,
        }
      }),

    [rules]
  )

  useScrollToRule()

  const dispatch = useDispatch()
  const [ports, setPorts] = React.useState<any>()

  useEffect(() => {
    if (!ports) return
    ports.nodesChangedSub?.send(nodes)
  }, [nodes, ports])

  useEffect(() => {
    if (!ports) return
    ports.exceptionsChangedSub?.send(exceptions)
  }, [exceptions, ports])

  useEffect(() => {
    if (!ports) return
    ports.defaultNextStepChangedSub?.send(currentQuestionNext)
  }, [currentQuestionNext, ports])

  const flags = {
    questions: questionsWithAnswers,
    currentQuestion: questionId,
    currentQuestionNext: currentQuestionNext,
    currentUrl: window.location.href,
    nodes: nodes,
    exceptions: exceptions,
  }

  useEffect(() => {
    return () => {
      // on component unmount clear the changes
      dispatch(clearChanges(advisorId))
    }
  }, [advisorId, dispatch])

  useEffect(() => {
    if (ports) {
      const handler = (rule: Rule) => {
        dispatch(changeRule(rule, { advisorId }))
      }
      const newHandler = (rule: Rule) => {
        dispatch(
          addRule(
            questionId,
            rule,
            { advisorId },
            rule.targetQuestionId,
            rule.id
          )
        )
      }
      const removeHandler = (id: string) => {
        dispatch(removeRule(questionId, id, { advisorId }))
      }
      const defaultNextChangedHandler = ({ next }) => {
        dispatch(changeNextQuestion(questionId, next, { advisorId }))
      }

      const addNewQuestionAsDefaultNextStepHandler = (
        newQuestionId: string
      ) => {
        dispatch(
          addNewQuestion(
            { advisorId },
            { id: newQuestionId, next: currentQuestionNext || "ADVICE" }
          )
        )
        dispatch(changeNextQuestion(questionId, newQuestionId, { advisorId }))
        navigation.toQuestionInAdvisor(advisorId, newQuestionId)
      }

      const addNewInfoPageAsDefaultNextStepHandler = (newScreenId: string) => {
        dispatch(
          addAfter(
            {
              id: newScreenId,
              type: "infoScreen",
              next: currentQuestionNext || "ADVICE",
            },
            questionId,
            { advisorId }
          )
        )
        dispatch(changeNextQuestion(questionId, newScreenId, { advisorId }))

        navigation.toScreenInAdvisor(advisorId, newScreenId)
      }

      const addNewQuestionAsNextStepHandler = ({
        flowExceptionId,
        currentNextStep,
        newQuestionId,
      }) => {
        dispatch(
          addNewQuestion(
            { advisorId },
            { id: newQuestionId, next: currentQuestionNext || "ADVICE" }
          )
        )
        dispatch(
          changeRuleTarget(flowExceptionId, newQuestionId, { advisorId })
        )
        navigation.toQuestionInAdvisor(advisorId, newQuestionId)
      }

      const addNewInfoPageAsNextStepHandler = ({
        flowExceptionId,
        currentNextStep,
        newScreenId,
      }) => {
        dispatch(
          addAfter(
            {
              id: newScreenId,
              type: "infoScreen",
              next: currentNextStep || "ADVICE",
            },
            undefined,
            { advisorId }
          )
        )
        dispatch(changeRuleTarget(flowExceptionId, newScreenId, { advisorId }))

        navigation.toScreenInAdvisor(advisorId, newScreenId)
      }

      const proposeChangeHandler = ({ fromId, toId }) => {
        dispatch(proposeChange(fromId, toId, { advisorId }))
      }
      const clearChangesHandler = () => {
        dispatch(clearChanges(advisorId))
      }

      ports?.sendFlowExceptionChanged?.subscribe(handler)
      ports?.sendNewFlowException?.subscribe(newHandler)
      ports?.removeException?.subscribe(removeHandler)
      ports?.sendDefaultNextStepChanged?.subscribe(defaultNextChangedHandler)
      ports?.sendNewQuestionAsDefaultNextStep?.subscribe(
        addNewQuestionAsDefaultNextStepHandler
      )
      ports?.sendNewInfoPageAsDefaultNextStep?.subscribe(
        addNewInfoPageAsDefaultNextStepHandler
      )
      ports?.sendNewQuestionAsNextStep?.subscribe(
        addNewQuestionAsNextStepHandler
      )
      ports?.sendNewInfoPageAsNextStep?.subscribe(
        addNewInfoPageAsNextStepHandler
      )
      ports?.sendProposeChange?.subscribe(proposeChangeHandler)
      ports?.sendClearChanges?.subscribe(clearChangesHandler)

      return () => {
        ports?.sendFlowExceptionChanged?.unsubscribe(handler)
        ports?.sendNewFlowException?.unsubscribe(newHandler)
        ports?.removeException?.unsubscribe(removeHandler)
        ports?.sendDefaultNextStepChanged?.unsubscribe(
          defaultNextChangedHandler
        )
        ports?.sendNewQuestionAsDefaultNextStep?.unsubscribe(
          addNewQuestionAsDefaultNextStepHandler
        )
        ports?.sendNewInfoPageAsDefaultNextStep?.unsubscribe(
          addNewInfoPageAsDefaultNextStepHandler
        )
        ports?.sendNewQuestionAsNextStep?.unsubscribe(
          addNewQuestionAsNextStepHandler
        )
        ports?.sendNewInfoPageAsNextStep?.unsubscribe(
          addNewInfoPageAsNextStepHandler
        )
        ports?.sendProposeChange?.unsubscribe(proposeChangeHandler)
        ports?.sendClearChanges?.unsubscribe(clearChangesHandler)
      }
    }
  }, [advisorId, currentQuestionNext, dispatch, navigation, ports, questionId])

  return (
    <>
      <Elm
        src={ElmModule.Conversation.FlowExceptions}
        flags={flags}
        ports={setPorts}
      />
    </>
  )
}

export default FlowExceptionsEditor
