import React, { useCallback, useState } from "react"
import { useAuth } from "~/context/auth"
import { advisors } from "~/api"
import LoadingSpinner from "~/components/LoadingSpinner"
import { useDispatch } from "react-redux"
import useNotifications from "~/hooks/useNotifications"
import { advisorPublished }from "~/actions/advisors"
import { AdvisorStatus }from "~/store/advisors"
import { useTranslation } from "react-i18next"
import PublishIcon from "~/components/icons/PublishIcon"
import Button from "~/components/Button"

const PublishButton: React.FC<{
  advisor: { status?: string; id: string }
  primary?: boolean
  disabled?: boolean
  onSuccess: (resp) => void
  onError?: (resp) => void
}> = ({
  advisor,
  onSuccess,
  onError = console.error,
  primary = false,
  disabled,
}) => {
  const { t } = useTranslation()
  const { token, organisationId } = useAuth()
  const [publishing, setPublishing] = useState(false)
  const live = advisor.status === AdvisorStatus.Online
  const dispatch = useDispatch()
  const { notify } = useNotifications()

  const publish = useCallback(() => {
    const successCallback = (resp) => {
      if (resp.status === "LIVE") {
        dispatch(
          advisorPublished(
            advisor.id,
            AdvisorStatus.Online,
            new Date(resp.releasedAt)
          )
        )
      } else {
        dispatch(
          advisorPublished(
            advisor.id,
            advisor.status as any,
            new Date(resp.releasedAt)
          )
        )
      }
      notify({
        text: live
          ? t("integration.feedback.updated")
          : t("integration.feedback.published"),
        type: "success",
      })
      onSuccess(resp)
    }
    if (!publishing) {
      setPublishing(true)
      advisors
        .publish(advisor.id, token!, organisationId)
        .then(successCallback)
        .catch(onError)
        .finally(() => setPublishing(false))
    }
  }, [publishing, notify, live, t, onSuccess, dispatch, advisor.id, advisor.status, token, organisationId, onError])

  const children = (
    <>
      <span className="icon">
        {publishing ? <LoadingSpinner size="small" /> : <PublishIcon />}
      </span>
      <span className="label">
        {t("integration.publish")}
        {/* {live ? t("integration.update") : t("integration.publish")} */}
      </span>
    </>
  )

  return (
    <Button hasIconLeft primary disabled={disabled} onClick={publish}>
      {children}
    </Button>
  )
}

export default PublishButton
