import {
  AdvisorActionTypes,
  FETCH_ADVISOR_SUCCESS,
  FETCH_ADVISOR_FAILURE,
  FETCH_ADVISOR,
}from "~/actions/advisors"

type LoadingState = { loading: string[] }

const defaultState = { loading: [] }

const advisorLoadingReducer = (
  state: LoadingState = defaultState,
  action: AdvisorActionTypes
): LoadingState => {
  switch (action.type) {
    case FETCH_ADVISOR: {
      return { loading: [...state.loading, action.payload.advisorId] }
    }
    case FETCH_ADVISOR_FAILURE: {
      return {
        loading: [
          ...state.loading.filter((a) => a !== action.payload.advisorId),
        ],
      }
    }
    case FETCH_ADVISOR_SUCCESS: {
      return {
        loading: [
          ...state.loading.filter((a) => a !== action.payload.advisor.id),
        ],
      }
    }

    default:
      return state
  }
}

export { advisorLoadingReducer }
