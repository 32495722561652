import { action } from "typesafe-actions"
import { CHANGE_RULE, ADD_RULE, CHANGE_RULE_TARGET, REMOVE_RULE } from "."
import { UUID }from "~/store/types"
import { Rule }from "~/store/rules"
import { v4 as uuid } from 'uuid';
import { AdvisorMeta }from "~/actions/advisors"

/**
 * Add a Rule to a Question
 */
export const addRule = (
  questionId: UUID,
  rule: Omit<Rule, "id">,
  meta: AdvisorMeta,
  targetQuestionId?: UUID,
  id: UUID = uuid()
) =>
  action(ADD_RULE, {
    questionId,
    rule: { ...rule, id, targetQuestionId: targetQuestionId },
    targetQuestionId,
    ...meta,
  })

/**
 * Remove a Rule from a Question
 */
export const removeRule = (questionId: UUID, ruleId: UUID, meta: AdvisorMeta) =>
  action(REMOVE_RULE, { ruleId, questionId, ...meta })

/**
 * Change the Rule by providing a new Rule structure.
 * Optionally accepts a target Question where to route to.
 */
export const changeRule = (rule: Rule, meta: AdvisorMeta, targetQuestionId?: UUID) =>
  action(CHANGE_RULE, { rule, targetQuestionId, ...meta })

/**
 * Change the target Question where to route to.
 */
export const changeRuleTarget = (ruleId: UUID, targetQuestionId: UUID, meta: AdvisorMeta) =>
  action(CHANGE_RULE_TARGET, { id: ruleId, targetQuestionId, ...meta })
