import { useQandASidebarContext } from "~/components/advisors/QandA/QandASidebarContext"
import React from "react"
import { useSelector } from "react-redux"
import { ApplicationState } from "~/store"
import { getScreens } from "~/store/screens/selectors"
import { UUID } from "~/store/types"
import useNavigation from "./useNavigation"

export default (advisorId: string) => {
  const { changeCollapse } = useQandASidebarContext()

  const { toQuestionInAdvisor, toScreenInAdvisor } = useNavigation()
  const screens = useSelector((state: ApplicationState) =>
    getScreens(state, advisorId)
  )

  const navigateTo = React.useCallback(
    (id: UUID, type: "start" | "advice" | "screen" | "question") => {
      if (type === "start") {
        toScreenInAdvisor(advisorId, screens!.intro.id)
      } else if (type === "advice") {
        toScreenInAdvisor(advisorId, screens!.advice.id)
      } else if (type === "screen") {
        toScreenInAdvisor(advisorId, id)
      } else {
        toQuestionInAdvisor(advisorId, id)
      }
      changeCollapse(false)
    },
    [advisorId, changeCollapse, screens, toQuestionInAdvisor, toScreenInAdvisor]
  )
  return {
    navigateTo,
  }
}
