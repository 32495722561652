import { useHistory } from "react-router"
import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React, { useState } from "react"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar } from "~/utils/environment"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import Page from "~/components/advisors/Page"
import { useImpactAnalytics } from "~/hooks/useImpactAnalytics"
import LoadingSpinner from "~/components/LoadingSpinner"

export const ElmAnalyze: React.FC<{
  advisorId: string
}> = ({ advisorId }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { token: authToken, organisationId } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string

  const { productLabel } = useAdvisorContext()
  const {
    apiKey,
    isConfigured,
    timeConfigured,
    setConfigurationComplete,
    loading,
  } = useImpactAnalytics()
  const [ports, setPorts] = useState<any>()

  React.useEffect(() => {
    if (!ports) return

    const configCompleteHandler = () => {
      setConfigurationComplete()
      ports.impactAnalyticsConfigured.send(true)
    }
    ports.impactAnalyticsConfigurationDone.subscribe(configCompleteHandler)
    return () => {
      ports.impactAnalyticsConfigurationDone.unsubscribe()
    }
  }, [ports])

  // Add click handler for everything in this element,
  // then prevent default to make anchor elements possible for navigation
  // also send url of anchor to Elm to respond
  // https://stackoverflow.com/questions/2136461/use-javascript-to-intercept-all-document-link-clicks
  //https://github.com/elm/browser/blob/1.0.2/notes/navigation-in-elements.md

  const locale =
    (navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language) || "en-US"

  if (loading)
    return (
      <Page maxWidth>
        <LoadingSpinner />
      </Page>
    )

  return (
    <Page maxWidth>
      {/* // @ts-ignore */}
      <Elm
        src={ElmModule.Analyze}
        flags={{
          authToken: authToken,
          organisationId: organisationId,
          advisorId: advisorId,
          productLabel: productLabel,
          impactAnalyticsConfigured: isConfigured,
          impactAnalyticsTimeConfigured: timeConfigured || null,
          impactAnalyticsApiKey: apiKey || null,
          apiUrl: apiUrl,
          userLocale: locale,
          url: window.location.href,
          now: Date.now(),
        }}
        ports={setPorts}
      />
    </Page>
  )
}
