import styled from "styled-components"

export default styled.label<{ focussed?: boolean; disabled?: boolean }>`
  margin: 0.5rem 0;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: bold;
  gap: 0.25rem;
  font-size: 0.875rem;
  color: ${(p) => {
    // if (p.focussed) return p.theme.secondary
    // if (p.disabled) return p.theme.shade3
    return p.theme.main
  }};
`
