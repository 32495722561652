import React, { useState } from "react"
import { Advisor }from "~/store/advisors"
import { ApplicationState }from "~/store"
import { useSelector } from "react-redux"
import { getScreens }from "~/store/screens/selectors"
import { ScreenType }from "~/store/screens"
import { useTranslation } from "react-i18next"
import useDatasources from "../../../hooks/useDatasources"
import useMountEffect from "../../../hooks/useMountEffect"
import { DataSource } from "../../../types"
import AdviceScreenEditor from "./ScreenEditor/AdviceScreenEditor"
import InfoScreenEditor from "./ScreenEditor/InfoScreenEditor"
import IntroScreenEditor from "./ScreenEditor/IntroScreenEditor"

const ScreenEditor: React.FC<{
  advisor: Advisor
  screenId: string
}> = ({ advisor, screenId }) => {
  const screens = useSelector((state: ApplicationState) => {
    return getScreens(state, advisor.id)
  })
  const { t } = useTranslation()
  const { getAll } = useDatasources(advisor.id)
  const [dataSource, setDataSource] = useState<DataSource | undefined>(
    undefined
  )
  useMountEffect(() => {
    getAll(advisor.id)
      .then((sources) => {
        setDataSource(sources[0])
      })
      .catch(() => setDataSource(undefined))
  })

  if (!screens) return null

  const infoScreen = screens?.infoScreens.find((s) => s.id === screenId)
  const screen =
    screens.intro.id === screenId
      ? screens.intro
      : screens.advice.id === screenId
      ? screens.advice
      : infoScreen
      ? infoScreen
      : undefined

  if (!screen) return null

  return (
    <div data-test-id="screen-editor">
      {screen.type === ScreenType.INTRO && (
        <>
          <h2>{t("screenEditor.introTitle")}</h2>
          <IntroScreenEditor advisor={advisor} screen={screen} />
        </>
      )}
      {screen.type === ScreenType.ADVICE && (
        <>
          <h2>{t("screenEditor.adviceTitle")}</h2>
          <AdviceScreenEditor
            advisor={advisor}
            screen={screen}
            dataSource={dataSource}
          />
        </>
      )}
      {screen.type === ScreenType.INFO && (
        <InfoScreenEditor advisor={advisor} screen={screen} />
      )}
    </div>
  )
}

export default ScreenEditor
