import React, { useState } from "react"
import { useImageSelectPreview } from "~/hooks/useImageSelectPreview"
import AccountInfo from "./AccountInfo"
import { Account } from "~/store/currentUser/types"
import UserInfo from "./UserInfo"
import ArrowRight from "~/components/icons/ArrowRight"
import Button from "~/components/Button"
import ButtonBar from "~/components/ButtonBar"
import { useTranslation } from "react-i18next"
import useMeasure from "react-use-measure"
import { accounts } from "~/api"
import { useHistory } from "react-router"
import useNavigation from "~/hooks/useNavigation"
import { useAuth } from "~/context/auth"
import HelpText from "~/components/HelpText"
import LoadingSpinner from "~/components/Loading"
import { logError } from "~/Sentry"

const defaultCrop = { width: 0, height: 0, x: 0, y: 0 }
const InvitationForm: React.FC<{ inviteToken: string; email: string }> = ({
  inviteToken,
  email,
}) => {
  const { t } = useTranslation()
  const { login, organisationId } = useAuth()
  const { routes } = useNavigation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [firstName, setFirstName] = useState("")
  const [familyName, setFamilyName] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [validPassword, setValidPassword] = useState(false)
  const [isCropping, setIsCropping] = useState(false)
  const [crop, setCrop] = useState(defaultCrop)

  const { selectedImage, previewUrl, onSelectImage } = useImageSelectPreview()

  // Gets the height of the element (ref)
  const [ref, { height }] = useMeasure()

  const accountInfoValid = validPassword && email.length >= 3
  const userInfoValid =
    firstName.length >= 1 && familyName.length >= 1 && !isCropping

  const createAccount = async () => {
    try {
      setLoading(true)
      const account: Account = await accounts.newAccountFromInvite(
        inviteToken,
        {
          password,
          passwordConfirm: passwordConfirmation,
          firstName,
          familyName,
          emailPreferences: { sendPeriodicUsageRecap: true },
        }
      )

      const validCrop = Object.values(crop).some((v) => v !== 0)
      const { token } = await login(account.email, password)
      if (selectedImage && validCrop) {
        try {
          await accounts.updateAvatar(
            account.id,
            selectedImage,
            crop,
            token,
            organisationId
          )
        } catch (error) {
          console.error(error)
        }
      }

      history.replace(routes.advisorOverview())
    } catch (e: any) {
      switch (e?.data?.status) {
        case "already_exists":
          setError(t("invitation.error.already_exists"))
          break
        case "not_found":
        case "invalid":
          setError(t("invitation.error.not_found"))
          break
        default:
          setError(t("errors.error"))
          logError("Unable to create account. The API returned an unexpected response", e)
      }

      setLoading(false)
    }
  }

  const next = createAccount

  const disabled = loading || !userInfoValid || !accountInfoValid

  return (
    <>
      <div>
        <form
          ref={ref}
          onSubmit={(e) => {
            e.preventDefault()
            !disabled && next()
          }}
        >
          <div>
            <div>
              <AccountInfo
                email={email}
                password={password}
                passwordConfirmation={passwordConfirmation}
                setPasswordConfirmation={setPasswordConfirmation}
                setPassword={setPassword}
                setValidPassword={setValidPassword}
              />
            </div>
            <div>
              <UserInfo
                firstName={firstName}
                familyName={familyName}
                isCropping={isCropping}
                onSelectImage={onSelectImage}
                setFirstName={setFirstName}
                setFamilyName={setFamilyName}
                setIsCropping={setIsCropping}
                imagePreviewUrl={previewUrl}
                setCrop={setCrop}
              />
            </div>
          </div>
          <button type="submit" style={{ display: "none" }} />
        </form>
        {error && <HelpText hasError>{error}</HelpText>}
      </div>
      <hr />
      <ButtonBar
        style={{ margin: "0" }}
        right={
          <Button primary onClick={next} disabled={disabled}>
            <span>{t("create")}</span>
            {loading ? <LoadingSpinner size="small" /> : <ArrowRight />}
          </Button>
        }
      />
    </>
  )
}

export default InvitationForm
