import { ApplicationState } from ".."
import { Answer } from "./types"
import { UUID } from "../types"
import { Question, QuestionType }from "~/store/questions"
import Advisor from "~/lib/Advisor"

export const getAnswer = (
  state: ApplicationState,
  answerId: UUID
): Answer | undefined => state.answers[answerId]

export const getAnswers = (
  state: ApplicationState,
  question: Question
  // answerIds: UUID[]
): Answer[] =>
  question.type === QuestionType.Drilldown
    ? Advisor.answersForDrilldown(question)
    : question.answers
        .map((id) => state.answers[id])
        .filter((answer) => answer !== undefined)

export const getAnswersWithQuestionId = (
  state: ApplicationState,
  questionId: string,
  answerIds: UUID[]
): (Answer & { questionId: string })[] =>
  answerIds
    .map((id) => state.answers[id])
    .filter((answer) => answer !== undefined)
    .map((answer) => {
      return { ...answer, questionId: questionId }
    })

export const getMappableAnswers = (
  state: ApplicationState,
  question: Question
): Answer[] =>
  getAnswers(state, question).filter((answer) => !answer.isNeutralAnswer)
