import React from "react"
import { Advisor }from "~/store/advisors"
import { useAuth } from "~/context/auth"
import AdvisorSettings from "~/elm-tsx-wrappers/AdvisorSettings"
import { envVar, EnvVar }from "~/utils/environment"

const Settings: React.FC<{ advisor: Advisor }> = ({ advisor }) => {
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL)
  const { token } = useAuth()

  return (
    <AdvisorSettings
      advisorId={advisor.id}
      apiUrl={apiUrl as string}
      authtoken={token as string}
      mode="update"
    />
  )
}

export default Settings
