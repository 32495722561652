import API from "./api"
import { OrganisationId } from "./types"

export type FieldAvailability = {
  advisor: { name: string; id: string }
  isLive: boolean
  missingInCatalogue: boolean
  missingInLiveRelease: boolean
}

const validateField = async (
  field: string,
  token: string,
  organisationId: OrganisationId
): Promise<{ availability: FieldAvailability[] }> =>
  API.post<{ availability: FieldAvailability[] }>({
    path: `/organisations/${organisationId.orgId}/integrations/squeezely/product-field-availability`,
    data: { field },
    token,
    organisationId,
  }).catch(() => ({availability: []}))

export { validateField }
