import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import { ApplicationState } from "../store"
import { useSidebar } from "~/context/sidebar"

import {
  getFirstQuestion as getFirstAfterIntro,
  getQuestionsWithRules,
} from "~/store/questions/selectors"
import { UUID } from "~/store/types"
import { useDispatch, useSelector } from "react-redux"
import { changeRuleTarget } from "~/actions/rules"
import { getCurrentColor } from "../themes"
import ConversationVisualization from "~/lib/ConversationVisualization"
import ElmWrapper from "./ElmWrapper"
import { Flow } from "~/actions/flow"
import { v4 as uuid } from "uuid"
import React from "react"
import useConversationNavigation from "~/hooks/useConversationNavigation"
import { getInfoScreens } from "~/store/screens/selectors"
import { removeInfoScreen } from "~/actions/screens/actions"
import useNavigation from "~/hooks/useNavigation"

const ConversationsCanvas: React.FC<{
  advisorId: string
  deleteQuestion: (questionId: UUID, confirmedDeletion: boolean) => void
  openFlowTab: (questionId: UUID, ruleId: UUID) => void
  selectedNodeId: string | undefined
}> = (props) => {
  const dispatch = useDispatch()
  const { open } = useSidebar()
  const advisorId = props.advisorId
  const meta = React.useMemo(() => ({ advisorId }), [advisorId])

  const { navigateTo } = useConversationNavigation(advisorId)
  const { toAdvisorFlow } = useNavigation()

  const questions = useSelector((state: ApplicationState) => {
    return getQuestionsWithRules(state, advisorId)
  })

  const infoScreens = useSelector((state: ApplicationState) =>
    getInfoScreens(state, advisorId)
  )

  const firstQuestionId = useSelector((state: ApplicationState) =>
    getFirstAfterIntro(state, advisorId)
  )

  const [ports, setPorts] = React.useState<any>()

  React.useEffect(() => {
    if (ports) {
      // @ts-ignore
      ports.navbarOpen.send(open)
    }
  }, [ports, open])

  React.useEffect(() => {
    if (ports) {
      try {
        const graph = ConversationVisualization.layout(
          questions,
          infoScreens,
          firstQuestionId
        )
        ports.graphReceiver.send(graph)
      } catch (e: any) {
        console.error(e)
      }
    }
  }, [questions, firstQuestionId, ports, infoScreens])

  React.useEffect(() => {
    const handler = ([id, type]) => navigateTo(id, type)
    if (ports) {
      ports?.selectNode.subscribe(handler)
    }
    return () => {
      ports?.selectNode.unsubscribe(handler)
    }
  }, [navigateTo, ports])

  React.useEffect(() => {
    const handler = async ([questionId, confirmedDeletion]) => {
      try {
        const matching = await props.deleteQuestion(
          questionId,
          confirmedDeletion
        )

        if (matching !== undefined) {
          ports.deleteWarningReceiver.send(matching)
        } else {
          ports.stopLoading.send(questionId)
        }
      } catch (e) {
        ports.stopLoading.send(questionId)
      }
    }
    if (ports) ports.deleteQuestion.subscribe(handler)

    return () => {
      if (ports) ports.deleteQuestion.unsubscribe(handler)
    }
  }, [ports, props.deleteQuestion])

  React.useEffect(() => {
    const handler = (screenId) => {
      dispatch(removeInfoScreen(screenId, meta))
      toAdvisorFlow(advisorId)
    }
    if (ports) ports?.deleteScreen.subscribe(handler)
    if (ports) {
      return () => ports?.deleteScreen.unsubscribe(handler)
    }
  }, [advisorId, dispatch, meta, ports, toAdvisorFlow])

  React.useEffect(() => {
    const handler = ([ruleId, targetQuestionId]) => {
      const id = uuid()
      dispatch(
        Flow.addInException(
          {
            type: "question",
            id: id,
            next: targetQuestionId,
          },
          ruleId,
          meta
        )
      )
      navigateTo(id, "question")
    }
    if (ports) ports.addQuestionInException.subscribe(handler)

    return () => {
      if (ports) ports.addQuestionInException.unsubscribe(handler)
    }
  }, [dispatch, meta, navigateTo, ports])

  React.useEffect(() => {
    const handler = ([prevQuestionId, nextQuestionId]) => {
      const id = uuid()

      dispatch(
        Flow.addAfterCreator(
          {
            type: "question",
            id: id,
            next: nextQuestionId,
          },
          prevQuestionId,
          meta
        )
      )
      navigateTo(id, "question")
    }

    if (ports) ports.addQuestionAfter.subscribe(handler)

    return () => {
      if (ports) ports.addQuestionAfter.unsubscribe(handler)
    }
  }, [dispatch, meta, navigateTo, ports])

  React.useEffect(() => {
    const handler = ([ruleId, targetQuestionId]) => {
      const id = uuid()

      dispatch(
        Flow.addInException(
          {
            type: "infoScreen",
            id: id,
            next: targetQuestionId,
          },
          ruleId,
          meta
        )
      )
      navigateTo(id, "screen")
    }
    const handler2 = ([prevQuestionId, nextQuestionId]) => {
      const id = uuid()
      dispatch(
        Flow.addAfterCreator(
          {
            type: "infoScreen",
            id: id,
            next: nextQuestionId,
          },
          prevQuestionId,
          meta
        )
      )
      navigateTo(id, "screen")
    }

    if (ports) {
      ports.addInfoScreenInException.subscribe(handler)
      ports.addInfoScreenAfter.subscribe(handler2)
      return () => {
        ports.addInfoScreenInException.unsubscribe(handler)
        ports.addInfoScreenAfter.unsubscribe(handler2)
      }
    }
  }, [dispatch, meta, navigateTo, ports])

  React.useEffect(() => {
    const handler = ([event, label, colorValue]) => {
      var backgroundColor =
        getCurrentColor(colorValue).value === "Color1"
          ? "white"
          : getCurrentColor(colorValue).color
      var elem = document.createElement("div")
      var labelElem = document.createElement("div")
      var markerElem = document.createElement("div")
      elem.classList.add("node")
      elem.id = "drag-ghost"

      markerElem.classList.add("node__marker")
      markerElem.style.backgroundColor = backgroundColor
      labelElem.classList.add("node__label")
      labelElem.innerText = label

      elem.appendChild(markerElem)
      elem.appendChild(labelElem)
      elem.style.position = "absolute"
      elem.style.top = "-100vh"
      elem.style.boxShadow = "none"
      document.body.appendChild(elem)
      event.dataTransfer.setDragImage(
        elem,
        ConversationVisualization.NODE_WIDTH / 2,
        ConversationVisualization.NODE_HEIGHT / 2
      )
    }

    const endHandler = () => {
      document.getElementById("drag-ghost")?.remove()
    }

    if (ports) ports.dragStartEvent.subscribe(handler)
    if (ports) ports.dragEndEvent.subscribe(endHandler)

    return () => {
      if (ports) ports.dragStartEvent.unsubscribe(handler)
      if (ports) ports.dragEndEvent.unsubscribe(endHandler)
    }
  }, [ports])

  React.useEffect(() => {
    const handler = ([questionId, ruleId]) =>
      props.openFlowTab(questionId, ruleId)

    if (ports) ports.openFlowTab.subscribe(handler)

    return () => {
      if (ports) ports.openFlowTab.unsubscribe(handler)
    }
  }, [ports, props.openFlowTab])

  const nodeIds = React.useMemo(() => {
    return [...questions.map((q) => q.id), ...infoScreens.map((s) => s.id)]
  }, [
    questions.map((q) => q.id).join("-"),
    infoScreens.map((s) => s.id).join("-"),
  ])

  React.useEffect(() => {
    const handler = ([questionOrScreenId, ruleId, targetQuestionId]) => {
      const id = nodeIds.find((id) => id === questionOrScreenId)

      if (id) {
        dispatch(Flow.move({ id: id, next: targetQuestionId }, undefined, meta))
        dispatch(changeRuleTarget(ruleId, id, { advisorId }))
      }
    }

    if (ports) ports.moveQuestionInException.subscribe(handler)

    return () => {
      if (ports) ports.moveQuestionInException.unsubscribe(handler)
    }
  }, [ports, dispatch, advisorId, nodeIds, meta])

  React.useEffect(() => {
    const handler = ([questionOrScreenId, prevQuestionId, nextQuestionId]) => {
      const id = nodeIds.find((id) => id === questionOrScreenId)
      if (id) {
        if (id) {
          dispatch(
            Flow.move({ id: id, next: nextQuestionId }, prevQuestionId, meta)
          )
        }
      }
    }

    if (ports) ports.moveQuestionAfter.subscribe(handler)

    return () => {
      if (ports) ports.moveQuestionAfter.unsubscribe(handler)
    }
  }, [ports, dispatch, nodeIds, meta])

  React.useEffect(() => {
    if (ports) ports.selectedNodeId.send(props.selectedNodeId)
  }, [ports, props.selectedNodeId])

  return <ElmWrapper src={ElmModule.ConversationsCanvas} ports={setPorts} />
}

export default ConversationsCanvas
