import { Middleware } from "redux"

import { CONSTANTS as AnswerConstants }from "~/actions/answers"
import { CONSTANTS as QuestionConstants }from "~/actions/questions"
import { CONSTANTS as RuleConstants }from "~/actions/rules"
import { CONSTANTS as ScreenConstants }from "~/actions/screens"
import { CONSTANTS as FlowConstants }from "~/actions/flow"
import TaskQueue from "~/lib/TaskQueue"
import debounce from "lodash/debounce"
import { autoSaveAdvisor, CHANGE_CUSTOMER_INFO_CONFIG }from "~/actions/advisors"
import { ApplicationState }from "~/store"
import { isAdvisorLoading } from "~/store/advisors/selectors"

const autoSavableActions: string[] = [
  ...AnswerConstants,
  ...QuestionConstants,
  ...RuleConstants,
  ...ScreenConstants,
  ...FlowConstants,
  CHANGE_CUSTOMER_INFO_CONFIG,
]

type FluxStandardAction = {
  type: string
  payload?: Record<string, any>
  meta?: Record<string, any>
}

export const createAutosaveMiddleware = () => {
  const saveQueue = TaskQueue.new()
  const enqueueSave: typeof saveQueue.push = debounce(saveQueue.push, 1000)

  const autoSaveMiddleware: Middleware =
    (storeAPI) => (next) => (action: FluxStandardAction) => {
      // Calling `next` passes the action to the reducer and updates the store
      // So everything after next deals with the updated store
      let result = next(action)
      const store: ApplicationState = storeAPI.getState()
      const advisorId = action.payload?.advisorId
      const isLoading = isAdvisorLoading(store, advisorId)

      if (
        autoSavableActions.includes(action.type) &&
        !action.meta?.skipSave &&
        !!advisorId &&
        !isLoading
      ) {
        enqueueSave(
          () =>
            new Promise((resolve, reject) => {
              try {
                autoSaveAdvisor(store, advisorId, {
                  context: {
                    token: localStorage.getItem("authToken") || "",
                    organisationId: {
                      orgId: localStorage.getItem("selectedOrganisation") || "",
                    },
                  },

                  finishCallback: () => {
                    resolve(true)
                  },
                })(storeAPI.dispatch)
              } catch (e) {
                console.error(e)
                reject(e)
              }
            })
        )
      }
      return result
    }

  return autoSaveMiddleware
}

const qAndAAutosaveMiddleware = createAutosaveMiddleware()

export default qAndAAutosaveMiddleware
