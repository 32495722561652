import styled from "styled-components"
import React from "react"
import Appear from "~/animations/Appear"

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  animation-name: ${Appear.fadeIn};
  animation-duration: 250ms;
  z-index: 900;
`

const ModalBackground = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.35);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 899;
`

const ModalContent = styled.div`
  width: 100%;
  max-width: 70rem;
  max-height: 80vh;
  background: ${(p) => p.theme.background};
  position: relative;
  border-radius: 0.25rem;
  z-index: 900;
  display: grid;
  grid-template-columns: 20rem 1fr;
  overflow: hidden;
  margin: 10vh auto 3rem auto;
  padding: 0;
`

const ModalSidebar = styled.div`
  background: white;
  padding: 1rem 0rem;
  border-right: 2px solid ${(p) => p.theme.shade1};
`

const ModalInnerContent = styled.div`
  padding: 2rem 3rem;
  background: ${(p) => p.theme.background};
  position: relative;
  border-radius: 0.25rem;
  z-index: 999999999999;
  overflow: auto;
  max-height: 80vh;
`

type ModalWithSidebarProps = {
  left: React.ReactNode
  right: React.ReactNode
  onClose: () => void
}

const ModalWithSidebar: React.FC<ModalWithSidebarProps> = ({
  onClose,
  left,
  right,
}) => {
  return (
    <Modal>
      <ModalBackground onClick={onClose} />
      <ModalContent>
        <ModalSidebar className="is-open">{left}</ModalSidebar>
        <ModalInnerContent>{right}</ModalInnerContent>
      </ModalContent>
    </Modal>
  )
}

export default ModalWithSidebar
