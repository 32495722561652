export enum EnvVar {
  ADVISOR_API_URL = "ADVISOR_API_URL",
  PRODUCT_UPDATE_API_URL = "PRODUCT_UPDATE_API_URL",
  WEBSHOP_FRONTEND_URL = "WEBSHOP_FRONTEND_URL",
  DUMMY_API_ENABLED = "DUMMY_API_ENABLED",
  SENTRY_ENABLED = "SENTRY_ENABLED",
  SENTRY_DSN = "SENTRY_DSN",
  SENTRY_SAMPLE_RATE = "SENTRY_SAMPLE_RATE",
  SENTRY_ENVIRONMENT = "SENTRY_ENVIRONMENT",
  SENTRY_SERVICE = "SENTRY_SERVICE",
  INTERCOM_ENABLED = "INTERCOM_ENABLED",
  INTERCOM_APP_ID = "INTERCOM_APP_ID",
  INTERCOM_API_BASE = "INTERCOM_API_BASE",
}

export const envVar = (key: EnvVar): string | boolean | undefined => {
  if (!window._env_) {
    return
  }

  const val = window._env_[key]
  switch (val) {
    case "true":
      return true
    case "false":
      return false
    default:
      return val
  }
}
