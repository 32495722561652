import React from "react"
import { PreviewMode, PreviewModeStorage } from "~/types"

type PreviewModeState = {
  getPreviewMode: (advisorId: string) => PreviewMode
  savePreviewMode: (advisorId: string, previewMode: PreviewMode) => void
}

const PreviewModeContext = React.createContext({
  getPreviewMode: (id) => "inpage",
  savePreviewMode: (id, mode) => {},
} as PreviewModeState)

const key = "preview-mode"

const PreviewModeContextProvider = (props) => {
  const storageItem = localStorage.getItem(key)
  const storage: PreviewModeStorage = !!storageItem
    ? JSON.parse(storageItem)
    : {}
  const [storageState, setStorageState] =
    React.useState<PreviewModeStorage>(storage)
  const getPreviewMode = (advisorId) => {
    if (storageState[advisorId] === "banner") return "banner"
    if (storageState[advisorId] === "sidebar") return "sidebar"
    if (storageState[advisorId] === "modal") return "modal"
    return "inpage"
  }
  const savePreviewMode = (advisorId, value) => {
    const newStorage = { ...storageState, [advisorId]: value }
    setStorageState(newStorage)
    localStorage.setItem(key, JSON.stringify(newStorage))
  }

  return (
    <PreviewModeContext.Provider
      value={{ getPreviewMode, savePreviewMode }}
      {...props}
    >
      {props.children}
    </PreviewModeContext.Provider>
  )
}

const usePreviewMode = () =>
  React.useContext<PreviewModeState>(PreviewModeContext)
export { PreviewModeContextProvider, usePreviewMode }
