import React, { useEffect, useState } from "react"
import styled from "styled-components"
import CardItem from "~/components/CardItem"
import { Link, useHistory, useLocation } from "react-router-dom"
import qs from "qs"
import InvitationForm from "./InvitationForm"
import Logo from "~/components/Logo"
import LoadingSpinner from "~/components/LoadingSpinner"
import { accounts } from "~/api"
import { useTranslation } from "react-i18next"
import HelpText from "~/components/HelpText"
import { useAuth } from "~/context/auth"
import { logError } from "~/Sentry"

const Wrapper = styled.div`
  display: block;
  min-height: 100vh;
  padding-top: 10vh;
  ${CardItem} {
    max-width: 40rem;
    margin: 0 auto;
    padding: 2rem;
    width: 100%;
  }
  .shiny-new-advisor-glow {
    z-index: 0;
  }
  .shiny-new-advisor-glow:before {
    z-index: -1;
  }
`

export default () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const token: string = qs.parse(
    location.search.substring(1, location.search.length)
  )["token"] as string
  const { logout } = useAuth()

  const [email, setEmail] = useState("")
  const [isInvalid, setInvalid] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  useEffect(() => {
    accounts
      .acceptInvitation(token)
      .then((response) => {
        setEmail(response.email)
        // log the user out before continueing
        // should be changed once we have multi account support
        logout()
      })
      .catch((e) => {
        if (e.status === 404) {
          setInvalid(true)
        } else {
          setError(t("errors.error"))
          logError("Unable to accept invitation. The API returned an unexpected response", e)
        }
      })
      .finally(() => setLoading(false))
  }, [token, t, logout])

  useEffect(() => {
    if (!token) history.replace("/login")
  }, [token, history])

  return (
    <Wrapper>
      <div
        style={{
          maxWidth: "70rem",
          margin: "0 auto",
        }}
        className="shiny-new-advisor-glow semi-muted animate-glow"
      >
        <CardItem>
          <div style={{ textAlign: "center" }}>
            <Logo
              style={{ width: "9rem", height: "3.5rem", marginBottom: "1rem" }}
            />
          </div>
          {loading ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : isInvalid ? (
            <>
              <p>This invitation is has already been used or has expired.</p>
              <p>
                If you've already created an account you can log in{" "}
                <Link className="link" to={"/login"}>
                  here.
                </Link>
              </p>
            </>
          ) : error ? (
            <>
              <HelpText hasError>{error}</HelpText>
              <p>
                If you've already created an account you can log in{" "}
                <Link className="link" to={"/login"}>
                  here.
                </Link>
              </p>
            </>
          ) : (
            <>
              <p style={{ textAlign: "center" }} className="subtle-text">
                {"Create your Aiden account"}
              </p>
              <InvitationForm inviteToken={token} email={email} />
            </>
          )}
        </CardItem>
      </div>
    </Wrapper>
  )
}
