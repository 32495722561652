import { EnvVar, envVar } from "~/utils/environment"
const webshopPreviewURL = envVar(EnvVar.WEBSHOP_FRONTEND_URL) as string
class AidenPreview extends HTMLElement {
  constructor() {
    super()
  }

  iframeElem: undefined | HTMLElement = undefined

  handler = (msg) => {
    if (
      msg.data &&
      msg.data.advisorId === "preview" &&
      msg.data.type === "viewport-dimensions" &&
      this.iframeElem
    ) {
      this.iframeElem.style.height = msg.data.data.height + "px"
    }
  }

  connectedCallback() {
    const iframe = document.createElement("iframe")
    // @ts-ignore
    iframe.src = import.meta.env.PROD
      ? webshopPreviewURL + "/build/preview.html"
      : webshopPreviewURL + "/preview.html"
    iframe.id = "preview"
    iframe.style.width = "100%"
    this.iframeElem = iframe
    iframe.onload = () => {
      this.dispatchEvent(new CustomEvent("preview-loaded"))
    }

    this.innerHTML = ""
    this.appendChild(iframe)
    window.addEventListener("message", this.handler)
  }

  disconnectedCallback() {
    window.removeEventListener("message", this.handler)
  }
}

const define = () => customElements.define("aiden-preview", AidenPreview)

export { define }
