import { Manager, Reference, Popper } from "react-popper"
import React, { useState, useEffect, createRef, useCallback } from "react"
import ReactDOM from "react-dom"
import { v4 as uuid } from "uuid"

type PopoutProps = {
  position:
    | "auto"
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "auto-start"
    | "auto-end"
    | "top-start"
    | "top-end"
    | "right-start"
    | "right-end"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | undefined
  toggle: React.ReactNode
  content: React.ReactNode
  theme?: "dark" | "light"
  id: string
  setOpen: (open: boolean) => void
  isOpen: boolean
}

export const usePopoutState = (elementId: string) => {
  const [open, setOpen] = useState(false)
  // const popoutRef = createRef<HTMLDivElement>()
  const id = elementId
  const toggleId = `${elementId}-toggle`

  const onGlobalClick = useCallback(
    (e: any) => {
      // if (popoutRef?.current?.contains(e.target)) return
      if (document?.getElementById(id)?.contains(e.target)) return
      if (document?.getElementById(toggleId)?.contains(e.target)) return
      setOpen(false)
    },
    [id, toggleId]
  )

  useEffect(() => {
    if (open) document.addEventListener("mouseup", onGlobalClick)
    return () => document.removeEventListener("mouseup", onGlobalClick)
  }, [onGlobalClick, open])

  return { isOpen: open, setOpen }
}

const Popout: React.FC<PopoutProps> = (props) => {
  const theme = props.theme || "light"
  return (
    <div>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              id={`${props.id}-toggle`}
              ref={ref}
              onClick={() => props.setOpen(!props.isOpen)}
            >
              {props.toggle}
            </div>
          )}
        </Reference>
        {props.isOpen &&
          ReactDOM.createPortal(
            <Popper placement={props.position}>
              {({ ref, style, placement, arrowProps }) => (
                <div
                  id={props.id}
                  ref={ref}
                  style={{...style, zIndex: 999}}
                  data-placement={placement}
                >
                  <div className="dropdown__popout_style animate slide-top">
                    <div
                      className={
                        "dropdown__popout_arrow " +
                        ("theme-" + theme) +
                        " " +
                        placement
                      }
                    />
                    <div style={{ overflow: "auto", maxHeight: "30rem", borderRadius: "0.75rem" }}>
                      {props.content}
                    </div>
                  </div>
                </div>
              )}
            </Popper>,
            document.querySelector("#root")!
          )}
      </Manager>
    </div>
  )
}

export default Popout
