import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { getAdvisor } from "~/store/advisors/selectors"
import { useSelector } from "react-redux"
import { matchPath } from "react-router"
import { ApplicationState } from "~/store"

const useDocumentTitle = () => {
  const location = useLocation()
  const state = useSelector((state: ApplicationState) => state)

  function setTitle(title: string, subTitle?: string) {
    const titleEnd = window.location.host.includes("x.aiden.cx")
      ? "Aiden Test"
      : "Aiden"
    document.title = [title, subTitle, titleEnd].filter(Boolean).join(" | ")
  }


  useEffect(() => {
    const advisorId = matchPath(location.pathname, {
        path: "/advisors/:advisorId/",
        exact: false,
        strict: false
    })?.params["advisorId"]
    const advisorName = advisorId && getAdvisor(state, advisorId)?.name
    const title = getTitleByPath(location.pathname)
    advisorName ? setTitle(title, advisorName) : setTitle(title)
  }, [location, state])
}

function matchPathPattern(path: string, pattern: string, exact?: boolean) {
  return !!(matchPath(path, {
      path: pattern,
      exact: exact || false,
      strict: false
  }))
}
function getTitleByPath(path: string) {
  switch (true) {
    case matchPathPattern(path, "/advisors/:advisorId/settings", true):
      return "App Settings"
    case matchPathPattern(path, "/advisors/:advisorId/flow"):
      return "Conversation"
    case matchPathPattern(path, "/advisors/:advisorId/look-and-feel", true):
      return "Look & Feel"
    case matchPathPattern(path, "/advisors/:advisorId/catalogue"):
      return "Catalogue"
    case matchPathPattern(path, "/advisors/:advisorId/advice/match"):
      return "Match"
    case matchPathPattern(path, "/advisors/:advisorId/advice/scoring"):
      return "Bulk match"
    case matchPathPattern(path, "/advisors/:advisorId/advice/tweak"):
      return "Test & Tweak"
    case matchPathPattern(path, "/advisors/:advisorId/advice"):
      return "Matching"
    case matchPathPattern(path, "/advisors/:advisorId/publish", true):
      return "Publish your app"
    case matchPathPattern(path, "/advisors/:advisorId/integrate"):
      return "Embed your app"
    case matchPathPattern(path, "/advisors/:advisorId/analyze/impact"):
      return "Impact analytics"
    case matchPathPattern(path, "/advisors/:advisorId/analyze/usage"):
      return "Usage analytics"
    case matchPathPattern(path, "/advisors/:advisorId/analyze/behaviour"):
      return "Conversation analytics"
    case matchPathPattern(path, "/advisors/:advisorId/analyze/product"):
      return "Product analytics"
    case matchPathPattern(path, "/advisors/:advisorId/analyze"):
      return "Analytics"
    case matchPathPattern(path, "/advisors", true):
      return "My Apps"
    case matchPathPattern(path, "/analytics", true):
      return "Analytics"
    case matchPathPattern(path, "/create-advisor", true):
      return "New app"
  }
  return ""
}

export default useDocumentTitle
