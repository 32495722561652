import React from "react"

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    fill="currentColor">
    <path d="M13.042 18.718s-2.047 2.051-4.344 4.349a11.295 11.295 0 0 0 0 15.986c.08.084.16.168.25.252a11.303 11.303 0 0 0 15.986 0c2.296-2.296 4.353-4.35 4.353-4.35a2.498 2.498 0 0 0-3.535-3.529s-2.057 2.052-4.353 4.35a6.313 6.313 0 0 1-8.926 0l-.25-.252a6.317 6.317 0 0 1 0-8.927c2.297-2.297 4.353-4.35 4.353-4.35a2.498 2.498 0 1 0-3.534-3.53Zm14.857-2.145L16.576 27.896a2.494 2.494 0 0 0 3.525 3.53l11.323-11.324a2.494 2.494 0 0 0-3.525-3.53Zm7.03 12.733 4.373-4.373a11.295 11.295 0 0 0 0-15.986 5.904 5.904 0 0 0-.25-.252 11.303 11.303 0 0 0-15.986 0c-2.296 2.295-4.343 4.347-4.343 4.347a2.494 2.494 0 0 0 3.525 3.53s2.057-2.051 4.353-4.347a6.313 6.313 0 0 1 8.926 0l.25.251a6.317 6.317 0 0 1 0 8.927c-2.306 2.308-4.373 4.373-4.373 4.373a2.494 2.494 0 0 0 3.524 3.53Z" fillRule="evenodd"/>
  </svg>
)
