import styled, { css } from "styled-components"
import React, { useState, useEffect } from "react"

const AvatarComp = styled.div<{ image?: string; size: string }>`
  border-radius: 100%;
  width: ${(p) => (p.size === "large" ? "2.5em" : "2em")};
  height: ${(p) => (p.size === "large" ? "2.5em" : "2em")};
  font-size: 0.875rem;
  background: ${(p) => p.theme.tertiary};
  ${(p) =>
    p.image &&
    css`
      background-image: url("${p.image}");
      background-size: cover;
    `}
  display: flex;
  place-items: center;
  place-content: center;
  font-weight: bold;
  text-transform: uppercase;
  img {
    display: none;
  }
`

const AvatarWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`
const AvatarName = styled.span`
  font-weight: bold;
  margin-left: .5em;
  font-size: 0.875rem;
  color: inherit;
`

const Avatar: React.FC<{
  name: string
  label?: string
  image?: string
  size?: "large" | "small"
}> = ({ name, label, image, size = "small" }) => {
  const [failedToLoad, setFailedToLoad] = useState(false)

  useEffect(() => {
    setFailedToLoad(false)
  }, [image])

  return (
    <div style={{ display: "inline-block", verticalAlign: "middle" }}>
      <AvatarWrapper>
        <AvatarComp size={size} image={image}>
          {image && (
            <img src={image} alt={name} onError={() => setFailedToLoad(true)} />
          )}
          {(!image || failedToLoad) && name.substring(0, 1)}
        </AvatarComp>
        {label && <AvatarName>{label}</AvatarName>}
      </AvatarWrapper>
    </div>
  )
}

export default Avatar
