import React, { useEffect } from "react"
import Field from "~/components/forms/Field"
import { OutlinedCircle, Check } from "~/components/icons"
import HelpText from "~/components/HelpText"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"

const Requirement = styled.div<{ valid: boolean }>`
  padding: 0.5rem 0 0.5rem 2rem;
  font-size: 0.75rem;
  position: relative;
  span {
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 0;
    top: 0.25rem;
    transition: color 200ms ease-out;
    color: ${p => p.theme.shade3};
  }
  ${p =>
    p.valid &&
    css`
      span:nth-child(2) {
        color: ${p.theme.tertiary};
        &,
        svg {
          width: 16px;
        }
        left: 0.25rem;
      }
    `}
`

const ChangePasswordForm: React.FC<{
  password: string
  passwordConfirmation: string
  setPassword: (value: string) => void
  setPasswordConfirmation: (value: string) => void
  setValid: (valid: boolean) => void
}> = ({
  password,
  passwordConfirmation,
  setPassword,
  setPasswordConfirmation,
  setValid,
}) => {
  const { t } = useTranslation()

  const matchingPasswords = password === passwordConfirmation

  const hasCapital = /[A-Z]+/g.test(password)
  const hasNumber = /[0-9]+/g.test(password)
  const hasSpecial = /[!@#$%^&*=]+/g.test(password)
  const tooShort = password.length < 8
  const tooLong = password.length > 64
  const validLength = !tooShort && !tooLong
  const validPassword =
    hasCapital && hasNumber && hasSpecial && !tooShort && !tooLong

  const invalidConfirmation =
    password.length > 0 && passwordConfirmation.length > 0 && !matchingPasswords

  useEffect(() => {
    setValid(validPassword && matchingPasswords)
  }, [validPassword, matchingPasswords, setValid])

  return (
    <>
      <Field
        onChange={setPassword}
        label={t("changePassword.password")}
        type="password"
        value={password}
      />
      <div data-test-id="requirements">
        <Requirement valid={validLength} className={validLength ? "valid" : ""}>
          <span>
            <OutlinedCircle />
          </span>
          <span>{validLength ? <Check /> : <OutlinedCircle />}</span>
          {t("changePassword.requirements.length")}
        </Requirement>
        <Requirement valid={hasCapital} className={hasCapital ? "valid" : ""}>
          <span>
            <OutlinedCircle />
          </span>
          <span>{hasCapital ? <Check /> : <OutlinedCircle />}</span>
          {t("changePassword.requirements.capital")}
        </Requirement>
        <Requirement valid={hasNumber} className={hasNumber ? "valid" : ""}>
          <span>
            <OutlinedCircle />
          </span>
          <span>{hasNumber ? <Check /> : <OutlinedCircle />}</span>
          {t("changePassword.requirements.number")}
        </Requirement>
        <Requirement valid={hasSpecial} className={hasSpecial ? "valid" : ""}>
          <span>
            <OutlinedCircle />
          </span>
          <span>{hasSpecial ? <Check /> : <OutlinedCircle />}</span>
          {t("changePassword.requirements.special")}
        </Requirement>
      </div>
      <Field
        onChange={setPasswordConfirmation}
        label={t("changePassword.passwordConfirmation")}
        type="password"
        value={passwordConfirmation}
        hasError={invalidConfirmation}
      />
      <div data-test-id="password-errors">
        {invalidConfirmation && (
          <HelpText hasError>{t("changePassword.error.invalidConfirmation")} </HelpText>
        )}
      </div>
    </>
  )
}

export default ChangePasswordForm
