import { QuestionColor }from "~/store/questions/types"
import { QuestionLabelColor } from "./types"

export default {
  background: "#ffffff",
  main: "#150c3c",
  mainAlt: "#1C1D48",
  mainShade: "#312663",
  primary: "#FFE200",
  primaryDark: "#fadd00",
  secondary: "#008DFF",
  tertiary: "#3EE9B6",
  success: "#3EE9B6",
  error: "#EE0202",
  errorBackground: "#FDE4E4",
  warning: "#ff8e1b",
  warningBackground: "#fcf9ec",
  warningLight: "#ffd2b7",
  backgroundShade: "#fafbfd",
  shade1: "#EDF3F9",
  shade2: "#EDF3F9",
  shade3: "#E4EBEF",
  shade4: "#A3ADB3",
  shade5: "#7a8bac",
  shadowColor: "rgba(116,142,166,0.25)",
  defaultShadow: `0 1px 1px rgba(155, 163, 161, 0.11),
  0 2px 2px rgba(155, 163, 161, 0.11), 0 4px 4px rgba(155, 163, 161, 0.11),
  0 6px 8px rgba(155, 163, 161, 0.11), 0 8px 16px rgba(155, 163, 161, 0.11)`,
  modalShadow: `0 1px 1px rgba(0, 0, 0, 0.08),
  0 2px 2px rgba(0, 0, 0, 0.08), 0 4px 4px rgba(0, 0, 0, 0.08),
  0 12px 36px rgba(0, 0, 0, 0.08), 0 12px 36px rgba(0, 0, 0, 0.08)`,
  borderRadius: "0.75rem",
  labelPadding: "0.5em",
  inputPadding: "0.625em",
  buttonPadding: "0.625em 1.5em",
  emptyButtonHeight: "2.75em", // for reference go to scss/settings.variables.scss
  dp: {
    5: "rgba(116,142,166,0.05)",
    10: "rgba(116,142,166,0.10)",
    18: "rgba(116,142,166,0.18)",
    20: "rgba(116,142,166,0.20)",
    25: "rgba(116,142,166,0.25)",
    30: "rgba(116,142,166,0.30)",
    32: "rgba(116,142,166,0.32)",
  },

  fontMonospace: "source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace",

  blueshadelight: "#DEF0FF",
  blueshadedark: "#0A2A45",
  greenshadeligh: "#C7F9EA",
  greenshadedark: "#09523C",
}


export const questionLabelColors: QuestionLabelColor[] = [
  { value: QuestionColor.Color1, label: "Default", color: "#EDF3F9", text: "#53616E" },
  { value: QuestionColor.Color2, label: "Brown", color: "#F5EDDC", text: "#846A3B" },
  { value: QuestionColor.Color3, label: "Orange", color: "#FFEDDB", text: "#846232" },
  { value: QuestionColor.Color4, label: "Yellow", color: "#FFF8C2", text: "#83792E" },
  { value: QuestionColor.Color5, label: "Green", color: "#DDF5EE", text: "#4C7C6D" },
  { value: QuestionColor.Color6, label: "Blue", color: "#E0F1FF", text: "#4A7192" },
  { value: QuestionColor.Color7, label: "Purple", color: "#F1ECFF", text: "#725A96" },
  { value: QuestionColor.Color8, label: "Red", color: "#FFE2ED", text: "#804646" },
]
