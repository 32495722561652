import React, { ReactNode } from "react"
import { CSVReader } from "react-papaparse"
import FileSelect from "~/components/FileSelect"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const buttonRef = React.createRef<any>()

const FileContainer = styled.div`
  & + * {
    display: none;
  }
`

const SelectCSVFile: React.FC<{
  fileName: string
  introContents: ReactNode
  setFile: (setFileName: File | undefined) => void
  setHeaders: (headers: string[]) => void
  setData: (data: string[][]) => void
  setDelimiter: (delimiter: string) => void
}> = ({ setHeaders, fileName, setData, setDelimiter, setFile, introContents }) => {
  // const [data, setData] = useState<ParseResult[]>([])
  const { t } = useTranslation()

  const handleOpenDialog = e => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) buttonRef.current.open(e)
  }

  const normalizeHeaderNames = (headers:  string[] )  : string[] =>
    headers.map(h => h.trim().replace(/\./g, "_"))

  const handleOnFileLoad = (csvData, file) => {
    const [header, ...data] = csvData
    setFile(file)
    const headers = normalizeHeaderNames(header.data)

    setHeaders(headers)
    setDelimiter(header.meta.delimiter)
    const d = data
      .map(d => d.data)
      .filter(d => d.length === header.data.length
      )
    setData(d)
  }

  const handleOnError = (err, file, inputElem, reason) => console.log(err)

  const handleOnRemoveFile = data => {
    setFile(undefined)
    setHeaders([])
    setData([])
  }

  const handleRemoveFile = e => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) buttonRef?.current?.removeFile(e)
  }

  return (
    <>
      <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        onRemoveFile={handleOnRemoveFile}
        config={{
          // preview: 10, currently has a bug which breaks the parsing
          worker: true,
        }}
      >
        {({ file }) => (
          <FileContainer>
            {introContents}
            <FileSelect
              label={t("productsUpload.selectFile")}
              filename={fileName}
              onRemove={handleRemoveFile}
              onClick={handleOpenDialog}
            />
          </FileContainer>
        )}
      </CSVReader>
    </>
  )
}

export default SelectCSVFile
