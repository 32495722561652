import React from "react"
// import HelpIcon from "~/components/icons/HelpIcon"
import { ArticleName } from "~/utils/intercomArticles"

import { define } from "~/webcomponents/tutorial-link"
define()

const TutorialLink: React.FC<{
  label: string
  article: ArticleName
}> = ({ label, article }) => {
  return (
    // @ts-ignore
    <tutorial-link article-id={article} label={label}></tutorial-link>
  )
}

export default TutorialLink
