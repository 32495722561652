import {
  RulesActionTypes,
  CHANGE_RULE,
  ADD_RULE,
  CHANGE_RULE_TARGET,
  REMOVE_RULE,
}from "~/actions/rules"

import { Rule, BinaryCombinator } from "./types"
import { UUID } from "../types"
import {
  NEW_ADVISOR_SUCCESS,
  AdvisorActionTypes,
  FETCH_ADVISOR_SUCCESS,
}from "~/actions/advisors"
import { QuestionActionTypes }from "~/actions/questions"

type RulesState = Record<UUID, Rule>

const defaultState = {}

export default (
  state: RulesState = defaultState,
  action: RulesActionTypes | AdvisorActionTypes | QuestionActionTypes
): RulesState => {
  switch (action.type) {
    case FETCH_ADVISOR_SUCCESS:
    case NEW_ADVISOR_SUCCESS: {
      const { rules } = action.payload
      return rules.reduce((acc, q) => ({ ...acc, [q.id]: q }), state)
    }

    case ADD_RULE: {
      const { rule, targetQuestionId } = action.payload
      return {
        ...state,
        [rule.id]: {
          id: rule.id,
          targetQuestionId: targetQuestionId || undefined,
          predicate: rule.predicate,
        },
      }
    }

    case CHANGE_RULE_TARGET: {
      const { id, targetQuestionId } = action.payload
      const target = state[id]
      return {
        ...state,
        [id]: {
          ...target,
          targetQuestionId: targetQuestionId,
        },
      }
    }

    case CHANGE_RULE: {
      const { rule } = action.payload
      return { ...state, [rule.id]: rule }
    }

    case REMOVE_RULE: {
      const { ruleId } = action.payload
      return Object.entries(state).reduce((acc, r) => {
        const [id, rule] = r
        if (id === ruleId) return acc
        return { ...acc, [id]: rule }
      }, {})
    }


    default:
      return state
  }
}
