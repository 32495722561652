class InView extends HTMLElement {
  constructor() {
    super()
  }

  handler(element) {
    let wasVisible = false
    return () => {
      const isVisible = this.isElementInViewport(element)
      if (wasVisible !== isVisible) {
        wasVisible = isVisible
        this.dispatchEvent(
          new CustomEvent("visibility-change", {
            detail: { isVisible },
          })
        )
      }
    }
  }

  isElementInViewport(elem) {
    var rect = elem.getBoundingClientRect()
    if (rect.top === 0 && rect.bottom === 0 && rect.left === 0 && rect.right === 0) return false

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        ((window.innerHeight || document.documentElement.clientHeight)) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  connectedCallback() {
    const el = this
    addEventListener("scroll", this.handler(el), false)
    addEventListener("resize", this.handler(el), false)
    this.handler(el)()
  }

  attributeChangedCallback(_name, _oldValue, newValue) {}

  disconnectedCallback() {
    const el = this
    removeEventListener("scroll", this.handler(el), false)
    removeEventListener("resize", this.handler(el), false)
  }
}

const define = () => customElements.define("in-view", InView)

export { define }
