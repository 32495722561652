import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import qs from "qs"
import Field from "~/components/forms/Field"
import Button, { ButtonLink } from "~/components/Button"
import Logo from "../../components/Logo"
import HelpText from "../../components/HelpText"
import { useLocation } from "react-router"
import FormSection from "~/components/forms/FormSection"
import { Link } from "react-router-dom"
import ChangePasswordForm from "~/components/forms/ChangePasswordForm"

const ResetPasswordForm: React.FC<{
  email: string
  setEmail: (email: string) => void
  reset: (
    email: string,
    token: string,
    password: string,
    confirmPassword: string
  ) => Promise<boolean>
}> = ({ reset, email, setEmail }) => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState([] as string[])
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [success, setSuccess] = useState(false)
  const [validPassword, setValidPassword] = useState(false)

  const location = useLocation()
  const token =
    qs.parse(location.search.substring(1, location.search.length))["token"]  as string ||
    ""

  const valid = email.length > 1 && validPassword

  const onSubmit = async e => {
    e.preventDefault()

    valid &&
      reset(email!, token, password, confirmPassword)
        .then(() => {
          setSuccess(true)
        })
        .catch(error => {
          console.log(error)
          if (error.data && error.data.payload && error.data.payload.password)
            setErrors(error.data.payload.password)
          else if (error.data && error.data.payload && error.data.payload.token)
            setErrors(error.data.payload.token)
          else if (error.data?.status === "invalid") setErrors(["invalid"])
          else setErrors(["other"])
        })
  }

  const formErrors = errors

  return (
    <>
      {!success ? (
        <form onSubmit={onSubmit} data-test-id="reset-password-form">
          <div style={{ textAlign: "center", marginBottom: "2rem" }}>
            <Logo style={{ width: "8rem" }} />
            <p style={{ marginTop: 0 }}>{t("login.reset.intro")}</p>
          </div>
          <FormSection>
            <Field
              onChange={setEmail}
              label={t("login.email")}
              type="email"
              value={email || ""}
            />
            <ChangePasswordForm
              password={password}
              passwordConfirmation={confirmPassword}
              setPassword={setPassword}
              setPasswordConfirmation={setConfirmPassword}
              setValid={setValidPassword}
            />
            <div data-test-id="errors">
              {formErrors.map((error, i) => (
                <div key={i}>
                  <HelpText hasError>
                    {t("login.reset.error." + error || "other")}{" "}
                  </HelpText>
                  {error === "invalid" && (
                    <Link to="/login/forgot-password">
                      {t("login.reset.error.reset")}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </FormSection>
          <Button primary fullWidth disabled={!valid}>
            {t("login.reset.submit")}
          </Button>
        </form>
      ) : (
        <div>
          <div style={{ textAlign: "center", marginBottom: "2rem" }}>
            <Logo style={{ width: "8rem" }} />
            <p style={{ marginTop: 0 }}>{t("login.reset.successTitle")}</p>
            <p style={{ textAlign: "left", marginTop: 0 }}>
              {t("login.reset.successMessage.1")}
            </p>
            <p style={{ textAlign: "left", marginTop: 0 }}>
              {t("login.reset.successMessage.2")}
            </p>
            <ButtonLink data-test-id="login-link" primary fullWidth to="/login">
              {t("login.reset.successButton")}
            </ButtonLink>
          </div>
        </div>
      )}
    </>
  )
}

export default ResetPasswordForm
