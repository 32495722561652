import {
  MatchingStat,
  QuestionMatchingStat,
} from "~/lib/Conversation"
import React from "react"
import { useTranslation } from "react-i18next"
import { Answer }from "~/store/answers/types"


const isMatchingStat = (obj: any): obj is MatchingStat =>
  typeof obj === "object" && "hasMatching" in obj && "count" in obj

const isQuestionMatchingStat = (obj: any): obj is QuestionMatchingStat =>
  typeof obj === "object" && "hasMatching" in obj && "stats" in obj

export const StatListContent: React.FC<{
  answers: Answer[]
  productLabel: string
  stats: QuestionMatchingStat | MatchingStat
}> = ({ stats, answers, productLabel }) => {
  const { t } = useTranslation()

  if (isMatchingStat(stats)) {
    return (
      <p>
        {t("questionsEditor.feedback.delete.questionImpact", {
          count: stats.count,
          productLabel,
        })}
      </p>
    )
  } else if (isQuestionMatchingStat(stats)) {
    return (
      <ul>
        {answers
          .filter((a) => !a.isNeutralAnswer)
          .map((a) => {
            return (
              <li key={a.id}>
                {t("questionsEditor.feedback.delete.answerImpact", {
                  count: stats.stats[a.id].count,
                  answerLabel: a.title,
                  productLabel,
                })}
              </li>
            )
          })}
      </ul>
    )
  } else {
    return null
  }
}
