import React from "react"
import FormSection from "~/components/forms/FormSection"
import Field from "~/components/forms/Field"
import ChangePasswordForm from "~/components/forms/ChangePasswordForm"
import { useTranslation } from "react-i18next"

type AccountInfoProps = {
  email: string
  password: string
  passwordConfirmation: string
  setPasswordConfirmation: (val: string) => void
  setPassword: (val: string) => void
  setValidPassword: (valid: boolean) => void
}

const AccountInfo: React.FC<AccountInfoProps> = ({
  email,
  password,
  passwordConfirmation,
  setPasswordConfirmation,
  setPassword,
  setValidPassword,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <FormSection>
        <Field
          label={t("invitation.accountInfo.email")}
          value={email}
          disabled
          onChange={() => {}}
        />
        <ChangePasswordForm
          password={password}
          passwordConfirmation={passwordConfirmation}
          setPassword={setPassword}
          setPasswordConfirmation={setPasswordConfirmation}
          setValid={setValidPassword}
        />
      </FormSection>
    </>
  )
}
export default AccountInfo
