import React from "react"
import styled, { css } from "styled-components"
import Tooltip from "./Tooltip"

const PillButtonsWrapper = styled.div`
  display: flex;
`

const Btn = styled.button<{
  selected?: boolean
  hover?: boolean
  hasTextContent?: boolean
}>`
  padding: ${(p) => "0.5rem 0.75rem"};
  -webkit-appearance: none;
  background: ${(p) => p.theme.shade3};
  cursor: pointer;
  display: flex;
  place-items: center;
  color: inherit;
  outline: none;
  transition: 200ms background-color ease-out;
  margin: 0 0;
  margin-left: 2px;
  font-weight: 600;
  border-radius: 0;
  position: relative;

  ${(p) =>
    !p.hasTextContent &&
    css`
      // > span {
      //   display: block;
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      svg {
        width: 1.5em;
        height: auto;
      }
      // }
    `};

  &:first-child {
    border-right: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    margin-left: 0;
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-right: 0;
  }

  &:hover {
    background: ${(p) => p.theme.mainAlt};
    color: white;
  }

  ${(p) =>
    p.selected &&
    css`
      background: ${(p) => p.theme.mainAlt};
      color: white;

      &:hover {
        background: ${(p) => p.theme.mainAlt};
        color: white;
      }
    `}
  ${(p) =>
    p.hover &&
    css`
      background: ${(p) => p.theme.tertiary};
      color: #fff;

      &:hover {
        background: ${(p) => p.theme.tertiary};
      }
    `}
`

const PillBtn: React.FC<{
  onClick?: (val: any) => void
  onMouseEnter?: (val: any) => void
  onMouseLeave?: (val: any) => void
  selected?: boolean
  hover?: boolean
  hasTextContent?: boolean
  value: any
}> = ({
  onClick,
  value,
  onMouseEnter,
  onMouseLeave,
  children,
  selected,
  hover,
  hasTextContent,
}) => (
  <Btn
    selected={selected}
    hover={hover}
    hasTextContent={hasTextContent}
    onClick={() => onClick && onClick(value)}
    onMouseEnter={() => onMouseEnter && onMouseEnter(value)}
    onMouseLeave={() => onMouseLeave && onMouseLeave(value)}
  >
    <span>{children}</span>
  </Btn>
)

type PillButton = {
  content?: React.ReactNode
  onClick?: (val: any) => void
  onMouseEnter?: (val: any) => void
  onMouseLeave?: (val: any) => void
  value: any
  selected?: boolean
  hover?: boolean
  hasTextContent?: boolean
  tooltipContent?: React.ReactNode
}

const PillButtons: React.FC<{
  buttons: PillButton[]
  tooltipDelay?: number
}> = ({ buttons, tooltipDelay = 100 }) => (
  <PillButtonsWrapper className={"pill-buttons"}>
    {buttons.map((b, i) =>
      b.tooltipContent ? (
        <Tooltip content={b.tooltipContent} enterDelay={tooltipDelay} key={i}>
          <PillBtn
            onClick={b.onClick}
            onMouseEnter={b.onMouseEnter}
            onMouseLeave={b.onMouseLeave}
            value={b.value}
            selected={b.selected}
            hover={b.hover}
            hasTextContent={b.hasTextContent}
          >
            {b.content}
          </PillBtn>
        </Tooltip>
      ) : (
        <PillBtn
          key={i}
          onClick={b.onClick}
          onMouseEnter={b.onMouseEnter}
          onMouseLeave={b.onMouseLeave}
          value={b.value}
          selected={b.selected}
          hover={b.hover}
          hasTextContent={b.hasTextContent}
        >
          {b.content}
        </PillBtn>
      )
    )}
  </PillButtonsWrapper>
)

export default PillButtons
