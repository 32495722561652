import React, { useState } from "react"
import useDatasources from "~/hooks/useDatasources"
import useMountEffect from "~/hooks/useMountEffect"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "~/components/Loading"
import ButtonBar from "~/components/ButtonBar"
import Button from "~/components/Button"
import { DataSource } from "~/types"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"

const ReviewChanges: React.FC<{
  dataSource: DataSource
  ids: string[]
  next?: () => void
  previous?: () => void
}> = ({ dataSource, previous, next, ids }) => {
  const { id: advisorId } = useAdvisorContext()
  const { previewChanges } = useDatasources(advisorId)
  const [changes, setChanges] = useState<
    | {
        insert: number
        update: number
        delete: number
      }
    | undefined
  >(undefined)
  const { t } = useTranslation()

  useMountEffect(() => {
    previewChanges(dataSource.id, ids).then(setChanges)
  })

  return (
    <>
      <h2>{t("productDataset.update.step2.title")}</h2>
      {changes ? (
        <div style={{ maxWidth: "40rem" }}>
          <p>{t("productDataset.update.step2.info.1")}</p>
          <ul>
            <li>
              {t("productDataset.update.step2.info.2", {
                count: changes.insert,
              })}
            </li>
            <li>
              {t("productDataset.update.step2.info.3", {
                count: changes.update,
              })}
            </li>
            <li>
              {t("productDataset.update.step2.info.4", {
                count: changes.delete,
              })}
            </li>
          </ul>
        </div>
      ) : (
        <LoadingSpinner />
      )}
      <ButtonBar
        left={
          <Button onClick={() => previous && previous()}>
            {t("back")}
          </Button>
        }
        right={
          <Button
            primary
            disabled={next ? false : true}
            onClick={() => {
              next && next()
            }}
          >
            {t("next")}
          </Button>
        }
      />
    </>
  )
}

export default ReviewChanges
