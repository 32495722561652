import { Manager, Reference, Popper } from "react-popper"
import React, { useState, useEffect, createRef, useCallback } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { v4 as uuid } from 'uuid';

const ActionListWrapper = styled.div`
  position: relative;
  padding: .5rem;
  border-radius: 5em;

  &:hover {
    background: ${props => props.theme.shade2};
  }
`

const ActionListList = styled.div`
  background: #fff;
  display: inline-block;
  width: 300px;
  box-shadow: ${props => props.theme.defaultShadow};
  border: 1px solid ${props => props.theme.shade1};
  border-radius: ${p => p.theme.borderRadius};
  overflow: hidden;
  z-index: 100;
`

const Selection = styled.div`
  padding: 0;
  width: 100%;
  border-radius: ${p => p.theme.borderRadius};
  cursor: pointer;
  user-select: none;
  border: none;
  transition: color 200ms ease-in, background 200ms ease-in;
  color: ${props => props.theme.main};
  &:focus {
    background: ${props => props.theme.backgroundShade1};
  }
  position: relative;
`

type ActionListProps = {
  position:
    | "auto"
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "auto-start"
    | "auto-end"
    | "top-start"
    | "top-end"
    | "right-start"
    | "right-end"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | undefined
  options: (close: () => void) => React.ReactNode
}

const ActionList: React.FC<ActionListProps> = props => {
  const [open, setOpen] = useState(false)
  const ActionListRef = createRef<HTMLDivElement>()
  const id = uuid()

  const onGlobalClick = useCallback(
    (e: any) => {
      if (ActionListRef?.current?.contains(e.target)) return
      if (document?.getElementById(id)?.contains(e.target)) return
      setOpen(false)
    },
    [ActionListRef, id]
  )

  useEffect(() => {
    if (open) document.addEventListener("mouseup", onGlobalClick)
    return () => document.removeEventListener("mouseup", onGlobalClick)
  }, [onGlobalClick, open])

  const close = () => setOpen(false)

  return (
    <ActionListWrapper ref={ActionListRef}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <Selection ref={ref} onClick={() => setOpen(!open)}>
              {props.children}
            </Selection>
          )}
        </Reference>
        {open &&
          ReactDOM.createPortal(
            <Popper placement={props.position}>
              {({ ref, style, placement, arrowProps }) => (
                <ActionListList
                  id={id}
                  ref={ref}
                  style={style}
                  data-placement={placement}
                >
                  {props.options(close)}
                  <div ref={arrowProps.ref} style={arrowProps.style} />
                </ActionListList>
              )}
            </Popper>,
            document.querySelector("#root")!
          )}
      </Manager>
    </ActionListWrapper>
  )
}

export const ActionListItem = styled.div`
  & > * {
    padding: 1rem;
  }
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: ${p => p.theme.shade1};
  }
`

export default ActionList
