import React from "react"
import { useSelector } from "react-redux"
import { ApplicationState }from "~/store"
import { getQuestions }from "~/store/questions"
import { getAdvisor }from "~/store/advisors/selectors"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import { useTranslation } from "react-i18next"
import QuestionSidebar from "./QuestionSidebar"
import ScreenEditor from "./ScreenEditor"
import { getScreens } from "../../../store/screens/selectors"
import SideBarContent from "./SidebarContent"
import HelpText from "~/components/HelpText"

const SideBar: React.FC<{ advisorId: string }> = props => {
  const { advisorId } = props
  const { url } = useRouteMatch()
  const { t } = useTranslation()

  const advisor = useSelector((state: ApplicationState) =>
    getAdvisor(state, advisorId),
  )

  const questions = useSelector((state: ApplicationState) => {
    if (!advisor) return []
    return getQuestions(state, advisor.questions) || []
  })
  const screens = useSelector((state: ApplicationState) => {
    if (!advisor) return undefined
    return getScreens(state, advisor.id)
  })

  if (!advisor) return null

  return (
    <Switch>
      <Route
        path={`${url}/questions/:questionId`}
        render={(match) => (
          <QuestionSidebar
            advisorId={advisorId}
            questionId={match.match.params.questionId}
          />
        )}
      />

      <Route
        path={`${url}/screens/:screenId`}
        render={(r) => (
          <ScreenEditor advisor={advisor} screenId={r.match.params.screenId} />
        )}
      />

      <Route path={`${url}`} exact>
        <>
          <h2>
            {t("advisor.navigation.conversation.title")}
          </h2>
          <HelpText>
            {t("advisor.navigation.conversation.intro")}
          </HelpText>
          <SideBarContent
            url={url}
            screens={screens!}
            questions={questions}
            advisorId={advisor.id}
          />
        </>
      </Route>
    </Switch>
  )
}

export default SideBar
