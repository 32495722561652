import styled from "styled-components"
import React from "react"
import TextareaAutosize from "react-autosize-textarea"
import { InputState, Placeholders } from "~/styles/TextStyles"

const TextareaWrapper = styled.div<{ hasError?: boolean; resizable?: boolean }>`
  textarea {
    padding: ${p => p.theme.inputPadding};
    border-radius: ${p => p.theme.borderRadius};
    -webkit-appearance: none;
    border: none;
    transition: color 200ms ease-in, background 200ms ease-in;
    background: ${props => props.theme.white};
    /* margin-bottom: 0.5rem; */
    color: ${props => props.theme.main};
    resize: ${p => (p.resizable ? "vertical" : "none")};
    width: 100%;
    transition: 200ms border-color ease-in;
    ${InputState}
    ${Placeholders}
    border: 2px solid ${p => (p.hasError ? p.theme.error : p.theme.shade3)};
  }
  textarea:disabled {
    background-color: ${p => p.theme.shade3};
    color: ${p => p.theme.shade4};
    border-color: ${p => p.theme.shade2};
  }
`

// export default TextArea
type StylingProps = { hasError?: boolean }

export type TextareaAutogrowProps = TextareaAutosize.Props & StylingProps

export const TextareaAutogrow = React.forwardRef<
  HTMLTextAreaElement,
  TextareaAutogrowProps
>((props, ref) => {
  const { hasError, ...areaProps } = props
  return (
    <TextareaWrapper hasError={hasError}>
      <TextareaAutosize {...areaProps} ref={ref} />
    </TextareaWrapper>
  )
})

export const Textarea: React.FC<React.HTMLProps<HTMLTextAreaElement> &
  StylingProps> = props => (
  <TextareaWrapper resizable>
    <textarea {...props} />
  </TextareaWrapper>
)
