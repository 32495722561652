import {
  CHANGE_ADVISOR_META,
  CHANGE_ADVISOR_STYLE,
  CHANGE_CUSTOMER_INFO_CONFIG,
  DELETE_ADVISOR,
  DELETE_ADVISOR_FAILURE,
  DELETE_ADVISOR_SUCCESS,
  FETCH_ADVISOR,
  FETCH_ADVISOR_FAILURE,
  FETCH_ADVISOR_SUCCESS,
  FETCH_ADVISORS,
  FETCH_ADVISORS_SUCCESS,
  NEW_ADVISOR,
  NEW_ADVISOR_FAILURE,
  NEW_ADVISOR_SUCCESS,
  UPDATE_ADVISOR,
  UPDATE_ADVISOR_FAILURE,
  UPDATE_ADVISOR_SUCCESS,
  LATEST_RELEASE,
  ADVISOR_PUBLISHED,
  TRACK_CHANGE,
  FETCHED_ADVISOR_STYLE,
  ADVISOR_VALIDATED,
} from "./constants"
import { action } from "typesafe-actions"
import {
  AdviceCustomerInfoConfig,
  Advisor,
  AdvisorStatus,
  StyleConfig,
} from "../../store/advisors"
import { Question }from "~/store/questions"
import { Rule }from "~/store/rules"
import { Answer }from "~/store/answers/types"
import { UUID }from "~/store/types"
import { default as AdvisorLib } from "~/lib/Advisor"
import { ConversationScreens }from "~/store/screens"
import { AdvisorValidationResult } from "~/types"

export type AdvisorMeta = { advisorId: string; trackChanges?: boolean }

/**
 * Retrieve all Advisors from the API.
 */
export const fetchAdvisors = () => action(FETCH_ADVISORS, {})

/** Private */
export const fetchAdvisorsSuccess = (advisors: Advisor[]) =>
  action(FETCH_ADVISORS_SUCCESS, { advisors })

/**
 * Retrieve an Advisor from the API.
 */
export const fetchAdvisor = (advisorId: string) =>
  action(FETCH_ADVISOR, { advisorId })

export const changeAdvisorMeta = (
  advisorId: string,
  name: string,
  productLabel: string,
  icon: string,
  analytics: Advisor["analytics"],
  locales: string[],
  trackChanges: boolean = true
) =>
  action(CHANGE_ADVISOR_META, {
    advisorId,
    name,
    productLabel,
    icon,
    analytics,
    locales,
    trackChanges,
  })

export const changeAdvisorStyle = (advisorId: string, style: StyleConfig) =>
  action(CHANGE_ADVISOR_STYLE, { advisorId, style }, { trackChanges: false })
export const fetchedAdvisorStyle = (advisorId: string, style: StyleConfig) =>
  action(FETCHED_ADVISOR_STYLE, { advisorId, style }, { trackChanges: false })

/** Private */
export const fetchAdvisorSuccess = (
  advisor: Advisor,
  questions: Question[],
  rules: Rule[],
  answers: Answer[],
  screens: ConversationScreens
) =>
  action(FETCH_ADVISOR_SUCCESS, {
    advisor: advisor,
    questions,
    rules,
    answers,
    screens,
  })

export const fetchAdvisorFailure = (advisorId: string) =>
  action(FETCH_ADVISOR_FAILURE, { advisorId })

/** Private */
export const newAdvisor = (
  advisor?: Pick<
    Advisor,
    "name" | "id" | "productLabel" | "icon" | "startQuestionId" | "style"
  >
) =>
  action(NEW_ADVISOR, {
    advisor: {
      ...AdvisorLib.defaultAdvisor(
        advisor?.name,
        advisor?.productLabel,
        advisor?.icon,
        advisor?.id
      ),
      startQuestionId: advisor?.startQuestionId,
    },
  })

/** Private */
export const newAdvisorSuccess = (
  advisor: Advisor,
  questions: Question[],
  rules: Rule[],
  answers: Answer[],
  screens: ConversationScreens
) =>
  action(NEW_ADVISOR_SUCCESS, {
    advisor: advisor,
    questions,
    rules,
    answers,
    screens,
  })

export const newAdvisorFailure = (advisorId: UUID) =>
  action(NEW_ADVISOR_FAILURE, { advisorId })

export const advisorPublished = (
  advisorId: UUID,
  status: AdvisorStatus,
  publishedAt: Date
) => action(ADVISOR_PUBLISHED, { advisorId, status, publishedAt })

export const advisorLatestRelease = (
  advisorId: UUID,
  lastEdited: Date,
  releasedAt: Date
) =>
  action(LATEST_RELEASE, {
    advisorId,
    releasedAt,
    lastEdited,
    trackChanges: false,
  })

export const updateAdvisor = (advisorId: UUID) =>
  action(UPDATE_ADVISOR, { advisorId })

export const updateAdvisorSuccess = (
  advisor: Advisor,
  questions: Question[],
  rules: Rule[],
  answers: Answer[],
  screens: ConversationScreens
) =>
  action(UPDATE_ADVISOR_SUCCESS, {
    advisor: advisor,
    questions,
    rules,
    answers,
    screens,
  })

export const updateAdvisorFailure = (advisorId: UUID) =>
  action(UPDATE_ADVISOR_FAILURE, { advisorId })

export const deleteAdvisor = (advisorId: UUID) =>
  action(DELETE_ADVISOR, { advisorId })

export const deleteAdvisorSuccess = (advisorId: UUID) =>
  action(DELETE_ADVISOR_SUCCESS, { advisorId })

export const deleteAdvisorFailure = (advisorId: UUID) =>
  action(DELETE_ADVISOR_FAILURE, { advisorId })

export const validated = (advisorId: UUID, validation: AdvisorValidationResult) =>
  action(ADVISOR_VALIDATED, { advisorId, validation }, {trackChanges: false})

/**
 * Change the configuration of the form for asking customers their information
 * */
export const changeCustomerInfoConfig = (
  advisorId: UUID,
  customerInfoConfig: AdviceCustomerInfoConfig
) => action(CHANGE_CUSTOMER_INFO_CONFIG, { advisorId, customerInfoConfig })

export const trackChange = (advisorId: UUID) =>
  action(TRACK_CHANGE, { advisorId })
