import React, { useState, useEffect } from "react"
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
} from "react-router"
import Table from "~/components/Table"
import MapColumns from "./MapColumns"
import { guessColumnMapping, defaultColumnMapping } from "~/lib/ColumnMapping"
import { useTranslation } from "react-i18next"
import {
  ProgressBar,
  UploadStep,
  SelectDataFileStep,
  Preview,
} from "../SharedComponents"
import { ColumnMapping, Currency } from "~/types"
import Card from "~/components/Card"

const UploadNewData: React.FC<{}> = (props) => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const match = useRouteMatch(`${url}/:step`)
  const current = parseInt(match?.params["step"]) || 1
  const history = useHistory()

  const [file, setFile] = useState<File | undefined>(undefined)
  const [headers, setHeaders] = useState<string[]>([])
  const [uploaded, setUploaded] = useState(false)
  const [data, setData] = useState<string[][]>([])

  const [delimiter, setDelimiter] = useState<string>(",")
  const [columnMapping, setColumnMapping] =
    useState<ColumnMapping>(defaultColumnMapping)
  const [priceCurrency, setPriceCurrency] = useState(Currency.EUR)
  const previewData = data.slice(0, 10)

  const step1Completed = uploaded || !!(headers.length && data.length)
  const step2Completed =
    uploaded ||
    (!!columnMapping.id &&
      !!columnMapping.name &&
      !!columnMapping.url &&
      !!columnMapping.image)
  const step3Completed = uploaded

  const gotoStep = (step: number) => () => {
    history.push(`${url}/${step}`)
  }

  const back = () => history.goBack()
  const backToCatalogueCreate = () => history.goBack()
  const step1Next = step1Completed ? gotoStep(2) : undefined
  const step2Next = step2Completed ? gotoStep(3) : undefined

  useEffect(() => {
    // Check columnmapping at step 2 to prevent "completing" step 2
    // when selecting a file
    if (current === 2) {
      const guess = guessColumnMapping(headers)
      const existing: ColumnMapping = defaultColumnMapping
      setColumnMapping({
        id: existing.id || guess.id,
        name: existing.name || guess.name,
        url: existing.url || guess.url,
        image: existing.image || guess.image,
        price: existing.price || guess.price,
      })
    }
  }, [headers, current])

  useEffect(() => {
    if (uploaded) {
      setUploaded(true)
      setColumnMapping(defaultColumnMapping)
      setFile(undefined)
      setData([])
      setHeaders([])
      if (current !== 3) {
        setUploaded(false)
      }
    }
  }, [current, uploaded])

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30rem minmax(0, 1fr)",
          gap: "2rem",
        }}
        data-test-id="datasource-upload-flow"
      >
        <Card>
          <ProgressBar
            current={current}
            steps={[
              {
                label: t("productDataset.create.step1.progressLabel"),
                nav: gotoStep(1),
                completed: step1Completed,
              },
              {
                label: t("productDataset.create.step2.progressLabel"),
                nav: step1Completed ? gotoStep(2) : undefined,
                completed: step2Completed,
              },
              {
                label: t("productDataset.create.step3.progressLabel"),
                nav: undefined,
                completed: step3Completed,
              },
            ]}
          />
          <Switch>
            <Route
              path={`${url}/1`}
              render={() => (
                <SelectDataFileStep
                  fileName={file?.name || ""}
                  setFile={setFile}
                  setHeaders={setHeaders}
                  setData={setData}
                  setDelimiter={setDelimiter}
                  back={backToCatalogueCreate}
                  next={step1Next}
                  title={t("productDataset.create.step1.title")}
                  introContents={<p>{t("productsUpload.intro")}</p>}
                  infoBarContents={
                    <>
                      <p>{t("productDataset.create.step1.info.1")}</p>
                      <p>{t("productDataset.create.step1.info.2")}</p>
                      <ul>
                        <li>{t("productDataset.create.step1.info.3")}</li>
                        <li>{t("productDataset.create.step1.info.4")}</li>
                        <li>{t("productDataset.create.step1.info.5")}</li>
                        <li>{t("productDataset.create.step1.info.6")}</li>
                      </ul>
                      <p>{t("productDataset.create.step1.info.7")}</p>
                    </>
                  }
                ></SelectDataFileStep>
              )}
            />
            {step1Completed && (
              <Route
                path={`${url}/2`}
                render={() => (
                  <MapColumns
                    data={data}
                    headers={headers}
                    columnMapping={columnMapping}
                    setColumnMapping={setColumnMapping}
                    priceCurrency={priceCurrency}
                    setPriceCurrency={setPriceCurrency}
                    previous={back}
                    next={step2Next}
                  />
                )}
              />
            )}
            {
              <Route
                path={`${url}/3`}
                render={() => (
                  <UploadStep
                    updateDataSource={(dataSource) => dataSource}
                    columnMapping={columnMapping}
                    delimiter={delimiter}
                    priceCurrency={
                      columnMapping["price"] ? priceCurrency : undefined
                    }
                    file={file}
                    errorBtnFn={() => gotoStep(1)}
                  />
                )}
              />
            }
            <Redirect to={`${url}/1`} push={false} />
          </Switch>
        </Card>
        <div>
          {headers.length > 0 && (
            <Preview>
              <h2>{t("preview")}</h2>
              <div className="card no-padding-v">
                <Table headers={headers} data={previewData} />
              </div>
            </Preview>
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadNewData
