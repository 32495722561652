import API from "./api"
import { UUID } from "~/store/types"
import {
  QuestionColor,
  QuestionMediaType,
  QuestionType,
} from "~/store/questions"
import { AnswerExternal } from "~/store/answers/types"
import { Rule } from "~/store/rules"
import {
  AdviceCustomerInfoConfig,
  Advisor,
  EmbedConfig,
  InfoPageCustomerInfoConfig,
  NoResultsCustomerInfoConfig,
  StyleConfig,
} from "~/store/advisors"
import { AdvisorValidationResult } from "~/types"
import {
  CardField,
  ProductScopeConfig,
  ScreenType, ShowBackorder,
  Sort,
} from "~/store/screens"
import { OrganisationId, Paginated } from "./types"

export type QuestionExternal = {
  id: UUID
  advisorId: UUID
  title: string
  label: string
  color: QuestionColor
  helpText: string | undefined
  type: QuestionType
  mediaType: QuestionMediaType
  next: UUID | undefined
  answers: AnswerExternal[]
  rules: Rule[]
  config: Record<string, any>
}

export type ConversationScreenExternal =
  | ExternalAdviceScreen
  | ExternalIntroScreen
  | ExternalInfoScreen

export type ExternalIntroScreen = {
  advisorId: UUID
  id: UUID
  type: ScreenType
  next: UUID
  config: {
    title: string
    text: string | null
    info: string | null
    buttonText: string
    skipIntro?: string | boolean
  }
}
export type ExternalInfoScreen = {
  advisorId: UUID
  id: UUID
  type: ScreenType
  next: UUID | undefined
  customerInfoConfig: InfoPageCustomerInfoConfig
  config: {
    title: string
    text: string | null
    info: string | null
    label: string
    color: QuestionColor
    mediaType: QuestionMediaType
  }
}
export type ExternalAdviceScreen = {
  advisorId: UUID
  id: UUID
  type: ScreenType
  noResultsCustomerInfoConfig: NoResultsCustomerInfoConfig
  productScopeConfig: ProductScopeConfig
  config: {
    title: string
    text: string | null
    info: string | null
    buttonText?: string
    bestMatchText?: string
    noResultsTitle?: string
    noResultsText?: string
    resultSize?: string
    showProductLink?: string | boolean
    skipIntro?: string | boolean
    showBestMatch: boolean
    showMatchingIndicators: boolean
    showPrice: boolean
    showCurrency: boolean
    sort?: Sort[]
    extraField1?: CardField
    extraField2?: CardField
    extraField3?: CardField
    groupByVariants: boolean
    displayVariantsOfGroup: {
      enabled: boolean
      field: string | null
    }

    showPriceSuffix: boolean
    showPricePrefix: boolean
    priceSuffix: string
    pricePrefix: string

    showBackorder: ShowBackorder

    callToActionType: "ProductLink" | "AddToCart"
  }
}

export type AdvisorExternal = {
  id: UUID
  name: string
  productLabel: string
  icon: string
  analytics: Record<string, string>
  locales: string[]
  questions: QuestionExternal[]
  updatedAt?: number
  status?: string
  style: StyleConfig
  screenOrder: Record<string, number>
  screens: ConversationScreenExternal[]
  customerInfo: AdviceCustomerInfoConfig
  embedConfig: EmbedConfig
  appUpdate: { updatedAt: null | number }
}

/** Create a new Advisor */
const create = async (
  id: UUID,
  advisor: AdvisorExternal,
  token: string,
  organisationId: OrganisationId
) =>
  API.post<AdvisorExternal>({
    path: "/advisors",
    data: {
      ...advisor,
      id,
    },
    token,
    organisationId,
  })

// /** Update a Advisor */
const update = async (
  advisor: AdvisorExternal,
  token: string,
  organisationId: OrganisationId
) =>
  API.put<AdvisorExternal>({
    path: "/advisors/" + advisor.id,
    data: advisor,
    token,
    organisationId,
  })

const changeMeta = async (
  advisorId: string,
  name: string,
  productLabel: string,
  icon: string,
  analytics: Advisor["analytics"],
  locales: string[],
  token: string,
  organisationId: OrganisationId
) =>
  API.put<AdvisorExternal>({
    path: `/advisors/${advisorId}/update-meta`,
    data: { name, productLabel, icon, analytics, locales },
    token,
    organisationId,
  })

const changeStyle = async (
  advisorId: string,
  style: StyleConfig,
  token: string,
  organisationId: OrganisationId
) =>
  API.put<AdvisorExternal>({
    path: `/advisors/${advisorId}/update-style`,
    data: style,
    token,
    organisationId,
  })

// /** Retrieve a Advisor */
const all = async (
  token: string,
  organisationId: OrganisationId,
  page: number
) => {
  const size = 4
  const offset = (page - 1) * size
  return API.get<Paginated<AdvisorExternal[]>>({
    path: `/advisors?&size=${size}&offset=${offset}`,
    token,
    organisationId,
  })
}

// /** Retrieve an Advisor */
const get = async (id: string, token: string, organisationId: OrganisationId) =>
  API.get<AdvisorExternal>({ path: "/advisors/" + id, token, organisationId })

const validation = async (
  id: string,
  token: string,
  organisationId: OrganisationId
) =>
  API.get<AdvisorValidationResult>({
    path: `/advisors/${id}/validation`,
    token,
    organisationId,
  })

const conversationValidation = async (
  id: string,
  token: string,
  organisationId: OrganisationId
) =>
  API.get<AdvisorValidationResult>({
    path: `/advisors/${id}/conversation-validation`,
    token,
    organisationId,
  })

const publish = async (
  id: string,
  token: string,
  organisationId: OrganisationId
) =>
  API.post<Release>({ path: `/advisors/${id}/publish`, token, organisationId })

const duplicate = async (
  id: string,
  name: string,
  token: string,
  organisationId: OrganisationId
) =>
  API.post<AdvisorExternal>({
    path: `/advisors/${id}/duplicate`,
    data: { name },
    token,
    organisationId,
  })

const preview = async (
  id: string,
  token: string,
  organisationId: OrganisationId
) =>
  API.post<AdvisorExternal>({
    path: `/advisors/${id}/preview`,
    token,
    organisationId,
  })

export type Release = {
  releasedAt: number
  status: "LIVE" | "OFFLINE"
  lastEdited: number
}
const latestRelease = async (
  id: string,
  token: string,
  organisationId: OrganisationId
): Promise<Release | undefined> =>
  API.get<Release>({
    path: `/advisors/${id}/latest-release`,
    token,
    organisationId,
  })
    .then((resp) => {
      return {
        releasedAt: resp.releasedAt,
        status: resp.status,
        lastEdited: resp.lastEdited,
      }
    })
    .catch(() => undefined)

const remove = async (
  id: string,
  token: string,
  organisationId: OrganisationId
) => API.delete({ path: `/advisors/${id}`, token, organisationId })

const suggestConversation = async (
  locale: string,
  productLabel: string,
  token: string,
  organisationId: OrganisationId
): Promise<
  {
    question: string
    answers: string[]
    multiple: boolean
    explanation: string
    label: string
  }[]
> =>
  API.post({
    path: `/advisors/suggest-conversation`,
    data: {
      productLabel,
      locale,
    },
    token,
    organisationId,
  })

const getProductCheckConfig = async (
  id: string,
  token: string,
  organisationId: OrganisationId
): Promise<{
  settings: {
    enabled: boolean
  }
}> =>
  API.get({
    path: `/advisors/${id}/product-check-config`,
    token,
    organisationId,
  })

export {
  create,
  all,
  get,
  getProductCheckConfig,
  update,
  changeMeta,
  suggestConversation,
  changeStyle,
  publish,
  remove,
  validation,
  conversationValidation,
  preview,
  duplicate,
  latestRelease,
}
