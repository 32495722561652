import Card from "~/components/Card"
import { ButtonLink } from "~/components/Button"
import React from "react"

const EmptyState: React.FC<{
  text: string
  button: string
  href: string
}> = ({ text, button, href }) => {
  return (
    <Card style={{maxWidth: "30rem", margin: "1rem 0"}}>
      <p>{text}</p>
      <ButtonLink primary to={href}>
        {button}
      </ButtonLink>
    </Card>
  )
}

export default EmptyState
