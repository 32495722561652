export const moveElem = <T extends unknown>(
  array: T[],
  from: number,
  to: number
) => {
  const clone = [...array]
  const ap = Array.prototype
  ap.splice.call(clone, to, 0, ap.splice.call(clone, from, 1)[0])
  return clone
}
