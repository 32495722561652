import useQuestion from "../../../hooks/useQuestion"
import React, { createRef, useEffect } from "react"
import { TextareaAutogrow } from "~/components/forms/Textarea"
import { QuestionType } from "../../../store/questions/types"
import { useTranslation } from "react-i18next"
import Label from "~/components/forms/Label"
import HelpText from "~/components/HelpText"
import QuestionLabelEditor from "~/components/QuestionLabelEditor"
import QuestionColorEditor from "~/components/QuestionColorEditor"
import { SingleSelect } from "~/components/selection"
import Tooltip from "~/components/Tooltip"
import SelectionUI from "~/components/selection/SelectionUI"
import singleQuestionImage from "~/images/tooltips/tooltip-single-question.jpg"
import multiQuestionImage from "~/images/tooltips/tooltip-multi-question.jpg"
import numericQuestionImage from "~/images/tooltips/tooltip-numeric-question.jpg"
import MarkdownTextarea from "../../MarkdownTextarea"
import { useAuth } from "~/context/auth"
import Conversation, {
  MatchingStat,
  QuestionMatchingStat,
} from "~/lib/Conversation"
import Modal, { ModalButtons, ModalContent } from "~/components/Modal"
import LoadingSpinner from "~/components/LoadingSpinner"
import { StatListContent } from "./ConversationsCanvas/DeleteQuestionButton"
import useQuestions from "~/hooks/useQuestions"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import Button from "~/components/Button"
import {
  DrilldownAnswerIcon,
  MultiAnswerIcon,
  NumericAnswerIcon,
  SingleAnswerIcon,
} from "~/components/icons/QuestionType"
import { INTRO_MAX_LENGTH, TITLE_MAX_LENGTH } from "./settings"

const ref = createRef<HTMLTextAreaElement>()

const QuestionEditor: React.FC<{
  advisorId: string
  questionId: string
}> = ({ questionId, advisorId }) => {
  const {
    question,
    changeQuestionTitle,
    changeQuestionLabel,
    changeQuestionColor,
    changeQuestionType,
    changeQuestionHelpText,
  } = useQuestion(advisorId, questionId)
  const { productLabel } = useAdvisorContext()

  const { questionsWithAnswers } = useQuestions(advisorId)
  const answers = questionsWithAnswers.find(
    (q) => q.id === question?.id
  )?.answers

  useEffect(() => {
    ref.current && ref.current.select()
  }, [questionId])

  const { t } = useTranslation()
  const { token, organisationId } = useAuth()

  const [state, setState] = React.useState<
    | { type: "normal" }
    | { type: "checking" }
    | {
        type: "confirmChange"
        stats: QuestionMatchingStat | MatchingStat | undefined
        chosenType: QuestionType
      }
  >({ type: "normal" })

  const transitionQuestionTypeTo = async (chosenType: QuestionType) => {
    if (!question) return
    if (chosenType === question.type) return

    const changingToSingleOrMulti =
      question.type === QuestionType.Numeric &&
      chosenType !== QuestionType.Numeric
    const changingToNumeric =
      (question.type === QuestionType.Single ||
        question.type === QuestionType.Multi) &&
      chosenType === QuestionType.Numeric

    if (changingToSingleOrMulti || changingToNumeric) {
      try {
        setState({ type: "checking" })
        const stats =
          question?.type === QuestionType.Numeric
            ? await Conversation(advisorId)(
                token!!,
                organisationId
              ).numericQuestionMatchingStats(question.id)
            : await Conversation(advisorId)(
                token!!,
                organisationId
              ).questionMatchingStats(question.id)

        if (stats.hasMatching) {
          setState({
            type: "confirmChange",
            stats: stats.hasMatching ? stats : undefined,
            chosenType: chosenType,
          })
        } else {
          changeQuestionType(chosenType)
          setState({ type: "normal" })
        }
      } catch (e) {
        setState({ type: "normal" })
      }
    } else {
      changeQuestionType(chosenType)
    }
  }
  const cancelTypeChange = () => setState({ type: "normal" })
  const confirmTypeChange = (type: QuestionType) => {
    changeQuestionType(type)
    setState({ type: "normal" })
  }

  if (!question) return null

  const iconFor = (type: QuestionType): React.ReactNode => {
    switch (type) {
      case QuestionType.Multi: {
        return <MultiAnswerIcon />
      }
      case QuestionType.Single: {
        return <SingleAnswerIcon />
      }
      case QuestionType.Numeric: {
        return <NumericAnswerIcon />
      }
      case QuestionType.Drilldown: {
        return <DrilldownAnswerIcon />
      }
      case QuestionType.AutoDrilldown: {
        return <DrilldownAnswerIcon />
      }
    }
    return null
  }

  const opts = [
    { value: QuestionType.Single, key: "single" },
    { value: QuestionType.Multi, key: "multiple" },
    { value: QuestionType.Numeric, key: "numeric" },
    { value: QuestionType.Drilldown, key: "drilldown" },
  ]

  return (
    <div data-test-id="question-editor">
      {state.type === "confirmChange" && (
        <>
          <Modal onClose={cancelTypeChange}>
            <ModalContent>
              <h2>Are you sure you want to change the question type?</h2>

              {state.stats && (
                <>
                  <p>
                    If you change to this type the following matching will be
                    removed:
                  </p>
                  <StatListContent
                    answers={answers || []}
                    productLabel={productLabel}
                    stats={state.stats}
                  />
                </>
              )}
              <ModalButtons>
                <Button onClick={cancelTypeChange}>{t("cancel")}</Button>
                <Button
                  primary
                  onClick={() => confirmTypeChange(state.chosenType)}
                >
                  {t("confirm")}
                </Button>
              </ModalButtons>
            </ModalContent>
          </Modal>
        </>
      )}

      <div>
        <Label>{t("questions", { count: 1 })}</Label>
      </div>
      <TextareaAutogrow
        ref={ref}
        onChange={(e: any) => changeQuestionTitle(e.target.value)}
        placeholder={t("questionsEditor.titlePlaceholder")}
        value={question.title}
        maxLength={TITLE_MAX_LENGTH}
        data-test-id="question-input"
      />
      <div>
        <Label>{t("questionsEditor.questionLabel")}</Label>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "max-content max-content",
          gap: "0.5rem",
        }}
      >
        <QuestionLabelEditor
          onChange={(e: any) =>
            changeQuestionLabel(e.target.value.slice(0, 24))
          }
          value={question.label}
          color={question.color}
        />
        <QuestionColorEditor
          onSelect={(value: any) => changeQuestionColor(value)}
          value={question.color}
        />
      </div>
      {question.label.length < 3 ? (
        <HelpText hasError>{t("questionsEditor.validations.label")}</HelpText>
      ) : (
        <HelpText>{t("questionsEditor.questionLabelHelp")}</HelpText>
      )}

      <MarkdownTextarea
        id={"add-info-" + question.id}
        label={t("questionsEditor.helpText")}
        isOptional={true}
        placeholder={t("questionsEditor.helpTextPlaceholder")}
        text={question.helpText}
        onChange={(value) => changeQuestionHelpText(value)}
        data-test-id="question-helptext-input"
        bounds={"#sidebar"}
        toolbar={["bold", "italic", "link", "list"]}
        maxLength={INTRO_MAX_LENGTH}
      />
      <HelpText>
        {
          "This text is shown underneath the question and can be used to provide more context (in max. 250 characters)."
        }
      </HelpText>

      <div>
        <Label>
          {t("questionsEditor.type")}{" "}
          {state.type === "checking" && <LoadingSpinner size="small" />}
        </Label>
      </div>

      {/* Fix required to correctly display tooltips  */}
      <img alt="" src={singleQuestionImage} style={{ display: "none" }} />
      <img alt="" src={multiQuestionImage} style={{ display: "none" }} />
      <img alt="" src={numericQuestionImage} style={{ display: "none" }} />

      <SingleSelect
        value={
          question.type === QuestionType.AutoDrilldown
            ? QuestionType.Drilldown
            : question.type
        }
        testId="question-type-input"
        disabled={state.type === "checking"}
        onSelect={(value: QuestionType) => transitionQuestionTypeTo(value)}
        options={opts.map((opt, i) => ({
          value: opt.value,
          label: (
            <div className="horizontal-group">
              <span className="muted-text">{iconFor(opt.value)}</span>{" "}
              {t(`questionsEditor.types.${opt.key}`)}
            </div>
          ),
        }))}
        listElement={(listOption) => {
          return (
            <Tooltip
              content={
                <>
                  <img
                    src={
                      listOption.value === "single"
                        ? singleQuestionImage
                        : listOption.value === "multi"
                        ? multiQuestionImage
                        : listOption.value === "numeric"
                        ? numericQuestionImage
                        : ""
                    }
                    alt=""
                  />
                  {t(`tooltips.questionTypes.${listOption.value}`)}
                </>
              }
              position="right center"
              enterDelay={500}
            >
              <SelectionUI.SelectOption>
                {listOption.label}
              </SelectionUI.SelectOption>
            </Tooltip>
          )
        }}
      />
    </div>
  )
}

export default QuestionEditor
