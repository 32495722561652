import styled from "styled-components"
import React, { useState } from "react"
import Edit from "./icons/Edit"
import { getCurrentColor } from "~/themes"
import { QuestionLabelColor } from "themes/types"

const QuestionLabelInputWrapper = styled.div<{ hasError?: boolean; col: QuestionLabelColor }>`
  position: relative;
  display: inline-flex;
  margin: 0 0 0 0;

  svg {
    height: 1.0625rem;
    position: absolute;
    right: 0.25rem;
    top: 0.6125rem;
    pointer-events: none;
    transition: opacity .2s;
  }

  button, input {
    -webkit-appearance: none;
    -moz-osx-appearance: none;
    appearance: none;
    padding:  ${(p) =>
      `${p.theme.labelPadding} 2rem ${p.theme.labelPadding} ${p.theme.labelPadding}`};
    border: 2px solid ${p => (p.hasError ? p.theme.error : p.theme.shade2)};
    border-radius: ${(p) => p.theme.borderRadius};
    font-weight: bold;
    color: ${(p) => p.col.text};
    background: ${(p) => p.col.color};
    transition: 200ms border-color ease-in;
  }
`

type StylingProps = { hasError?: boolean, color?: string }

export const QuestionLabelEditor: React.FC<React.HTMLProps<HTMLInputElement> &
  StylingProps> = props => {
  const [isEditing, setEditing] = useState(false)

  const color: QuestionLabelColor = getCurrentColor(props.color)

  return (
    <QuestionLabelInputWrapper
      data-test-id="question-label"
      hasError={props.hasError}
      col={color}
    >
      {isEditing ? (
        <input
          autoFocus
          value={props.value}
          onChange={props.onChange}
          onBlur={() => setEditing(false)}
        />
      ) : (
        <button onClick={() => setEditing(true)}>
          {props.value}
        </button>
      )}
      <Edit />
    </QuestionLabelInputWrapper>
  )
}

export default QuestionLabelEditor
