import { Route, Switch, useRouteMatch } from "react-router"
import React from "react"
import TabNav from "~/components/TabNav"
import RuleEditor from "./RuleEditor"
import QuestionEditor from "./QuestionEditor"
import AnswersEditor from "./AnswersEditor"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import MediaEditor from "./MediaEditor"
import useQuestion from "~/hooks/useQuestion"
import FlowExceptionsEditor from "~/elm-tsx-wrappers/FlowExceptionsEditor"

const Section = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`

const QuestionSidebar: React.FC<{
  advisorId: string
  questionId: string
}> = ({ questionId, advisorId }) => {
  const { url } = useRouteMatch()
  const { t } = useTranslation()

  const { question } = useQuestion(advisorId, questionId)
  if (!question) return null

  const tabs = (
    <>
      <Section>
        <TabNav
          withUnderLineBorder={true}
          exact={true}
          options={[
            {
              label: (
                <>
                  <span>{t("flowQuestionNav.content")}</span>
                </>
              ),
              link: `${url}`,
            },
            {
              label: (
                <>
                  <span>{t("flowQuestionNav.answers")}</span>
                </>
              ),
              link: `${url}/answers`,
            },
            {
              label: (
                <>
                  <span>{t("flowQuestionNav.media")}</span>
                </>
              ),
              link: `${url}/media`,
            },
            {
              label: (
                <>
                  <span>{t("flowQuestionNav.flow")}</span>
                </>
              ),
              link: `${url}/rules`,
            },
          ]}
        />
      </Section>
    </>
  )

  return (
    <Switch>
      <Route
        path={`${url}/rules`}
        exact
        render={({ match }) => (
          <>
            {tabs}
            <Section>
              <FlowExceptionsEditor
                advisorId={advisorId}
                questionId={questionId}
                key={questionId}
              />
              {/* <RuleEditor advisorId={advisorId} questionId={questionId} /> */}
            </Section>
          </>
        )}
      />
      <Route
        path={`${url}/answers`}
        exact
        render={({ match }) => (
          <>
            {tabs}
            <Section>
              <AnswersEditor questionId={questionId} advisorId={advisorId} />
            </Section>
          </>
        )}
      />
      <Route
        path={`${url}/media`}
        exact
        render={({ match }) => (
          <>
            {tabs}
            <Section>
              <MediaEditor questionId={questionId} advisorId={advisorId} />
            </Section>
          </>
        )}
      />
      <Route
        path={`${url}`}
        exact
        render={({ match }) => (
          <>
            {tabs}
            <Section>
              <QuestionEditor questionId={questionId} advisorId={advisorId} />
            </Section>
          </>
        )}
      />
    </Switch>
  )
}

export default QuestionSidebar
