import React, { useState, useEffect } from "react"
import {
  ProgressBar,
  SelectDataFileStep,
  UploadStep,
} from "../SharedComponents"
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
} from "react-router"
import Table from "~/components/Table"
import { useTranslation } from "react-i18next"
import ReviewChanges from "./ReviewChanges"
import { Invalid } from "~/components/icons"
import {
  missingColumns as missingColumnsFn,
  ValidColumnMapping,
} from "~/lib/ColumnMapping"
import { DataSource } from "~/types"

const UpdateExistingData: React.FC<{
  dataSource: DataSource
  onUpload: (datasource: DataSource) => void
}> = (props) => {
  const { dataSource } = props
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const match = useRouteMatch(`${url}/:step`)
  const current = parseInt(match?.params["step"]) || 1
  const history = useHistory()

  const [file, setFile] = useState<File | undefined>(undefined)
  const [headers, setHeaders] = useState<string[]>([])
  const [uploaded, setUploaded] = useState(false)
  const [data, setData] = useState<string[][]>([])
  const [delimiter, setDelimiter] = useState<string>(",")
  const columnMapping = dataSource.columnMapping
  const previewData = data.slice(0, 10)

  let missingColumns: (string | undefined)[] = []
  if (
    columnMapping.id &&
    columnMapping.name &&
    columnMapping.image &&
    columnMapping.url
  ) {
    missingColumns = missingColumnsFn(
      headers,
      columnMapping as ValidColumnMapping
    )
  } else {
    throw Error("ColumnMapping is invalid: Missing required column mappings")
  }

  const validHeaders = !!headers.length && missingColumns.length === 0

  const step1Completed = uploaded || (validHeaders && !!data.length)
  const step2Completed =
    uploaded || (Object.values(columnMapping).every((v) => v) && current >= 2)
  const step3Completed = uploaded

  const gotoStep = (step: number) => () => history.push(`${url}/${step}`)
  const back = () => history.goBack()
  const goBack = () => history.goBack()
  const step1Next = step1Completed ? gotoStep(2) : undefined
  const step2Next = step2Completed ? gotoStep(3) : undefined

  useEffect(() => {
    if (uploaded) {
      setUploaded(true)
      setFile(undefined)
      setData([])
      setHeaders([])
      if (current !== 3) setUploaded(false)
    }
  }, [current, uploaded])

  const idHeaderIndex = columnMapping.id
    ? headers.indexOf(columnMapping.id)
    : undefined
  const ids: string[] =
    idHeaderIndex !== undefined ? data.map((d) => d[idHeaderIndex]) : []

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "30rem minmax(0, 1fr)",
        gap: "2rem",
      }}
      data-test-id="datasource-update-flow"
    >
      <div>
        <div className="card">
          <ProgressBar
            current={current}
            steps={[
              {
                label: t("productDataset.update.step1.progressLabel"),
                nav: gotoStep(1),
                completed: step1Completed,
              },
              {
                label: t("productDataset.update.step2.progressLabel"),
                nav: step1Completed ? gotoStep(2) : undefined,
                completed: step2Completed,
              },
              {
                label: t("productDataset.update.step3.progressLabel"),
                nav: undefined,
                completed: step3Completed,
              },
            ]}
          />
          <Switch>
            <Route
              path={`${url}/1`}
              render={() => (
                <SelectDataFileStep
                  fileName={file?.name || ""}
                  setFile={setFile}
                  setHeaders={setHeaders}
                  setData={setData}
                  setDelimiter={setDelimiter}
                  back={goBack}
                  next={step1Next}
                  introContents={<p>{t("productsUpload.intro")}</p>}
                  title={t("productDataset.update.step1.title")}
                  infoBarContents={
                    <>
                      <p>{t("productDataset.update.step1.info.1")}</p>
                      <ul>
                        {Object.entries(columnMapping).map(([k, v]) => (
                          <li key={k}>{v}</li>
                        ))}
                      </ul>
                    </>
                  }
                ></SelectDataFileStep>
              )}
            />
            {step1Completed && (
              <Route
                path={`${url}/2`}
                render={() => (
                  <ReviewChanges
                    ids={ids}
                    previous={back}
                    next={step2Next}
                    dataSource={dataSource}
                  />
                )}
              />
            )}
            {step1Completed && step2Completed && dataSource && (
              <Route
                path={`${url}/3`}
                render={() => (
                  <UploadStep
                    existingDataSource={dataSource}
                    delimiter={delimiter}
                    updateDataSource={(dataSource) => {
                      props.onUpload(dataSource)
                      return dataSource
                    }}
                    columnMapping={columnMapping}
                    errorBtnFn={() => gotoStep(1)}
                    file={file}
                    uploadCompletedCallback={() => setUploaded(true)}
                  />
                )}
              />
            )}
            <Redirect to={`${url}/1`} push={false} />
          </Switch>
        </div>
      </div>
      {!!headers.length && (
        <div>
          <h2>{t("preview")}</h2>
          {!validHeaders && (
            <p>
              <Invalid />
              {t("productDataset.update.step1.validationMessage")}
              <strong>{missingColumns.join(", ") + "."}</strong>
            </p>
          )}
          <div className="card no-padding-v">
            <Table headers={headers} data={previewData} />
          </div>
        </div>
      )}
    </div>
  )
}

export default UpdateExistingData
