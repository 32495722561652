import { ApplicationState } from ".."
import { AdviceScreen, ConversationScreens, InfoScreen } from "."

export const getScreens = (
  state: ApplicationState,
  advisorId: string
): ConversationScreens | undefined => state.screens[advisorId]

export const getAdviceScreen = (
  state: ApplicationState,
  advisorId: string
): AdviceScreen | undefined => getScreens(state, advisorId)?.advice

export const getInfoScreens = (
  state: ApplicationState,
  advisorId: string
): InfoScreen[] => {
  const flow = state.flow[advisorId]
  const infoScreens = state.screens[advisorId]?.infoScreens || []
  return infoScreens.map((infoScreen) => {
    return {
      ...infoScreen,
      next: flow?.nodes[infoScreen.id],
    }
  })
}
