import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import { useAuth } from "~/context/auth"
import JWT from "~/lib/JWT"
import { EnvVar, envVar } from "~/utils/environment"
import React from "react"
import useNavigation from "~/hooks/useNavigation"
import { useImpactAnalytics } from "~/hooks/useImpactAnalytics"
import LoadingSpinner from "~/components/LoadingSpinner"

const AppImpactAnalytics: React.FC<{}> = () => {
  const { token } = useAuth()
  const { organisationId } = JWT.parseValues(token!)
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string
  const {
    apiKey,
    isConfigured,
    timeConfigured,
    setConfigurationComplete,
    loading,
  } = useImpactAnalytics()
  const [ports, setupPorts] = React.useState<any>(undefined)

  if (!apiKey) return null
  if (loading) return <LoadingSpinner />
  return (
    <Elm
      src={ElmModule.AppImpactAnalytics}
      flags={{
        authToken: token,
        organisationId: organisationId,
        apiUrl: apiUrl,
        impactAnalyticsTimeConfigured: timeConfigured || null,
        impactAnalyticsApiKey: apiKey || null,
      }}
      ports={setupPorts}
    />
  )
}

export default AppImpactAnalytics
