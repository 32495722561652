import i18n from "~/i18n"

const MonthMap = {
  1: "januari",
  2: "february",
  3: "march",
  4: "april",
  5: "may",
  6: "june",
  7: "july",
  8: "august",
  9: "september",
  10: "october",
  11: "november",
  12: "december",
}

export default {
  formatDate: (timestamp: number | string | undefined): string => {
    if (!timestamp) return ""
    const d = new Date(timestamp)

    const month = MonthMap[d.getMonth() + 1],
      day = "" + d.getDate(),
      year = d.getFullYear()

    return i18n.t("date.format", {
      day,
      month: i18n.t(`months.${month}`),
      year,
    })
  },
  format: (timestamp: number | undefined, now: Date = new Date()): string => {
    // Make a fuzzy time
    if (!timestamp) return ""

    const d = new Date(timestamp)
    const delta = Math.round((+now - d.getTime()) / 1000)

    const minute = 60,
      hour = minute * 60,
      day = hour * 24,
      week = day * 7,
      month = day * 30,
      year = month * 12

    let fuzzy: string

    if (delta < 30) {
      // Single second
      fuzzy = i18n.t("datetime.now", { count: delta })
    } else if (delta < minute) {
      // Seconds
      fuzzy = i18n.t("datetime.seconds", { count: delta })
    } else if (delta < 2 * minute) {
      // Single minute
      fuzzy = i18n.t("datetime.minutes", { count: 1 })
    } else if (delta < hour) {
      // Multiple minutes
      fuzzy = i18n.t("datetime.minutes", { count: Math.floor(delta / minute) })
    } else if (Math.floor(delta / hour) === 1) {
      // Single hours
      fuzzy = i18n.t("datetime.hours", { count: 1 })
    } else if (delta < day) {
      // Multiple hours
      fuzzy = i18n.t("datetime.hours", { count: Math.floor(delta / hour) })
    } else if (delta < week) {
      fuzzy = i18n.t("datetime.days", { count: Math.floor(delta / day) })
    } else if (delta < month) {
      fuzzy = i18n.t("datetime.weeks", { count: Math.floor(delta / week) })
    } else if (delta < year) {
      fuzzy = i18n.t("datetime.months", { count: Math.floor(delta / month) })
    } else {
      // const month = "" + (d.getMonth() + 1),
      //   day = "" + d.getDate(),
      //   year = d.getFullYear(),
      //   hours = "" + d.getHours(),
      //   minutes = "" + d.getMinutes()

      // const p = (str: string) => str.padStart(2, "0")

      // fuzzy = `${year}-${p(month)}-${p(day)} ${p(hours)}:${p(minutes)}`
      fuzzy = i18n.t("datetime.years", { count: Math.floor(delta / year) })
    }
    return fuzzy
  },
}
