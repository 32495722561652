import { ActionType } from "typesafe-actions"
import * as actions from "./actions"
import * as constants from "./constants"

export const CONSTANTS = Object.values(constants)

export * from "./constants"
export * from "./actions"
export * from "./actionCreators"
export type AdvisorActionTypes = ActionType<typeof actions>
