import {
  AdvisorActionTypes,
  FETCH_ADVISOR_SUCCESS,
  NEW_ADVISOR_SUCCESS,
  UPDATE_ADVISOR,
  UPDATE_ADVISOR_FAILURE,
  UPDATE_ADVISOR_SUCCESS,
}from "~/actions/advisors"

export enum AdvisorSavedState {
  PENDING = "PENDING",
  FAILED = "FAILED",
  SAVED = "SAVED",
}

type SavingState = Record<
  string,
  {
    state: AdvisorSavedState
    lastSaved: Date
  }
>

export default (
  state: SavingState = {},
  action: AdvisorActionTypes
): SavingState => {
  switch (action.type) {
    case NEW_ADVISOR_SUCCESS: {
      return {
        ...state,
        [action.payload.advisor.id]: {
          state: AdvisorSavedState.SAVED,
          lastSaved: new Date(),
        },
      }
    }
    case UPDATE_ADVISOR: {
      return {
        ...state,
        [action.payload.advisorId]: {
          ...state[action.payload.advisorId],
          state: AdvisorSavedState.PENDING,
        },
      }
    }
    case UPDATE_ADVISOR_FAILURE: {
      return {
        ...state,
        [action.payload.advisorId]: {
          ...state[action.payload.advisorId],
          state: AdvisorSavedState.FAILED,
        },
      }
    }
    case FETCH_ADVISOR_SUCCESS:
    case UPDATE_ADVISOR_SUCCESS: {
      return {
        ...state,
        [action.payload.advisor.id]: {
          state: AdvisorSavedState.SAVED,
          lastSaved: new Date(),
        },
      }
    }

    default:
      return state
  }
}
