import { useHistory } from "react-router"
import { DataSource } from "~/types"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar } from "~/utils/environment"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { questionLabelColors } from "~/themes"
import ElmWrapper from "./ElmWrapper"
import { useSelector } from "react-redux"
import { ApplicationState } from "~/store"
import { getAdvisor } from "~/store/advisors/selectors"

export const Catalogue: React.FC<{
  advisorId: string
  dataSource: DataSource
}> = (props) => {
  const history = useHistory()

  const [ports, setPorts] = React.useState<any>()
  const { token: authToken } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string

  const advisorLanguage: string = useSelector(
    (state: ApplicationState) =>
      getAdvisor(state, props.advisorId)?.locales[0] || "en-US"
  )

  const { productLabel } = useAdvisorContext()

  function setupPorts(ports) {
    setPorts(ports)
  }

  React.useEffect(() => {
    return () => {
      if (ports) ports.stop.send(true)
    }
  }, [ports])

  return (
    // @ts-ignore
    <ElmWrapper
      src={ElmModule.Catalogue}
      flags={{
        advisorId: props.advisorId,
        dataSource: props.dataSource,
        authToken: authToken,
        apiUrl: apiUrl,
        advisorTitle: "advisorTitle",
        catalogueTitle: productLabel,
        productLabel: productLabel,
        url: window.location.href,
        questionLabelColors: questionLabelColors,
        advisorLanguage: advisorLanguage,
      }}
      ports={setupPorts}
    />
  )
}
