import {
  Column,
  ColumnType,
  Combinator,
  Filter,
  FilterAPIObj,
  FilterChainT,
  FilterKind,
  FilterSource,
} from "./types"
import { assertUnreachable } from "~/lib/TSHelpers"

const isCombinator = (val: any): val is Combinator =>
  Object.values(Combinator).includes(val)

const isFilter = (val: any): val is Filter =>
  !isCombinator(val) && typeof val === "object" && "column" in val

const filterKindForColumn = (column: Column) => {
  switch (column.type) {
    case ColumnType.TEXT:
      return FilterKind.MULTI
    case ColumnType.NUMBER:
      return FilterKind.NUMERIC_RANGE
  }
  return assertUnreachable(column.type)
}


const filterAPIObjFrom = (
  filterChain: FilterChainT
): FilterAPIObj | undefined => {
  if (filterChain.length === 0) {
    return undefined
  } else if (filterChain.length === 1) {
    const f = filterChain[0]
    return isFilter(f) ? filterObjFromFilter(f) : undefined
  } else {
    const [filter, combinator, ...tail] = filterChain
    return isFilter(filter) && isCombinator(combinator)
      ? filterObjFromFilter(filter, combinator, tail)
      : undefined
  }
}

export const withInactiveFilter = (f: FilterAPIObj): FilterAPIObj => {
  return {
      // @ts-ignore
    source: undefined,
    column: "active",
    kind: FilterKind.SINGLE,
    combinator: Combinator.AND,
    values: ["true"],
    filter: f,
  }
}

const filterObjFromFilter = (
  f: Filter,
  combinator = Combinator.AND,
  tail: FilterChainT = []
): FilterAPIObj => ({
  source:
    f.type === "productColumn" ? FilterSource.FIELDS : FilterSource.MAPPING,
  column: f.column!.name,
  kind: filterKindForColumn(f.column!),
  combinator: combinator,
  values: f.values,
  filter: filterAPIObjFrom(tail),
})
