import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import {
  Advisor,
  InfoPageCustomerInfoConfig,
  NoResultsCustomerInfoConfig,
} from "~/store/advisors"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"
import useInfoScreen from "~/hooks/useInfoScreen"
import ScreenCustomerInfoEditor from "~/components/advisors/QandA/ScreenEditor/ScreenCustomerInfoEditor"
import { defaultInfoPageCustomerInfoConfig } from "~/lib/Advisor"
import FieldTextArea from "~/components/forms/FieldTextArea"
import HelpText from "~/components/HelpText"

const InfoScreenCustomerInfoEditor: React.FC<{ advisor: Advisor, screenId: string }> = ({ advisor, screenId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { featureFlags } = useFeatureFlags()
  const { screen, changeScreenCustomerInfo } = useInfoScreen(advisor.id, screenId)

  const customerInfo: InfoPageCustomerInfoConfig = screen.customerInfo || defaultInfoPageCustomerInfoConfig()

  const updateCustomerInfo = React.useCallback(
    (customerInfo: InfoPageCustomerInfoConfig | NoResultsCustomerInfoConfig) => {
      if (!("formIdentifier" in customerInfo)) return;
      featureFlags.customerInfoCollectionEnabled &&
      dispatch(changeScreenCustomerInfo(customerInfo))
    },
    [advisor.id, dispatch]
  )
  const identifierField = (<>
    <FieldTextArea
      disabled={!customerInfo.showForm}
      value={customerInfo.formIdentifier}
      label={t("screenEditor.customerInfo.infoScreen.identifierLabel")}
      maxLength={70}
      hasError={
        customerInfo.showForm && customerInfo.formIdentifier.length > 70
      }
      onChange={(value) =>
        updateCustomerInfo({
          ...customerInfo,
          formIdentifier: value,
        })
      }
    />
    <HelpText>{t("screenEditor.customerInfo.infoScreen.identifierHelp")}</HelpText>
  </>)

  return (
    <ScreenCustomerInfoEditor customerInfo={customerInfo} updateCustomerInfo={updateCustomerInfo} extraFormFields={identifierField} />
  )
}

export default InfoScreenCustomerInfoEditor
