import { useCallback, useEffect, useState } from "react"
import { dataSources } from "~/api"
import { useAuth } from "~/context/auth"
import { Product } from "~/types"

export function useProductOptions(
  advisorId: string,
  shouldLoad: boolean,
  offset: number = 0,
  size: number = 100
): {
  refresh: () => void
  loading: boolean
  products: {
    id: string
    productId: string
    name: string
    fields: Record<string, (string | number)[]>
  }[]
} {
  const { token, organisationId } = useAuth()
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<
    {
      id: string
      productId: string
      name: string
      fields: Record<string, (string | number)[]>
    }[]
  >([])

  const load = useCallback(() => {
    setLoading(true)
    dataSources
      .all(advisorId, token!!, organisationId)
      .then((resp) => {
        const ds = resp[0]
        if (ds) {
          dataSources
            .get(advisorId, ds.id, token!!, organisationId)
            .then((ds) =>
              dataSources
                .activeProducts(
                  advisorId,
                  ds.id,
                  offset,
                  size,
                  token!!,
                  organisationId
                )
                .then((productsResponse) =>
                  productsResponse.results
                    .map((p) => {
                      return {
                        ...p,
                        title: p.name,
                        // (p.data[ds.columnMapping["name"] || ""] || [])[0] ||
                        // "",
                      }
                    })
                    .sort((a, b) => {
                      if (a.title < b.title) return -1
                      if (a.title > b.title) return 1
                      return 0
                    })
                )
            )
            .then((products) => {
              setProducts(products)
            })
            .finally(() => {
              setLoading(false)
            })
        } else {
          setProducts([])
          //handle no catalogue case
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [advisorId, token, organisationId, offset, size])
  useEffect(() => {
    if (shouldLoad) {
      load
    }
  }, [shouldLoad])

  return {
    refresh: load,
    loading: loading,
    products: products,
  }
}
