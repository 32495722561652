import React from "react"
import Popup from "reactjs-popup"

// can be replaced with custom styling
import { PopupPosition, EventType } from "reactjs-popup/dist/types"
import styled from "styled-components"

const StyledPopup = styled(Popup)`
  &-arrow {
    -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
    color: ${(p) => p.theme.main};
    stroke-width: 2px;
    stroke: ${(p) => p.theme.main};
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
  }
  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

  &-overlay {
    /* background: rgba(0, 0, 0, 0.5); */
  }
  &-content {
    margin: auto;
    background: ${(p) => p.theme.main};
    border: 1px solid ${(p) => p.theme.main};
    border-radius: 0.25rem;
    color: #fff;
    max-width: 32rem;
    padding: 1rem;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    font-weight: 500;
    font-size: .875rem;

    img {
      max-width: 100%;
      margin: 0 auto 1rem;
      display: block;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Tooltip: React.FC<{
  position?: PopupPosition
  content: React.ReactNode
  on?: EventType | EventType[]
  enterDelay?: number
  leaveDelay?: number
  defaultOpen?: boolean
  open?: boolean
}> = (props) => (
  <StyledPopup
    trigger={(open) => <span>{props.children}</span>}
    position={props.position || "top center"}
    on={props.on || ["hover", "focus"]}
    defaultOpen={props.defaultOpen}
    open={props.open}
    modal={false}
    arrow={props.position !== "center center"}
    mouseEnterDelay={props.enterDelay || 100}
    mouseLeaveDelay={props.leaveDelay || 100}
    keepTooltipInside
    repositionOnResize
    closeOnDocumentClick
  >
    {props.content}
  </StyledPopup>
)

export default Tooltip
