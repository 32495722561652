
export enum Role {
  Admin = "ADMIN",
  Contributor = "CONTRIBUTOR",
}

export type Avatar = {
  extension: string
  sizes: string[]
  supportedExtensions: string[]
  type: string
  urls: Record<string, string>
}

export type Account = {
  id: string
  email: string
  firstName: string
  familyName: string
  profileImage: Avatar | undefined
  updatedAt: number | undefined
  role: Role
  emailPreferences: EmailPreferences
}

export type EmailPreferences = {
  sendPeriodicUsageRecap: boolean
}
