import React, { useState } from "react"
import { accounts } from "~/api"
import { useAuth } from "~/context/auth"

export const useImpactAnalytics = () => {
  const [apiKey, setApiKey] = useState<string | undefined>(undefined)
  const [configured, setConfigured] = useState(false)
  const [timeConfigured, setTimeConfigured] = useState<number | undefined>(
    undefined
  )
  const [loading, setLoading] = useState(true)

  const { token, organisationId } = useAuth()

  React.useEffect(() => {
    setLoading(true)
    accounts
      .get(token!!, organisationId)
      .then((resp) => {
        setApiKey(resp.organisationSettings.impactAnalyticsApiKey || undefined)
        setConfigured(resp?.integrationStatus.purchaseTracking === "OK")
        setTimeConfigured(
          resp?.integrationStatus.purchaseTrackingTimeConfigured || undefined
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [token, organisationId])

  const setConfigurationComplete = React.useCallback(() => {
    setConfigured(true)
    setTimeConfigured(new Date().getTime())
  }, [setConfigured, setTimeConfigured])

  return {
    apiKey: apiKey,
    loading: loading,
    isConfigured: configured,
    timeConfigured: timeConfigured,
    setConfigurationComplete: setConfigurationComplete,
  }
}
