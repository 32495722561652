import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Page from "./Page"
import OrganisationName from "~/components/OrganisationName"
import { PageHeader } from "~/components/advisors/Page"
import ElmAdvisorsOverview from "~/elm-tsx-wrappers/AppOverview"
import { envVar, EnvVar } from "~/utils/environment"
import { useAuth } from "~/context/auth"
import useAdvisors from "~/hooks/useAdvisors"
import useMountEffect from "~/hooks/useMountEffect"
import { endOfDay, startOfDay, addDays } from "date-fns/esm"
import useNavigation from "~/hooks/useNavigation"

const Divider = styled.div`
  display: flex;
  flex-flow: column-reverse;

  > div:first-child {
    flex: 1;
  }
`

const Advisors: React.FC<{
  newAdvisor: () => void
}> = ({ newAdvisor }) => {
  const { t } = useTranslation()
  const { token } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string
  const [{ start, end }] = React.useState({
    end: endOfDay(addDays(new Date(), -1)),
    start: startOfDay(addDays(new Date(), -30)),
  })
  const { getAdvisors } = useAdvisors()
  const [showMetrics, setShowMetrics] = React.useState(false)
  const { map } = useNavigation()

  useMountEffect(() => {
    token &&
      getAdvisors(1).then(({ total }) =>
        total >= 1 ? setShowMetrics(true) : setShowMetrics(false)
      )
  })

  return (
    <Page maxWidth style={{ margin: "0 auto" }}>
      <PageHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <OrganisationName style={{ marginLeft: "0" }} />
        </div>
      </PageHeader>

      <Divider>
        <ElmAdvisorsOverview />
      </Divider>
    </Page>
  )
}

export default Advisors
