import React from "react"
import { Emoji } from "emoji-mart"

type EmojiProps = {
  size?: number
  emoji: string
}

export default (props: EmojiProps) => {
  return <Emoji emoji={props.emoji} set="apple" size={props.size || 32} />
}
