import APIBase from "./APIBase"
import { envVar, EnvVar }from "~/utils/environment"

const domain = envVar(EnvVar.ADVISOR_API_URL) || ""

/**
 * API Wrapper around Axios to absract Axios away.
 * Use this instead of Axios directly.
 */
const API = APIBase(domain as string)

export default API
