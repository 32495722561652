import { useSelector } from "react-redux"
import { ApplicationState }from "~/store"
import { getInfoScreens } from "../../store/screens/selectors"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { action, ActionType } from "typesafe-actions"
import { changeRuleTarget, removeRule } from "../rules"
import { UUID }from "~/store/types"
import { AdvisorMeta }from "~/actions/advisors"
import { Rule }from "~/store/rules"

export const ADD_AFTER = "@@flow/ADD_AFTER"
export const PROPOSE_CHANGE = "@@flow/PROPOSE_CHANGE"
export const CLEAR_CHANGES = "@@flow/CLEAR_CHANGES"
export const MOVE = "@@flow/MOVE"
export const REMOVE = "@@flow/REMOVE"
export const REMOVE_EXCEPTION = "@@flow/REMOVE_EXCEPTION"
export const CHANGE_EXCEPTION = "@@flow/CHANGE_EXCEPTION"
export const CONSTANTS = [
  MOVE,
  ADD_AFTER,
  // ADD_IN_EXCEPTION,
  REMOVE,
  REMOVE_EXCEPTION,
  CHANGE_EXCEPTION,
]

export const clearChanges = (advisorId: string) =>
  action(CLEAR_CHANGES, {
    advisorId,
  })

export const proposeChange = (
  fromId: string,
  toId: string,
  meta: AdvisorMeta
) =>
  action(PROPOSE_CHANGE, {
    fromId,
    toId,
    ...meta,
  })

export const changeException = (
  ruleId: string,
  newTarget: string | undefined,
  meta: AdvisorMeta
) =>
  action(CHANGE_EXCEPTION, {
    ruleId,
    newTarget,
    ...meta,
  })

export const removeException = (ruleId: string, meta: AdvisorMeta) =>
  action(REMOVE_EXCEPTION, {
    ruleId,
    ...meta,
  })

export const addAfter = (
  toAdd: Movable,
  beforeInFlow: UUID | undefined,
  meta: AdvisorMeta
) =>
  action(ADD_AFTER, {
    toAdd,
    beforeInFlow,
    ...meta,
  })

export const addAfterCreator =
  (toAdd: Movable, beforeInFlow: UUID | undefined, meta: AdvisorMeta) =>
  (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => ApplicationState
  ) => {
    dispatch(addAfter(toAdd, undefined, meta))
    dispatch(move(toAdd, beforeInFlow, meta))
  }

export const move = (
  toMove: {
    id: string
    next: string
  },
  previousId: UUID | undefined,
  meta: AdvisorMeta
) =>
  action(MOVE, {
    toMove,
    previousId,
    ...meta,
  })

export const addInException =
  (toAdd: Movable, ruleId: UUID, meta: AdvisorMeta) =>
  (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => ApplicationState
  ) => {
    dispatch(addAfter(toAdd, undefined, meta))
    dispatch(move(toAdd, undefined, meta))
    dispatch(changeRuleTarget(ruleId, toAdd.id, { advisorId: meta.advisorId }))
  }

export const actions = {
  addAfter,
  move,
  changeException,
  removeException,
  clearChanges,
  proposeChange,
}
export type FlowActionTypes = ActionType<typeof actions>

export const Flow = {
  addAfter,
  addAfterCreator,
  move,
  addInException,
  useInfoScreens: (advisorId: string) => {
    return useSelector((state: ApplicationState) =>
      getInfoScreens(state, advisorId)
    )
  },
}

export type Movable = {
  id: string
  type: "question" | "infoScreen"
  next: string
}
/**
 * Move question and possibly change any rules
 * */
export const changeFlow =
  (movable: Movable, prevId: UUID | undefined, meta: AdvisorMeta) =>
  (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => ApplicationState
  ) => {
    const store = getState()
    const questions = Object.values(store.questions)

    dispatch(move(movable, prevId, meta))
    adjustRulesForChange(
      Object.values(store.rules),
      movable,
      (ruleId) => questions.find((q) => q.rules.includes(ruleId)),
      meta
    )(dispatch)
  }

const adjustRulesForChange =
  (
    rules: Rule[],
    nodeToMove: Movable,
    nodeForRule: (
      ruleId: string
    ) => { id: string; next: string | undefined } | undefined,
    meta: AdvisorMeta
  ) =>
  (dispatch) => {
    rules
      // Find all rules that point to the affectedMovable
      .filter((r) => r.targetQuestionId === nodeToMove.id)
      .forEach((rule) => {
        const node = nodeForRule(rule.id)

        // Check if the next thing of the thing being moved is the same as the
        // next thing of the thing on which the rule is defined, then the rule is
        // obsolete and should be deleted
        if (node && node.next === nodeToMove.next) {
          dispatch(removeRule(node.id, rule.id, meta))
        } else if (nodeToMove.next) {
          dispatch(changeRuleTarget(rule.id, nodeToMove.next, meta))
        }
      })
  }
