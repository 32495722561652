import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import {
  AutoDrilldownQuestion,
  DrilldownQuestion,
  QuestionType,
} from "~/store/questions"
import { UUID } from "~/store/types"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar } from "~/utils/environment"
import {
  autoDrilldownConfigChanged,
  changeQuestionType,
  drilldownConfigChanged,
} from "~/actions/questions"
import { useDispatch } from "react-redux"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { addAnswer, removeAnswer } from "~/actions/answers"
import { v4 as uuid } from "uuid"

type DrilldownAnswerEditorProps = {
  advisorId: UUID
  question: DrilldownQuestion | AutoDrilldownQuestion
}

const DrilldownAnswerEditor: React.FC<DrilldownAnswerEditorProps> = ({
  advisorId,
  question,
}) => {
  const dispatch = useDispatch()
  const [ports, setPorts] = React.useState<any>(undefined)
  const { token } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string
  const { productLabel } = useAdvisorContext()

  React.useEffect(() => {
    if (ports) {
      const handler = (config) => {
        if ("answers" in config) {
          dispatch(drilldownConfigChanged(question.id, config, { advisorId }))
        } else {
          dispatch(
            autoDrilldownConfigChanged(question.id, config, { advisorId })
          )
        }
      }
      ports.updatedDrilldownConfigPort.subscribe(handler)

      const changeTypeHandler = (type: string) => {
        if (type.toLowerCase() === "manual") {
          dispatch(
            changeQuestionType(question.id, QuestionType.Drilldown, {
              advisorId,
            })
          )
          question.answers.forEach((answer) => {
            dispatch(removeAnswer(question.id, answer, { advisorId }))
          })
        } else if (type.toLowerCase() === "auto") {
          dispatch(
            changeQuestionType(question.id, QuestionType.AutoDrilldown, {
              advisorId,
            })
          )
          dispatch(
            addAnswer(
              question.id,
              uuid(),
              "My answer isn't listed",
              "",
              "",
              true,
              { advisorId }
            )
          )
        }
      }

      ports.drilldownTypeChanged.subscribe(changeTypeHandler)

      return () => {
        ports.updatedDrilldownConfigPort.unsubscribe(handler)
        ports.drilldownTypeChanged.unsubscribe(changeTypeHandler)
      }
    }
  }, [advisorId, dispatch, ports, question.answers, question.id])

  const seeds = React.useMemo(
    () => Array.from(crypto.getRandomValues(new Uint32Array(4))),
    []
  )
  return (
    // @ts-ignore
    <Elm
      src={ElmModule.DrilldownAnswerEditor}
      flags={{
        advisorId: advisorId,
        authToken: token,
        apiUrl: apiUrl,
        questionId: question.id,
        drilldownConfig: question.config,
        seeds: seeds,
        productLabel: productLabel,
      }}
      ports={setPorts}
    />
  )
}

export default DrilldownAnswerEditor
