import React from "react"
// <rect className="border" width="425" height="405" x="13" y="13" stroke="#008DFF" strokeWidth="30" rx="76"/>

export const InPage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="319"
    height="308"
    viewBox="0 0 319 308"
  >
    <g fill="none" fillRule="evenodd">
      <rect
        width="312"
        height="301"
        x="4"
        y="4"
        fill="#FFF"
        stroke="#B7DFFF"
        strokeWidth="7"
        rx="36"
      />
      <rect
        width="76"
        height="34"
        x="103"
        y="189"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="51"
        height="34"
        x="33"
        y="189"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="51"
        height="34"
        x="33"
        y="133"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="51"
        height="34"
        x="33"
        y="77"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="51"
        height="34"
        x="33"
        y="245"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="251"
        height="22"
        x="33"
        y="33"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="86"
        height="34"
        x="198"
        y="189"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="76"
        height="34"
        x="103"
        y="245"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="86"
        height="34"
        x="198"
        y="245"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="181"
        height="92"
        x="103"
        y="77"
        fill="#008DFF"
        fillRule="nonzero"
        rx="4"
      />
    </g>
  </svg>
)

export const FullPage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="319"
    height="308"
    viewBox="0 0 319 308"
  >
    <g fill="none" fillRule="evenodd">
      <rect
        width="312"
        height="301"
        x="4"
        y="4"
        fill="#FFF"
        stroke="#B7DFFF"
        strokeWidth="7"
        rx="36"
      />
      <rect
        width="251"
        height="22"
        x="33"
        y="33"
        fill="#B7DFFF"
        fillRule="nonzero"
        rx="4"
      />
      <rect
        width="251"
        height="202"
        x="33"
        y="77"
        fill="#008DFF"
        fillRule="nonzero"
        rx="4"
      />
    </g>
  </svg>
)
export const Sidebar = () => (
  <svg
    width="312px"
    height="301px"
    viewBox="0 0 312 301"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="new-advisor-flow"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icn" fillRule="nonzero">
        <rect
          id="Rectangle"
          stroke="#B7DFFF"
          fill="#FFFFFF"
          x="0"
          y="0"
          width="312"
          height="301"
          rx="36"
        ></rect>
        <rect
          id="Rectangle-Copy-12"
          fill="#B7DFFF"
          x="99"
          y="106"
          width="181"
          height="169"
          rx="4"
        ></rect>
        <rect
          id="Rectangle"
          fill="#B7DFFF"
          x="29"
          y="106"
          width="51"
          height="169"
          rx="4"
        ></rect>
        <rect
          id="Rectangle"
          fill="#B7DFFF"
          x="29"
          y="29"
          width="251"
          height="60"
          rx="4"
        ></rect>
        <rect
          id="Rectangle"
          fill="#B7DFFF"
          opacity="0.3"
          x="194"
          y="185"
          width="86"
          height="34"
          rx="4"
        ></rect>
        <rect
          id="Rectangle"
          fill="#B7DFFF"
          opacity="0.3"
          x="194"
          y="241"
          width="86"
          height="34"
          rx="4"
        ></rect>
        <path
          d="M191,0 L276,0 C295.882251,-3.65231026e-15 312,16.117749 312,36 L312,265 C312,284.882251 295.882251,301 276,301 L191,301 L191,301 L191,0 Z"
          id="Rectangle"
          fill="#008DFF"
        ></path>
      </g>
    </g>
  </svg>
)
