import { EnvVar, envVar } from "~/utils/environment"
const webshopPreviewURL = envVar(EnvVar.WEBSHOP_FRONTEND_URL) as string
class CurrencyFormat extends HTMLElement {
  constructor() {
    super()
  }
  static observedAttributes = ["number", "currency", "locale"]

  connectedCallback() {
    this.setNumber()
  }

  setNumber() {
    // number as a string. example: 1000000.12
    const number = parseNumber(this.getAttribute("number"))
    // currency as an ISO 4217 code, example: "JPY"
    const currency = this.getAttribute("currency") || ""
    // IETF language tag, example: "en-US", "nl"
    const locale = this.getAttribute("locale") || ""

    if (locale && currency) {
      this.innerHTML = format(number, locale, currency)
    }
  }

  attributeChangedCallback(_name, _oldValue, newValue) {
    this.setNumber()
  }

  disconnectedCallback() {}
}

const define = () => customElements.define("formatted-currency", CurrencyFormat)

export { define }

function format(number: number, locale: string, currency: string): string {
  try {
    const withCurrency = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number)
    const justNumber = new Intl.NumberFormat(locale, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number)
    const parts = withCurrency.split(justNumber)

    if (withCurrency.startsWith(justNumber)) {
      const currency = parts[1]
      return `${justNumber}<span class='currency'>${currency}</span>`
    } else {
      const currency = parts[0]
      return `<span class='currency'>${currency}</span>${justNumber}`
    }
  } catch (e) {
    console.error(e)
    return "ERROR"
  }
}
function parseNumber(attr: string | null): number {
  try {
    if (attr) {
      return parseFloat(attr)
    } else {
      return 0
    }
  } catch (_) {
    return 0
  }
}
