export const FETCH_ADVISORS = "@@advisors/FETCH_ADVISORS"
export const FETCH_ADVISORS_SUCCESS = "@@advisors/FETCH_ADVISORS_SUCCESS"
export const FETCH_ADVISORS_FAILURE = "@@advisors/FETCH_ADVISORS_FAILURE"

export const FETCH_ADVISOR = "@@advisors/FETCH_ADVISOR"
export const FETCH_ADVISOR_SUCCESS = "@@advisors/FETCH_ADVISOR_SUCCESS"
export const FETCH_ADVISOR_FAILURE = "@@advisors/FETCH_ADVISOR_FAILURE"

export const NEW_ADVISOR = "@@advisors/NEW_ADVISOR"
export const NEW_ADVISOR_SUCCESS = "@@advisors/NEW_ADVISOR_SUCCESS"
export const NEW_ADVISOR_FAILURE = "@@advisors/NEW_ADVISOR_FAILURE"

export const UPDATE_ADVISOR = "@@advisors/UPDATE_ADVISOR"
export const UPDATE_ADVISOR_SUCCESS = "@@advisors/UPDATE_ADVISOR_SUCCESS"
export const UPDATE_ADVISOR_FAILURE = "@@advisors/UPDATE_ADVISOR_FAILURE"

export const DELETE_ADVISOR = "@@advisors/DELETE_ADVISOR"
export const DELETE_ADVISOR_SUCCESS = "@@advisors/DELETE_ADVISOR_SUCCESS"
export const DELETE_ADVISOR_FAILURE = "@@advisors/DELETE_ADVISOR_FAILURE"

export const CHANGE_ADVISOR_META = "@@advisors/CHANGE_ADVISOR_META"
export const CHANGE_ADVISOR_STYLE = "@@advisors/CHANGE_ADVISOR_STYLE"
export const FETCHED_ADVISOR_STYLE = "@@advisors/FETCHED_ADVISOR_STYLE"
export const CHANGE_CUSTOMER_INFO_CONFIG = "@@advisors/CHANGE_CUSTOMER_INFO_CONFIG"

export const ADVISOR_PUBLISHED = "@@advisors/ADVISOR_PUBLISHED"
export const LATEST_RELEASE = "@@advisors/LATEST_RELEASE"
export const TRACK_CHANGE = "@@advisors/TRACK_CHANGE"
export const ADVISOR_VALIDATED = "@@advisors/ADVISOR_VALIDATED"
