import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import React, { useEffect } from "react"
import AdvisorContainer from "~/containers/advisors/AdvisorContainer"
import Home from "~/components/Home"
import Create from "../components/advisors/Create"
import AccountSettings from "~/components/AccountSettings"
import useCurrentUser from "~/hooks/useCurrentUser"
import { useAuth } from "~/context/auth"
import { useRedirectTo } from "~/context/redirectTo"
import styled from "styled-components"
import Notifications from "~/components/Notifications"
import { SidebarContextProvider, useSidebar } from "~/context/sidebar"
import { PreviewModeContextProvider } from "~/context/previewMode"
import Layout from "~/components/Layout"
import { useRouteMatch, useHistory } from "react-router"
import { AdvisorNavigation } from "~/components/navigation/AdvisorNavigation"
import { Navigation } from "~/components/navigation"
import ElmGlobalAnalytics from "~/elm-tsx-wrappers/AppImpactAnalytics"
import { HomeNavigation } from "~/components/navigation/HomeNavigation"
import { useIntercom } from "react-use-intercom"
import { useSelector } from "react-redux"
import { ApplicationState } from "../store"
import useIntercomPageSync from "~/hooks/useIntercomPageSync"
import useDocumentTitle from "~/hooks/useDocumentTitle"
import { isAidenSupport } from "~/utils/user"

const Global = styled.div`
  a.link,
  a.link:visited {
    color: ${(p) => p.theme.secondary};
  }
  a.link:hover {
    text-decoration: underline;
  }
`

export default () => {
  const { logout, organisationId } = useAuth()
  const { fetchCurrentUser, currentUser } = useCurrentUser()
  const { boot } = useIntercom()
  const organisationName = useSelector(
    (state: ApplicationState) => state.organisations.organisation.name
  )

  useEffect(() => {
    fetchCurrentUser((apiError) => {
      if (apiError.status === 500) logout()
    })
  }, [fetchCurrentUser, logout])

  useEffect(() => {
    // Login user in Intercom if user is not an Aiden account in a non Aiden organisation
    if (
      currentUser &&
      organisationName &&
      organisationId &&
      !isAidenSupport(currentUser, organisationName)
    ) {
      boot({
        email: currentUser.email,
        userId: currentUser.id,
        name: currentUser.familyName,
        company: {
          companyId: organisationId.orgId,
          name: organisationName,
        },
      })
    }
  }, [boot, currentUser, organisationName, organisationId])
  return (
    <Global>
      <SidebarContextProvider>
        <PreviewModeContextProvider>
          <Notifications />
          <Router>
            <LoggedInContent currentUser={currentUser} />
          </Router>
        </PreviewModeContextProvider>
      </SidebarContextProvider>
    </Global>
  )
}

const LoggedInContent = ({ currentUser }) => {
  const match = useRouteMatch("/advisors/*")
  const history = useHistory()
  useDocumentTitle()
  useIntercomPageSync()
  const { redirectTo, clearRedirectTo } = useRedirectTo()
  const { open } = useSidebar()
  const showAdvisorMenu = !!match
  const collapsed = !showAdvisorMenu ? true : !open

  React.useEffect(() => {
    if (redirectTo) {
      clearRedirectTo()
      history.replace(redirectTo)
    }
  }, [redirectTo, clearRedirectTo])

  if (!currentUser) return null
  return (
    <>
      <Layout.WithSidebar
        navigation={
          <Navigation.Default open={open}>
            <Switch>
              <Route
                path="/advisors/:advisorId"
                render={(p) => (
                  <AdvisorNavigation
                    open={!collapsed}
                    advisorId={p.match.params.advisorId}
                  />
                )}
              />
            </Switch>
            <Switch>
              <Route
                path="/advisors"
                exact
                render={(p) => <HomeNavigation open={open} />}
              />
              <Route
                path="/analytics"
                render={(p) => <HomeNavigation open={open} />}
              />
            </Switch>
          </Navigation.Default>
        }
        // disableToggle={!showAdvisorMenu}
        collapsed={!open}
      >
        <Switch>
          <Route path="/advisors/:advisorId" component={AdvisorContainer} />
          <Route path="/create-advisor" component={Create} />
          <Route path="/analytics" component={ElmGlobalAnalytics} />
          <Route path="*" component={Home} />
        </Switch>
      </Layout.WithSidebar>
      <AccountSettings />
    </>
  )
}
