import styled from "styled-components"

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem auto;
  & > * {
    margin-left: 1rem;
  }
  & > *:first-child {
    margin-left: 0;
  }
`

export default ButtonGroup
