import React from "react"
import { useTranslation } from "react-i18next"
import ActionList, { ActionListItem } from "./ActionList"
import { useAuth } from "~/context/auth"
import { linkWithParam } from "~/lib/URLHelpers"
import { Link, useLocation } from "react-router-dom"
import { SettingRoute } from "./AccountSettings"
import AvatarWithAccount from "./AvatarWithAccount"

const UserProfile: React.FC<{ hideName?: boolean }> = ({ hideName }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const { logout } = useAuth()

  return (
    <ActionList
      position="bottom-start"
      options={(close) => {
        return (
          <>
            <ActionListItem onClick={() => close()}>
              <Link
                style={{ width: "100%", display: "inline-block" }}
                to={linkWithParam(
                  location.pathname,
                  location.search,
                  "settings",
                  SettingRoute.MyAccount
                )}
              >
                {t("homeOptions.settings")}
              </Link>
            </ActionListItem>
            <ActionListItem onClick={() => logout()}>
              <div>
                {logoutIcn}
                {t("homeOptions.logout")}
              </div>
            </ActionListItem>
          </>
        )
      }}
    >
      <AvatarWithAccount hideName={hideName} />
    </ActionList>
  )
}

const logoutIcn = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
    />
  </svg>
)

export default UserProfile
