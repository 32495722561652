import React from "react"
import { UUID }from "~/store/types"
import { ApplicationState }from "~/store"
import { useSelector } from "react-redux"
import { getQuestion }from "~/store/questions/selectors"
import { QuestionType }from "~/store/questions"
import SelectAnswersEditor from "./SelectAnswersEditor"
import NumericAnswerEditor from "./NumericAnswerEditor"
import DrilldownAnswerEditor from "~/elm-tsx-wrappers/DrilldownAnswerEditor"

const AnswersEditor: React.FC<{
  questionId: UUID
  advisorId: UUID
}> = props => {
  const { advisorId, questionId } = props

  const question = useSelector((state: ApplicationState) =>
    getQuestion(state, questionId)
  )
  if (!question) return null

  switch (question.type) {
    case QuestionType.Multi:
      return <SelectAnswersEditor advisorId={advisorId} question={question} />
    case QuestionType.Single:
      return <SelectAnswersEditor advisorId={advisorId} question={question} />
    case QuestionType.Numeric:
      return <NumericAnswerEditor advisorId={advisorId} question={question} />
    case QuestionType.Drilldown:
      return <DrilldownAnswerEditor advisorId={advisorId} question={question}/>
    case QuestionType.AutoDrilldown:
      return <DrilldownAnswerEditor advisorId={advisorId} question={question}/>
  }
  return null
}

export default AnswersEditor
