import sortBy from "lodash/sortBy"
import { Question }from "~/store/questions/types"
import { Rule }from "~/store/rules/types"
import { getCurrentColor } from "~/themes"
import { Graph as GraphSorter } from "./Graph"
import dagre from "dagre"
import { InfoScreen }from "~/store/screens"

const StartNode = { id: "START" }
const AdviceNode = { id: "ADVICE" }

const NODE_WIDTH = 250
const NODE_HEIGHT = 70

type Graph = {
  width: number
  height: number
  content: Record<
    string,
    {
      color: Question["color"]
      id: string
      label: string
      x: number
      y: number
      width: number
      height: number
      edges:
        | {
            type: "NEXT"
            from: string
            to: string
          }
        | {
            from: string
            ruleId: string
            to: string
            type: "RULE"
          }[]
    }
  >
}

const topologicalSort = (
  unsortedQuestions: (Omit<Question, "rules"> & { rules: Rule[] })[],
  infoScreens: InfoScreen[],
  firstNodeId: string
): Nodes => {
  const sortedQuestions = sortBy(unsortedQuestions, ["id"])
  const sortedInfoScreens = sortBy(infoScreens, ["id"])

  const nodes = [...sortedQuestions, ...sortedInfoScreens]

  const topoGraph = new GraphSorter()
  topoGraph.addVertex(StartNode.id)
  topoGraph.addVertex(AdviceNode.id)

  sortedQuestions.forEach((q) => topoGraph.addVertex(q.id))
  sortedInfoScreens.forEach((c) => topoGraph.addVertex(c.id))

  sortedQuestions.forEach((q) => {
    topoGraph.addEdge(q.id, q.next)
    q.rules
      .filter((r) => !!r.targetQuestionId)
      .forEach((r) => topoGraph.addEdge(q.id, r.targetQuestionId))
  })
  sortedInfoScreens.forEach((c) => topoGraph.addEdge(c.id, c.next))

  const path = topoGraph
    .topoSort(firstNodeId)
    .filter((id) => id !== StartNode.id && id !== AdviceNode.id)

  return sortBy(nodes, (node) => path.indexOf(node.id))

  // let arr = Array.of(nodes.length).map((a) => undefined) as any[]

  // nodes.forEach((node) => {
  //   const i = path.indexOf(node.id)
  //   if (i !== undefined && i !== -1) {
  //     arr[i] = node
  //     return
  //   }

  //   arr.push(node)
  // })
  // return arr.filter((a) => !!a)
}

type QuestionWithRules = Omit<Question, "rules"> & { rules: Rule[] }
type Nodes = (QuestionWithRules | InfoScreen)[]
const graphLayout = (nodes: Nodes, firstNodeId: string): Graph => {
  const modifier = nodes.length ? 1 : 2.5

  const config = {
    rankdir: "TB",
    // align: "DL",
    ranksep: 175 * modifier,
    marginx: NODE_WIDTH,
    marginy: NODE_HEIGHT,
  }

  const nodeIds = nodes.map((q) => q.id)

  const g = new dagre.graphlib.Graph({ directed: true })
  g.setGraph(config)

  g.setNode(AdviceNode.id, {
    id: AdviceNode.id,
    width: NODE_WIDTH,
    height: NODE_HEIGHT,
    type: "screen",
  })
  g.setNode(StartNode.id, {
    id: StartNode.id,
    width: NODE_WIDTH,
    height: NODE_HEIGHT,
    type: "screen",
    edge: { type: "NEXT", from: StartNode.id, to: firstNodeId },
  })
  g.setEdge(StartNode.id, firstNodeId, {})

  nodes.forEach((q) => {
    const next = q.next
    g.setEdge(q.id, next, {})

    const defaultEdges = (
      next ? [{ type: "NEXT", from: q.id, to: next }] : []
    ) as any[]

    const edges =
      "rules" in q
        ? q.rules.reduce((acc, r) => {
            if (
              nodeIds.includes(r.targetQuestionId as string) ||
              r.targetQuestionId === "ADVICE"
            ) {
              g.setEdge(q.id, r.targetQuestionId, {})
              return [
                ...acc,
                {
                  type: "RULE",
                  ruleId: r.id,
                  from: q.id,
                  to: r.targetQuestionId,
                },
              ]
            } else {
              return acc
            }
          }, defaultEdges)
        : defaultEdges

    g.setNode(q.id, {
      id: q.id,
      label: q.label,
      type: "rules" in q ? "question" : "screen",
      questionType: q.type,
      color: getCurrentColor(q.color),
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
      edges: edges,
    })
  })
  dagre.layout(g)

  const { width, height } = g.graph()

  const edgesInGraph = g.edges().map((e) => ({
    edge: { from: e.v, to: e.w },
    points: g.edge(e).points,
  }))

  const getStartContent = (node, acc) => {
    const withPoints = (edge) => {
      const points = edgesInGraph.filter(
        (e) => e.edge.from === edge.from && e.edge.to === edge.to
      )[0]?.points
      return { ...edge, points: points }
    }
    if (!node) return acc
    return {
      ...acc,
      [node.id]: { ...node, edge: withPoints(node.edge) },
    }
  }

  const getNodeContent = (node, acc) => {
    const edgesFromNode =
      node.edges &&
      node.edges.map((edge) => {
        const points = edgesInGraph.filter(
          (e) => e.edge.from === edge.from && e.edge.to === edge.to
        )[0]?.points
        return { ...edge, points: points }
      })
    if (!node) return acc
    return {
      ...acc,
      [node.id]: { ...node, edges: edgesFromNode },
    }
  }

  const graphContent = g.nodes().reduce((acc, n) => {
    const node = g.node(n)

    if (!node) return acc

    return node.id === "START"
      ? getStartContent(node, acc)
      : getNodeContent(node, acc)
  }, {})

  return {
    width: Math.ceil(width),
    height: Math.ceil(height),
    content: graphContent,
  }
}

const layoutGraph = (
  unsortedQuestions: (Omit<Question, "rules"> & { rules: Rule[] })[],
  infoScreens: InfoScreen[],
  firstNodeId: undefined | string = AdviceNode.id
): Graph =>
  graphLayout(
    topologicalSort(unsortedQuestions, infoScreens, firstNodeId),
    firstNodeId
  )

export default {
  layout: layoutGraph,
  NODE_WIDTH,
  NODE_HEIGHT,
}
