import React from "react"
import { Preview } from "~/components/Preview"
import Emoji from "~/components/Emoji"
import useNavigation from "~/hooks/useNavigation"
import { MenuLink, MenuSection } from "."
import PublishIcon from "~/components/icons/PublishIcon"
import { useSelector } from "react-redux"
import { ApplicationState }from "~/store"
import LoadingSpinner from "~/components/LoadingSpinner"
import Tooltip from "~/components/Tooltip"
import CheckForChanges from "./CheckForChanges"

export const AdvisorNavigation: React.FC<{
  advisorId: string
  open: boolean
}> = ({ advisorId, open }) => {
  const advisor = useSelector(
    (state: ApplicationState) => state.advisors[advisorId]
  )

  const { map } = useNavigation()

  if (!advisor) return <LoadingSpinner style={{ textAlign: "center" }} />
  return (
    <div>
      <MenuLink
        link={map.advisorSettings(advisor.id)}
        icon={<Emoji emoji={advisor.icon} size={24} />}
        label={<strong>{advisor.name}</strong>}
      />

      <MenuSection open={open}>ACTIONS</MenuSection>
      <Preview label={"Preview"} advisorId={advisor.id} />

      {/* <PublishButton
          isMenuLink
          primary
          advisor={advisor}
          onSuccess={(r) => {}}
          // onError={(r) => notifyError()}
          onError={(r) => {}}
        /> */}

      <nav>
        <MenuSection>CREATE</MenuSection>

        <MenuLink
          link={map.conversation(advisor.id)}
          label={"Conversation"}
          icon={conversation}
        />
        <MenuLink
          link={map.lookAndFeel(advisor.id)}
          label={"Look & Feel"}
          icon={lookandfeel}
        />
        <MenuLink
          link={map.catalogue(advisor.id)}
          label={"Catalogue"}
          icon={catalogue}
        />
        <MenuLink
          link={map.matching(advisor.id)}
          label={"Matching"}
          icon={matching}
        />

        <MenuSection>LAUNCH</MenuSection>

        <CheckForChanges
          advisorId={advisorId}
          unpublished={advisor.status !== "ONLINE"}
          render={(hasChanges) => (
            <MenuLink
              link={map.publishing(advisor.id)}
              label={"Publish"}
              extra={
                hasChanges && (
                  <div
                    style={{
                      width: "0.5rem",
                      height: "0.5rem",
                      background: "#ff8e1b",
                      borderRadius: "100%",
                      position: "absolute",
                      left: open ? "2rem" : "1.5rem",
                      top: "0.25rem",
                    }}
                  ></div>
                )
              }
              icon={<PublishIcon />}
            />
          )}
        />

        <MenuLink
          link={map.integration(advisor.id)}
          label={"Embed"}
          icon={integrate}
        />

        <MenuLink
          icon={analytics}
          link={map.analyze(advisor.id)}
          label={"Analytics"}
        />

        {/*
          <MenuLink
            link={map.advisorSettings(advisor.id)}
            icon={<SettingsIcon />}
            label={"Settings"}
          />
          */}
      </nav>
    </div>
  )
}

const catalogue = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
    />
  </svg>
)

const conversation = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
    />
  </svg>
)

const analytics = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
    />
  </svg>
)

const integrate = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
    />
  </svg>
)

const lookandfeel = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
    />
  </svg>
)

const matching = (
  <svg width="96px" height="82px" viewBox="0 0 96 82" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-1125.000000, -2313.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(1125.418253, 2313.000000)">
          <path
            d="M24.7817473,64.1 L19.2817473,63.7 C18.4817473,63.6 17.7817473,63.1 17.4817473,62.4 L15.3817473,57.4 C14.6817473,55.6 12.1817473,55.6 11.3817473,57.4 L9.28174729,62.4 C8.98174729,63.1 8.28174729,63.7 7.48174729,63.7 L1.98174729,64.1 C0.0817472925,64.3 -0.718252707,66.6 0.781747293,67.9 L4.98174729,71.5 C5.58174729,72 5.88174729,72.8 5.68174729,73.6 L4.38174729,78.9 C3.98174729,80.8 5.98174729,82.3 7.58174729,81.3 L12.2817473,78.5 C12.9817473,78.1 13.8817473,78.1 14.4817473,78.5 L19.1817473,81.3 C20.7817473,82.3 22.8817473,80.8 22.3817473,79 L21.0817473,73.7 C20.8817473,72.9 21.1817473,72.1 21.7817473,71.6 L25.9817473,68 C27.4817473,66.7 26.6817473,64.3 24.7817473,64.1 Z"
            id="Path"
          ></path>
          <path
            d="M58.9817473,64.1 L53.4817473,63.7 C52.6817473,63.6 51.9817473,63.1 51.6817473,62.4 L49.5817473,57.4 C48.8817473,55.6 46.3817473,55.6 45.5817473,57.4 L43.4817473,62.4 C43.1817473,63.1 42.4817473,63.7 41.6817473,63.7 L36.1817473,64.1 C34.2817473,64.3 33.4817473,66.6 34.9817473,67.9 L39.1817473,71.5 C39.7817473,72 40.0817473,72.8 39.8817473,73.6 L38.5817473,78.9 C38.1817473,80.8 40.1817473,82.2 41.7817473,81.2 L46.4817473,78.4 C47.1817473,78 48.0817473,78 48.6817473,78.4 L53.3817473,81.2 C54.9817473,82.2 57.0817473,80.7 56.5817473,78.9 L55.2817473,73.6 C55.0817473,72.8 55.3817473,72 55.9817473,71.5 L60.1817473,67.9 C61.5817473,66.7 60.8817473,64.3 58.9817473,64.1 Z"
            id="Path"
          ></path>
          <path
            d="M94.2817473,67.9 C95.7817473,66.7 94.9817473,64.3 93.0817473,64.1 L87.5817473,63.7 C86.7817473,63.6 86.0817473,63.1 85.7817473,62.4 L83.6817473,57.4 C82.9817473,55.6 80.4817473,55.6 79.6817473,57.4 L77.5817473,62.4 C77.2817473,63.1 76.5817473,63.7 75.7817473,63.7 L70.2817473,64.1 C68.3817473,64.3 67.5817473,66.6 69.0817473,67.9 L73.2817473,71.5 C73.8817473,72 74.1817473,72.8 73.9817473,73.6 L72.6817473,78.9 C72.2817473,80.8 74.2817473,82.2 75.8817473,81.2 L80.5817473,78.4 C81.2817473,78 82.1817473,78 82.7817473,78.4 L87.4817473,81.2 C89.0817473,82.2 91.1817473,80.7 90.6817473,78.9 L89.3817473,73.6 C89.1817473,72.8 89.4817473,72 90.0817473,71.5 L94.2817473,67.9 Z"
            id="Path"
          ></path>
          <path
            d="M47.5817473,3.55271368e-15 C33.9817473,3.55271368e-15 22.8817473,11 22.8817473,24.7 C22.8817473,38.4 33.8817473,49.4 47.5817473,49.4 C61.2817473,49.4 72.2817473,38.4 72.2817473,24.7 C72.2817473,11 61.1817473,3.55271368e-15 47.5817473,3.55271368e-15 Z M58.7817473,21.9 L47.6817473,33 C46.8817473,33.8 45.8817473,34.1 44.8817473,34.1 C43.8817473,34.1 42.8817473,33.7 42.0817473,33 L36.2817473,27.2 C34.7817473,25.7 34.7817473,23.2 36.2817473,21.7 C37.7817473,20.2 40.2817473,20.2 41.7817473,21.7 L44.7817473,24.7 L53.1817473,16.3 C54.6817473,14.8 57.1817473,14.8 58.6817473,16.3 C60.3817473,17.9 60.3817473,20.3 58.7817473,21.9 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
