import React from "react"
import { handleElmError } from "~/Sentry"

export default (ports: any | undefined) => {
  React.useEffect(() => {
    if (ports) {
      ports?.sendError?.subscribe(handleElmError)
      return () => ports?.sendError?.unsubscribe(handleElmError)
    }
  }, [ports])
}
