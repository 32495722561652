import {
  AdvisorActionTypes,
  UPDATE_ADVISOR_SUCCESS,
  FETCH_ADVISOR_SUCCESS,
  CONSTANTS as AdvisorConstants,
  ADVISOR_PUBLISHED,
  CHANGE_CUSTOMER_INFO_CONFIG,
}from "~/actions/advisors"
import {
  AnswerActionTypes,
  CONSTANTS as AnswerConstants,
}from "~/actions/answers"
import {
  QuestionActionTypes,
  CONSTANTS as QuestionConstants,
}from "~/actions/questions"
import { RulesActionTypes, CONSTANTS as RuleConstants }from "~/actions/rules"
import {
  ScreenActionTypes,
  CONSTANTS as ScreenConstants,
}from "~/actions/screens"
import { CONSTANTS as FlowConstants }from "~/actions/flow"

const actionsThatDontChange = [ADVISOR_PUBLISHED]

const ActionsThatChange = [
  ...AdvisorConstants.filter((c) => !actionsThatDontChange.includes(c)),
  ...AnswerConstants,
  ...QuestionConstants,
  ...RuleConstants,
  ...FlowConstants,
  ...ScreenConstants,
  CHANGE_CUSTOMER_INFO_CONFIG,
]

export enum Status {
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

interface AdvisorAction {
  payload: { advisorId: string }
}

const isAdvisorAction = (action: any = {}): action is AdvisorAction => {
  return "advisorId" in (action.payload || {})
}

type ChangeTrackerState = Record<string, { hasChanges: boolean }>

export default (
  state: ChangeTrackerState = {},
  action:
    | AdvisorActionTypes
    | AnswerActionTypes
    | QuestionActionTypes
    | RulesActionTypes
    | ScreenActionTypes
): ChangeTrackerState => {
  const track =
    //@ts-ignore
    action?.payload?.trackChanges !== undefined
      ? //@ts-ignore
        action?.payload?.trackChanges
      : //@ts-ignore
      action?.meta?.trackChanges !== undefined
      ? //@ts-ignore
        action?.meta?.trackChanges
      : true
  if (
    action.type === UPDATE_ADVISOR_SUCCESS ||
    action.type === FETCH_ADVISOR_SUCCESS
  ) {
    return {
      ...state,
      [action.payload.advisor.id]: {
        hasChanges: false,
      },
    }
  } else if (
    isAdvisorAction(action) &&
    ActionsThatChange.includes(action.type) &&
    track
  ) {
    const advisorId = action.payload.advisorId
    return {
      ...state,
      [advisorId]: {
        hasChanges: true,
      },
    }
  } else {
    return state
  }
}
