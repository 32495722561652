import { useCallback, useState } from "react"
import { OrganisationId } from "~/api/types"
import { integrations as integrationsApi } from "~/api"
import { FieldAvailability } from "~/api/integrations"

export function useProductFieldAvailability(
  token: string | undefined,
  organisationId: OrganisationId
) {
  const [checking, setChecking] = useState(false)
  const [invalid, setInvalid] = useState<FieldAvailability[] | undefined>(
    undefined
  )
  const clearField = () => setInvalid(undefined)
  const checkField = useCallback(
    (field: string) => {
      if (token) {
        setChecking(true)
        integrationsApi
          .validateField(field, token, organisationId)
          .then((resp) => {
            setInvalid(
              resp.availability.filter(
                (a) => a.missingInCatalogue || a.missingInLiveRelease
              )
            )
          })
          .finally(() => setChecking(false))
      }
    },
    [token, organisationId]
  )

  return {
    isChecking: checking,
    advisorsWithMissingField: invalid,
    checkField: checkField,
    clearField: clearField,
  }
}
