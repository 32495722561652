import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import React from "react"
import Login from "./login"
import useDocumentTitle from "~/hooks/useDocumentTitle"
import { useHistory } from "react-router"

export default () => {
  useHistory()
  useDocumentTitle()
  return (
    <Router>
      <Switch>
        <Route path="/login" render={() => <Login />} />
        <Redirect to="/login" />
      </Switch>
    </Router>
  )
}
