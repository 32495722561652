import { ApplicationState } from ".."
import { Rule } from "./types"

const getRule = (
  state: ApplicationState,
  ruleId: string
): Rule | undefined => ({ ...state.rules[ruleId], id: ruleId })

export const getRules = (
  state: ApplicationState,
  ruleIds: string[]
): Rule[] => {
  return ruleIds.reduce((acc, r) => {
    const rule = getRule(state, r)
    return rule ? [...acc, rule] : acc
  }, [] as Rule[])
}
