import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"

const EmojiPicker: React.FC<{
  icon: string
  setIcon: (string) => void
}> = ({ icon, setIcon }) => {
  function setupPorts(ports) {
    ports.setIcon.subscribe(function (selectedIcon) {
      setIcon(selectedIcon)
    })
  }

  return (
    <Elm src={ElmModule.EmojiPicker} flags={{ icon }} ports={setupPorts} />
  )
}

export default EmojiPicker
