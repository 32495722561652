import React, { ReactNode } from "react"
import SelectCSVFile from "./SelectCSVFile"
import Button from "~/components/Button"
import ButtonBar from "~/components/ButtonBar"
import styled from "styled-components"

const InfoBar = styled.div`
  background: ${p => p.theme.shade1};
  padding: 2rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
`

type SelectDataFileStepProps = {
  fileName: string
  title: string
  introContents: ReactNode
  infoBarContents: ReactNode
  setFile: (setFileName: File | undefined) => void
  setHeaders: (headers: string[]) => void
  setData: (data: string[][]) => void
  setDelimiter: (delimiter: string) => void
  back: () => void
  next?: () => void
}

const SelectDataFileStep: React.FC<SelectDataFileStepProps> = ({
  setHeaders,
  setData,
  setDelimiter,
  back,
  next,
  fileName,
  setFile,
  children,
  title,
  introContents,
  infoBarContents,
}) => {
  return (
    <>
      <h2>{title}</h2>

      <InfoBar>{infoBarContents}</InfoBar>

      <SelectCSVFile
        introContents={introContents}
        fileName={fileName}
        setFile={setFile}
        setHeaders={setHeaders}
        setData={setData}
        setDelimiter={setDelimiter}
      />
      <ButtonBar
        left={
          <Button onClick={back}>
            Back
          </Button>
        }
        right={
          <Button
            style={{ marginLeft: "auto", marginRight: 0 }}
            primary
            disabled={next ? false : true}
            onClick={() => next && next()}
          >
            Next
          </Button>
        }
      />
      {children}
    </>
  )
}

export default SelectDataFileStep
