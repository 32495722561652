import API from "./api"
import { Config } from "./APIBase"
import { UUID }from "~/store/types"
import { OrganisationId } from "./types"

type ExternalImageObject = {
  advisorId: UUID
  id: UUID
  metaInfo: Record<string, string>
  referenceId: UUID
  storedObject: {
    extension: string //"jpg"
    sizes: string[] //"560x300", "original"
    supportedExtensions: string[] //".jpg"
    type: "image"
    url: string
    urls: Record<string, string>
  }
}
export type ImageObject = ExternalImageObject & {
  urls: Record<string, string>
}
const processImageObjects = (
  imageObjects: ExternalImageObject[]
): ImageObject[] =>
  imageObjects.map((imgObj) => ({ ...imgObj, urls: imgObj.storedObject.urls }))

const mediaForQuestion = async (
  advisorId: string,
  questionId: string,
  opts: { token: string; organisationId: OrganisationId }
): Promise<ImageObject[]> => {
  return API.get<ExternalImageObject[]>({
    path: `/advisors/${advisorId}/questions/${questionId}/media`,
    token: opts.token,
    organisationId: opts.organisationId
  }).then(processImageObjects)
}
const mediaForScreen = async (
  advisorId: string,
  screenId: string,
  opts: { token: string; organisationId: OrganisationId }
): Promise<ImageObject[]> => {
  return API.get<ExternalImageObject[]>({
    path: `/advisors/${advisorId}/screens/${screenId}/media`,
    token: opts.token,
    organisationId: opts.organisationId
  }).then(processImageObjects)
}

const mediaForAdvisor = async (
  advisorId: string,
  refs: string[],
  opts: { token: string; organisationId: OrganisationId }
): Promise<ImageObject[]> => {
  return API.get<ExternalImageObject[]>({
    path: `/advisors/${advisorId}/media?refs=${refs.join(",")}`,
    token: opts.token,
    organisationId: opts.organisationId
  }).then(processImageObjects)
}

const deleteImage = async (
  advisorId: string,
  imageId: string,
  opts: { token: string; organisationId: OrganisationId }
) =>
  API.delete({
    path: `/advisors/${advisorId}/media/image/${imageId}`,
    token: opts.token,
    organisationId: opts.organisationId
  })

const uploadImageForAnswer = async (
  advisorId: string,
  answerId: string,
  image: File,
  crop: {
    x: number
    y: number
    width: number
    height: number
  },
  opts: {
    token: string
    organisationId: OrganisationId
    onUploadProgress?: Config["onUploadProgress"]
  }
): Promise<ImageObject> =>
  upload(
    `/advisors/${advisorId}/media/image/${answerId}`,
    image,
    //28 : 15
    ["560x300"],
    crop,
    opts
  ).then((img) => processImageObjects([img])[0])

const upload = async (
  url: string,
  file: File,
  sizes: string[],
  crop: {
    x: number
    y: number
    width: number
    height: number
  },
  opts: {
    token: string
    organisationId: OrganisationId
    onUploadProgress?: Config["onUploadProgress"]
  }
) => {
  const formData = new FormData()
  formData.append("cropX", `${crop.x}`)
  formData.append("cropY", `${crop.y}`)
  formData.append("cropWidth", `${crop.width}`)
  formData.append("cropHeight", `${crop.height}`)
  formData.append("file", file)
  formData.append("size", sizes.join(","))
  return API.post<ExternalImageObject>({
    path: url,
    data: formData,
    token: opts.token,
    organisationId: opts.organisationId,
    onUploadProgress: opts.onUploadProgress,
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export {
  uploadImageForAnswer,
  mediaForQuestion,
  mediaForScreen,
  mediaForAdvisor,
  deleteImage,
}
