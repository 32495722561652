type Task = () => Promise<any>

const queue = (concurrency = 1) => {
  let running = 0
  const taskQueue: Task[] = []

  const runTask = async (task: Task) => {
    running++
    await task()
    running--
    if (taskQueue.length > 0) {
      const nextTask = taskQueue.shift()
      nextTask && runTask(nextTask)
    }
  }

  const enqueueTask = (task: Task) => {
    taskQueue.push(task)
  }

  return {
    taskQueue,
    push: (task: Task): void => {
      running < concurrency ? runTask(task) : enqueueTask(task)
    },
  }
}

export default {
  new: queue,
}
