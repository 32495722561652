import styled, { css } from "styled-components"
import React from "react"
import ChevronCollapse from "~/components/icons/ChevronCollapse"
import ChevronExpand from "~/components/icons/ChevronExpand"

const DropdownWrapper = styled.div<{ inline?: boolean }>`
  position: relative;
  display: ${(p) => (p.inline ? "inline-block" : "block")};
  /* margin-bottom: 0.5rem; */
  &:last-child,
  &:first-child {
    margin-bottom: 0;
  }
`

const ChevronClickFix = styled.span`
  position: absolute;
  right: ${(p) => p.theme.inputPadding};
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  svg {
    height: 100%;
    width: 100%;
  }
  &:after {
    position: absolute;
    content: " ";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`

const DropdownList = styled.div`
  background: #fff;
  display: inline-block;
  width: 100%;
  min-width: 20rem;
  box-shadow: ${(props) => props.theme.dp[25]} 0 2px 4px 0;
  border-radius: ${(p) => p.theme.borderRadius};
  overflow: hidden;
  overflow-y: auto;
  max-height: 30vh;
  z-index: 10;
`

const SelectOption = styled.div`
  white-space: nowrap;
  cursor: pointer;
  padding: ${(p) => p.theme.inputPadding};
  transition: background 150ms ease-in;
  user-select: none;
  &:hover {
    background: ${(p) => p.theme.shade1};
  }
  display: flex;
  align-items: center;
  .marker {
    color: ${(p) => p.theme.shade3};
    display: inline-block;
    &,
    svg {
      height: 1rem;
    }
    &.selected {
      color: ${(p) => p.theme.tertiary};
    }
  }
`

const SelectedMarker = styled.span<{ selected: boolean }>`
  color: ${(p) => p.theme.shade3};
  display: inline-block;
  &,
  svg {
    height: 1rem;
  }
  ${(p) =>
    p.selected &&
    css`
      color: ${(p) => p.theme.tertiary};
    `}
`

const SelectedValue = styled.div<{ inline?: boolean; small?: boolean }>`
  background: ${(props) => props.theme.background};
  padding: ${(p) =>
    `${p.theme.inputPadding} 2rem ${p.theme.inputPadding} ${p.theme.inputPadding}`};

  line-height: normal !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadius};
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  border: none;
  transition: color 200ms ease-in, background 200ms ease-in;
  border: 2px solid ${(p) => p.theme.shade3};
  position: relative;
  overflow-wrap: break-word;
  hyphens: auto;

  ${(p) =>
    p.small &&
    css`
      padding: ${(p) =>
        `${p.theme.labelPadding} 2rem ${p.theme.labelPadding} ${p.theme.labelPadding}`};
      ${ChevronClickFix} {
        right: 0;
      }
    `}
`

const Selection = styled.div`
  padding: 0.75rem 0.5rem;
  display: flex;
  align-items: center;
  border-radius: ${(p) => p.theme.borderRadius};
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  border: none;
  transition: color 200ms ease-in, background 200ms ease-in;
  box-shadow: ${(props) => props.theme.dp[25]} 0 2px 4px 0;
  background: #fff;
  border: 1px solid ${(props) => props.theme.shade1};
  /* margin-bottom: 0.5em; */
  &:focus {
    background: ${(props) => props.theme.shade1};
  }
  position: relative;
`

// click events through the dom don't work on SVG, hence the fix
const Chevron: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
  <ChevronClickFix>
    {isOpen ? <ChevronCollapse /> : <ChevronExpand />}
  </ChevronClickFix>
)

export default {
  DropdownWrapper,
  DropdownList,
  Selection,
  SelectOption,
  SelectedValue,
  SelectedMarker,
  Chevron,
}
