import React, { useEffect, useState } from "react"
import { light, dark } from "~/themes"
import { ThemeProvider } from "styled-components"

type ThemeContextT = {
  theme: typeof light
  themeId: string
  setTheme: (theme: string) => void
}

const ThemeContext = React.createContext({
  theme: light,
  themeId: "light",
  setTheme: (theme: string) => {},
} as ThemeContextT)

const ThemeContextProvider = (props) => {
  const storedTheme =
    localStorage.getItem("aiden-saas-default-theme") || "light"

  const [theme, setTheme] = useState(storedTheme)
  const selectedTheme = theme === "dark" ? dark : light

  useEffect(() => {
    localStorage.setItem("aiden-saas-default-theme", theme || "light")
  }, [theme])

  return (
    <ThemeContext.Provider
      value={{ theme: selectedTheme, setTheme, themeId: theme }}
      {...props}
    >
      <ThemeProvider theme={selectedTheme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContextProvider }
