import React from "react"
import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import { Answer } from "../store/answers/types"
import { BinaryCombinator, Predicate } from "~/store/rules"
import { defaultExpressionForQuestionType } from "~/components/rules/Expression/helpers"
import { v4 as uuid } from "uuid"
import useNavigation from "~/hooks/useNavigation"
import useRules from "~/hooks/useRules"
import useAnswer from "~/hooks/useAnswer"
import { changeAnswer, addAnswer } from "~/actions/answers"
import { useDispatch, useSelector } from "react-redux"
import Conversation, { MatchingStat } from "~/lib/Conversation"
import useNotifications from "~/hooks/useNotifications"
import { useTranslation } from "react-i18next"
import { useAuth } from "~/context/auth"
import Modal, { ModalContent, ModalButtons } from "~/components/Modal"
import Button from "~/components/Button"
import { ApplicationState } from "~/store"

const isAnswerLinkedTo = (id: string, predicate: Predicate): Boolean => {
  if (predicate.type === "CONTAINS_ANY") {
    return predicate.value.includes(id)
  }
  if (predicate.type === "NOT") {
    return isAnswerLinkedTo(id, predicate.predicate)
  }
  if (predicate.type === "OR" || predicate.type === "AND") {
    return predicate.predicates.some((p) => isAnswerLinkedTo(id, p))
  }

  return false
}

const AnswerItem: React.FC<{
  answer: Answer & { questionId: string }
  isNewAnswer: boolean
  advisorId: string
  questionId: string
  isEditing: boolean
  setEditing: (bool: boolean) => void
  setUnsavedChanges: (bool: boolean) => void
  removeNewAnswer: () => void
}> = ({
  answer,
  isNewAnswer,
  advisorId,
  questionId,
  setEditing,
  setUnsavedChanges,
  isEditing,
  removeNewAnswer,
}) => {
  const navigation = useNavigation()
  const { addRule, questionWithRules, rules } = useRules(advisorId, questionId)
  const { removeAnswer } = useAnswer(advisorId, answer.id)
  const dispatch = useDispatch()
  const [ports, setPorts] = React.useState<any>()
  const rulesOfAnswer = rules
    .filter((r) => isAnswerLinkedTo(answer.id, r.predicate))
    .map((a) => a.id)
  const [stats, setStats] = React.useState<MatchingStat | undefined>(undefined)
  const { notify } = useNotifications()
  const { t } = useTranslation()
  const { token, organisationId } = useAuth()
  const productLabel = useSelector(
    (state: ApplicationState) => state.advisors[advisorId]?.productLabel
  )

  function setupPorts(ports) {
    setPorts(ports)
    ports.addRule.subscribe(function () {
      if (questionWithRules) {
        const ruleId = uuid()
        addRule(
          {
            id: ruleId,
            predicate: {
              type: "AND",
              predicates: [
                { type: "CONTAINS_ANY", field: questionId, value: [answer.id] },
              ],
            },
          },
          questionWithRules.next
        )
        navigation.toRuleOfQuestion(advisorId, questionId, ruleId)
      }
    })
    ports.navigateToFlowTab.subscribe(function (ruleId: string) {
      navigation.toRuleOfQuestion(advisorId, questionId, ruleId)
    })
    ports.deleteAnswer.subscribe(async function () {
      ports.pendingDeletion.send(true)
      setEditing(false)
      try {
        const stats = await Conversation(advisorId)(
          token!!,
          organisationId
        ).answerMatchingStats(questionId, answer.id)

        stats.hasMatching ? setStats(stats) : deleteAnswer()
      } catch (e) {
        ports.pendingDeletion.send(false)
        console.error(e)
        notify({ type: "error", text: t("errors.error") })
      }
    })
    ports.setEditing.subscribe(function (isEditing: boolean) {
      setEditing(isEditing)
    })

    ports.setUnsavedChanges.subscribe(function (hasUnsavedChanges: boolean) {
      setUnsavedChanges(hasUnsavedChanges)
    })
    ports.removeNewAnswer.subscribe(function () {
      removeNewAnswer()
      setEditing(false)
    })
    ports.saveAnswer.subscribe(function ({ title, helpText, customLabel }) {
      setEditing(false)
      setUnsavedChanges(false)
      if (isNewAnswer) {
        removeNewAnswer()
        dispatch(
          addAnswer(
            questionId,
            answer.id,
            title,
            helpText,
            customLabel,
            answer.isNeutralAnswer,
            { advisorId }
          )
        )
      } else {
        dispatch(
          changeAnswer(answer.id, title, helpText, customLabel, { advisorId })
        )
      }
    })
  }

  const deleteAnswer = () => {
    removeAnswer(questionId, answer.id)
    if (ports) {
      ports.pendingDeletion.send(false)
    }
  }
  const unsetPendingDeletion = () => {
    setStats(undefined)
    if (ports) {
      ports.pendingDeletion.send(false)
    }
  }

  // useEffect somehow had the old value when dependencies changed,
  // so firing this forces a sync of data on every render, but is not ideal.
  if (ports) {
    ports.answerUpdated.send(answer)
  }

  return (
    <>
      {!!stats && (
        <Modal onClose={unsetPendingDeletion}>
          <ModalContent>
            <>
              <h2>Are you sure you want to delete this answer?</h2>
              <p>
                The matching for this answer will be removed, which affects{" "}
                {stats.count} {productLabel}.
              </p>

              <p>{"This cannot be undone."}</p>
              <ModalButtons>
                <Button onClick={unsetPendingDeletion}>Cancel</Button>
                <Button primary onClick={deleteAnswer}>
                  Delete
                </Button>
              </ModalButtons>
            </>
          </ModalContent>
        </Modal>
      )}
      <Elm
        src={ElmModule.Answer}
        flags={{
          answer: answer,
          rulesOfAnswer: rulesOfAnswer,
          isNewAnswer: isNewAnswer,
        }}
        ports={setupPorts}
      />
    </>
  )
}

export default AnswerItem
