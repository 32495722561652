import React from "react"

const GoogleShopping: React.FC<{}> = (props) => {
  return (
    <svg
      viewBox=".381 -4.99021715 516.22889793 516.22821715"
      xmlns="http://www.w3.org/2000/svg"
      style={{maxWidth: "100%"}}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="m411.503 136.66c20.182 0 36.545-16.362 36.545-36.544s-16.363-36.544-36.545-36.544-36.544 16.362-36.544 36.544 16.362 36.544 36.544 36.544zm-258.888-11.155 112.108-112.108c8.552-8.552 20.428-13.777 33.493-13.777h166.276a47.343 47.343 0 0 1 47.508 47.507v166.276c0 13.065-5.226 24.942-14.015 33.493l-111.937 112.044z"
          fill="#4285f4"
        />
        <path
          d="m386.048 358.94-138.153 138.283c-8.79 8.551-20.666 14.015-33.73 14.015s-24.942-5.464-33.493-14.015l-166.277-166.275c-8.789-8.552-14.014-20.428-14.014-33.493 0-13.302 5.463-25.179 14.014-33.73l138.22-138.22z"
          fill="#34a853"
        />
        <path
          d="m95.085 411.637-80.69-80.69c-8.789-8.549-14.014-20.427-14.014-33.491 0-13.302 5.463-25.179 14.014-33.73l138.22-138.22 114.3 114.302z"
          fill="#fbbc05"
        />
        <path
          d="m495.595 11.127a47.344 47.344 0 0 0 -31.103-11.507h-166.276c-13.065 0-24.941 5.225-33.493 13.777l-112.108 112.108 114.3 114.302 116.415-116.415a36.394 36.394 0 0 1 -8.37-23.276c0-20.182 16.361-36.544 36.543-36.544a36.394 36.394 0 0 1 23.277 8.37z"
          fill="#ea4335"
        />
        <circle cx="266.849" cy="252.764" fill="#4285f4" r="74.23" />
        <path
          d="m367.983 230.928h-98.726v42.358h56.828c-5.298 26.91-27.45 42.358-56.828 42.358-34.675 0-62.607-28.903-62.607-64.783s27.932-64.783 62.607-64.783c14.93 0 28.414 5.482 39.009 14.452l30.822-31.894c-18.782-16.943-42.862-27.408-69.831-27.408-58.754 0-105.95 48.837-105.95 109.633s47.196 109.633 105.95 109.633c52.975 0 101.134-39.867 101.134-109.633 0-6.478-.963-13.455-2.408-19.933z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default GoogleShopping
