import { useDispatch, useSelector } from "react-redux"
import { ApplicationState }from "~/store"
import { InfoScreen, ScreenMediaType }from "~/store/screens"
import {
    changeMediaType,
    changeScreenColor,
    changeScreenCustomerInfo,
    changeScreenInfo,
    changeScreenLabel,
    changeScreenNext,
    changeScreenText,
    changeScreenTitle,
}from "~/actions/screens/actions"
import { QuestionColor }from "~/store/questions"
import { UUID }from "~/store/types"
import { InfoPageCustomerInfoConfig } from "~/store/advisors"

export default (advisorId: string, screenId: string) => {
  const dispatch = useDispatch()
  const screen: InfoScreen = useSelector((state: ApplicationState) => {
    const flow = state.flow[advisorId]
    return state.screens[advisorId].infoScreens
      .map((c) => {
        return { ...c, next: flow?.nodes[c.id] }
      })
      .find((s) => s.id === screenId) as InfoScreen
  })

  return {
    screen,
    changeScreenMediaType: (type: ScreenMediaType) =>
      screen && dispatch(changeMediaType(screen.id, type, { advisorId })),
    changeScreenTitle: (title: string) =>
      screen && dispatch(changeScreenTitle(screen.id, title, { advisorId })),
    changeScreenLabel: (label: string) =>
      screen && dispatch(changeScreenLabel(screen.id, label, { advisorId })),
    changeScreenColor: (color: QuestionColor) =>
      screen && dispatch(changeScreenColor(screen.id, color, { advisorId })),
    changeScreenText: (text: string) =>
      screen && dispatch(changeScreenText(screen.id, text, { advisorId })),
    changeScreenInfo: (info: string) =>
      screen && dispatch(changeScreenInfo(screen.id, info, { advisorId })),
    changeScreenNext: (nextId: UUID) =>
      screen && dispatch(changeScreenNext(screen.id, nextId, { advisorId })),
    changeScreenCustomerInfo: (customerInfo: InfoPageCustomerInfoConfig) =>
      screen && dispatch(changeScreenCustomerInfo(screen.id, customerInfo, { advisorId })),
  }
}
