import { UUID }from "~/store/types"
import { useDispatch, useSelector } from "react-redux"

import { removeAnswerAndUpdateDependencies }from "~/actions/answers/actionCreators"
import { getAnswer }from "~/store/answers/selectors"
import { ApplicationState }from "~/store"

export default (advisorId: UUID, answerId: UUID) => {
  const dispatch = useDispatch()

  const answer = useSelector((state: ApplicationState) =>
    getAnswer(state, answerId)
  )
  return {
    answer,
    removeAnswer: (questionId: UUID, answerId: UUID) =>
      dispatch(
        removeAnswerAndUpdateDependencies(questionId, answerId, { advisorId })
      ),
  }
}
