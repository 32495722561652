import React from "react"
// import { Redirect, Route, Switch, useRouteMatch } from "react-router"
// import TabNav from "~/components/TabNav"
// import Page, { PageHeader } from "~/components/advisors/Page"
// import AnalyzeUsage from "./Usage"
// import AnalyzeQuestions from "./Conversation"
import { Advisor }from "~/store/advisors"
// import ElmDateRangePicker from "~/elm-tsx-wrappers/ElmDateRangePicker"
// import AnalyticsImpact from "~/elm-tsx-wrappers/AnalyticsImpact"
import { ElmAnalyze } from "~/elm-tsx-wrappers/Analyze"

const Analyze: React.FC<{  advisor: Advisor}> = ({ advisor }) => {
  return <ElmAnalyze advisorId={advisor.id} />
}

export default Analyze
