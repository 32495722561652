import { UUID } from "../types"

export enum AdvisorStatus {
  Online = "ONLINE",
  Offline = "OFFLINE",
}

export type HeaderStyle = "bold" | "underline" | "italic"

export type StyleConfig = {
  background: string
  focus: string
  font: string
  fontHeader: string
  header: HeaderStyle[]
  primary: string
  selectHover: string
  text: string
  headerColor: string
  buttonPaddingVertical: string
  buttonPaddingHorizontal: string
  borderRadius: string
  borderRadiusCard: string
  boxShadowOn: boolean
}

export type Advisor = {
  id: string
  name: string
  productLabel: string
  icon: string
  analytics: Record<string, string>
  locales: string[]
  questions: UUID[]
  screenOrder: UUID[]
  startQuestionId?: UUID
  updatedAt?: Date
  status?: string
  style: StyleConfig
  customerInfo: AdviceCustomerInfoConfig
  embedConfig: EmbedConfig
  appUpdateInfo: { updatedAt: null | Date }
}

export type EmbedConfig = {
  modalButtonText?: string
  inPageOffset: number
  inPageShowStartScreenInfo: boolean
  fullPageOffset: number
}

export type AdviceCustomerInfoConfig = {
    showForm: boolean
    title: string
    intro: string
    ctaButtonLabel: string
    showCheckbox: boolean
    checkboxLabel: string
    formSubmittedMessage: string
}

export type InfoPageCustomerInfoConfig = {
    showForm: boolean
    showCheckbox: boolean
    checkboxLabel: string
    formSubmittedMessage: string
    formIdentifier: string
}

export type NoResultsCustomerInfoConfig = {
    showForm: boolean
    showCheckbox: boolean
    checkboxLabel: string
    formSubmittedMessage: string
}

export type AdvisorsState = Record<UUID, Advisor>
