import { removeAnswer } from "."
import { UUID } from "../../store/types"
import { AdvisorMeta }from "~/actions/advisors"
import { ThunkDispatch } from "redux-thunk"
import { AnyAction } from "redux"
import { ApplicationState }from "~/store"
import { getQuestion }from "~/store/questions"
import { getRules }from "~/store/rules/selectors"
import { ExpressionType }from "~/store/rules"
import { changeRule, removeRule }from "~/actions/rules"

/**
 * Remove an Answer option to a question and possibly update rules
 */
export const removeAnswerAndUpdateDependencies = (
  questionId: string,
  answerId: UUID,
  meta: AdvisorMeta
) => {
  return (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => ApplicationState
  ) => {
    dispatch(removeAnswer(questionId, answerId, meta))

    const state = getState()
    const question = getQuestion(state, questionId)
    const rules = getRules(state, question?.rules || [])

    // Check if rule needs to change or becomes obsolete and if so remove it
    //   rules.forEach(rule => {
    //     if (
    //       rule.left?.kind === ExpressionType.Multi &&
    //       (rule.left.right || []).includes(answerId)
    //     ) {
    //       const filteredRight = (rule.left.right || []).filter(
    //         a => a !== answerId
    //       )
    //       if (filteredRight.length) {
    //         return dispatch(
    //           changeRule(
    //             {
    //               ...rule,
    //               left: {
    //                 ...rule.left,
    //                 right: filteredRight,
    //               },
    //             },
    //             meta
    //           )
    //         )
    //       } else {
    //         return dispatch(removeRule(questionId, rule.id, meta))
    //       }
    //     } else if (rule.left?.kind === ExpressionType.Single) {
    //       if (rule.left.right === answerId) {
    //         return dispatch(removeRule(questionId, rule.id, meta))
    //       }
    //     }
    //   })
    // }
  }
}
