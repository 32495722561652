import { dataSources, mapping } from "~/api"
import { AnswerMappingStat } from "~/api/mapping"
import { OrganisationId } from "~/api/types"
import { withInactiveFilter } from "~/components/advisors/Products/Advice/ProductMapping/Filters/helpers"
import { DataSource } from "~/types"

export type MatchingStat = {
  hasMatching: boolean
  count: number
}
export type QuestionMatchingStat = {
  hasMatching: boolean
  stats: Record<string, MatchingStat>
}

const Conversation =
  (advisorId: string) => (token: string, organisationId: OrganisationId) => {
    const numericQuestionMatchingStats = async (
      questionId: string
    ): Promise<MatchingStat> => {
      const datasource = await getDataSource()
      const emptyStat = { hasMatching: false, count: 0 }
      if (!datasource) return emptyStat

      try {
        const numericMappingP = dataSources.getNumericMapping(
          advisorId,
          datasource.id,
          questionId,
          token,
          organisationId
        )
        const totalProductsP = mapping.productsWithMapping(
          advisorId,
          datasource.id,
          questionId,
          {
            filter: withInactiveFilter(undefined),
            query: "",
            page: 1,
            size: 1,
            token: token!!,
            organisationId,
          }
        )
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_numericMapping, totalProducts] = await Promise.all([
          numericMappingP,
          totalProductsP,
        ])
        return { hasMatching: true, count: totalProducts.total }
      } catch (e: any) {
        if (e.status === 404) return emptyStat
        throw e
      }
    }

    const questionMatchingStats = async (
      questionId: string
    ): Promise<QuestionMatchingStat> => {
      const questionStats = await getQuestionStats(questionId)
      if (!questionStats) return { hasMatching: false, stats: {} }

      const stats = questionStats.answers.reduce((acc, a) => {
        return {
          ...acc,
          [a.id]: answerToMatchingStat(a),
        }
      }, {} as Record<string, MatchingStat>)

      return {
        hasMatching: Object.values(stats).some((stat) => stat.count > 0),
        stats,
      }
    }

    const answerMatchingStats = async (
      questionId: string,
      answerId: string
    ): Promise<MatchingStat> => {
      const stats = await getQuestionStats(questionId)
      const emptyStat = { hasMatching: false, count: 0 }
      if (!stats) return emptyStat

      const answerStats = stats.answers.find((stat) => stat.id === answerId)
      if (!answerStats) return emptyStat

      return answerToMatchingStat(answerStats)
    }

    // Implementation
    const getDataSource = async (): Promise<DataSource | undefined> => {
      const datasources = await dataSources.all(
        advisorId,
        token,
        organisationId
      )
      return datasources[0]
    }

    const getQuestionStats = async (
      questionId: string
    ): Promise<mapping.MappingStats | undefined> => {
      const datasource = await getDataSource()
      if (!datasource) return undefined

      const stats = await mapping.getStats(
        advisorId,
        datasource.id,
        token,
        organisationId
      )
      return stats.find((stat) => stat.id === questionId)
    }

    const answerToMatchingStat = (answer: AnswerMappingStat): MatchingStat => {
      const count = Object.entries(answer.buckets).reduce(
        (acc, [key, count]) => {
          if (key === "no_mapping") return acc
          return acc + count
        },
        0
      )
      return {
        hasMatching: count > 0,
        count,
      }
    }

    return {
      answerMatchingStats,
      questionMatchingStats,
      numericQuestionMatchingStats,
    }
  }
export default Conversation
