import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Question as QuestionStruct } from "../../../store/questions"
import { ConversationScreens } from "../../../store/screens"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import QuestionLabel from "../../QuestionLabel"
import { getCurrentColor } from "~/themes"
import Emoji from "../../Emoji"
import ChevronForward from "../../icons/ChevronForward"
import { useHistory } from "react-router-dom"
import useIntroScreen from "~/hooks/useIntroScreen"
import { UUID }from "~/store/types"
import useAdviceScreen from "~/hooks/useAdviceScreen"
import { reorderQuestion }from "~/actions/questions"

import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc"

import DragIndicator from "~/components/icons/DragIndicator"
import { Flow }from "~/actions/flow"
import { ApplicationState }from "~/store"
import sortBy from "lodash/sortBy"

const ListComp = styled.div``

const ListItemComp = styled.div`
  width: 100%;
  padding: 0.125rem 0;
  display: grid;
  grid-template-columns: 1fr 1.5fr min-content;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.shade1};
  background: #ffffff;
  cursor: pointer;

  &:hover {
    background: ${(p) => p.theme.backgroundShade};
  }
`

const HandleWrapper = styled.div`
  width: 2rem;
  padding: 1rem 1rem 1rem 0;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const SortableElementWrapper = styled.div``

const Title = styled.div<{ isPlaceholder?: boolean }>`
  color: ${(p) => (p.isPlaceholder ? p.theme.shade4 : p.theme.main)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

const ScreenLabelAndTitle = ({ label, title, emoji, isPlaceholder }) => (
  <>
    <div>
      <QuestionLabel col={getCurrentColor(undefined)}>
        <Emoji emoji={emoji} size={16} /> {label}
      </QuestionLabel>
    </div>
    <Title isPlaceholder={isPlaceholder}>{title}</Title>
  </>
)

const QuestionLabelAndTitle = ({ question }) => (
  <>
    <div style={{ display: "flex", alignItems: "center" }}>
      <Handle tabIndex={1} />
      <QuestionLabel col={getCurrentColor(question.color)}>
        {question.label}
      </QuestionLabel>
    </div>
    <Title>{question.title}</Title>
  </>
)

type ListItemProps = {
  link: string
  children: React.ReactNode
}

const ListItem = React.forwardRef(
  (props: ListItemProps, ref?: React.Ref<HTMLDivElement>) => {
    const { link, children } = props
    const history = useHistory()
    return (
      <ListItemComp ref={ref} onClick={() => history.push(link)}>
        {children}
        <div>
          <ChevronForward />
        </div>
      </ListItemComp>
    )
  }
)

const Handle = SortableHandle(({ tabIndex }) => (
  <HandleWrapper tabIndex={tabIndex}>
    <DragIndicator />
  </HandleWrapper>
))

const SortableListItem = SortableElement(({ value }) => (
  <SortableElementWrapper>{value}</SortableElementWrapper>
))

const QuestionsList = SortableContainer(({ items, url, func }) => {
  return (
    <div>
      {items.map(
        (
          q: QuestionStruct & {
            itemType: "question" | "screen"
            ref: React.Ref<HTMLDivElement>
          },
          i: number
        ) => (
          <SortableListItem
            key={`item-${i}-${q.id}`}
            index={i}
            value={
              q.itemType === "question" ? (
                <ListItem ref={q.ref} link={`${url}/questions/${q.id}`}>
                  <QuestionLabelAndTitle question={q} />
                </ListItem>
              ) : (
                <ListItem ref={q.ref} link={`${url}/screens/${q.id}`}>
                  <QuestionLabelAndTitle question={q} />
                </ListItem>
              )
            }
          />
        )
      )}
    </div>
  )
})

const SideBarContent: React.FC<{
  url: string
  screens: ConversationScreens
  questions: QuestionStruct[]
  advisorId: UUID
}> = ({ url, screens, questions, advisorId }) => {
  const { t } = useTranslation()

  const { introScreen } = useIntroScreen(advisorId)
  const { adviceScreen } = useAdviceScreen(advisorId)
  const dispatch = useDispatch()
  const sortOrder = useSelector(
    (state: ApplicationState) => state.advisors[advisorId]?.screenOrder || {}
  )

  const onSortEnd = ({ oldIndex, newIndex }) =>
    oldIndex !== newIndex &&
    dispatch(reorderQuestion(advisorId, oldIndex, newIndex))

  const infoScreens = Flow.useInfoScreens(advisorId)
  let refs: React.RefObject<HTMLDivElement>[] = []

  const itemsWithRef = sortBy(
    [
      ...questions.map((i) => {
        const ref = React.createRef<HTMLDivElement>()
        refs.push(ref)

        return { ...i, itemType: "question", ref: ref }
      }),
      ...infoScreens.map((i) => {
        const ref = React.createRef<HTMLDivElement>()
        refs.push(ref)

        return { ...i, itemType: "screen", ref: ref }
      }),
    ],
    (item) => sortOrder.indexOf(item.id)
  )

  return (
    <ListComp>
      <ListItem link={`${url}/screens/${screens!.intro.id}`}>
        <ScreenLabelAndTitle
          emoji=":checkered_flag:"
          label={t("start")}
          isPlaceholder={!introScreen.title}
          title={
            introScreen.title || t("components.advisorSidebar.introExplanation")
          }
        />
      </ListItem>
      <QuestionsList
        items={itemsWithRef}
        url={url}
        helperClass="sortableHelper"
        useDragHandle
        onSortEnd={onSortEnd}
        lockAxis={"y"}
        func={{}}
      />

      <ListItem link={`${url}/screens/${screens!.advice.id}`}>
        <ScreenLabelAndTitle
          emoji=":point_right:"
          label={t("advise")}
          isPlaceholder={!adviceScreen.title}
          title={
            adviceScreen.title ||
            t("components.advisorSidebar.adviseExplanation")
          }
        />
      </ListItem>
    </ListComp>
  )
}
export default SideBarContent
