import React from "react"
import SingleSelect, { SingleSelectProps } from "./SingleSelect"
import SelectionUI from "./SelectionUI"
import styled from "styled-components"

const DarkSelection = styled(SelectionUI.SelectedValue)`
  /* background: ${p => p.theme.shade1}; */
  font-weight: bold;
  /* border: none; */
  margin: .25rem 0;
`

type DarkSelectProps = {
  inline?: boolean
  small?: boolean
} & Omit<SingleSelectProps, "selectionElement" | "listElement">

const DarkSelect: React.FC<DarkSelectProps> = props => {
  const currentValue = props.options.find(opt => opt.value === props.value)

  return (
    <SingleSelect
      {...props}
      inline={!!props.inline}
      selectionElement={open => (
        <DarkSelection small={props.small} inline={props.inline}>
          {currentValue?.label || props.emptyText}
          <SelectionUI.Chevron isOpen={open} />
        </DarkSelection>
      )}
      listElement={opt => (
        <SelectionUI.SelectOption style={{ fontWeight: "bold" }}>
          {opt.label}
        </SelectionUI.SelectOption>
      )}
    />
  )
}

export default DarkSelect
