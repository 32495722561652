import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Advisor, NoResultsCustomerInfoConfig } from "~/store/advisors"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"
import { updateAdviceScreenData } from "~/actions/screens/actions"
import { AdviceScreen } from "~/store/screens"
import ScreenCustomerInfoEditor from "~/components/advisors/QandA/ScreenEditor/ScreenCustomerInfoEditor"
import { defaultNoResultsCustomerInfoConfig } from "~/lib/Advisor"

const NoResultsCustomerInfoEditor: React.FC<{ advisor: Advisor, screen: AdviceScreen }> = ({ advisor, screen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { featureFlags } = useFeatureFlags()

  const customerInfo = screen.noResultsCustomerInfo || defaultNoResultsCustomerInfoConfig()

  const updateAdvice = (customerInfo: NoResultsCustomerInfoConfig) =>
    featureFlags.customerInfoCollectionEnabled && dispatch(updateAdviceScreenData(advisor.id, {
      ...screen,
      noResultsCustomerInfo: customerInfo,
    }))

  return (
    <ScreenCustomerInfoEditor customerInfo={customerInfo} updateCustomerInfo={updateAdvice} />
  )
}

export default NoResultsCustomerInfoEditor
