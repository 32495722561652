import React, { useEffect, useState } from "react"
import { StyleConfig } from "~/store/advisors"
import PillButtons from "~/components/PillButtons"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import Mobile from "~/components/icons/Mobile"
import Desktop from "~/components/icons/Desktop"

import Warning from "~/components/icons/Warning"
import HelpText from "~/components/HelpText"
import Appear from "~/animations/Appear"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"
import { PreviewMode } from "~/types"
import { FullPage, InPage } from "~/components/icons/PreviewMode"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Options = styled.div`
  margin: 1rem 0 2rem;
  display: flex;
  flex-wrap: wrap;
  div + div {
    margin-left: 1rem;
  }
`

const PreviewContainer = styled.div<{
  mode: string
  background: string
  bannerMode: boolean
}>`
  margin: 0;
  display: flex;
  max-width: 100%;
  animation: ${Appear.fadeIn} 0.2s 0.25s ease-in forwards;
  opacity: 0;

  ${(p) => {
    if (!p.bannerMode) {
      return css`
        box-shadow: 0 0 0 1px ${(p) => p.theme.shade2};
      `
    }
  }}

  ${(p) => {
    if (p.mode === "desktop") {
      return css`
        width: 900px;
      `
    } else {
      return css`
        width: 375px;

        #preview {
          .product-card.product-results__result {
            width: 95% !important;
          }
        }
      `
    }
  }}
  #preview {
    width: 100%;

    .aiden-webshop-app {
      padding: 0 !important;

      .wrapper {
        box-shadow: none !important;
      }
    }
  }

  main {
    min-height: 100%;
  }
`

type PreviewProps = {
  advisorId: string
  style: StyleConfig
  introScreenDisabled: boolean
}

const AidenPreview: React.FC<PreviewProps> = ({
  advisorId,
  style,
  introScreenDisabled,
}) => {
  const [screen, setScreen] = useState<
    "intro" | "question" | "advice" | "info"
  >("intro")
  const [mode, setMode] = useState<"desktop" | "mobile">("desktop")
  const { t } = useTranslation()

  const [previewMode, setPreviewMode] = React.useState<PreviewMode>("inpage")
  const { featureFlags } = useFeatureFlags()

  const previewRef = React.useRef(null)

  const screenProperties = React.useMemo(() => {
    return featureFlags.showPoweredByAiden ? { showPoweredByAiden: "true" } : {}
  }, [featureFlags.showPoweredByAiden])

  useEffect(() => {
    const handler = (e) => {
      const ref = previewRef.current as any
      const frame = ref.querySelector("iframe")?.contentWindow

      frame?.postMessage(
        {
          messageType: "powered-by-aiden",
          value: featureFlags.showPoweredByAiden,
        },
        "*"
      )
      frame?.postMessage({ messageType: "screen-type", value: "intro" }, "*")
      frame?.postMessage({ messageType: "style-config", value: style }, "*")
    }
    ;(previewRef.current as any)?.addEventListener("preview-loaded", handler)
    return () => {
      ;(previewRef.current as any)?.removeEventListener(
        "preview-loaded",
        handler
      )
    }
  }, [featureFlags.showPoweredByAiden])

  useEffect(() => {
    if (previewRef) {
      const ref = previewRef.current as any

      ref
        .querySelector("iframe")
        ?.contentWindow?.postMessage(
          { messageType: "style-config", value: style },
          "*"
        )
    }
  }, [previewRef.current, style])

  useEffect(() => {
    let scr
    const ref = previewRef.current as any

    const setPreviewScreen = (screen) => {
      ref
        .querySelector("iframe")
        ?.contentWindow?.postMessage(
          { messageType: "screen-type", value: screen },
          "*"
        )
    }
    switch (screen) {
      case "intro":
        setPreviewScreen("intro")
        break
      case "question":
        setPreviewScreen("question")
        break
      case "info":
        setPreviewScreen("info")
        break
      case "advice":
        setPreviewScreen("advice")
        break
    }
  }, [previewRef.current, screen])

  useEffect(() => {
    const ref = previewRef.current as any
    ref
      .querySelector("iframe")
      ?.contentWindow?.postMessage(
        { messageType: "embed-mode", value: previewMode },
        "*"
      )
  }, [previewRef.current, previewMode])

  // if (loading) return <LoadingSpinner />
  // if (error) return null

  return (
    <Wrapper>
      <Options>
        <PillButtons
          buttons={[
            {
              content: t("lookAndFeel.screens.intro"),
              value: "intro",
              selected: screen === "intro",
              onClick: setScreen,
              hasTextContent: true,
            },
            {
              content: t("lookAndFeel.screens.question"),
              value: "question",
              selected: screen === "question",
              onClick: setScreen,
              hasTextContent: true,
            },
            {
              content: t("lookAndFeel.screens.info"),
              value: "info",
              selected: screen === "info",
              onClick: setScreen,
              hasTextContent: true,
            },
            {
              content: t("lookAndFeel.screens.advice"),
              value: "advice",
              selected: screen === "advice",
              onClick: setScreen,
              hasTextContent: true,
            },
          ]}
        />
        <PillButtons
          buttons={[
            {
              content: <Desktop />,
              value: "desktop",
              selected: mode === "desktop",
              onClick: setMode,
            },
            {
              content: <Mobile />,
              value: "mobile",
              selected: mode === "mobile",
              onClick: setMode,
            },
          ]}
        />
        <PillButtons
          buttons={[
            {
              content: (
                <div>
                  <FullPage />
                </div>
              ),
              value: "inpage",
              selected: previewMode === "inpage",
              onClick: () => setPreviewMode("inpage"),
              hasTextContent: false,
            },

            {
              content: (
                <div>
                  <InPage />
                </div>
              ),
              value: "banner",
              selected: previewMode === "banner",
              onClick: () => setPreviewMode("banner"),
              hasTextContent: false,
            },
          ]}
        />
      </Options>
      {introScreenDisabled && (
        <HelpText>
          <Warning />
          {t("lookAndFeel.feedback.disabledIntroScreen")}
        </HelpText>
      )}
      <PreviewContainer
        mode={mode}
        background={style.background}
        bannerMode={previewMode === "banner"}
      >
        {/* <div id="preview"></div> */}

        {/* @ts-ignore */}
        <aiden-preview style={{ width: "100%" }} ref={previewRef} />
      </PreviewContainer>
    </Wrapper>
  )
}

export default AidenPreview
