import SplitLayout, { SidebarContent } from "~/components/SplitLayout"
import React, { useState } from "react"
import { Advisor, HeaderStyle, StyleConfig }from "~/store/advisors"
import StyleForm from "./StyleForm"
import AidenPreview from "./AidenPreview"
import Button from "~/components/Button"
import { advisors } from "~/api"
import { useAuth } from "~/context/auth"
import { useDispatch } from "react-redux"
import { changeAdvisorStyle, fetchedAdvisorStyle }from "~/actions/advisors"
import LoadingSpinner from "~/components/Loading"
import { useTranslation } from "react-i18next"
import ButtonGroup from "~/components/ButtonGroup"
import useNotifications from "~/hooks/useNotifications"
import useIntroScreen from "~/hooks/useIntroScreen"
import Page, { PageHeader } from "../Page"
import { SidebarToggle } from "~/components/Layout"
import isEqual from "lodash/isEqual"
import NavPrompt from "~/components/NavPrompt"
import useMountEffect from "~/hooks/useMountEffect"

type LookAndFeelProps = {
  advisor: Advisor
}

const LookAndFeel: React.FC<LookAndFeelProps> = ({ advisor }) => {
  const [loading, setLoading] = React.useState(true)
  const dispatch = useDispatch()
  const { token, organisationId, logout } = useAuth()

  useMountEffect(() => {
    advisors
      .get(advisor.id, token!!, organisationId)
      .then((resp) => {
        dispatch(fetchedAdvisorStyle(advisor.id, resp.style))
      })
      .catch((error) => {
        if (error.status === 401) logout()
        return Promise.reject(error)
      })
      .finally(() => setLoading(false))
  })

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Form advisor={advisor} styleConfig={advisor.style} />
  )
}

export default LookAndFeel

const Form: React.FC<LookAndFeelProps & { styleConfig: StyleConfig }> = ({
  advisor,
  styleConfig,
}) => {
  const { token, organisationId, logout } = useAuth()
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const { t } = useTranslation()

  const { notify } = useNotifications()
  const originalStyle: StyleConfig = {
    ...styleConfig,
    fontHeader: styleConfig.fontHeader || styleConfig.font,
  }
  const [style, setStyle] = useState(originalStyle)
  const hasChanges = !isEqual(originalStyle, style)

  // This is used to trigger a side-effect in the styleform.
  // The issue is that when using the color picker if you drag the selection
  // in the conversion between hex and hsl causes trippy behaviour so separate state needs to be tracked.
  // There is probably a more elegant solution to this.
  const [resetCount, setResetCount] = useState(0)

  const { introScreen } = useIntroScreen(advisor.id)

  const reset = () => {
    setResetCount(resetCount + 1)
    setStyle(originalStyle)
  }

  const selectHeaderStyle = (value: HeaderStyle) => {
    if (style.header.includes(value)) {
      setStyle({ ...style, header: style.header.filter((v) => v !== value) })
    } else {
      setStyle({ ...style, header: [...style.header, value] })
    }
  }

  const changeFont = (value: string) => changeStyleStringVal("font", value)

  const changeFontHeader = (value: string) =>
    changeStyleStringVal("fontHeader", value)

  const changeStyleStringVal = React.useCallback(
    (key: keyof StyleConfig, value: string) =>
      setStyle((style) => ({ ...style, [key]: value })),
    [setStyle]
  )

  const changeStyleBoolVal = React.useCallback(
    (key: keyof StyleConfig, value: boolean) =>
      setStyle((style) => ({ ...style, [key]: value })),
    [setStyle]
  )

  const invalid = false

  const save = () => {
    let isMounted = true
    if (!invalid) {
      setSaving(true)
      advisors
        .changeStyle(advisor.id, style, token!!, organisationId)
        .then(() => {
          dispatch(changeAdvisorStyle(advisor.id, style))
          notify({
            text: t("general.feedback.changesSaved"),
            type: "success",
          })
        })
        .catch((error) => {
          if (error.status === 401) {
            logout()
          } else {
            notify({
              text: t("errors.error"),
              type: "error",
            })
          }
        })
        .finally(() => {
          if (isMounted) setSaving(false)
        })
    }
    return () => (isMounted = false)
  }

  const [open, setOpen] = React.useState(true)

  const toggleSidebar = React.useCallback(() => {
    setOpen((open) => !open)
  }, [])

  return (
    <>
      <NavPrompt
        pathSection={"/look-and-feel"}
        extraCondition={() => hasChanges}
        leftButtonLabel={t("advisorFlow.unsavedChangesConfirm")}
      >
        <p>{t("advisorFlow.unsavedChanges")}</p>
      </NavPrompt>
      <SidebarToggle onRight={true} open={open} toggle={toggleSidebar} />
      <SplitLayout
        setHeight
        sidebarOpen={open}
        left={
          <Page>
            <PageHeader>
              <h1>Look & Feel</h1>
              <p className="intro">{t("lookAndFeel.intro")}</p>
            </PageHeader>
            <AidenPreview
              advisorId={advisor.id}
              style={style}
              introScreenDisabled={introScreen.skipIntro}
            />
          </Page>
        }
        right={
          <SidebarContent>
            <div className="layout__main-actions-bar">
              <ButtonGroup style={{ margin: "0" }}>
                <Button onClick={reset}>{t("reset")}</Button>
                <Button primary onClick={save} disabled={!hasChanges}>
                  {saving ? <LoadingSpinner size="small" /> : t("save")}
                </Button>
              </ButtonGroup>
            </div>
            <StyleForm
              reset={resetCount}
              style={style}
              changeStyleStringVal={changeStyleStringVal}
              changeStyleBoolVal={changeStyleBoolVal}
              selectHeaderStyle={selectHeaderStyle}
              changeFont={changeFont}
              changeFontHeader={changeFontHeader}
            />
          </SidebarContent>
        }
      />
    </>
  )
}
