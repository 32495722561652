import { UUID } from "../types"
import { QuestionColor } from "~/store/questions/types"
import {
  InfoPageCustomerInfoConfig,
  NoResultsCustomerInfoConfig,
} from "../advisors"

export enum ScreenType {
  INTRO = "INTRO",
  ADVICE = "ADVICE",
  INFO = "INFO",
}

export enum ScreenMediaType {
  SCREEN_WITH_VIDEO = "SCREEN_VIDEO",
  SCREEN_WITH_IMAGE = "SCREEN_IMAGE",
  SCREEN_WITH_NO_MEDIA = "NO_MEDIA",
}
export enum QuestionMediaType {}

export type InfoScreen = {
  id: UUID
  type: ScreenType.INFO
  label: string
  title: string
  text: string
  info: string
  color: QuestionColor
  mediaType: ScreenMediaType
  next: UUID | undefined
  customerInfo: InfoPageCustomerInfoConfig
}

export type IntroScreen = {
  id: UUID
  type: ScreenType.INTRO
  title: string
  text: string
  info: string | null
  buttonText: string
  skipIntro: boolean
  next: UUID
}
export type AdviceScreen = {
  id: UUID
  type: ScreenType.ADVICE
  title: string
  text: string
  info: string
  buttonText: string
  bestMatchText: string
  noResultsTitle: string
  noResultsText: string
  noResultsCustomerInfo: NoResultsCustomerInfoConfig
  resultSize: number
  showProductLink: boolean
  sort: Sort
  extraField1?: CardField
  extraField2?: CardField
  extraField3?: CardField
  showBestMatch: boolean
  showMatchingIndicators: boolean
  showPrice: boolean
  showCurrency: boolean
  groupByVariants: boolean
  displayVariantsOfGroup: {
    enabled: boolean
    field: string | null
  }
  productScopeConfig: ProductScopeConfig

  showPriceSuffix: boolean
  showPricePrefix: boolean
  priceSuffix: string
  pricePrefix: string

  showBackorder: ShowBackorder

  callToActionType: "ProductLink" | "AddToCart"
}
export type ProductScopeConfig = {
  enabled: boolean
  bestFitTitle: string
  bestFitAltTitle: string
  goodFitTitle: string
  goodFitAltTitle: string
  badFitTitle: string
  badFitAltTitle: string
  emptyAdviceTitle: string
  emptyAdviceText: string
}

export type CardField = {
  column: string
  label?: string
  suffix?: string
}

export type Sort = {
  sortBy: string
  sortOrder: SortOrder
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ShowBackorder {
  ALWAYS = "ALWAYS",
  NEVER = "NEVER",
  ON_EMPTY_ADVICE = "ON_EMPTY_ADVICE",
}

export type ConversationScreens = {
  intro: IntroScreen
  advice: AdviceScreen
  infoScreens: Omit<InfoScreen, "InfoScreen">[]
}

export type ScreensState = Record<UUID, ConversationScreens>
