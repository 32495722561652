import React from "react"

type SidebarContextT = {
  open: boolean
  toggle: () => void
  setOpen: () => void
}

const SidebarContext = React.createContext({
  open: true,
  toggle: () => {},
  setOpen: () => {},
} as SidebarContextT)

const key = "aiden-saas-sidebar-open"

const SidebarContextProvider = (props) => {
  const stored = localStorage.getItem(key)
  const [open, setOpen] = React.useState(
    stored === "true" ? true : stored === null ? true : false
  )

  const toggle = React.useCallback(() => setOpen(!open), [open])

  React.useEffect(() => {
    localStorage.setItem(key, `${open}`)
  }, [open])

  return (
    <SidebarContext.Provider value={{ open, toggle, setOpen }} {...props}>
      {props.children}
    </SidebarContext.Provider>
  )
}

const useSidebar = () => React.useContext<SidebarContextT>(SidebarContext)
export { SidebarContextProvider, useSidebar }
