import { useHistory } from "react-router"
import Elm from "./ElmWrapper"
import { DataSource } from "~/types"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar } from "~/utils/environment"
import { questionLabelColors } from "~/themes"
import Page from "~/components/advisors/Page"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"
import { useSelector, useStore } from "react-redux"
import { ApplicationState } from "~/store"
import { getAdvisor } from "~/store/advisors/selectors"
import { StyleConfig } from "~/store/advisors"
import useAdviceScreen from "~/hooks/useAdviceScreen"

export const Matching: React.FC<{
  advisorId: string
  dataSource: DataSource
}> = (props) => {
  const history = useHistory()

  const styleConfig: StyleConfig | undefined = useSelector(
    (store: ApplicationState) => {
      return getAdvisor(store, props.advisorId)?.style
    }
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { token: authToken } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string
  const { featureFlags } = useFeatureFlags()

  const advisorLanguage: string = useSelector(
    (state: ApplicationState) =>
      getAdvisor(state, props.advisorId)?.locales[0] || "en-US"
  )

  const { productLabel } = useAdvisorContext()

  function setupPorts(ports) {
    ports.getFromLocal.subscribe(({ key }) => {
      const val = localStorage.getItem(key)
      ports.gotFromLocal.send({ key: key, value: val ? JSON.parse(val) : null })
    })

    ports.storeInLocal.subscribe(({ key, value }) => {
      localStorage.setItem(key, JSON.stringify(value))
    })

    ports.logError.subscribe((errorString) => {
      console.error(errorString)
    })

    const scrollIntoViewHandler = function (htmlId) {
      document.getElementById(htmlId)?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      })
    }

    ports.scrollIntoView?.subscribe(scrollIntoViewHandler)

    const aidenPreviewHandler = function ({ element, messageType, value }) {
      //@ts-ignore
      const iframe: HTMLIFrameElement | null = document.getElementById(element)

      iframe?.contentWindow?.postMessage({ messageType, value }, "*")
      iframe?.contentWindow?.postMessage(
        { messageType: "style-config", value: styleConfig },
        "*"
      )
    }
    ports.aidenPreview.subscribe(aidenPreviewHandler)

    const urlChangeHandler = function () {
      ports.urlChanged.send(window.location.href)
    }
    // Inform app of browser navigation (the BACK and FORWARD buttons)
    window.addEventListener("popstate", urlChangeHandler)

    // eslint-disable-next-line react-hooks/rules-of-hooks

    return () => {
      ports.storeInLocal.unsubscribe()
      ports.aidenPreview.unsubscribe(aidenPreviewHandler)
      ports.getFromLocal.unsubscribe()
      ports.scrollIntoView?.unsubscribe(scrollIntoViewHandler)
      window.removeEventListener("popstate", urlChangeHandler)
    }
  }

  return (
    <Page maxWidth>
      {/* // @ts-ignore */}
      <Elm
        src={ElmModule.Matching}
        flags={{
          advisorId: props.advisorId,
          dataSource: props.dataSource,
          authToken: authToken,
          apiUrl: apiUrl,
          advisorTitle: "advisorTitle",
          catalogueTitle: productLabel,
          productLabel: productLabel,
          url: window.location.href,
          questionLabelColors: questionLabelColors,
          packageAdviceEnabled: featureFlags.packageAdviceEnabled || false,
          productCheckEnabled: featureFlags.pdpIntegrationEnabled,
          advisorLanguage: advisorLanguage,
        }}
        ports={setupPorts}
      />
    </Page>
  )
}
