import React, { useState } from "react"
import styled from "styled-components"
import { Answer as AnswerStruct } from "../../../store/answers/types"
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc"
import { UUID } from "../../../store/types"
import { useTranslation } from "react-i18next"
import DragIndicator from "~/components/icons/DragIndicator"
import Button from "~/components/Button"
import {} from "~/components/Button"
import Add from "~/components/icons/Add"
import ElmAnswer from "~/elm-tsx-wrappers/Answer"
import NavPrompt from "~/components/NavPrompt"
import { v4 as uuid } from "uuid"

const AnswerWrapper = styled.div`
  margin: 0 0 2rem 0;

  .hints {
    margin-top: 2.5rem;
  }
`
const NewAnswerWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
`

const NeutralAnswerWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
`

const HandleWrapper = styled.div<{ disabled: boolean }>`
  padding: 1rem 0.5rem 1rem 0.25rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  color: ${(p) => (p.disabled ? p.theme.shade3 : p.theme.main)};
  cursor: ${(p) => (p.disabled ? `default` : `pointer`)};
  transition: color 0.2s ease-out;

  + div {
    flex: 1;
  }
`
const SortableElementWrapper = styled.div<{ disabled: boolean }>`
  > div {
    display: grid;
    grid-template-columns: 2.25rem minmax(0, 1fr);
    width: 100%;
    align-items: start;
    background: white;
    margin: 0.5em 0;
    border: 1px solid ${(p) => p.theme.shade3};
    border-radius: ${(p) => p.theme.borderRadius};
    transition: border-color 0.2s ease-out;

    &:hover {
      border-color: ${(p) => (p.disabled ? p.theme.shade3 : p.theme.secondary)};
    }
  }
`

const List = styled.div`
  margin-bottom: 1rem;
`

const Handle = SortableHandle(({ tabIndex, disabled }) => (
  <HandleWrapper tabIndex={tabIndex} disabled={disabled}>
    <DragIndicator />
  </HandleWrapper>
))

const AnswerItem = SortableElement(
  (props: {
    canDrag: boolean
    isDisabled: boolean
    value: React.ReactNode
  }) => {
    const { value, isDisabled, canDrag } = props
    return (
      <SortableElementWrapper disabled={isDisabled}>
        <div>
          <Handle tabIndex={1} disabled={!canDrag} />
          {value}
        </div>
      </SortableElementWrapper>
    )
  }
)

const AnswerList = SortableContainer(
  (props: {
    items: any[]
    advisorId: string
    questionId: string
    canDrag: boolean
    checkIsEditing: (string: string) => boolean
    updateIsEditing: (bool: boolean, id: string) => void
    setUnsavedChanges: (bool: boolean) => void
    isNewAnswer: (answerId: string) => boolean
    removeNewAnswer: () => void
  }) => {
    const {
      items,
      advisorId,
      questionId,
      checkIsEditing,
      updateIsEditing,
      setUnsavedChanges,
      isNewAnswer,
      removeNewAnswer,
      canDrag,
    } = props
    return (
      <List data-test-id="answers-editor" id="answers-editor">
        {items.map(
          (
            answer: AnswerStruct & { questionId: string; ref: any },
            index: number
          ) => (
            <AnswerItem
              disabled={checkIsEditing(answer.id) || !canDrag}
              isDisabled={checkIsEditing(answer.id)}
              canDrag={canDrag}
              key={`item-${index}-${answer.id}`}
              index={index}
              value={
                <ElmAnswer
                  answer={answer}
                  isNewAnswer={isNewAnswer(answer.id)}
                  advisorId={advisorId}
                  questionId={questionId}
                  setEditing={(bool) => updateIsEditing(bool, answer.id)}
                  isEditing={checkIsEditing(answer.id)}
                  setUnsavedChanges={setUnsavedChanges}
                  removeNewAnswer={removeNewAnswer}
                />
              }
            />
          )
        )}
      </List>
    )
  }
)

const useAnswerEditing = (
  questionId: string,
  answers: (AnswerStruct & { questionId: string })[]
) => {
  const [newAnswer, setNewAnswer] = useState<
    (AnswerStruct & { questionId: string }) | undefined
  >(
    answers.length
      ? undefined
      : {
          id: uuid(),
          questionId: questionId,
          title: "",
          helpText: "",
          matchingFeatureLabel: "",
          isNeutralAnswer: false,
        }
  )

  const [editingSet, setEditing] = useState<Set<string>>(
    new Set(!!newAnswer ? [newAnswer.id] : [])
  )

  // const editing: string[] = [...editingSet.values()]

  const checkIsEditing = (id: string) => editingSet.has(id)
  // [editing]
  // )

  const updateIsEditing = React.useCallback((bool: boolean, id: string) => {
    if (bool === true) {
      setEditing((editing) => new Set(editing.add(id)))
    } else {
      setEditing((editing) => {
        editing.delete(id)
        return new Set(editing)
      })
    }
  }, [])

  const addNewAnswer = React.useCallback(
    (isNeutral: boolean) => {
      const answerId = uuid()
      setEditing((editing) => new Set(editing.add(answerId)))
      setNewAnswer({
        id: answerId,
        questionId: questionId,
        title: "",
        helpText: "",
        matchingFeatureLabel: "",
        isNeutralAnswer: isNeutral,
      })
    },
    [questionId]
  )

  const isEditing = editingSet.size !== 0
  const removeNewAnswer = () => {
    setNewAnswer(undefined)
    setEditing((editing) => {
      // @ts-ignore
      editing.delete(newAnswer?.id)
      return new Set(editing)
    })
  }

  return {
    newAnswer,
    addNewAnswer,
    updateIsEditing,
    checkIsEditing,
    isEditing,
    removeNewAnswer,
  }
}

const AnswerComp: React.FC<{
  answers: (AnswerStruct & { questionId: string })[]
  advisorId: UUID
  questionId: UUID
  reorderAnswer: (oldPosition: number, newPosition: number) => void
}> = ({ answers, advisorId, questionId, reorderAnswer }) => {
  const onSortEnd = ({ oldIndex, newIndex }) =>
    oldIndex !== newIndex && reorderAnswer(oldIndex, newIndex)
  // eslint-disable-next-line
  const { t } = useTranslation()

  const [hasUnsavedChanges, setUnsavedChanges] = useState(false)

  const {
    newAnswer,
    addNewAnswer,
    updateIsEditing,
    checkIsEditing,
    isEditing,
    removeNewAnswer,
  } = useAnswerEditing(questionId, answers)

  const answersWithNew = [...answers, newAnswer].filter((a) => !!a)

  const mayAddNeutralAnswer =
    answers.filter((a) => a.isNeutralAnswer).length === 0 &&
    answers.length >= 1 &&
    answers.length < 8

  const hasNeutralAnswer = answers.filter((a) => a.isNeutralAnswer).length > 0

  return (
    <AnswerWrapper>
      <NavPrompt
        pathSection={"/answers"}
        extraCondition={() => hasUnsavedChanges}
        leftButtonLabel={t("answersEditor.unsavedChangesConfirm")}
      >
        <p>{t("answersEditor.unsavedChanges")}</p>
      </NavPrompt>
      <AnswerList
        advisorId={advisorId}
        items={answersWithNew}
        helperClass="sortableHelper"
        useDragHandle
        onSortEnd={onSortEnd}
        lockAxis={"y"}
        questionId={questionId}
        checkIsEditing={checkIsEditing}
        updateIsEditing={updateIsEditing}
        canDrag={!isEditing}
        setUnsavedChanges={setUnsavedChanges}
        isNewAnswer={(answerId: string) => newAnswer?.id === answerId}
        removeNewAnswer={removeNewAnswer}
        // func={{
        //   newAnswer,
        //   setNewAnswer: removeNewAnswer,
        // }}
      />
      {/* {displayHelp && <HelpText>{t("answersEditor.helpText")}</HelpText>} */}
      {answers.length >= 0 && answers.length < 8 && (
        <NewAnswerWrapper>
          <Button
            data-test-id="add-answer"
            round
            hasIconLeft
            onClick={() => addNewAnswer(false)}
            disabled={!!newAnswer}
          >
            <Add />
            {t("answersEditor.addAnswer")}
          </Button>
        </NewAnswerWrapper>
      )}
      {mayAddNeutralAnswer && (
        <NeutralAnswerWrapper>
          <Button
            isSmall
            link
            onClick={() => addNewAnswer(true)}
            disabled={!!newAnswer}
          >
            {t("answersEditor.addNeutralAnswer")}
          </Button>
        </NeutralAnswerWrapper>
      )}
      {hasNeutralAnswer && (
        <ul className="hints">
          <li>{t("answersEditor.neutralAnswer.hint1")}</li>
          <li>
            {t("answersEditor.neutralAnswer.hint2Start")}{" "}
            <b>{t("answersEditor.neutralAnswer.hint2Bold")}</b>{" "}
            {t("answersEditor.neutralAnswer.hint2End")}
          </li>
        </ul>
      )}
    </AnswerWrapper>
  )
}

export default AnswerComp
