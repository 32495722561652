import { useSelector } from "react-redux"
import { ApplicationState }from "~/store"

const useIntroScreen = (advisorId: string) => {
  const introScreen = useSelector(
    (state: ApplicationState) => state.screens[advisorId].intro
  )

  return { introScreen }
}

export default useIntroScreen
