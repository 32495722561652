export const CHANGE_QUESTION = "@@questions/CHANGE_QUESTION"
export const CHANGE_QUESTION_LABEL = "@@questions/CHANGE_QUESTION_LABEL"
export const CHANGE_QUESTION_COLOR = "@@questions/CHANGE_QUESTION_COLOR"
export const CHANGE_NEXT_QUESTION = "@@questions/CHANGE_NEXT_QUESTION"
export const CHANGE_QUESTION_TYPE = "@@questions/CHANGE_QUESTION_TYPE"
export const CHANGE_QUESTION_HELP_TEXT = "@@questions/CHANGE_QUESTION_HELP_TEXT"
export const CHANGE_NUMERIC_QUESTION_CONFIG =
  "@@questions/CHANGE_NUMERIC_QUESTION_CONFIG"
export const TOGGLE_MATCHING_FILTER = "@@questions/TOGGLE_MATCHING_FILTER"
export const DRILLDOWNCONFIG_CHANGED = "@@questions/DRILLDOWNCONFIG_CHANGED"
export const AUTO_DRILLDOWNCONFIG_CHANGED = "@@questions/AUTO_DRILLDOWNCONFIG_CHANGED"
export const ADD_QUESTION = "@@questions/ADD_QUESTION"
export const REMOVE_QUESTION = "@@questions/REMOVE_QUESTION"
export const REORDER_QUESTION = "@@questions/REORDER_QUESTION"
export const CHANGE_QUESTION_MEDIA_TYPE =
  "@@questions/CHANGE_QUESTION_MEDIA_TYPE"
export const MOVE_QUESTION = "@@questions/MOVE_QUESTION"
